import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ProjectStatusAssessments,
  ProjectStatusState,
} from './projectStatus.types';
import { projectStatusKey } from './projectStatus.const';
import { fetchProjectStatus } from './projectStatus.thunks';

const initialState: ProjectStatusState = {
  loading: false,
  error: null,
  assessments: [],
  statusReportsFolderLink: '',
  projectId: '',
};

export const projectStatusSlice = createSlice({
  name: projectStatusKey,
  initialState,
  reducers: {
    cleanProjectStatus(state) {
      state.assessments = [];
      state.statusReportsFolderLink = '';
      state.loading = false;
      state.error = null;
      state.projectId = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectStatus.pending, (state) => {
        state.loading = true;
        state.error = initialState.error;
      })
      .addCase(
        fetchProjectStatus.fulfilled,
        (state, { payload }: PayloadAction<ProjectStatusAssessments>) => {
          state.loading = false;
          state.assessments = payload.assessments;
          state.statusReportsFolderLink = payload.statusReportsFolderLink;
          state.projectId = payload.projectId;
        },
      )
      .addCase(fetchProjectStatus.rejected, (state) => {
        state.loading = false;
        state.error = new Error('Oops. Something went wrong. :(');
      });
  },
});

export const { cleanProjectStatus } = projectStatusSlice.actions;
