import React from 'react';
import Papaparse from 'papaparse';
import { Button } from '@mui/material';
import { downloadCsv } from '../../reports/Reports.utils';
import { GroupedMembers, Member } from '../../../types';
import { useAppSelector } from '../../../redux/hooks';
import {
  getAccountDummyMembers,
  getAccountDummyProjects,
  getEditableMode,
  getSelectedAccount,
  getSelectedAccountMembers,
  getSelectedProject,
} from '../../../redux/account';
import { BudgetsReportType } from '../types';
import { DateTime } from 'luxon';
export const CsvDownloadButton = () => {
  const accountMembers = useAppSelector(getSelectedAccountMembers);
  const editableMode = useAppSelector(getEditableMode);
  const account = useAppSelector(getSelectedAccount);
  const project = useAppSelector(getSelectedProject);
  const accountDummyProjects = useAppSelector(getAccountDummyProjects);
  const accountDummyMembers = useAppSelector(getAccountDummyMembers);

  const handleDownloadCSV = () => {
    const BUDGET_REPORT_KEYS = {
      AccountName: 'Account Name',
      MemberName: 'Member Name',
      ProjectName: 'Project Name',
      Stream: 'Stream',
      Seniority: 'Seniority',
      Margin: 'Margin',
      PayRate: 'Pay Rate',
      PayRangeMin: 'Compensation Range Min',
      PayRangeMax: 'Compensation Range Max',
      PayRangeApprove: 'Compensation Range Approve',
      BillRate: 'Bill Rate',
      RateCard: 'Rate Card',
      Location: 'Location',
      Allocation: 'Allocation',
    };

    const csvName = project
      ? project.name
      : account
      ? account.name
      : 'Margins-details';

    const transformMemberData = (member: BudgetsReportType) => ({
      memberName: member.memberName,
      projectName: member.projectName,
      stream: member.stream,
      seniority: member.seniority,
      billRate: member.billRate,
      grossMargin: member.grossMargin,
      payRate: member.payRate,
      rateCard: member.rateCard,
      payRangeMin: member.payRangeMin,
      payRangeMax: member.payRangeMax,
      payRangeApprove: member.payRangeApprove,
      location: member.location,
      allocation: member.allocation,
    });

    const data: BudgetsReportType[] = [];
    if (!accountMembers || account) {
      data.push(
        transformMemberData({
          accountName: account?.name,
          grossMargin: account?.grossMargin,
        }),
      );
    }

    if (accountMembers.length > 0) {
      accountMembers?.forEach((projectDetails) => {
        if (!project || projectDetails?.projectId === project?.id) {
          data.push(
            transformMemberData({
              projectName: projectDetails.groupName,
              grossMargin: projectDetails.margin,
            }),
          );
        }
      });

      accountMembers
        ?.flatMap((projectItem: GroupedMembers) =>
          projectItem.members
            .filter((member: Member) => !member.isHidden)
            .map((member: Member) => {
              const isNotRestricted = [
                member.grossMargin,
                member.compensationRate,
                member.compensationRange?.max,
                member.compensationRange?.min,
              ].some((val) => typeof val === 'number');

              data.push(
                transformMemberData({
                  projectName: projectItem?.groupName,
                  memberName: member?.fullName,
                  stream: member?.stream,
                  seniority: member?.seniority,
                  billRate: isNotRestricted ? member?.resource?.billRate : null,
                  payRate: isNotRestricted ? member?.compensationRate : null,
                  payRangeMin: member?.compensationRange?.min,
                  payRangeMax: member.compensationRange?.max,
                  payRangeApprove: member.compensationRange?.approvedMax,
                  rateCard: isNotRestricted
                    ? member?.companyRateCard?.billRate
                    : null,
                  grossMargin: isNotRestricted ? member?.grossMargin : null,
                  location: isNotRestricted ? member?.legalLocation : null,
                  allocation: isNotRestricted ? member?.allocation : null,
                }),
              );
            }),
        )
        .filter(Boolean);
    }

    if (accountDummyMembers?.length) {
      const projectIdMap = new Map();

      if (accountDummyProjects?.length)
        accountDummyProjects.forEach((dummyProject) => {
          projectIdMap.set(dummyProject.id, dummyProject);
        });

      accountMembers?.forEach((dummyProject) => {
        projectIdMap.set(dummyProject.projectId, dummyProject);
      });

      accountDummyMembers.forEach((member) => {
        if (!member.isHidden) {
          const projectDet = projectIdMap.get(member.projectId);
          const projectName = projectDet
            ? projectDet.name || projectDet.groupName
            : '';
          data.push(
            transformMemberData({
              projectName: projectName,
              memberName: `${member.seniority} ${member.stream} ${member.country}`,
              stream: member.stream,
              seniority: member.seniority,
              billRate: member.billRate,
              payRate: member.compensationRate,
              payRangeMin: member?.payRange[0],
              payRangeMax: member.payRange[1],
              grossMargin: member.grossMargin,
              rateCard: member.rateCard,
              location: member.country,
              allocation: member.allocation,
            }),
          );
        }
      });
    }

    if (!data) {
      return;
    }

    const reportData = data.map((row: BudgetsReportType) => ({
      [BUDGET_REPORT_KEYS.AccountName]: account?.name ?? '-',
      [BUDGET_REPORT_KEYS.MemberName]: row.memberName ?? '-',
      [BUDGET_REPORT_KEYS.ProjectName]: row.projectName,
      [BUDGET_REPORT_KEYS.Stream]: row.stream ?? '-',
      [BUDGET_REPORT_KEYS.Seniority]: row.seniority ?? '-',
      [BUDGET_REPORT_KEYS.Margin]: row.grossMargin ?? '-',
      [BUDGET_REPORT_KEYS.BillRate]: row.billRate ?? '-',
      [BUDGET_REPORT_KEYS.RateCard]: row.rateCard ?? '-' ?? '-',
      [BUDGET_REPORT_KEYS.PayRate]: row.payRate ?? '-',
      [BUDGET_REPORT_KEYS.PayRangeMin]: row.payRangeMin ?? '-',
      [BUDGET_REPORT_KEYS.PayRangeMax]: row.payRangeMax ?? '-',
      [BUDGET_REPORT_KEYS.PayRangeApprove]: row.payRangeApprove ?? '-',
      [BUDGET_REPORT_KEYS.Location]: row.location ?? '-',
      [BUDGET_REPORT_KEYS.Allocation]: row.allocation ?? '-',
    }));

    const parseOptions = {
      quotes: true,
      columns: Object.values(BUDGET_REPORT_KEYS),
    };

    const csvData = Papaparse.unparse(reportData, parseOptions);
    const currentDate = DateTime.now().toFormat('yyyy-MM-dd');
    const csvFilename = `${csvName}-${currentDate}.csv`;
    downloadCsv(csvData, csvFilename);
  };

  return (
    <Button
      sx={{
        color: editableMode ? 'inherit' : 'primary.main',
        margin: '2px',
      }}
      color="inherit"
      onClick={() => handleDownloadCSV()}
    >
      Export
    </Button>
  );
};
