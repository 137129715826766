import React, { PropsWithChildren } from 'react';
import { KPI_FREQUENCY } from '../../constants';
import { Typography, Stack, Tooltip } from '@mui/material';
import { PlainObject } from '../../types';
import { priceFormat } from './tableCellFormats';
import { QuarterIcon } from '../svg';

interface Props {
  value?: number;
  frequency: KPI_FREQUENCY;
  defaultEmptyValue?: string | JSX.Element;
  nowrap?: boolean;
  disabled?: boolean;
  fontWeight?: number;
  variant?: 'body1' | 'body2';
  alignRight?: boolean;
  trueup?: number | null;
}

export function BonusTableCell({
  value,
  frequency,
  defaultEmptyValue,
  nowrap,
  fontWeight,
  variant,
  disabled,
  children,
  alignRight,
  trueup,
  ...otherProps
}: PropsWithChildren<Props>): JSX.Element {
  const sx: PlainObject = nowrap ? { whiteSpace: 'nowrap' } : {};

  const props = disabled
    ? {
        color: 'text.disabled',
      }
    : {};
  const stackProps = alignRight
    ? {
        justifyContent: 'end',
      }
    : {};

  const icon = frequency === KPI_FREQUENCY.QUARTERLY && (
    <Tooltip title="Quarterly" enterDelay={300} placement="top">
      <div style={{ display: 'flex' }}>
        <QuarterIcon style={{ marginRight: '5px' }} />
      </div>
    </Tooltip>
  );

  return (
    <Stack direction="row" alignItems="center" {...stackProps}>
      {icon}
      <Typography
        fontWeight={fontWeight}
        variant={variant || 'body1'}
        {...otherProps}
        {...props}
        sx={sx}
      >
        {children}
        {priceFormat({ value, defaultEmptyValue, trueup })}
      </Typography>
    </Stack>
  );
}
