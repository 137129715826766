import { styled } from '@mui/material';

import { statusColors } from '../../../../../constants';

export const StyledStatusDiv = styled('div')`
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
  width: 100%;

  &.red {
    background-color: ${statusColors[0]};
  }

  &.yellow {
    background-color: ${statusColors[1]};
  }

  &.green {
    background-color: ${statusColors[2]};
  }
`;

export const IconStyle = {
  fontSize: '1.2rem',
  color: 'white',
  mr: '0.5rem',
  display: 'inline-block',
  verticalAlign: 'middle',
};
