import { createAsyncThunk } from '@reduxjs/toolkit';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { timeAndPeopleKey } from './timeAndPeople.const';
import axios from 'axios';
import { apiEndpoints } from '../../constants/api';
import {
  FetchProjectsAndMembersDataParams,
  ProjectAndMembersData,
  ProjectTimetracking,
} from './timeAndPeople.types';

export const fetchProjectsAndMembersData = createAsyncThunk(
  `${timeAndPeopleKey}/fetchProjectsAndMembersData`,
  async (
    { projectId }: FetchProjectsAndMembersDataParams,
    thunkAPI,
  ): Promise<ProjectAndMembersData> => {
    try {
      const { data } = await axios.get(
        `${apiEndpoints.projectAndMembers(projectId)}`,
      );

      return { projectId, ...data };
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(
          formatErrorMessage(err, 'Fetching project status has failed'),
          {
            variant: 'error',
          },
        ),
      );
      throw new Error(String(err));
    }
  },
);

export const fetchProjectTimetracking = createAsyncThunk(
  `${timeAndPeopleKey}/fetchProjectTimetracking`,
  async (
    {
      projectIds,
      startDate,
      endDate,
    }: {
      projectIds: string[];
      startDate: string;
      endDate: string;
    },
    thunkAPI,
  ): Promise<ProjectTimetracking> => {
    try {
      const { data } = await axios.get(
        `${apiEndpoints.projectTimetracking()}`,
        {
          params: {
            projectIds,
            startDate,
            endDate,
          },
        },
      );

      return data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(
          formatErrorMessage(err, 'Fetching project timetracking has failed'),
          {
            variant: 'error',
          },
        ),
      );
      throw new Error(String(err));
    }
  },
);
