import React, { useState } from 'react';
import {
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import { CustomSelectProps } from '../types';

function CustomSelect<T = string>({
  label,
  options,
  value,
  minWidth = 100,
  onChange,
  renderOption,
  className,
}: CustomSelectProps<T>) {
  const [selectedOption, setSelectedOption] = useState(value);

  const handleSelectedOption = (event: SelectChangeEvent) => {
    setSelectedOption(event.target.value);
    onChange?.({ name: event.target.name, value: event.target.value });
  };

  return (
    <FormControl variant="filled" sx={{ m: 1, minWidth: { minWidth } }}>
      <InputLabel size="small" sx={{ fontSize: (theme) => theme.spacing(1.5) }}>
        {label}
      </InputLabel>
      <Select
        name={label.toLocaleLowerCase()}
        size="small"
        onChange={handleSelectedOption}
        value={selectedOption}
        IconComponent={() => null}
        className={className}
      >
        {options.map(renderOption)}
      </Select>
    </FormControl>
  );
}

export default CustomSelect;
