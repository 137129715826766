import { TableBody, styled } from '@mui/material';

export const StyledTableBody = styled(TableBody)`
  tr:not(:last-child, .projectRow) {
    box-shadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)';
  }

  .projectRow {
    position: sticky;
    top: 56px;
    z-index: 2;
  }
`;
