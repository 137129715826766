export interface ClientAndProjectMenuData
  extends Omit<
    Project,
    | 'membersCount'
    | 'dm'
    | 'pdm'
    | 'latestTimelineAssessment'
    | 'latestGeneralAssessment'
    | 'latestScopeAssessment'
  > {
  membersCount?: number;
  isAddCommentIconShown?: boolean;
  latestTimelineAssessmentScore?: number;
  latestGeneralAssessmentScore?: number;
  latestScopeAssessmentScore?: number;
}

export enum AssessmentsTypesEnum {
  GENERAL = 'GENERAL',
  TIMELINE = 'TIMELINE',
  SCOPE = 'SCOPE',
}

export enum AssessmentsScoreColorEnum {
  RED = 0,
  YELLOW = 1,
  GREEN = 2,
}
// The order of assessment types here defines the order in the graph.
export const assessmentsTypes: AssessmentsTypesEnum[] = [
  AssessmentsTypesEnum.GENERAL,
  AssessmentsTypesEnum.TIMELINE,
  AssessmentsTypesEnum.SCOPE,
];

export interface AccountsStatus {
  id: string;
  name: string;
  department?: { id: string };
  deliveryManager: UserOverview;
  portfolioDeliveryManager: UserOverview;
  latestScopeAssessment?: ScopeTimelineAssessment;
  latestTimelineAssessment?: ScopeTimelineAssessment;
  latestGeneralAssessment?: GeneralAssessment;
  projects: Project[];
}

export interface Project
  extends Omit<
    AccountsStatus,
    'department' | 'projects' | 'deliveryManager' | 'portfolioDeliveryManager'
  > {
  membersCount: number;
  dm?: UserOverview;
  pdm?: UserOverview;
  latestScopeAssessment?: ScopeTimelineAssessment;
  latestTimelineAssessment?: ScopeTimelineAssessment;
  latestGeneralAssessment?: GeneralAssessment;
}

export interface ScopeTimelineAssessment {
  id: string;
  type: AssessmentsTypesEnum;
  forDate: Date;
  score: number;
  comment: string | null;
}

export interface ProjectMember {
  avatarUrl: string;
  city: string;
  country: string;
  createdAt: Date;
  deactivatedAt: Date | null;
  deletedAt: Date | null;
  departmentId: string;
  designation: string;
  email: string;
  fullName: string;
  id: string;
  legalLocation: string;
  updatedAt: Date | null;
  zohoEmployeeId: string;
  zohoId: string;
}

export interface GeneralAssessment {
  id: string;
  type: AssessmentsTypesEnum;
  forDate: Date;
  score: number;
  comment: string | null;
}

export interface UserOverview {
  designation: string | null;
  fullName: string;
  avatarUrl: string;
  zohoId: string;
  id: string;
  legalLocation: string;
}

export type AccountsStatusOrProject =
  | (AccountsStatus & { membersCount?: number })
  | Project;

export type AssessmentData = {
  [key: string]: AssessmentDateContent;
};

export type AssessmentDateContent = {
  [key in AssessmentsTypesEnum]: AssessmentTypeContent;
};

export type AssessmentTypeContent = Pick<
  ScopeTimelineAssessment,
  'id' | 'score' | 'comment'
>;
