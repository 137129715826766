import { LocationsState } from './locations.slice';
import { LocationType } from '../../types';
import { locationsStoreKey } from './locations.const';

interface State {
  [locationsStoreKey]: LocationsState;
}

const selectLocationsState = (state: State) => {
  return state[locationsStoreKey];
};

const getLocationsApiStatus = (state: State): string => {
  return selectLocationsState(state).apiStatus;
};

const getLocationsList = (state: State): LocationType[] => {
  return selectLocationsState(state).locationsList;
};

export const locationsSelectors = {
  getLocationsApiStatus,
  getLocationsList,
};
