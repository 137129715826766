import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants/api';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { kpiOwnersStoreKey } from './kpiOwners.const';

interface FetchKpiOwnersParams {
  kpiId: string;
  userId: string;
}

export const fetchKpiOwners = createAsyncThunk(
  `${kpiOwnersStoreKey}/fetchKpiOwners`,
  async ({ userId }: FetchKpiOwnersParams, thunkAPI) => {
    try {
      const response = await axios.get(apiEndpoints.kpiOwners(userId));
      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(
          formatErrorMessage(err, 'Fetching KPI owners failed'),
          {
            variant: 'error',
          },
        ),
      );
      throw new Error(String(err));
    }
  },
);
