import { COLORS } from '../../utils/colors';

export const customAlertStyles = {
  borderRadius: 0,
  boxShadow: '0 -1px 0 0 rgba(255,255,255,0.1)',
  color: COLORS.WHITE,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',

  '& .MuiAlert-action': {
    margin: '-6px 0 0',
  },

  '& .MuiAlert-message': {
    margin: 'auto',
    padding: '11px 0',
  },

  '&:before': {
    content: '""',
    zIndex: -1,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    transform: 'translate3d(0px, 10px, 0) scale(0.95)',
    filter: 'blur(20px)',
    opacity: '0.6',
    borderRadius: 'inherit',
  },

  '&.actionAlert': {
    background: COLORS.ACTION.PRIMARY,
  },
  '&.actionAlert:before': {
    background: COLORS.ACTION.PRIMARY,
  },
};

export const customAlertBlue = {
  background: COLORS.ACTION.PRIMARY,

  '&:before': {
    background: COLORS.ACTION.PRIMARY,
  },
};
