import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getSelectedAccount } from '../../redux/account';
import { useParams } from 'react-router-dom';
import { fetchProjectTimetracking } from '../../redux/timeAndPeople/timeAndPeople.thunks';
import { selectTimeAndPeoplePageData } from '../../redux/timeAndPeople/timeAndPeople.selectors';
import { ButtonMenuGroup } from '../../components';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import {
  getDateRange,
  TimetrackingPeriod,
} from '../../features/globalTracker/utils';
import { DropdownMenuOption } from '../../components/dropdown';
import {
  TimeAndPeopleTableColumns,
  TimetrackinDropdownOptions,
} from './timeAndPeople.constatns';
import { TimeAndPeopleGroup } from './components/timeAndPeopleGroup';
import JiraLogo from '../../assets/jira.svg';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { TimeSum } from './components/timeSum';
import { setSelectedPeriod } from '../../redux/timeAndPeople';
import { DataState } from '../../types/asyncData';

export const TimeAndPeople: FC = () => {
  const { projectId } = useParams();
  const account = useAppSelector(getSelectedAccount);
  const accountProjects = account?.projects;
  const proejct = accountProjects?.find((p) => p.id === projectId);
  const timeAndPeoplePageData = useAppSelector(selectTimeAndPeoplePageData);
  const projectDataFull = projectId
    ? timeAndPeoplePageData.projects[projectId]?.data
    : null;

  const timeTrackingData = timeAndPeoplePageData.timetrackings.data;
  const timeTrackingDataState = timeAndPeoplePageData.timetrackings.state;

  const selectedPeriod = timeAndPeoplePageData.selectedPeriod;
  const dispatch = useAppDispatch();

  const [selectedPeriodPrev, setSelectedPeriodPrev] =
    React.useState(selectedPeriod);

  useEffect(() => {
    const { startDate, endDate } = getDateRange(selectedPeriod.value);
    const projectIdsToFetch = projectId
      ? [projectId]
      : accountProjects?.map((p) => p.id) ?? [];

    const alreadyFetchedTimesForProjectsIds = timeTrackingData
      ? Object.keys(timeTrackingData)
      : [];

    if (
      (!projectIdsToFetch.every((id) =>
        alreadyFetchedTimesForProjectsIds.includes(id),
      ) ||
        selectedPeriodPrev !== selectedPeriod) &&
      timeTrackingDataState !== DataState.Pending
    ) {
      dispatch(
        fetchProjectTimetracking({
          projectIds: projectIdsToFetch,
          startDate,
          endDate,
        }),
      );
    }
    setSelectedPeriodPrev(selectedPeriod);
  }, [
    dispatch,
    projectId,
    selectedPeriod,
    selectedPeriodPrev,
    accountProjects,
    timeTrackingData,
    timeTrackingDataState,
  ]);

  return (
    <Stack
      width="100%"
      sx={{
        border: '1px solid #e0e0e0',
        height: 'fit-content',
        borderTop: 'none',
        borderRadius: '0px',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
        backgroundColor: 'white',
      }}
    >
      <Stack
        sx={{
          padding: '22px 16px',
          paddingBottom: '8px',
        }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3">Billed hours</Typography>
        <Stack flexDirection="row" gap="16px" alignItems="center">
          {projectId ? (
            projectDataFull?.jiraId && (
              <Button
                href={`https://jira.agileengine.com/projects/${projectDataFull.jiraId}`}
                target="_blank"
                size="small"
                startIcon={<img src={JiraLogo} alt="jira-logo" />}
              >
                Open JIRA
              </Button>
            )
          ) : (
            <Tooltip title="Total time" placement="top">
              <Stack flexDirection="row" gap="8px" alignItems="center">
                <AccessTimeIcon style={{ opacity: '0.6' }} />
                <TimeSum
                  isTimeTrackingLoading={
                    timeAndPeoplePageData.timetrackings.state ===
                    DataState.Pending
                  }
                  time={Object.values(timeTrackingData ?? {}).reduce(
                    (acc, project) =>
                      acc +
                      Object.values(project).reduce(
                        (acc, time) => acc + time,
                        0,
                      ),
                    0,
                  )}
                />
              </Stack>
            </Tooltip>
          )}

          <Box>
            <ButtonMenuGroup<DropdownMenuOption<TimetrackingPeriod>>
              id="selectedPeriod"
              options={TimetrackinDropdownOptions}
              value={selectedPeriod}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(_, value) => dispatch(setSelectedPeriod(value))}
              color="highlight"
              typographyVariant="body1"
              fullWidth
              menuWidth="240px"
              startIcon={<EventNoteRoundedIcon style={{ opacity: '0.6' }} />}
            />
          </Box>
        </Stack>
      </Stack>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {TimeAndPeopleTableColumns.map(({ name, width, textAlign }) => (
              <TableCell
                sx={{ width, fontSize: '14px', textAlign }}
                key={name}
                variant="head"
              >
                <Typography variant="body2" component="p">
                  {name}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {proejct ? (
            <TimeAndPeopleGroup
              project={proejct}
              timeAndPeoplePageData={timeAndPeoplePageData}
              isProjectPage={true}
            />
          ) : (
            (accountProjects ?? []).map((project) => (
              <TimeAndPeopleGroup
                key={project.id}
                project={project}
                timeAndPeoplePageData={timeAndPeoplePageData}
              />
            ))
          )}
        </TableBody>
      </Table>
    </Stack>
  );
};
