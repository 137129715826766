import { NotificationsState } from './notifications.slice';
import { NotificationType } from '../../types';
import { notificationsStoreKey } from './notifications.const';

interface State {
  [notificationsStoreKey]: NotificationsState;
}

const selectNotificationsState = (state: State) => {
  return state[notificationsStoreKey];
};

const getNotifications = (state: State): NotificationType[] => {
  return selectNotificationsState(state).notifications;
};

export const notificationsSelector = {
  getNotifications,
};
