import React, { useCallback, useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';
import Typography from '@mui/material/Typography';
import { API_STATUS } from '../../constants';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import {
  clearSubmissions,
  fetchSubmissions,
  submissionsSelectors,
  updateIndividualKpis,
  updateSharedKpis,
  submitSubmissions,
} from '../../redux/submissions';
import { PageWrapper } from '../../components';
import SubmissionsTable from './components/submissionsTable/SubmissionsTable';
import SubmissionsFooter from './components/submissionsFooter/SubmissionsFooter';
import { getGeneralKpiMetric } from '../../utils/getGeneralKpiMetric';
import { Box } from '@mui/material';
import { PageTitle } from '../../utils/pageTitles';
import Periods from '../periods/Periods';
import { useTitle } from '../../hooks/useTitle';

const Submissions = () => {
  const setPageTitle = useTitle();
  useEffect(() => {
    setPageTitle(PageTitle.monthlyResults);
  }, [setPageTitle]);
  const dispatch = useAppDispatch();

  const sharedKpis = useAppSelector(submissionsSelectors.getSharedKpis);
  const individualKpis = useAppSelector(submissionsSelectors.getIndividualKpis);
  const submissionsApiStatus = useAppSelector(
    submissionsSelectors.getSubmissionsApiStatus,
  );
  const kpisForSubmission = useAppSelector(
    submissionsSelectors.getKpisForSubmission,
  );
  const isLoading = submissionsApiStatus === API_STATUS.LOADING;
  const isNoMetrics = _isEmpty(sharedKpis) && _isEmpty(individualKpis);

  useEffect(() => {
    dispatch(fetchSubmissions());
    return () => {
      dispatch(clearSubmissions());
    };
  }, [dispatch]);

  const handleSharedKpisValueUpdate = useCallback(
    (value: number | undefined, index: number) => {
      dispatch(updateSharedKpis({ value, index }));
    },
    [dispatch],
  );

  const handleIndividualKpisValueUpdate = useCallback(
    (value: number | undefined, index: number) => {
      dispatch(updateIndividualKpis({ value, index }));
    },
    [dispatch],
  );

  const handleSubmit = useCallback(async () => {
    const body = { kpiValues: kpisForSubmission };
    await dispatch(submitSubmissions(body));
    await dispatch(fetchSubmissions());
  }, [dispatch, kpisForSubmission]);

  return (
    <PageWrapper isLoading={isLoading} maxWidth={'1500px'}>
      <Box display="flex">
        <Typography variant="h2" mb={2.5}>
          Metrics owned by me
        </Typography>
        <Box marginLeft="auto">
          <Periods />
        </Box>
      </Box>
      {!_isEmpty(sharedKpis) && (
        <React.Fragment>
          <Typography variant="body1">Shared metrics</Typography>
          <SubmissionsTable
            kpis={(sharedKpis || []).map((kpi) => getGeneralKpiMetric(kpi))}
            handleKpiValuesUpdate={handleSharedKpisValueUpdate}
            hideHeader
          />
        </React.Fragment>
      )}
      {!_isEmpty(individualKpis) && (
        <React.Fragment>
          <Typography variant="body1">Individual metrics</Typography>
          <SubmissionsTable
            kpis={(individualKpis || []).map((kpi) => getGeneralKpiMetric(kpi))}
            handleKpiValuesUpdate={handleIndividualKpisValueUpdate}
          />
        </React.Fragment>
      )}
      {isNoMetrics && (
        <Typography variant="body1">No metrics available</Typography>
      )}
      <SubmissionsFooter
        handleSubmit={handleSubmit}
        sharedKpis={sharedKpis}
        individualKpis={individualKpis}
        kpisForSubmission={kpisForSubmission}
      />
    </PageWrapper>
  );
};

export default Submissions;
