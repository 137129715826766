import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TuneIcon from '@mui/icons-material/TuneRounded';
import Divider from '@mui/material/Divider';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import { PopoverButton } from '../../../../components';

interface Props {
  handleEditKpiDefinition?: () => void;
  handleActivateDeactivateKpiDefinition: () => void;
  isActive: boolean;
}

export function KpiDefinitionMenuDropDown({
  handleEditKpiDefinition,
  handleActivateDeactivateKpiDefinition,
  isActive,
}: Props): JSX.Element {
  return (
    <PopoverButton
      buttonIcon={<MoreVertIcon fontSize="small" />}
      style={{ marginTop: '-10px', marginBottom: '-10px' }}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={handleEditKpiDefinition}>
            <ListItemIcon>
              <TuneIcon />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={handleActivateDeactivateKpiDefinition}>
            {isActive ? (
              <>
                <ListItemIcon>
                  <RemoveCircleRoundedIcon />
                </ListItemIcon>
                <ListItemText primary={'Deactivate'} />
              </>
            ) : (
              <>
                <ListItemIcon>
                  <PlayCircleRoundedIcon />
                </ListItemIcon>
                <ListItemText primary={'Activate'} />
              </>
            )}
          </ListItemButton>
        </ListItem>
      </List>
    </PopoverButton>
  );
}
