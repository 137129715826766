import { PaletteOptions } from '@mui/material/styles/createPalette';
import { orange, amber, red, lightGreen, blue } from '@mui/material/colors';

export default <PaletteOptions>{
  background: {
    default: '#F6F8FC',
  },
  primary: {
    main: blue['A200'],
  },
  action: {
    hover: blue[50],
  },
  highlight: {
    main: amber[100],
    dark: amber[500],
    hover: amber[200],
    error: red[50],
    success: lightGreen[50],
    neutral: '#F6F8FC',
    orange: '#fd7901',
  },
  warning: {
    main: orange[600],
    light: orange[400],
    dark: orange[800],
  },
  brand: {
    primary: '#FF7A00',
    highlight: '#fff2de',
    secondary: 'black',
  },
  scale10: (score: number) => {
    switch (score) {
      case 1:
        return '#FF1502';
      case 2:
        return '#FF4802';
      case 3:
        return '#FE7D00';
      case 4:
        return '#FFAF00';
      case 5:
        return '#ecd200';
      case 6:
        return '#c7d001';
      case 7:
        return '#B4DC02';
      case 8:
        return '#83C001';
      case 9:
        return '#4BA601';
      case 10:
        return '#198C00';
    }
  },
};
