import React, { FC } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import {
  MemberInfoCell,
  priceFormat,
} from '../../../../components/tableCellRenders';
import { KPI_TYPES } from '../../../../constants';
import { User, UserKpiAssignment } from '../../../../types';
import { paths } from '../../../../constants/paths';

interface Props {
  kpiType: KPI_TYPES;
  userKpiAssignments: UserKpiAssignment[];
}

export const KpiContractorsTable: FC<Props> = ({
  userKpiAssignments,
  kpiType,
}: Props) => {
  const renderBestWorstCell = (
    row: UserKpiAssignment,
    key: 'bestCase' | 'worstCase',
  ) => {
    return (
      <TableCell>
        {(kpiType === KPI_TYPES.FIXED_PERCENTAGE ||
          kpiType === KPI_TYPES.VARIABLE_PERCENTAGE) &&
        row[key]
          ? `${row[key]}%`
          : `${row[key] ?? ''}`}
      </TableCell>
    );
  };

  const renderContractor = (user: User) => {
    return (
      <TableCell>
        <MemberInfoCell
          userInfo={{ ...user, stream: user.designation }}
          disabled={!!user.deactivatedAt}
          link={`${paths.contractors}/${user.departmentId}/${user.id}`}
        />
      </TableCell>
    );
  };

  const sortedUserKpiAssignments = [...userKpiAssignments]?.sort((a, b) =>
    a.user?.fullName > b.user?.fullName ? 1 : -1,
  );

  return (
    <Table sx={{ minWidth: 612 }}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ pl: 3 }}>Contractor</TableCell>
          <TableCell>Bonus</TableCell>
          <TableCell>Worst</TableCell>
          <TableCell>Best</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{ overflowY: 'scroll' }}>
        {sortedUserKpiAssignments.map((row) => (
          <TableRow key={row.id}>
            {renderContractor(row.user)}
            <TableCell>{priceFormat({ value: row.bonusShare })}</TableCell>
            {renderBestWorstCell(row, 'worstCase')}
            {renderBestWorstCell(row, 'bestCase')}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
