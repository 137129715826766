import { StatusReportState } from './statusReport.types';
import { statusReportStoreKey } from './statusReport.const';

interface State {
  [statusReportStoreKey]: StatusReportState;
}

const selectStatusReportState = (state: State) => {
  return state[statusReportStoreKey];
};

export const getStatusReportLink = (state: State): string => {
  return selectStatusReportState(state).statusReportLink;
};

export const getStatusReportIsLoading = (state: State): boolean => {
  return selectStatusReportState(state).isLoading;
};
