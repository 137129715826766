const reportIssueKey = '/feedback';
const reportIncorrectKey = './feedback/incorrect-report';

export const reportIssue = () => {
  return reportIssueKey;
};

export const reportIncorrectReport = () => {
  return reportIncorrectKey;
};
