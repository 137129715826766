import { KpiDefinitionDataState } from './kpiDefinitions.slice';
import { kpiDefinitionsStoreKey } from './kpiDefinitions.const';
import { ContractorType, KpiDefinitionItem } from '../../types';
import {
  KpiDefinitionCategoryItem,
  KpiDefinitionItemWithOwners,
} from './kpiDefinitions.types';

export interface AppStateWithUserData {
  [kpiDefinitionsStoreKey]: KpiDefinitionDataState;
}

const selectKpiDefinitionsState = (state: AppStateWithUserData) => {
  return state[kpiDefinitionsStoreKey];
};

const getKpiDefinitionsLoading = (state: AppStateWithUserData): boolean => {
  return selectKpiDefinitionsState(state).loading;
};

const getKpiDefinitions = (
  state: AppStateWithUserData,
): KpiDefinitionItemWithOwners[] => {
  return selectKpiDefinitionsState(state).kpiDefinitions || [];
};

const getAvailableKpiOwners = (
  state: AppStateWithUserData,
): ContractorType[] | undefined => {
  return selectKpiDefinitionsState(state).availableKpiOwners;
};

const getCreatedKpiDefinition = (
  state: AppStateWithUserData,
): KpiDefinitionItem | undefined => {
  return selectKpiDefinitionsState(state).createdKpiDefinition;
};

const getCreatedKpiDefinitionLoading = (
  state: AppStateWithUserData,
): boolean => {
  return selectKpiDefinitionsState(state).createdKpiDefinitionLoading;
};

const getKpiDefinitionsCategories = (
  state: AppStateWithUserData,
): KpiDefinitionCategoryItem[] => {
  return selectKpiDefinitionsState(state).categories;
};

export const kpiDefinitionsSelectors = {
  getKpiDefinitionsLoading,
  getKpiDefinitions,
  getAvailableKpiOwners,
  getCreatedKpiDefinition,
  getCreatedKpiDefinitionLoading,
  getKpiDefinitionsCategories,
};
