import React, { FC, PropsWithChildren } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { BoxStyles } from './styles';

interface Props {
  period: string;
}

const AssessmentsDetailsWrapper: FC<PropsWithChildren<Props>> = ({
  period,
  children,
}) => {
  return (
    <Box sx={BoxStyles}>
      <Typography variant="h3" mb={1}>
        {period}
      </Typography>
      {children}
    </Box>
  );
};

export default AssessmentsDetailsWrapper;
