import React from 'react';
import { TableCell, Typography } from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell';
import { ColumnTextAlign } from '../../constants';
import { PlainObject } from '../../types';
// eslint-disable-next-line no-restricted-imports
import { Variant } from '@mui/material/styles/createTypography';

interface CustomTableCellProps extends TableCellProps {
  autoWidth?: boolean;
  pure?: boolean;
  disabled?: boolean;
  typographyVariant?: Variant;
  fontWeight?: number;
  nowrap?: boolean;
}

export const CustomTableCell = ({
  pure,
  autoWidth,
  children,
  width,
  disabled,
  fontWeight,
  color,
  typographyVariant = 'body1',
  nowrap,
  ...otherProps
}: CustomTableCellProps) => {
  const props = disabled
    ? {
        color: 'text.disabled',
      }
    : {};

  const sx: PlainObject = nowrap ? { whiteSpace: 'nowrap' } : {};

  return (
    <TableCell
      {...otherProps}
      sx={{ ...(otherProps.sx || {}), ...sx }}
      width={
        autoWidth || width !== undefined
          ? width
          : (otherProps.colSpan || 1) * 70
      }
      align={otherProps.align || ColumnTextAlign.left}
      colSpan={otherProps.colSpan || 1}
    >
      {pure ? (
        children
      ) : (
        <Typography
          color={color}
          fontWeight={fontWeight}
          variant={typographyVariant}
          {...props}
          sx={sx}
        >
          {children}
        </Typography>
      )}
    </TableCell>
  );
};
