import React, { FC, ReactNode, useCallback, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { separators } from '../../../constants';
import InputAdornment from '@mui/material/InputAdornment';
import { NumericFormat } from 'react-number-format';
import { SxProps, Theme } from '@mui/material';

interface RateFieldProps {
  id: string;
  name: string;
  value: number | null;
  handleChange: (data: { fieldName: string; value?: number }) => void;
  error?: boolean;
  helperText?: ReactNode;
  autoFocus?: boolean;
  inputSx?: SxProps<Theme>;
  disabled?: boolean;
}

export const RateField: FC<RateFieldProps> = ({
  id,
  name,
  value,
  handleChange,
  error,
  helperText,
  autoFocus,
  inputSx,
  disabled,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocussed, setIsFocused] = useState(false);

  const onFocus = useCallback(() => {
    setTimeout(() => {
      setIsFocused(true);
    }, 500);
  }, [setIsFocused]);

  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, [setIsFocused]);

  return (
    <NumericFormat
      onClick={() => {
        const element: any = inputRef.current;
        if (!isFocussed) {
          element.setSelectionRange(element.value.length, element.value.length);
        }
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      id={id}
      name={name}
      customInput={TextField}
      inputProps={{ maxLength: 6 }}
      thousandSeparator={separators.comma}
      autoComplete="off"
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        size: 'small',
        autoFocus,
        sx: {
          maxWidth: (theme: Theme) => theme.spacing(11),
          minWidth: (theme: Theme) => theme.spacing(9.5),
          ...inputSx,
        },
      }}
      value={value}
      onValueChange={({ floatValue }) =>
        handleChange({ fieldName: name, value: floatValue })
      }
      error={error}
      helperText={helperText}
      variant="filled"
      allowNegative={false}
      hiddenLabel
      disabled={disabled}
      inputRef={inputRef}
    />
  );
};
