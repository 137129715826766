import { CircularProgress } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  isTimeTrackingLoading: boolean;
  time: number | null;
}
export const TimeSum: FC<Props> = ({ isTimeTrackingLoading, time }) => {
  return (
    <>
      {isTimeTrackingLoading ? (
        <CircularProgress size="14px" color="success" />
      ) : (
        (time ?? 0) + ' h'
      )}
    </>
  );
};
