import React from 'react';
import { is } from '../../../../utils';
import { formatByDefinitionType } from '../../../../components/tableCellRenders';
import { KPI_TYPES, KPI_FREQUENCY } from '../../../../constants';
import { CustomTableCell } from '../../../../components/customTable';
import { KpiMetricStatKeyFields } from '../../../../types';
import { BonusTableCell } from '../../../../components/tableCellRenders/bonusTableCell';

interface Props {
  item?: KpiMetricStatKeyFields;
  isFirst?: boolean;
  isCurrent?: boolean;
  kpiType: KPI_TYPES;
  frequency: KPI_FREQUENCY;
}

export function KpiMetricBestWorstCells({
  item,
  isFirst,
  isCurrent,
  kpiType,
  frequency,
}: Props): JSX.Element {
  if (!item) {
    return (
      <CustomTableCell autoWidth colSpan={3} sx={{ whiteSpace: 'nowrap' }} />
    );
  }

  const font = isCurrent || isFirst ? 'body1' : 'body2';

  const bonus = (
    <CustomTableCell
      typographyVariant={font}
      width={80}
      sx={{ whiteSpace: 'nowrap' }}
      pure
      align="right"
    >
      <BonusTableCell
        variant={font}
        value={item.bonusShare}
        frequency={frequency}
        nowrap
        alignRight
      />
    </CustomTableCell>
  );

  if (is.fixedMetric(kpiType)) {
    return (
      <>
        {bonus}
        <CustomTableCell
          disabled
          width={80}
          colSpan={2}
          sx={{ whiteSpace: 'nowrap' }}
          align="right"
        >
          {isFirst ? 'Fixed' : ''}
        </CustomTableCell>
      </>
    );
  }

  return (
    <>
      {bonus}
      <CustomTableCell
        typographyVariant={font}
        width={80}
        sx={{ whiteSpace: 'nowrap' }}
        align="right"
      >
        {formatByDefinitionType(kpiType, item.worstCase, '')}
      </CustomTableCell>
      <CustomTableCell
        typographyVariant={font}
        width={80}
        sx={{ whiteSpace: 'nowrap' }}
        align="right"
      >
        {formatByDefinitionType(kpiType, item.bestCase, '')}
      </CustomTableCell>
    </>
  );
}
