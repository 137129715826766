import { Button, DialogActions } from '@mui/material';
import {
  BaseSingleInputFieldProps,
  DatePicker,
  DateValidationError,
  FieldSection,
  PickersActionBarProps,
  UseDateFieldProps,
} from '@mui/x-date-pickers';
import { useLocaleText } from '@mui/x-date-pickers/internals';
import { AnyAction } from '@reduxjs/toolkit';
import React, { useCallback, useState } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { formatDateTo_MMM_dd_yyyy } from '../../utils';
import { DateTime } from 'luxon';

function CustomDatePicker({
  dateFilterValueLocal,
  setDateFilter,
}: {
  dateFilterValueLocal: string | null;
  setDateFilter: {
    func: (value: string | null) => AnyAction;
    setGlobalFunc: () => AnyAction;
    clean: () => AnyAction;
    type: 'dispatch';
  };
}) {
  const dispatch = useAppDispatch();

  const [isDateFilterOpen, setDateFilterOpen] = useState<boolean>(false);

  const handleDateFilterOnClick = useCallback(() => {
    setDateFilterOpen((prev) => !prev);
  }, [setDateFilterOpen]);

  const handleDateFilterOnClose = useCallback(() => {
    setDateFilterOpen(false);
    dispatch(setDateFilter.setGlobalFunc());
  }, [dispatch, setDateFilter]);

  const onDateFilterChange = useCallback(
    (newValue: any) => {
      if (newValue instanceof Date && !isNaN(newValue as any)) {
        dispatch(
          setDateFilter.func(
            DateTime.fromJSDate(newValue).toFormat('yyyy-MM-dd'),
          ),
        );
      }
    },
    [dispatch, setDateFilter],
  );

  const onDateFilterClear = useCallback(() => {
    dispatch(setDateFilter.clean());
  }, [dispatch, setDateFilter]);

  return (
    <DatePicker
      open={isDateFilterOpen}
      views={['year', 'month', 'day']}
      openTo="year"
      format="MM dd yyyy"
      value={
        dateFilterValueLocal === null
          ? null
          : DateTime.fromFormat(dateFilterValueLocal, 'yyyy-MM-dd').toJSDate()
      }
      label={
        dateFilterValueLocal === null
          ? 'Now'
          : formatDateTo_MMM_dd_yyyy(
              DateTime.fromFormat(
                dateFilterValueLocal,
                'yyyy-MM-dd',
              ).toJSDate(),
            )
      }
      slots={{
        field: DateFiltersButton as any,
        actionBar: CustomActionBar as any,
      }}
      slotProps={{
        field: {
          setOpen: handleDateFilterOnClick,
          variant: dateFilterValueLocal ? 'contained' : 'text',
          color: dateFilterValueLocal ? 'white' : 'black',
        } as any,
        actionBar: {
          onClearCustom: onDateFilterClear,
        } as any,
      }}
      onChange={onDateFilterChange}
      onClose={handleDateFilterOnClose}
    />
  );
}

interface ButtonFieldProps
  extends UseDateFieldProps<any>,
    BaseSingleInputFieldProps<
      any | null,
      any,
      FieldSection,
      DateValidationError
    > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;

  variant: 'text' | 'outlined' | 'contained';
  color: 'black' | 'white';
}

function DateFiltersButton(props: ButtonFieldProps) {
  const { setOpen, variant, label, color, InputProps: { ref } = {} } = props;

  return (
    <Button
      variant={variant}
      sx={{ color }}
      ref={ref}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      {label ? `Date: ${label}` : 'Pick a date'}
    </Button>
  );
}

interface CustomPickersActionBarProps extends PickersActionBarProps {
  onClearCustom: () => void;
}

function CustomActionBar(props: CustomPickersActionBarProps) {
  const { onAccept, onCancel, onClearCustom, onClear, className } = props;
  const localeText = useLocaleText();

  return (
    <DialogActions className={className}>
      <Button
        data-mui-test="today-action-button"
        variant="text"
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        }}
        sx={{
          borderRadius: '100px',
          color: 'rgba(0, 0, 0, 0.87)',
          marginRight: 'auto',
        }}
        onClick={() => {
          onClear();
          onClearCustom();
        }}
        key={'today'}
      >
        {localeText.todayButtonLabel}
      </Button>
      <Button onClick={onCancel} key={'cancel'}>
        {localeText.cancelButtonLabel}
      </Button>
      <Button onClick={onAccept} key={'accept'}>
        {localeText.okButtonLabel}
      </Button>
    </DialogActions>
  );
}

export default CustomDatePicker;
