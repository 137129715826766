import {
  createSlice,
  CreateSliceOptions,
  Slice,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export const createSliceCustom = <
  State,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends string = string,
>(
  options: CreateSliceOptions<State, CaseReducers, Name>,
): {
  slice: Slice<State, CaseReducers, Name>;
  reducers: typeof options.reducers;
  extraReducers: typeof options.extraReducers;
} => {
  const reducers = options.reducers;
  const extraReducers = options.extraReducers;

  return {
    slice: createSlice(options),
    reducers,
    extraReducers,
  };
};
