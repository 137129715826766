import { DropdownMenuOption } from '../../components/dropdown';
import { TimetrackingPeriod } from '../../features/globalTracker/utils';

export const TimeAndPeopleTableColumns = [
  {
    name: 'Project / Team member',
    width: '38%',
    textAlign: 'left',
  },
  {
    name: 'Seniority',
    width: '10%',
    textAlign: 'left',
  },
  {
    name: 'Location',
    width: '10%',
    textAlign: 'left',
  },
  {
    name: 'Manager',
    width: '100%',
    textAlign: 'left',
  },
  {
    name: 'Hours',
    width: '10%',
    textAlign: 'right',
  },
];

export const TimetrackinDropdownOptions: DropdownMenuOption<TimetrackingPeriod>[] =
  [
    { label: 'Current month', value: TimetrackingPeriod.CURRENT_MONTH },
    { label: 'Previous month', value: TimetrackingPeriod.PREVIOUS_MONTH },
  ];
