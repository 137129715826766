import { Breadcrumb } from '../../components/breadcrumbs/types';
import {
  AccountDetail,
  DummmyProjectDetail,
  GroupedMembers,
  MarginContractor,
  MarginProject,
  Member,
  ProjectDetail,
} from '../../types';
import { ToContractor } from './types';
import { paths } from '../../constants/paths';
import { NOT_DEFINED_SCORE_VALUE, SCORE_TO_COLOR } from '../../constants';

export const scopeTooltipText = (
  latestScopeAssessmentScore: number | undefined,
) =>
  `Scope: ${
    SCORE_TO_COLOR[latestScopeAssessmentScore ?? NOT_DEFINED_SCORE_VALUE]
  }`;

export const timelineTooltipText = (
  latestTimelineAssessmentScore: number | undefined,
) =>
  `Timeline: ${
    SCORE_TO_COLOR[latestTimelineAssessmentScore ?? NOT_DEFINED_SCORE_VALUE]
  }`;

export const getMyPortfolioBreadcrumbs = (
  account: AccountDetail,
  project: ProjectDetail | null,
): Breadcrumb[] => {
  const breadcrumbs = [
    {
      id: account.id,
      name: account.name,
      isAccessible: project ? true : false,
      path: `${paths.portfolio}/${account.id}`,
    },
  ];

  if (project) {
    breadcrumbs.push({
      id: project.id,
      name: project.name,
      isAccessible: false,
      path: `${paths.portfolio}/${account.id}/${project.id}`,
    });
  }

  return breadcrumbs;
};

export const toMarginContractor = ({
  id,
  compensationRate,
  billRate,
  projectId,
  allocation,
  legalLocation,
}: ToContractor): MarginContractor => {
  return {
    id,
    compensationRate,
    resource: { billRate: billRate ?? 0 },
    allocation,
    projectId: projectId,
    legalLocation,
  };
};

export const prepareMarginProjectData = ({
  allMembers,
  memberId,
  isHidden,
  projectId,
}: {
  memberId: string;
  isHidden: boolean;
  projectId: string;
  allMembers: Member[];
}): MarginContractor[] => {
  return allMembers
    .filter((member) => {
      const isNotRestricted = [
        member.grossMargin,
        member.compensationRate,
        member.companyRateCard?.billRate,
        member.compensationRange?.max,
        member.compensationRange?.min,
      ].some((val) => typeof val === 'number');

      const isHiddenMember =
        member.id === memberId && member.projectId === projectId
          ? isHidden
          : member.isHidden;
      return isNotRestricted || isHiddenMember;
    })
    .map((member) => {
      const isHiddenMember =
        member.id === memberId ? isHidden : member.isHidden;
      return isHiddenMember
        ? {
            id: member.id,
            compensationRate: 0,
            resource: { billRate: 0 },
            isHidden: true,
            allocation: member.dummy?.allocation ?? 100,
            legalLocation: member.legalLocation,
          }
        : {
            id: member.id,
            compensationRate: member.dummy?.compensationRate ?? 0,
            resource: { billRate: member.dummy?.billRate ?? 0 },
            allocation: member.dummy?.allocation ?? 100,
            legalLocation: member.legalLocation,
          };
    });
};

export const prepareMarginAccountData = ({
  accountMembers,
  accountDummyProjects,
  memberId,
  isHidden,
  projectId,
}: {
  memberId: string;
  isHidden: boolean;
  projectId: string;
  accountMembers: GroupedMembers[];
  accountDummyProjects?: DummmyProjectDetail[];
}): MarginProject[] => {
  const projectFromMembers = accountMembers.map((projectItem) => ({
    id: projectItem.projectId ?? '',
    contractors: projectItem.members
      .filter((member) => {
        const isNotRestricted = [
          member.grossMargin,
          member.compensationRate,
          member.compensationRange?.max,
          member.compensationRange?.min,
        ].some((val) => typeof val === 'number');
        const billRate = member.companyRateCard?.billRate;
        const isHiddenMember =
          member.id === memberId && projectItem.projectId === projectId
            ? isHidden
            : member.isHidden;
        return (isNotRestricted && billRate !== 0) || isHiddenMember;
      })
      .map((member) => {
        const isHiddenMember =
          member.id === memberId && projectItem.projectId === projectId
            ? isHidden
            : member.isHidden;

        return isHiddenMember
          ? {
              id: member.id,
              compensationRate: 0,
              resource: { billRate: 0 },
              isHidden: true,
              allocation: member.dummy?.allocation ?? 100,
              legalLocation: member.legalLocation,
            }
          : {
              id: member.id,
              compensationRate: member.dummy?.compensationRate ?? 0,
              resource: { billRate: member.dummy?.billRate ?? 0 },
              allocation: member.dummy?.allocation ?? 100,
              legalLocation: member.legalLocation,
            };
      }),
  }));

  const dummyProjects =
    accountDummyProjects?.map((dummyProject) => ({
      id: dummyProject.id,
      contractors: [],
    })) ?? [];

  return [...projectFromMembers, ...dummyProjects];
};
