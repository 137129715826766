import { PayloadAction } from '@reduxjs/toolkit';
import {
  AccountsPageFiltersInitialState,
  accountsStoreKey,
  groupAccontsByMap,
  sortAccountsMap,
} from './accounts.const';
import { saveLinks, fetchAccounts } from './accounts.thunks';
import { AccountOverview, SortOrder } from '../../types';
import {
  AccountsState,
  SortAccountsBy,
  GroupAccountsBy,
  AccountsSortPayloadType,
  AccountsPageFilters,
  saveLinkPayloadType,
} from './accounts.types';
import { createSliceCustom } from '../../utils/tests/createSliceCustom';

export const initialState: AccountsState = {
  loading: false,
  error: null,
  accounts: [],
  groupBy: GroupAccountsBy.AccountName,
  sortBy: SortAccountsBy.Margin,
  sortOrder: SortOrder.Ascending,

  uniqueFilterLists: {
    pdms: [],
    dms: [],
    cps: [],
  },
  isUniqueFilterListsDefined: false,

  dateFilterValue: null,
  dateFilterValueLocal: null,
  filters: AccountsPageFiltersInitialState,
};

export const returns = createSliceCustom({
  name: accountsStoreKey,
  initialState,
  reducers: {
    setAccountsPageFilters: (
      state,
      { payload }: PayloadAction<Partial<AccountsPageFilters>>,
    ) => {
      state.filters = { ...state.filters, ...payload };
    },
    resetAccountsPageFilters: (state) => {
      state.filters = initialState.filters;
    },
    setGroupBy: (
      state,
      { payload: groupBy }: PayloadAction<GroupAccountsBy>,
    ) => {
      state.groupBy = groupBy;
      const groupByFn = groupAccontsByMap[groupBy];
      state.accounts = groupByFn(state.accounts);
    },
    setSortBy: (state, { payload }: PayloadAction<AccountsSortPayloadType>) => {
      state.sortBy = payload.sortBy;
      state.sortOrder = payload.sortOrder ?? SortOrder.Ascending;

      const sortingFn = sortAccountsMap[state.sortBy];
      const accounts = state.accounts.sort(sortingFn);

      state.accounts =
        state.sortOrder === SortOrder.Ascending ? accounts : accounts.reverse();
    },

    setDateFilterValueLocal: (
      state,
      { payload }: PayloadAction<string | null>,
    ) => {
      state.dateFilterValueLocal = payload;
    },
    setDateFilterValue: (state) => {
      state.dateFilterValue = state.dateFilterValueLocal;
    },

    cleanDateFilterValues: (state) => {
      state.dateFilterValue = null;
      state.dateFilterValueLocal = null;
    },
    cleanAccountsTableRow: (
      state,
      { payload }: PayloadAction<{ errMessage: string | null }>,
    ) => {
      state.accounts = [];
      state.accountListFetchErrorMessage = payload.errMessage;
    },
  },
  extraReducers: (builder) => {
    // fetch data
    builder
      .addCase(fetchAccounts.pending, (state) => {
        state.loading = true;
        state.error = initialState.error;
        state.accountListFetchErrorMessage = null;
      })
      .addCase(
        fetchAccounts.fulfilled,
        (state, { payload }: PayloadAction<AccountOverview[]>) => {
          state.loading = false;

          const sortingFn = sortAccountsMap[state.sortBy];
          const accounts = payload.sort(sortingFn);

          state.accounts =
            state.sortOrder === SortOrder.Ascending
              ? accounts
              : accounts.reverse();

          if (state.isUniqueFilterListsDefined === false) {
            state.isUniqueFilterListsDefined = true;

            state.uniqueFilterLists.pdms = (() => {
              const filterArray: any = [];
              const uniqueItems: any = [];

              accounts.forEach((item) => {
                if (
                  item.portfolioDeliveryManager &&
                  !uniqueItems.includes(item.portfolioDeliveryManager.id)
                ) {
                  uniqueItems.push(item.portfolioDeliveryManager.id);
                  filterArray.push({
                    value: item.portfolioDeliveryManager.id,
                    label: item.portfolioDeliveryManager.fullName,
                  });
                }
              });

              return filterArray;
            })();

            state.uniqueFilterLists.dms = (() => {
              const filterArray: any = [];
              const uniqueItems: any = [];

              accounts.forEach((item) => {
                if (
                  item.deliveryManager &&
                  !uniqueItems.includes(item.deliveryManager.id)
                ) {
                  uniqueItems.push(item.deliveryManager.id);
                  filterArray.push({
                    value: item.deliveryManager.id,
                    label: item.deliveryManager.fullName,
                  });
                }
              });

              return filterArray;
            })();

            state.uniqueFilterLists.cps = (() => {
              const filterArray: any = [];
              const uniqueItems: any = [];

              accounts.forEach((item) => {
                if (
                  item.clientPartner &&
                  !uniqueItems.includes(item.clientPartner.id)
                ) {
                  uniqueItems.push(item.clientPartner.id);
                  filterArray.push({
                    value: item.clientPartner.id,
                    label: item.clientPartner.fullName,
                  });
                }
              });

              return filterArray;
            })();
          }
        },
      )
      .addCase(fetchAccounts.rejected, (state) => {
        state.loading = false;
        state.error = {
          message: `Oops. Something went wrong. :(`,
        };
      })
      .addCase(
        saveLinks.fulfilled,
        (state, { payload }: PayloadAction<saveLinkPayloadType>) => {
          state.accounts = state.accounts.map((account) => {
            if (account.id === payload.accountId) {
              return {
                ...account,
                compensationToolLink: payload.compensationToolLink ?? '',
                sharedDriveLink: payload.sharedDriveLink ?? '',
                customLinks: payload.customLinks ?? [],
              };
            } else {
              return account;
            }
          });
        },
      )
      .addCase(saveLinks.rejected, (state) => {
        state.error = {
          message: `Oops. Something went wrong. :(`,
        };
      });
  },
});

export const accountsSlice = returns.slice;
export const accountsReducers = returns.reducers;
export const accountsExtraReducers = returns.extraReducers;
