import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import emptyStateLogo from '../../../../../assets/noReports.svg';

export function NoReportsComponent() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: `${theme.spacing(21)} ${theme.spacing(51)}`,
        h2: {
          width: theme.spacing(36),
        },
      }}
    >
      <img src={emptyStateLogo} alt={'no results'} />
      <Typography variant="h2" color="text.secondary" pt={1}>
        No results for these locations and departments
      </Typography>
    </Box>
  );
}
