import { createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { ReportIssueState } from './reportIssue.types';
import { reportIssue, reportIncorrectReport } from './reportIssue.thunks';
import { reportIssueStoreKey } from './reportIssue.const';

const initialState: ReportIssueState = {
  apiStatus: API_STATUS.IDLE,
  incorrectReportStatus: API_STATUS.IDLE,
};

export const reportIssueSlice = createSlice({
  name: reportIssueStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(reportIssue.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(reportIssue.fulfilled, (state) => {
        state.apiStatus = API_STATUS.IDLE;
      })
      .addCase(reportIssue.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });

    builder
      .addCase(reportIncorrectReport.pending, (state) => {
        state.incorrectReportStatus = API_STATUS.LOADING;
      })
      .addCase(reportIncorrectReport.fulfilled, (state) => {
        state.incorrectReportStatus = API_STATUS.IDLE;
      })
      .addCase(reportIncorrectReport.rejected, (state) => {
        state.incorrectReportStatus = API_STATUS.FAILED;
      });
  },
});
