import React, { PropsWithChildren } from 'react';
import { Box, useTheme } from '@mui/material';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import { avatarRadius } from '../../theme';

interface PeopleIconWrapper {
  isImage?: boolean;
}

export function PeopleIconWrapper({
  children,
  isImage,
}: PropsWithChildren<PeopleIconWrapper>): JSX.Element {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        '&:not(.MuiTypography-root)': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
      }}
    >
      <Box
        sx={{
          color: palette.secondary.contrastText,
          backgroundColor: palette.secondary.main,

          ...(isImage
            ? {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 40,
                height: 40,
                borderRadius: avatarRadius,
              }
            : {
                display: 'inline-block',
                verticalAlign: 'middle',
                margin: '-2px 4px 0 0',
                width: '14px',
                height: '14px',
                borderRadius: '4px',
                textAlign: 'center',
                lineHeight: '14px',
              }),
        }}
      >
        <GroupRoundedIcon style={{ fontSize: 10 }} />
      </Box>
      <Box sx={{ paddingLeft: isImage ? 2 : 0 }}>{children}</Box>
    </Box>
  );
}
