import { User } from './user';
import { SharedKpiModel, IndividualKpi } from './contractors';
import { KPI_FREQUENCY, KPI_SCOPES, KPI_TYPES } from '../constants';

interface DepartmentType {
  id: string;
  name: string;
  zohoId: string;
  parentId: string | null;
  managerId: string | null;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
}

export interface ContractorKpiStats {
  total: number;
  periods: ContractorKpiStatsPeriod[];
}

export interface ContractorKpiStatsPeriod {
  period: number;
  payout: number;
}

export interface ContractorType extends User {
  department: DepartmentType;
  individualKpis: IndividualKpi[];
  sharedKpis: SharedKpiModel[];
  kpiStats?: ContractorKpiStats;
  breadcrumbs: BreadcrumbDto[];
  canModifyMetrics: boolean;
}
export interface BreadcrumbDto {
  id: string;
  name: string;
  parentId: string;
  isAccessible: boolean;
}

export enum DepartmentView {
  TEAMS = 'TEAMS',
  APPROVE = 'APPROVE',
  ADJUST = 'ADJUST',
}

export interface BonusPlanSegment {
  from: number;
  to: number | null;
}

export interface BaseBonusPlanModel {
  id: string;
  period: number;
  userKpiAssignmentId: string;
  bonusShare: number;
  isLast: boolean | null;
}

type BonusShare = Pick<BaseBonusPlanModel, 'period' | 'bonusShare'>;

export interface BonusEntry
  extends Omit<BaseBonusPlanModel, 'userKpiAssignmentId'> {
  assignmentId: string;
  assignmentName: string;
}

export interface BonusHistory extends BonusShare {
  bonusDetails: BonusEntry[];
}

export interface UserKpisBonusShare {
  globalUserId: string;
  kpiBonusshares: BonusShare[];
  bonusShareDetails: BonusEntry[][];
}

interface MonthlyHistoricalKpiValue {
  period: number;

  // Undefined in case the value is from current period, and auto-populated in 'historicalAssignments' useMemo
  value?: number;

  worstCase?: number | null;

  bestCase?: number | null;

  bonusShare: number;
}

export interface QuarterlyHistoricalKpiValue
  extends Omit<MonthlyHistoricalKpiValue, 'bonusShare'> {
  bonusPlan: BonusShare[];
}

export interface UpdateHistoricalAssignment {
  id: string;
  monthlyKpiValues?: MonthlyHistoricalKpiValue[];
  quarterlyKpiValues?: QuarterlyHistoricalKpiValue[];
}

export interface HistoricalDataUpdateBody {
  userId: string;
  assignments: UpdateHistoricalAssignment[];
}

export interface HistoricalAssignmentEntry {
  id: string;
  kpiValues: (MonthlyHistoricalKpiValue | QuarterlyHistoricalKpiValue)[];
  name: string;
  scope: KPI_SCOPES;
  frequency: KPI_FREQUENCY;
  type: KPI_TYPES;
  kpiDefinitionId: string;
}
