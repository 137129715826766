import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Slide,
  TableCell,
  TextField,
  Theme,
  styled,
} from '@mui/material';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import {
  HandleHideDummyProps,
  MemberRatesUpdate,
  MemberRatesUpdateForm,
  UpdateDummyMemberProps,
} from './types';
import { StyledTableRow } from './StyledTableRow';
import CustomSelect from './components/CustomSelect';
import { CustomBudgetBox, CustomBudgetCell } from './components';
import { MarginHighlightCell } from './components/MarginHighlightCell';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { useFormik } from 'formik';
import { compensationMarginsValidationSchema } from './CompensationMargins.schema';
import { debounce } from '../../utils/debounce';
import { fetchCompensationRange } from '../../redux/account';
import { useAppDispatch } from '../../redux/hooks';
import { DummyMember, LegalLocationType, MarginHighlight } from '../../types';
import {
  legalLocations,
  streams,
  getSeniorities,
} from './components/CustomSelect.const';
import { NumericFormat } from 'react-number-format';
import { RateField } from './components/RateField';

const CustomValueSelect = styled((props: any) => (
  <CustomSelect {...props}></CustomSelect>
))({
  '.MuiFilledInput-input': {
    minWidth: '50px',
  },
});

export interface CompensationDummyMemberRowProps {
  id: string;
  current?: Element | null;
  isLastRow: boolean;
  dummyMember: DummyMember;
  marginHighlights: MarginHighlight[];
  handleRatesChange: (values: MemberRatesUpdate) => void;
  handleUpdateDummyMembers: (values: UpdateDummyMemberProps) => void;
  handleHideDummyMember: (data: HandleHideDummyProps) => void;
  projectId: string;
}

export const CompensationDummyMemberRow = ({
  id: memberId,
  isLastRow,
  marginHighlights,
  current,
  dummyMember,
  handleHideDummyMember,
  handleRatesChange,
  handleUpdateDummyMembers,
  projectId,
}: CompensationDummyMemberRowProps) => {
  const dispatch = useAppDispatch();
  const formik = useFormik<MemberRatesUpdateForm>({
    initialValues: {
      compensationRate: 0,
      billRate: dummyMember.billRate ?? 20,
      allocation: dummyMember.allocation ?? 100,
      memberId,
      isHidden: dummyMember.isHidden ?? false,
    },
    validationSchema: compensationMarginsValidationSchema,
    onSubmit: (values) => {
      handleRatesChange({
        ...values,
        projectId,
      });
    },
  });

  useEffect(() => {
    formik.setFieldValue('billRate', dummyMember.billRate);
    formik.setFieldValue('compensationRate', dummyMember.compensationRate);
    // eslint-disable-next-line
  }, [dummyMember.billRate, dummyMember.compensationRate]);

  useEffect(() => {
    handleRatesChange({
      billRate: dummyMember.billRate ?? 0,
      isHidden: dummyMember.isHidden,
      memberId: dummyMember.id,
      allocation: dummyMember.allocation ?? 100,
      compensationRate: dummyMember.compensationRate ?? 0,
      payRange: dummyMember.payRange,
      projectId,
    });
    // eslint-disable-next-line
  }, [
    dummyMember.billRate,
    dummyMember.isHidden,
    dummyMember.id,
    dummyMember.compensationRate,
    dummyMember.payRange,
    dummyMember.allocation,
  ]);

  useEffect(() => {
    if (dummyMember.country && dummyMember.stream && dummyMember.seniority) {
      dispatch(
        fetchCompensationRange({
          memberId: memberId,
          legalLocation: dummyMember.country,
          seniority: dummyMember.seniority,
          stream: dummyMember.stream,
          employmentType: dummyMember.employmentType,
        }),
      );
    }
  }, [
    dispatch,
    dummyMember.country,
    dummyMember.stream,
    memberId,
    dummyMember.seniority,
    dummyMember.employmentType,
  ]);

  const debouncedSubmit = useCallback(
    () => debounce(formik.submitForm, 500),
    [formik],
  );

  const onRateChange = useCallback(
    (data: { fieldName: string; value?: number }) => {
      handleUpdateDummyMembers({
        memberId: memberId,
        fieldName: data.fieldName,
        value: data.value,
        projectId,
      });
      formik.setFieldValue(data.fieldName, data.value);
      debouncedSubmit();
    },
    [handleUpdateDummyMembers, formik, debouncedSubmit, memberId, projectId],
  );

  const onHandleSelect = useCallback(
    (data: { name: string; value: string | number }) => {
      handleUpdateDummyMembers({
        memberId: memberId,
        fieldName: data.name,
        value: data.value,
        projectId,
      });
    },
    [handleUpdateDummyMembers, memberId, projectId],
  );

  const onHandleSelectStream = useCallback(
    (data: { name: string; value: string | number }) => {
      handleUpdateDummyMembers({
        memberId: memberId,
        fieldName: 'stream',
        value: data.value,
        projectId,
      });
      handleUpdateDummyMembers({
        memberId: memberId,
        fieldName: 'seniority',
        value: undefined,
        projectId,
      });
    },
    [handleUpdateDummyMembers, memberId, projectId],
  );
  const onHandleSelectLocation = useCallback(
    (data: { name: string; value: string | number }) => {
      const location = legalLocations.find((x) => x.id === data.value);
      handleUpdateDummyMembers({
        memberId: memberId,
        fieldName: 'country',
        value: location?.country,
        projectId,
      });
      handleUpdateDummyMembers({
        memberId: memberId,
        fieldName: 'employmentType',
        value: location?.employmentType,
        projectId,
      });
    },
    [handleUpdateDummyMembers, memberId, projectId],
  );

  const handleAllocationChange = useCallback(
    (value: string | undefined) => {
      formik.setFieldValue('allocation', Number(value));
      handleUpdateDummyMembers({
        memberId: memberId,
        fieldName: 'allocation',
        value: Number(value),
        projectId,
      });
      debouncedSubmit();
    },
    [formik, handleUpdateDummyMembers, memberId, debouncedSubmit, projectId],
  );

  const selectedLocation = useMemo(
    () =>
      legalLocations.find(
        (x) =>
          x.country === dummyMember.country &&
          x.employmentType === dummyMember.employmentType,
      ),
    [dummyMember.country, dummyMember.employmentType],
  );

  const seniorities = useMemo(
    () => getSeniorities(dummyMember.stream),
    [dummyMember.stream],
  );

  const columnWidth = (theme: Theme) => theme.spacing(15);
  const nameColumnWidth = 370;

  return (
    <Slide direction="down" in container={current}>
      <StyledTableRow isLastRow={isLastRow} isHidden={dummyMember.isHidden}>
        <TableCell
          size="small"
          variant="body"
          sx={{
            width: nameColumnWidth,
            maxWidth: nameColumnWidth,
            textOverflow: 'ellipsis',
            borderStyle: 'border-box',
            borderBottom: 0,
          }}
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Avatar>
              <StarBorderRoundedIcon />
            </Avatar>
            <CustomValueSelect
              onChange={onHandleSelectLocation}
              minWidth={80}
              label="Country"
              options={legalLocations}
              value={selectedLocation?.id ?? ''}
              renderOption={(option: LegalLocationType, index: number) => (
                <MenuItem key={index} value={option.id}>
                  {option.country}
                  {option.employmentType && (
                    <> &bull; {option.employmentType}</>
                  )}
                </MenuItem>
              )}
            />
            <CustomValueSelect
              onChange={onHandleSelectStream}
              minWidth={80}
              label="Role"
              options={streams}
              value={dummyMember.stream ?? ''}
              renderOption={(option: string, index: number) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              )}
            />
            <CustomValueSelect
              minWidth={100}
              label="Seniority"
              options={seniorities}
              onChange={onHandleSelect}
              value={dummyMember.seniority ?? ''}
              renderOption={(option: string, index: number) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              )}
            />
          </Box>
        </TableCell>
        <MarginHighlightCell
          align="center"
          sx={{ borderBottom: 0, width: columnWidth }}
          marginHighlights={marginHighlights || []}
          value={dummyMember.grossMargin}
        >
          {dummyMember.grossMargin == null ? (
            <HorizontalRuleOutlinedIcon color="disabled" />
          ) : (
            `${dummyMember.grossMargin}%`
          )}
        </MarginHighlightCell>
        <TableCell align="center" sx={{ borderBottom: 0, width: columnWidth }}>
          <RateField
            id={'compensationRate'}
            name={'compensationRate'}
            value={formik.values.compensationRate}
            handleChange={onRateChange}
            error={Boolean(formik.errors.billRate)}
            //eslint-disable-next-line
            autoFocus
          />
        </TableCell>
        <CustomBudgetCell
          sx={{ borderBottom: 0, width: columnWidth }}
          align="center"
          justifyContent="center"
        >
          {dummyMember.payRange ? (
            <>
              <CustomBudgetBox>$</CustomBudgetBox>
              {'\u2009'}
              {dummyMember.payRange[0]}
              {'\u2009'}
              <CustomBudgetBox>..</CustomBudgetBox>
              {'\u2009'}
              <CustomBudgetBox>$</CustomBudgetBox>
              {'\u2009'}
              {dummyMember.payRange[1]}
            </>
          ) : (
            <HorizontalRuleOutlinedIcon color="disabled" />
          )}
        </CustomBudgetCell>
        <TableCell align="center" sx={{ borderBottom: 0, width: columnWidth }}>
          <RateField
            id={'billRate'}
            name={'billRate'}
            value={formik.values.billRate}
            handleChange={onRateChange}
            error={Boolean(formik.errors.billRate)}
          />
        </TableCell>
        <CustomBudgetCell
          sx={{ borderBottom: 0, width: columnWidth }}
          align="center"
          justifyContent="center"
        >
          {dummyMember.rateCard ? (
            <>
              <CustomBudgetBox>$</CustomBudgetBox>
              {'\u2009'}
              {dummyMember.rateCard}
            </>
          ) : (
            <HorizontalRuleOutlinedIcon color="disabled" />
          )}
        </CustomBudgetCell>

        <CustomBudgetCell justifyContent="center">
          <NumericFormat
            customInput={TextField}
            fullWidth
            id="allocation"
            autoComplete="off"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              size: 'small',
              sx: {
                maxWidth: (theme: Theme) => theme.spacing(8.5),
                minWidth: (theme: Theme) => theme.spacing(7.5),
                maxHeight: (theme: Theme) => theme.spacing(4.5),
              },
            }}
            inputProps={{ maxLength: 3 }}
            name="allocation"
            inputMode="decimal"
            InputLabelProps={{
              shrink: true,
            }}
            onValueChange={({ value }) => handleAllocationChange(value)}
            value={formik.values.allocation}
            variant="filled"
            helperText={formik.errors.allocation}
            hiddenLabel
          />
        </CustomBudgetCell>

        <TableCell align="right">
          <IconButton
            onClick={() => {
              handleHideDummyMember({
                id: dummyMember.id,
                compensationRate: formik.values.compensationRate,
                billRate: formik.values.billRate,
                allocation: formik.values.allocation,
                isHidden: !dummyMember.isHidden,
                payRange: formik.values.payRange,
              });
            }}
            color={!dummyMember.isHidden ? 'default' : 'primary'}
          >
            {!dummyMember.isHidden ? (
              <PersonOffOutlinedIcon />
            ) : (
              <PersonAddOutlinedIcon />
            )}
          </IconButton>
        </TableCell>
      </StyledTableRow>
    </Slide>
  );
};
