import { ContractorType, GeneralKpiModel } from '../../../types';
import React, { useMemo } from 'react';
import { getGeneralKpiMetric } from '../../../utils/getGeneralKpiMetric';
import { Box, Typography, TableRow, CircularProgress } from '@mui/material';
import {
  CustomTableCell,
  TableCellsByKpiType,
} from '../../../components/customTable';
import {
  PeopleIconWrapper,
  MemberInfoCell,
} from '../../../components/tableCellRenders';
import { ColumnTextAlign } from '../../../constants';
import { debounce } from '../../../utils/debounce';
import { useSelector } from 'react-redux';
import {
  approvalsSelectors,
  approvalsSlice,
  checkNewKpiBonus,
} from '../../../redux/approvals';
import { BonusTableCell } from '../../../components/tableCellRenders/bonusTableCell';
import { useAppDispatch } from '../../../redux/hooks';
import { ApprovalsPageMode } from '../Approvals.const';
import { ActionTakerBadge } from '../../../components/actionTaker/ActionTaker';
import { is } from '../../../utils';
import { periodsSelectors } from '../../../redux/periods';
import { getKpiValueColor } from '../../../utils/kpis';
import { MetricEditableCell } from '../MetricEditableCell/MetricEditableCell';

interface Props {
  member: ContractorType;
  minWidth?: number;
}

export function MemberKpisRows({ member, minWidth = 50 }: Props) {
  const { sharedKpis, individualKpis } = member;

  return (
    <>
      {(sharedKpis || []).map((sharedKpi) => (
        <MemberKpiRow
          key={sharedKpi.id}
          id={sharedKpi.id}
          data={getGeneralKpiMetric(sharedKpi)}
          minWidth={minWidth}
          member={member}
          isShared={true}
        />
      ))}
      {(individualKpis || []).map((individualKpi) => (
        <MemberKpiRow
          key={individualKpi.id}
          id={individualKpi.id}
          data={getGeneralKpiMetric(individualKpi)}
          minWidth={minWidth}
          member={member}
        />
      ))}
    </>
  );
}

interface MembersKpiRowProps {
  id: string;
  data: GeneralKpiModel;
  isShared?: boolean;
  member: ContractorType;
  minWidth?: number;
}

export function MemberKpiRow({
  id,
  data,
  isShared = false,
  member: { avatarUrl, fullName, designation, deactivatedAt, country, city },
  minWidth = 50,
}: MembersKpiRowProps) {
  const dispatch = useAppDispatch();
  const loadingKpiId = useSelector(approvalsSelectors.getLoadingKpiId);
  const newBonusShares = useSelector(approvalsSelectors.getNewBonusShares);
  const values = useSelector(approvalsSelectors.getApprovalsValues);
  const currentPeriod = useSelector(periodsSelectors.getCurrentPeriod);

  const pageMode: ApprovalsPageMode = useSelector(
    approvalsSelectors.getApprovalsPageMode,
  );

  const value = !values || values[id] === undefined ? data.value : values[id];

  return useMemo(() => {
    const checkNewBonusShare = (id: string, newValue: string | number) => {
      if (newValue === '') {
        debounce.decline();
        dispatch(approvalsSlice.actions.setLoadingKpiId());
        return;
      }

      if (loadingKpiId !== id) {
        dispatch(approvalsSlice.actions.setLoadingKpiId(id));
      }

      debounce(() => {
        dispatch(
          checkNewKpiBonus({
            id,
            value: newValue,
            period: currentPeriod?.period || 0,
          }),
        );
      }, 500);
    };

    const loader = loadingKpiId === id && (
      <CircularProgress
        color="inherit"
        size={20}
        sx={{ position: 'absolute', top: '28px', left: '-10px' }}
      />
    );

    return (
      <TableRow>
        <CustomTableCell pure width={380} colSpan={3}>
          <MemberInfoCell
            userInfo={{
              avatarUrl: avatarUrl,
              fullName: fullName,
              stream: designation,
              country: country,
              city: city,
            }}
            disabled={!!deactivatedAt}
          />
        </CustomTableCell>
        <CustomTableCell pure width={550} colSpan={5}>
          <Box>
            {isShared ? (
              <PeopleIconWrapper>{data.kpiDefinition.name}</PeopleIconWrapper>
            ) : (
              <Typography variant="body1">{data.kpiDefinition.name}</Typography>
            )}
          </Box>
        </CustomTableCell>
        <CustomTableCell
          colSpan={1}
          width={115}
          style={{ minWidth: `${minWidth}px` }}
          sx={{ position: 'relative' }}
          align="right"
          pure
        >
          <BonusTableCell
            alignRight
            disabled={!!loader}
            value={
              newBonusShares[id] === undefined
                ? data.payout ?? data.bonusShare
                : newBonusShares[id].payout
            }
            defaultEmptyValue=""
            frequency={data.kpiDefinition.frequency}
            nowrap
            trueup={
              newBonusShares[id] === undefined
                ? data.trueup
                : newBonusShares[id].trueup
            }
          >
            {loader}
          </BonusTableCell>
        </CustomTableCell>
        <TableCellsByKpiType
          width={90}
          type={data.kpiDefinition.type}
          worstCase={data.worstCase}
          bestCase={data.bestCase}
          align="right"
        />
        {!is.nullOrUndefined(value) || pageMode === ApprovalsPageMode.Edit ? (
          <CustomTableCell
            width={165}
            pure
            colSpan={1}
            align={ColumnTextAlign.right}
            style={{ maxWidth: `127px` }}
          >
            <MetricEditableCell
              id={id}
              status={data.status}
              value={value}
              type={data.kpiDefinition.type}
              disabled={isShared}
              onChange={(newValue: string | number) =>
                checkNewBonusShare(id, newValue)
              }
              valueColor={getKpiValueColor(
                data.value,
                data.worstCase,
                data.bestCase,
              )}
            />
          </CustomTableCell>
        ) : (
          <CustomTableCell
            width={165}
            pure
            colSpan={1}
            align={ColumnTextAlign.right}
            style={{ maxWidth: `127px` }}
          >
            {data.kpiOwner && (
              <Box sx={{ display: 'inline-flex', justifyContent: 'end' }}>
                <ActionTakerBadge
                  actionTaker={data.kpiOwner}
                  status={data.status}
                />
              </Box>
            )}
          </CustomTableCell>
        )}
      </TableRow>
    );
  }, [
    id,
    data,
    isShared,
    minWidth,
    avatarUrl,
    deactivatedAt,
    designation,
    fullName,
    newBonusShares,
    pageMode,
    value,
    currentPeriod?.period,
    dispatch,
    loadingKpiId,
    city,
    country,
  ]);
}
