import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/CloseRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { blue, grey } from '@mui/material/colors';
import CertificateLogo from '../../../../assets/certificate.svg';

import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  fetchStatusReport,
  getStatusReportIsLoading,
  getStatusReportLink,
} from '../../../../redux/statusReport';
import {
  AssessmentDateContent,
  AssessmentTypeContent,
  AssessmentsTypesEnum,
} from '../../types';
import { statusColors } from '../../../../constants';
import { capitalizeFirstLetter } from '../../utils';

const generalStatusIcons = [
  <ErrorRoundedIcon
    key="general-status-icon-0"
    sx={{ fontSize: '1.2rem', color: statusColors[0], margin: '0 auto' }}
  />,
  <WarningRoundedIcon
    key="general-status-icon-1"
    sx={{
      fontSize: '1.2rem',
      color: statusColors[1],
    }}
  />,
  <CheckCircleRoundedIcon
    key="general-status-icon-2"
    sx={{
      fontSize: '1.2rem',
      color: statusColors[2],
    }}
  />,
  <AddCommentIcon
    key="general-status-icon-3"
    sx={{
      fontSize: '1.5rem',
      color: blue[500],
    }}
  />,
  <FiberManualRecordIcon
    key="general-status-icon-4"
    sx={{
      fontSize: '1rem',
      color: statusColors[3],
    }}
  />,
];

interface Props {
  assessment: AssessmentDateContent | null;
  isModalOpen: boolean;
  period: string;
  title: string;
  handleCloseModal: () => void;
}

const GenerateReportModal: React.FC<Props> = ({
  assessment,
  isModalOpen,
  period,
  title,
  handleCloseModal,
}) => {
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const isReportLoading = useAppSelector(getStatusReportIsLoading);
  const statusReportLink = useAppSelector(getStatusReportLink);

  const [additionalNotes, setAdditonalNotes] = useState<string>('');

  const isReportGenerated = statusReportLink;

  const navigateToFile = (fileLink: string | undefined) => {
    if (!fileLink) return;

    window.open(fileLink, '_blank', 'noopener,noreferrer');
  };

  const handleGenerateReport = () => {
    const projectId = params.get('project');

    if (!projectId) return;

    dispatch(fetchStatusReport({ projectId, notes: additionalNotes }));
  };

  return (
    <Dialog
      scroll="body"
      open={isModalOpen}
      PaperProps={{ sx: { minWidth: '31.5rem' } }}
      onClose={handleCloseModal}
    >
      <DialogTitle>
        {!isReportGenerated && `Generate ${period} report`}
        {handleCloseModal ? (
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 14,
              top: 14,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        {!isReportGenerated && assessment && (
          <>
            <Box sx={{ my: '1.875rem' }}>
              <Typography sx={{ mb: '0.5rem' }}>{title}</Typography>
              <Stack direction="row" gap="1.5rem">
                {Object.entries(assessment).map(
                  ([typeName, typeData]: [string, AssessmentTypeContent]) => (
                    <Stack
                      key={`${period}-${typeName}`}
                      gap="0.25rem"
                      alignItems="center"
                    >
                      <Typography
                        sx={{ color: grey[600], fontSize: '0.813rem' }}
                      >
                        {capitalizeFirstLetter(typeName)}
                      </Typography>
                      {
                        // It's General assessment.
                        typeName === AssessmentsTypesEnum.GENERAL ? (
                          <Stack alignItems="center">
                            {generalStatusIcons[typeData.score ?? 4]}
                          </Stack>
                        ) : (
                          // It's either Scope or Timeline, render their bars.
                          <Box
                            sx={{
                              backgroundColor:
                                statusColors[typeData.score ?? 3],
                              width: '2.75rem',
                              height: '0.5rem',
                              borderRadius: '4px',
                              marginY: '0.375rem',
                            }}
                          />
                        )
                      }
                    </Stack>
                  ),
                )}
              </Stack>
            </Box>
            <Box>
              <TextField
                fullWidth
                id="general-comment"
                multiline
                minRows={3}
                placeholder="Additonal notes (optional)"
                value={additionalNotes}
                onChange={(e) => setAdditonalNotes(e.target.value)}
                sx={{ backgroundColor: 'white' }}
              />
            </Box>
          </>
        )}
        {isReportGenerated && (
          <Stack alignItems="center">
            <img
              width={179}
              height={179}
              src={CertificateLogo}
              alt="certificate"
            />
            <Typography variant="h2" marginTop="1.5rem">
              Project status report is ready
            </Typography>
            <Typography variant="body1" color="text.secondary">
              See you in 2 weeks, thanks!
            </Typography>
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: '0 1.5rem 1.25rem' }}>
        <Box>
          <Button onClick={handleCloseModal} sx={{ mr: 1 }}>
            {isReportGenerated ? 'Close' : 'Cancel'}
          </Button>
          {!isReportGenerated && (
            <LoadingButton
              variant="contained"
              disabled={isReportLoading}
              loading={isReportLoading}
              loadingPosition="end"
              endIcon={<KeyboardArrowRightRoundedIcon />}
              onClick={handleGenerateReport}
            >
              Generate
            </LoadingButton>
          )}
          {isReportGenerated && (
            <Button
              variant="contained"
              disabled={!statusReportLink}
              startIcon={<InsertDriveFileIcon />}
              onClick={() => navigateToFile(statusReportLink)}
            >
              Open report
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateReportModal;
