import { createSlice } from '@reduxjs/toolkit';
import {
  ProjectAndMembersData,
  ProjectTimetracking,
} from './timeAndPeople.types';
import { timeAndPeopleKey } from './timeAndPeople.const';
import {
  fetchProjectsAndMembersData,
  fetchProjectTimetracking,
} from './timeAndPeople.thunks';
import { AsyncData, DataState } from '../../types/asyncData';
import { DropdownMenuOption } from '../../components/dropdown';
import { TimetrackinDropdownOptions } from '../../pages/timeAndPeople/timeAndPeople.constatns';
import { TimetrackingPeriod } from '../../features/globalTracker/utils';

const initialState: {
  projects: { [projectId: string]: AsyncData<ProjectAndMembersData> | null };
  timetrackings: AsyncData<ProjectTimetracking>;
  selectedPeriod: DropdownMenuOption<TimetrackingPeriod>;
} = {
  projects: {},
  timetrackings: {
    state: DataState.Idle,
    data: null,
  },
  selectedPeriod: TimetrackinDropdownOptions[0],
};

export const timeAndPeopleSlice = createSlice({
  name: timeAndPeopleKey,
  initialState,
  reducers: {
    cleanTimeAndPeople(state) {
      state.projects = initialState.projects;
      state.timetrackings = initialState.timetrackings;
      state.selectedPeriod = initialState.selectedPeriod;
    },
    setSelectedPeriod(
      state,
      { payload }: { payload: DropdownMenuOption<TimetrackingPeriod> },
    ) {
      state.selectedPeriod = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectsAndMembersData.pending, (state, { meta }) => {
        state.projects[meta.arg.projectId] = {
          state: DataState.Pending,
          data: null,
        };
      })
      .addCase(
        fetchProjectsAndMembersData.fulfilled,
        (state, { meta, payload }) => {
          state.projects[meta.arg.projectId] = {
            state: DataState.Fulfilled,
            data: payload,
          };
        },
      )
      .addCase(fetchProjectsAndMembersData.rejected, (state, { meta }) => {
        state.projects[meta.arg.projectId] = {
          state: DataState.Rejected,
          data: null,
          error: {
            message: 'Oops. Something went wrong. :(',
          },
        };
      })
      .addCase(fetchProjectTimetracking.pending, (state) => {
        state.timetrackings = {
          state: DataState.Pending,
          data: null,
        };
      })
      .addCase(fetchProjectTimetracking.fulfilled, (state, { payload }) => {
        state.timetrackings = {
          state: DataState.Fulfilled,
          data: payload,
        };
      })
      .addCase(fetchProjectTimetracking.rejected, (state) => {
        state.timetrackings = {
          state: DataState.Rejected,
          data: null,
          error: {
            message: 'Oops. Something went wrong. :(',
          },
        };
      });
  },
});

export const { cleanTimeAndPeople, setSelectedPeriod } =
  timeAndPeopleSlice.actions;
