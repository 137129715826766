import { approvalsStoreKey } from './approvals.const';
import { DepartmentType, PlainObject } from '../../types';
import {
  ApprovalsPageMode,
  ApprovalsGroupBy,
} from '../../features/approvals/Approvals.const';
import { AppStateWithApprovalsData, ApprovalsState } from './approvals.types';
import { createSelector } from '@reduxjs/toolkit';

const selectApprovalsState = (state: AppStateWithApprovalsData) => {
  return state[approvalsStoreKey];
};

const getApprovalsLoading = createSelector(
  selectApprovalsState,
  (state: ApprovalsState): boolean => {
    return state.loading || state.approveKpisLoading;
  },
);

const getApprovalsDepartments = (
  state: AppStateWithApprovalsData,
): DepartmentType[] => {
  return selectApprovalsState(state).departments;
};

const getApprovalsValues = createSelector(
  selectApprovalsState,
  (state: ApprovalsState): PlainObject<number | string> => {
    return state.formValues;
  },
);

const getApprovalsErrors = createSelector(
  selectApprovalsState,
  (state: ApprovalsState): PlainObject | null => {
    return state.formErrors;
  },
);

const getApprovalsPageMode = (
  state: AppStateWithApprovalsData,
): ApprovalsPageMode => {
  return selectApprovalsState(state).pageMode;
};

const getApprovalsGroupBy = (
  state: AppStateWithApprovalsData,
): ApprovalsGroupBy => {
  return selectApprovalsState(state).groupBy;
};

const getLoadingKpiId = (
  state: AppStateWithApprovalsData,
): string | undefined => {
  return selectApprovalsState(state).loadingKpiId;
};

const getNewBonusShares = (state: AppStateWithApprovalsData): PlainObject => {
  return selectApprovalsState(state).newBonusShares;
};

export const approvalsSelectors = {
  getApprovalsLoading,
  getApprovalsDepartments,
  getApprovalsValues,
  getApprovalsPageMode,
  getApprovalsGroupBy,
  getApprovalsErrors,
  getLoadingKpiId,
  getNewBonusShares,
};
