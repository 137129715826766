import React from 'react';
import { TableCell, Stack, Typography, Icon } from '@mui/material';
import { PlainObject } from '../../../types/plainObject';
import { CustomBudgetCellProps } from '../types';

export const CustomBudgetCell = ({
  nowrap,
  children,
  color,
  icon,
  justifyContent = 'flex-end',
  paddingLeft,
  ...props
}: CustomBudgetCellProps) => {
  const sx: PlainObject = nowrap ? { whiteSpace: 'nowrap' } : {};

  return (
    <TableCell
      {...props}
      width={10}
      sx={{
        ...sx,
        borderBottom: '0',
      }}
    >
      <Stack direction="row" justifyContent={justifyContent}>
        <Typography
          pl={paddingLeft}
          variant="body1"
          component="div"
          color={color}
        >
          {children}
        </Typography>
        {icon && <Icon>{icon}</Icon>}
      </Stack>
    </TableCell>
  );
};
