import { createSlice } from '@reduxjs/toolkit';
import { State } from './pageTitle.types';
import { pageTitleStoreKey } from './pageTitle.const';

const initialState: State = {
  title: 'Rocky Finance',
};

export const pageTitleSlice = createSlice({
  name: pageTitleStoreKey,
  initialState,
  reducers: {
    setTitle(state, { payload }) {
      state.title = payload;
    },
  },
});

export const { setTitle } = pageTitleSlice.actions;
