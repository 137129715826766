import { PeriodsState } from './periods.types';
import { PeriodType } from '../../types';
import { periodsStoreKey } from './periods.const';

interface State {
  [periodsStoreKey]: PeriodsState;
}

const selectPeriodsState = (state: State) => {
  return state[periodsStoreKey];
};

const getPeriodsApiStatus = (state: State): string => {
  return selectPeriodsState(state).apiStatus;
};

const getPeriodsList = (state: State): PeriodType[] => {
  return selectPeriodsState(state).periodsList;
};

const getCurrentPeriod = (state: State): PeriodType | null => {
  return selectPeriodsState(state).currentPeriod;
};

const getIsClosePeriodAvailable = (state: State): boolean => {
  return selectPeriodsState(state).isClosePeriodAvailable;
};

export const periodsSelectors = {
  getCurrentPeriod,
  getIsClosePeriodAvailable,
  getPeriodsApiStatus,
  getPeriodsList,
};
