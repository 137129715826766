import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Button,
  Card,
  Chip,
  Grid,
  TextField,
  Typography,
  useTheme,
  ClickAwayListener,
  Popper,
  Paper,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Papaparse from 'papaparse';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { downloadCsv } from '../../reports/Reports.utils';
import { useTitle } from '../../../hooks/useTitle';
import { PageTitle } from '../../../utils/pageTitles';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  MarginReportAccount,
  MarginReportResponseDto,
  MarginReportType,
} from '../../../types/marginReports';
import {
  fetchAccounts,
  fetchMarginReports,
  marginReportsSelectors,
} from '../../../redux/marginReports';
import { API_STATUS } from '../../../constants';
import {
  ALL_ACCOUNTS_ITEM,
  marginReportFieldsOrder,
  MARGIN_REPORT_KEYS,
} from '../../../constants/marginReports';
import { LegalLocation } from '../../../types';

interface Props {
  anchorEl: React.MutableRefObject<HTMLElement | null>;
  open: boolean;
  handlePopperClose: () => void;
}

export const AccountReportsPopper: React.FC<Props> = ({
  anchorEl,
  open,
  handlePopperClose,
}) => {
  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(PageTitle.portfolio);
  }, [setPageTitle]);
  const { spacing } = useTheme();
  const dispatch = useAppDispatch();

  const accounts: MarginReportAccount[] = useAppSelector(
    marginReportsSelectors.getAccounts,
  );
  const marginReport: MarginReportResponseDto | null = useAppSelector(
    marginReportsSelectors.getMarginReport,
  );

  const areAccountsLoading = useAppSelector(
    (state) =>
      marginReportsSelectors.getAccountApiStatus(state) === API_STATUS.LOADING,
  );
  const isReportLoading = useAppSelector(
    (state) =>
      marginReportsSelectors.getReportApiStatus(state) === API_STATUS.LOADING,
  );

  const [selectedAccounts, setSelectedAccounts] = useState<
    MarginReportAccount[]
  >([]);

  useEffect(() => {
    dispatch(fetchAccounts());
  }, [dispatch]);

  useEffect(() => {
    const accountIds =
      selectedAccounts.length === 0 ||
      (selectedAccounts.length === 1 &&
        selectedAccounts[0]?.id === ALL_ACCOUNTS_ITEM.id)
        ? accounts.map((x) => x.id)
        : selectedAccounts.map((x) => x.id);

    if (accountIds.length) {
      dispatch(fetchMarginReports(accountIds));
    }
  }, [selectedAccounts, accounts, dispatch]);

  const accountFilters = useMemo(
    () => (accounts.length > 1 ? [ALL_ACCOUNTS_ITEM, ...accounts] : accounts),
    [accounts],
  );

  const handleDownloadCsv = useCallback(() => {
    const data = marginReport?.accounts
      ?.flatMap((account) =>
        account?.projects.flatMap((project) =>
          project.members?.map(
            (member) =>
              ({
                AccountName: account?.name,
                ProjectName: project.name,
                MemberName: member.fullName,
                Stream: member.stream,
                Seniority: member.seniority,
                BillRate: member.resource?.billRate,
                PayRate: member.compensationRate,
                CompensationRangeMin: member.compensationRange?.min,
                CompensationRangeMax: member.compensationRange?.max,
                CompensationRangeApproved:
                  member.compensationRange?.approvedMax,
                Ratecard: member.companyRateCard?.billRate,
                Location: member.compensationRange?.legalLocation,
                AvgMonthlyHours:
                  marginReport.locationData[
                    member.compensationRange?.legalLocation as LegalLocation
                  ]?.avgMonthlyHours,
              } as MarginReportType),
          ),
        ),
      )
      .filter((x) => x);

    if (!data) {
      return;
    }

    const reportData = data.map((row: MarginReportType | undefined) => ({
      [MARGIN_REPORT_KEYS.AccountName]: row?.AccountName,
      [MARGIN_REPORT_KEYS.ProjectName]: row?.ProjectName,
      [MARGIN_REPORT_KEYS.MemberName]: row?.MemberName,
      [MARGIN_REPORT_KEYS.Stream]: row?.Stream,
      [MARGIN_REPORT_KEYS.Seniority]: row?.Seniority,
      [MARGIN_REPORT_KEYS.BillRate]: row?.BillRate,
      [MARGIN_REPORT_KEYS.PayRate]: row?.PayRate,
      [MARGIN_REPORT_KEYS.CompensationRangeMin]: row?.CompensationRangeMin,
      [MARGIN_REPORT_KEYS.CompensationRangeMax]: row?.CompensationRangeMax,
      [MARGIN_REPORT_KEYS.CompensationRangeApproved]:
        row?.CompensationRangeApproved,
      [MARGIN_REPORT_KEYS.Ratecard]: row?.Ratecard,
      [MARGIN_REPORT_KEYS.Location]: row?.Location,
      [MARGIN_REPORT_KEYS.AvgMonthlyHours]: row?.AvgMonthlyHours,
    }));

    const csvData = Papaparse.unparse(reportData, {
      quotes: true,
      columns: marginReportFieldsOrder,
    });

    const csvFilename = `Gross-marginality-report.csv`;
    downloadCsv(csvData, csvFilename);
  }, [marginReport?.accounts, marginReport?.locationData]);

  const handleAccountChange = useCallback(
    (
      _e: React.SyntheticEvent<Element, Event>,
      options: MarginReportAccount[],
    ) => {
      const recentSelection = options.pop();
      setSelectedAccounts(
        recentSelection?.id
          ? [...options.filter((option) => option.id), recentSelection].filter(
              (x) => x,
            )
          : [],
      );
    },
    [setSelectedAccounts],
  );

  const getDefaultLocationOptionChipDeleteIcon = (
    option: MarginReportAccount,
  ) => {
    return option.id ? <CancelRoundedIcon /> : <></>;
  };

  return (
    <ClickAwayListener onClickAway={handlePopperClose}>
      <Popper
        anchorEl={anchorEl.current}
        open={open}
        placement={'bottom-end'}
        sx={{ zIndex: 9999 }}
      >
        <Paper elevation={3}>
          <Card
            variant="outlined"
            sx={{
              maxWidth: 400,
              marginX: 'auto',
              marginY: 1,
              padding: 3,
              borderRadius: `${spacing(1)} ${spacing(1)} 0 0`,
            }}
          >
            <Grid container spacing={2}>
              <Typography variant="h2" mx={2} mt={2}>
                Gross marginality report
              </Typography>
              <Grid item md={12}>
                <Autocomplete
                  id="accounts"
                  disableClearable
                  disableCloseOnSelect
                  disablePortal
                  openOnFocus
                  multiple
                  options={accountFilters}
                  value={
                    selectedAccounts.length
                      ? selectedAccounts
                      : accounts.length > 1
                      ? [ALL_ACCOUNTS_ITEM]
                      : []
                  }
                  getOptionLabel={(option) => option.name}
                  onChange={handleAccountChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose one of the accounts"
                      variant="filled"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {areAccountsLoading && (
                              <CircularProgress color="inherit" size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      // eslint-disable-next-line
                      <Chip
                        label={option.name}
                        {...getTagProps({ index })}
                        deleteIcon={getDefaultLocationOptionChipDeleteIcon(
                          option,
                        )}
                      />
                    ))
                  }
                />
              </Grid>
              <Grid item xs={12} display="flex">
                <Button
                  fullWidth
                  onClick={handleDownloadCsv}
                  disabled={isReportLoading}
                  startIcon={
                    isReportLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <DownloadRoundedIcon />
                    )
                  }
                  sx={{ minWidth: (theme) => theme.spacing(24) }}
                  variant="contained"
                >
                  Download .CSV
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
};
