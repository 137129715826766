import React, { useMemo } from 'react';
import { TableCell, Theme, Typography } from '@mui/material';
import { ProjectRowProps } from '../types';
import { Link } from 'react-router-dom';
import BreadcrumbLink from '@mui/material/Link';
import { ProjectTableRow } from './styledComponents/ProjectTableRow';
import { useAppSelector } from '../../../redux/hooks';
import { getGroupBy, GroupAccountMembersBy } from '../../../redux/account';

export const ProjectRow = ({
  members,
  name,
  projectId,
  margin,
  showMargin,
  hideLink = false,
}: ProjectRowProps) => {
  const isProjectView = useMemo(() => Boolean(projectId), [projectId]);
  const groupBy = useAppSelector(getGroupBy);

  const columnWidth = (theme: Theme) => theme.spacing(15);
  const nameColumnWidth = 370;

  const nameContent = (
    <>
      <Typography variant="h4" component="p">
        {name}
      </Typography>
      <Typography variant="subtitle2" color="text.secondary">
        {members}
      </Typography>
    </>
  );

  const nameCellContent =
    groupBy === GroupAccountMembersBy.Team && !hideLink ? (
      <BreadcrumbLink
        component={Link}
        to={`../${projectId}` || ''}
        className="projectRow no-height"
        underline="hover"
        sx={{
          color: 'text.secondary',
          textDecoration: 'none',
          '&:hover': {
            color: (theme) => theme.palette.primary.main,
          },
        }}
      >
        {nameContent}
      </BreadcrumbLink>
    ) : (
      nameContent
    );

  return (
    <ProjectTableRow
      isProjectView={isProjectView}
      className="projectRow no-height"
      sx={{ width: '100%' }}
    >
      <TableCell
        sx={{
          border: '0',
          py: 1,
          minWidth: nameColumnWidth,
        }}
        colSpan={1}
      >
        {nameCellContent}
      </TableCell>
      <TableCell
        sx={{
          border: '0',
          py: 1,
          textAlign: 'center',
          width: columnWidth,
        }}
        colSpan={1}
      >
        {showMargin && (
          <Typography variant="h4" component="p">
            {margin === undefined || margin === null ? 'NA' : `${margin}%`}
          </Typography>
        )}
      </TableCell>
      <TableCell
        colSpan={6}
        sx={{
          border: '0',
          py: 1,
        }}
      ></TableCell>
    </ProjectTableRow>
  );
};
