import { ContractorsState } from './contractors.slice';
import { DepartmentType } from '../../types/contractors';
import { contractorsStoreKey } from './contractors.const';

interface State {
  [contractorsStoreKey]: ContractorsState;
}

const selectContractorsState = (state: State) => {
  return state[contractorsStoreKey];
};

const getContractorsApiStatus = (state: State): string => {
  return selectContractorsState(state).apiStatus;
};

const getDepartmentTree = (state: State): DepartmentType[] => {
  return selectContractorsState(state).departmentTree;
};

export const contractorsSelectors = {
  getContractorsApiStatus,
  getDepartmentTree,
};
