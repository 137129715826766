import React, { PropsWithChildren, useMemo } from 'react';
import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { cn } from '../../utils';
import { drawerWidth, footerHeight } from '../../theme';

enum BottomPanelColor {
  Warning = 'warning-color',
  Info = 'info-color',
  Success = 'success-color',
  Highlight = 'highlight-color',
  Waiting = 'waiting-color',
  Actionable = 'actionable-color',
}

enum BottomPanelAlign {
  SpaceBetween = 'space-between',
  Center = 'center',
  Left = 'left',
}

interface BottomPanelProps {
  color?: BottomPanelColor;
  align?: BottomPanelAlign;
  isFixed?: boolean;
  isSticky?: boolean;
}

const getPosition = (isFixed: boolean, isSticky: boolean): string => {
  if (isSticky) return 'sticky';
  if (isFixed) return 'fixed';

  return 'relative';
};

export function BottomPanel({
  color = BottomPanelColor.Success,
  align = BottomPanelAlign.SpaceBetween,
  children,
  isFixed = true,
  isSticky = false,
}: PropsWithChildren<BottomPanelProps>): JSX.Element {
  const { palette, spacing } = useTheme();
  const position = getPosition(isFixed, isSticky);
  const fixedOrSticky = isSticky || isFixed;

  const colorProps: SxProps<Theme> = useMemo(() => {
    switch (color) {
      case BottomPanelColor.Warning:
        return {
          backgroundColor: palette.warning.dark,
          color: palette.warning.contrastText,
          '.MuiTypography-root': {
            color: palette.warning.contrastText,
          },
        };
      case BottomPanelColor.Info:
        return {
          backgroundColor: palette.primary.main,
          color: palette.primary.contrastText,
          '.MuiTypography-root': {
            color: palette.info.contrastText,
          },
        };
      case BottomPanelColor.Success:
        return {
          backgroundColor: palette.success.light,
          color: palette.success.contrastText,
          '.MuiTypography-root': {
            color: palette.info.contrastText,
          },
        };
      case BottomPanelColor.Highlight:
        return {
          backgroundColor: palette.highlight.main,
          color: palette.success.contrastText,
          '.MuiTypography-root': {
            color: palette.info.contrastText,
          },
        };
      case BottomPanelColor.Waiting:
        return {
          backgroundColor: palette.secondary.main,
          color: palette.secondary.contrastText,
          '.MuiTypography-root': {
            color: palette.info.contrastText,
          },
        };
      case BottomPanelColor.Actionable:
        return {
          backgroundColor: palette.action.hover,
          color: palette.success.contrastText,
        };
    }
  }, [color, palette]);

  const justifyContentProps: SxProps<Theme> = useMemo((): SxProps<Theme> => {
    switch (align) {
      case BottomPanelAlign.SpaceBetween:
        return {
          justifyContent: 'space-between',
        };
      case BottomPanelAlign.Center:
        return {
          justifyContent: 'center',
        };
      case BottomPanelAlign.Left:
        return {
          justifyContent: 'left',
        };
    }
  }, [align]);

  return (
    <>
      {(isFixed || isSticky) && (
        <Box
          sx={{
            height: `${footerHeight}px`,
            width: '100%',
            opacity: 0,
          }}
        />
      )}
      <Box
        sx={{
          paddingLeft: `${isFixed && !isSticky ? drawerWidth : '0'}px`,
          position,
          display: isSticky ? 'flex' : 'block',
          ...(isSticky && { alignItems: 'flex-end' }), // conditionally add alignItems if isSticky is true
          zIndex: 3,
          bottom: 0,
          left: 0,
          width: '100%',
        }}
      >
        <Box
          sx={{
            paddingRight: spacing(fixedOrSticky ? 5 : 3),
            paddingLeft: spacing(fixedOrSticky ? 5 : 3),
            marginY: 0,
            marginX: spacing(fixedOrSticky ? 2 : 0),
            borderTopLeftRadius: spacing(fixedOrSticky ? 1 : 0),
            borderTopRightRadius: spacing(fixedOrSticky ? 1 : 0),
            borderBottomRightRadius: spacing(fixedOrSticky ? 0 : 1),
            borderBottomLeftRadius: spacing(fixedOrSticky ? 0 : 1),
            display: 'flex',
            alignItems: 'center',
            width: isSticky ? '100%' : 'auto',
            height: footerHeight,
            transition: 'all 0.3s ease-in',

            ...colorProps,

            ...justifyContentProps,
          }}
          className={cn('content', color, align)}
        >
          {children}
        </Box>
      </Box>
    </>
  );
}

BottomPanel.color = BottomPanelColor;
BottomPanel.align = BottomPanelAlign;
