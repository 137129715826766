export const kpiDefinition = () => {
  return '/kpi-definition';
};

export const kpiDefinitionId = (id: string) => {
  return `${kpiDefinition()}/${id}`;
};

export const kpiDefinitionOwners = () => {
  return `${kpiDefinition()}/get-assignable-owners`;
};

export const kpiDefinitionCategories = () => {
  return `${kpiDefinition()}/get-department-category`;
};
