import { ReportsState } from './reports.types';
import { ReportType } from '../../types/reports';
import { reportsStoreKey } from './reports.const';

interface State {
  [reportsStoreKey]: ReportsState;
}

const selectReportsState = (state: State) => {
  return state[reportsStoreKey];
};

const getReports = (state: State): ReportType[] => {
  return selectReportsState(state).reports;
};

const getReportsApiStatus = (state: State): string => {
  return selectReportsState(state).apiStatus;
};

export const reportsSelectors = {
  getReports,
  getReportsApiStatus,
};
