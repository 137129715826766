import {
  calculateAccountMargin,
  calculateProjectMargin,
  fetchAccount,
  fetchCompensationRange,
} from './account.thunks';
import { isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import {
  accountStoreKey,
  mapDummyMembers,
  groupAccountMembersByMap,
  mapWithContractorMargin,
  mapWithDummyMargin,
  sortAccountMembersMap,
  sortAccountMap,
  setProjectMargin,
  getNewDummyProject,
  getDefaultDummyMember,
  mapMarignToDummyProject,
  sortAccountDummyMembersMap,
} from './account.const';
import {
  AccountDetail,
  DummyMargin,
  DummyMember,
  SortOrder,
} from '../../types';
import {
  AccountSortPayloadType,
  AccountState,
  CompensationRangesResponseDto,
  GroupAccountMembersBy,
  MemberDummyMarginRatesData,
  MemberField,
  SortAccountMembersBy,
} from './account.types';
import { DEFAULT_ERROR_MESSAGE } from '../../constants';
import { createSliceCustom } from '../../utils/tests/createSliceCustom';

export const initialState: AccountState = {
  account: null,
  project: null,
  selectedProjectId: null,
  accountMembers: [],
  accountDummyMembers: [],
  accountDummyProjects: [],
  groupBy: GroupAccountMembersBy.Team,
  sortBy: SortAccountMembersBy.Margin,
  sortOrder: SortOrder.Ascending,
  loading: false,
  error: null,
  editableMode: false,
  resetAllMode: false,
  dateFilterValue: null,
  dateFilterValueLocal: null,
  hideLoading: false,
};

export const returns = createSliceCustom({
  name: accountStoreKey,
  initialState,
  reducers: {
    setAccountDummyMembers: (
      state,
      { payload: dummyMember }: PayloadAction<DummyMember | undefined>,
    ) => {
      if (state.accountDummyMembers && typeof dummyMember !== 'undefined') {
        state.accountDummyMembers.unshift(dummyMember);
        state.accountDummyProjects?.forEach((project) => {
          if (dummyMember?.projectId === project.id) {
            project.membersCount++;
          }
        });
      } else {
        state.accountDummyMembers = [];
      }
    },
    updateAccountDummyMembers: (
      state,
      { payload }: PayloadAction<MemberField>,
    ) => {
      const { fieldName, id, value } = payload;
      if (state.accountDummyMembers?.length) {
        state.accountDummyMembers = state.accountDummyMembers.map(
          (dummyMember) => {
            if (id !== dummyMember.id) return dummyMember;
            return {
              ...dummyMember,
              [fieldName]: value,
            };
          },
        );
      }
    },
    updateAccountMember: (state, { payload }: PayloadAction<MemberField>) => {
      const { fieldName, id, value } = payload;
      if (state.accountMembers?.length) {
        state.accountMembers = state.accountMembers.map((group) => ({
          ...group,
          members: group.members.map((member) => {
            if (member.id !== id || payload.projectId !== group.projectId)
              return member;
            return {
              ...member,
              dummy: {
                ...(member.dummy as DummyMargin),
                [fieldName]: value,
              },
            };
          }),
        }));
      }
    },
    toggleEditableMode: (state) => {
      state.editableMode = !state.editableMode;
    },
    resetEditableMode: (state) => {
      state.editableMode = false;
    },
    toggleResetAll: (state) => {
      state.resetAllMode = !state.resetAllMode;
    },
    toggleHideDummyMembers: (state, { payload }) => {
      if (!payload?.id || !state.accountDummyMembers?.length) return;

      state.accountDummyMembers = state.accountDummyMembers.map((member) =>
        member.id === payload.id
          ? { ...member, isHidden: payload.isHidden }
          : member,
      );
    },
    hideLoading: (state) => {
      state.hideLoading = true;
    },
    //Todo: discuss and remove setSelectedAccount later
    setSelectedAccount: (
      state,
      { payload: selectedAccount }: PayloadAction<AccountDetail>,
    ) => {
      state.account = selectedAccount;

      const groupByFn = groupAccountMembersByMap[state.groupBy];
      const sortingFn = sortAccountMembersMap[state.sortBy];
      const accountSortingFn = sortAccountMap[state.sortBy];

      state.accountMembers = groupByFn(selectedAccount.projects).map(
        (group) => ({
          ...group,
          members: group.members.sort(sortingFn),
        }),
      );
      state.accountMembers = state.accountMembers.sort(accountSortingFn);
    },

    setGroupBy: (
      state,
      { payload: groupBy }: PayloadAction<GroupAccountMembersBy>,
    ) => {
      state.groupBy = groupBy;

      const groupByFn = groupAccountMembersByMap[groupBy];
      const sortingFn = sortAccountMembersMap[state.sortBy];
      const accountSortingFn = sortAccountMap[state.sortBy];

      if (state.account?.projects) {
        state.accountMembers = groupByFn(state.account.projects).map(
          (group) => ({
            ...group,
            members: group.members.sort(sortingFn),
          }),
        );
        state.accountMembers = state.accountMembers.sort(accountSortingFn);
      }
    },
    setSortBy: (state, { payload }: PayloadAction<AccountSortPayloadType>) => {
      state.sortBy = payload.sortBy;
      state.sortOrder = payload?.sortOrder ?? SortOrder.Ascending;

      const sortingFn = sortAccountMembersMap[state.sortBy];
      const accountSortingFn = sortAccountMap[state.sortBy];

      let accountMembers = state.accountMembers.map((group) => ({
        ...group,
        members:
          state.sortOrder === SortOrder.Ascending
            ? group.members.sort(sortingFn)
            : group.members.sort(sortingFn).reverse(),
      }));

      accountMembers = accountMembers.sort(accountSortingFn);

      state.accountMembers =
        state.sortOrder === SortOrder.Ascending
          ? accountMembers
          : accountMembers.reverse();

      if (state.accountDummyMembers?.length) {
        const dummyMemberSortingFn = sortAccountDummyMembersMap[state.sortBy];
        const sortedDummyMembers =
          state.accountDummyMembers.sort(dummyMemberSortingFn);
        state.accountDummyMembers =
          state.sortOrder === SortOrder.Ascending
            ? sortedDummyMembers
            : sortedDummyMembers.reverse();
      }
    },
    initMembersWithDummyMarginData: (state) => {
      if (!state.account) return;

      const groupByFn = groupAccountMembersByMap[state.groupBy];
      const sortingFn = sortAccountMembersMap[state.sortBy];
      const accountSortingFn = sortAccountMap[state.sortBy];

      const projects = state.project ? [state.project] : state.account.projects;
      state.accountMembers = groupByFn(projects).map((group) => ({
        ...group,
        members: group.members.map(mapWithDummyMargin).sort(sortingFn),
      }));
      state.accountMembers = state.accountMembers.sort(accountSortingFn);
    },
    updateMemberDummyMarginRates: (
      state,
      { payload }: PayloadAction<MemberDummyMarginRatesData>,
    ) => {
      if (!state.account?.id) return;
      const { memberId, isHidden, ...rest } = payload;
      state.accountMembers = state.accountMembers.map((group) => ({
        ...group,
        members: group.members.map((member) => {
          if (member.id !== memberId || group.projectId !== payload.projectId) {
            return member;
          }
          return {
            ...member,
            isHidden,
            dummy: {
              ...(member.dummy as DummyMargin),
              ...rest,
            },
          };
        }),
      }));
    },
    setSelectedProjectId: (
      state,
      { payload }: PayloadAction<{ projectId: string | null }>,
    ) => {
      state.selectedProjectId = payload?.projectId;
    },
    setDummyProjects: (state) => {
      const project = getNewDummyProject(state.accountDummyProjects);
      const member = getDefaultDummyMember(project.id);
      state.accountDummyProjects?.push(project);
      state.accountDummyMembers?.push(member);
    },
    resetDummyProjects: (state) => {
      state.accountDummyMembers = state.accountDummyMembers?.filter(
        (x) =>
          state.accountDummyProjects?.findIndex((y) => y.id === x.projectId) ===
          -1,
      );
      state.accountDummyProjects = [];
    },

    setDateFilterValueLocal: (
      state,
      { payload }: PayloadAction<string | null>,
    ) => {
      state.dateFilterValueLocal = payload;
    },
    setDateFilterValue: (state) => {
      state.dateFilterValue = state.dateFilterValueLocal;
    },
    cleanDateFilterValues: (state) => {
      state.dateFilterValue = null;
      state.dateFilterValueLocal = null;
    },
    cleanAccountsTableRow: (
      state,
      { payload }: PayloadAction<{ errMessage: string | null }>,
    ) => {
      state.accountMembers = [];
      state.accountListFetchErrorMessage = payload.errMessage;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchAccount.fulfilled,
        (state, { payload }: PayloadAction<AccountDetail>) => {
          state.loading = false;
          state.account = payload ?? null;
          state.project =
            payload?.projects?.find(
              ({ id: projectId }) => projectId === state.selectedProjectId,
            ) ?? null;

          const groupByFn = groupAccountMembersByMap[state.groupBy];
          const sortingFn = sortAccountMembersMap[state.sortBy];
          const accountSortingFn = sortAccountMap[state.sortBy];

          if (state.project) {
            state.accountMembers = groupByFn([state.project]).map((group) => ({
              ...group,
              members: group.members.sort(sortingFn),
            }));
            state.accountMembers = state.accountMembers.sort(accountSortingFn);
          } else if (state.account?.projects) {
            state.accountMembers = groupByFn(state.account.projects).map(
              (group) => ({
                ...group,
                members: group.members.sort(sortingFn),
              }),
            );
            state.accountMembers = state.accountMembers.sort(accountSortingFn);
          }
        },
      )
      .addCase(calculateAccountMargin.fulfilled, (state, { payload }) => {
        state.loading = false;

        if (!payload || !state.account) return;
        state.account = {
          ...state.account,
          grossMargin: payload.grossMargin,
          netMargin: payload.netMargin,
        };
        const contractors = payload.projects.flatMap((project) => {
          const contractorsWithId = project.contractors.map((contractor) => ({
            ...contractor,
            projectId: project.id,
          }));
          return contractorsWithId;
        });

        state.accountMembers = state.accountMembers.map((group) => ({
          ...group,
          margin: setProjectMargin(group.projectId, payload.projects),
          members: group.members.map(
            mapWithContractorMargin(contractors, group.projectId ?? ''),
          ),
        }));

        if (state.accountDummyMembers?.length) {
          state.accountDummyMembers = mapDummyMembers(
            state.accountDummyMembers,
            contractors,
          );
        }
        if (state.accountDummyProjects?.length) {
          state.accountDummyProjects = mapMarignToDummyProject(
            state.accountDummyProjects,
            payload.projects,
          );
        }
      })

      .addCase(calculateProjectMargin.fulfilled, (state, { payload }) => {
        state.loading = false;

        if (!payload || !state.project) return;
        state.project = {
          ...state.project,
          grossMargin: payload.grossMargin,
          netMargin: payload.netMargin,
        };

        if (state.accountDummyMembers?.length) {
          state.accountDummyMembers = mapDummyMembers(
            state.accountDummyMembers,
            payload.contractors,
          );
        }
        state.accountMembers = state.accountMembers.map((group) => ({
          ...group,
          members: group.members.map(
            mapWithContractorMargin(payload.contractors),
          ),
        }));
      })

      .addCase(
        fetchCompensationRange.fulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            data: CompensationRangesResponseDto;
            memberId: string;
          }>,
        ) => {
          if (state.accountDummyMembers?.length) {
            state.accountDummyMembers = state.accountDummyMembers.map(
              (dummyMember) => {
                if (dummyMember.id !== payload.memberId) return dummyMember;
                return {
                  ...dummyMember,
                  compensationRate: Math.floor(
                    ((payload.data.compensationRange?.min ?? 0) +
                      (payload.data.compensationRange?.max ?? 0)) /
                      2,
                  ),
                  payRange: [
                    payload.data.compensationRange?.min ?? 0,
                    payload.data.compensationRange?.max ?? 0,
                  ],
                  billRate: payload.data.billRate?.billRate ?? 0,
                  rateCard: payload.data.billRate?.billRate ?? 0,
                };
              },
            );
          }
        },
      )

      .addMatcher(isAnyOf(fetchAccount.pending), (state) => {
        state.loading = !state.hideLoading;
        state.error = initialState.error;
      })
      .addMatcher(
        isAnyOf(
          fetchAccount.rejected,
          calculateAccountMargin.rejected,
          calculateProjectMargin.rejected,
        ),
        (state) => {
          state.loading = false;
          state.error = new Error(DEFAULT_ERROR_MESSAGE);
        },
      );
  },
});

export const accountSlice = returns.slice;

export const {
  setAccountDummyMembers,
  toggleEditableMode,
  resetEditableMode,
  toggleResetAll,
  toggleHideDummyMembers,
  initMembersWithDummyMarginData,
  setGroupBy,
  setSortBy,
  updateMemberDummyMarginRates,
  updateAccountDummyMembers,
  updateAccountMember,
  setSelectedProjectId,
  setDummyProjects,
  resetDummyProjects,
  hideLoading,
  cleanAccountsTableRow,
} = accountSlice.actions;
export const accountReducers = returns.reducers;
export const accountExtraReducers = returns.extraReducers;
