import * as yup from 'yup';
import { KPI_F_TYPE } from '../../kpiDefinitions.const';

export const validationSchema = yup
  .object({
    name: yup
      .string()
      .trim('Name should not have leading or trailing whitespaces')
      .required()
      .min(1)
      .label('Name'),
    type: yup.string().required(),
    kpiOwner: yup
      .object({
        id: yup.string(),
      })
      .required(),
    defaultBonusShare: yup
      .number()
      .positive()
      .max(99999, '99999 max')
      .label('Bonus share')
      .required(),
    defaultBestCase: yup
      .number()
      .min(0)
      .label('Best case')
      .when('type', {
        is: KPI_F_TYPE.FIXED,
        then: (schema) => schema.notRequired().nullable(),
        otherwise: (schema) =>
          schema.required().notOneOf([yup.ref('defaultWorstCase')], ''),
      }),
    defaultWorstCase: yup
      .number()
      .min(0)
      .label('Worst case')
      .when('type', {
        is: KPI_F_TYPE.FIXED,
        then: (schema) => schema.notRequired().nullable(),
        otherwise: (schema) =>
          schema.required().notOneOf([yup.ref('defaultBestCase')], ''),
      }),
  })
  .strict();
