import React, { forwardRef } from 'react';
import { ToggleButtonProps, Tooltip, ToggleButton } from '@mui/material';

interface TooltipToggleButtonProps extends ToggleButtonProps {
  dataTestId?: string;
}

export const TooltipToggleButton = forwardRef<
  HTMLButtonElement,
  TooltipToggleButtonProps
>(({ title, dataTestId, ...props }, ref) => {
  const wrapTooltip = (content: JSX.Element) => (
    <Tooltip title={title as string} enterDelay={300}>
      {content}
    </Tooltip>
  );

  const button = <ToggleButton data-testid={dataTestId} ref={ref} {...props} />;

  return props.disabled ? button : wrapTooltip(button);
});

TooltipToggleButton.displayName = 'TooltipToggleButton';
