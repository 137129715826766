export enum SortOrder {
  Ascending,
  Descending,
}

export const SeniorityOrder = {
  '': 0,
  Junior: 2,
  'Junior 1': 3,
  'Junior 2': 4,
  Middle: 5,
  'Middle 1': 6,
  'Middle 2': 7,
  Senior: 8,
  'Senior 1': 9,
  'Senior 2': 10,
  Lead: 11,
  DM: 12,
};
