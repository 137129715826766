import React, { useMemo } from 'react';
import { TableCell } from '@mui/material';
import { MarginHighlightCellProps } from '../types';
import { MarginHighlightColor } from '../../../constants/marginTracker';

export const MarginHighlightCell = ({
  children,
  marginHighlights,
  value,
  ...props
}: MarginHighlightCellProps) => {
  const marginType = useMemo(() => {
    return marginHighlights.find(
      (marginHighlight) =>
        value != null &&
        (marginHighlight.min ?? -Infinity) <= value &&
        (marginHighlight.max ?? Infinity) >= value,
    )?.type;
  }, [value, marginHighlights]);

  const themeColor = MarginHighlightColor[marginType || ''] ?? 'text.primary';

  return (
    <TableCell
      {...props}
      width={10}
      sx={{
        bgcolor: value == null ? '' : themeColor,
        color: value == null ? 'text.primary' : 'common.white',
        fontSize: '0.875rem',
        fontWeight: 500,
        textShadow: value != null ? '0px 1px 0px rgba(0, 0, 0, 0.25)' : '',
        ...(props.sx ?? {}),
      }}
    >
      {children}
    </TableCell>
  );
};
