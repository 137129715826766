import { is } from './is';

export function getKpiValueColor(
  value?: number | null,
  worstCase?: number,
  bestCase?: number,
): string | undefined {
  if (value === undefined || value === null) {
    return;
  }

  if (is.undefined(worstCase) || is.undefined(bestCase)) {
    return;
  }

  if (bestCase >= worstCase) {
    if (value <= worstCase) {
      return 'error';
    }

    if (value >= bestCase) {
      return 'success.light';
    }
  } else {
    if (value <= worstCase) {
      return 'success.light';
    }

    if (value >= bestCase) {
      return 'error';
    }
  }
}
