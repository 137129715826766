import React, { useMemo } from 'react';
import _isEmpty from 'lodash/isEmpty';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { KpiForSubmissionType } from '../../../../types/submissions';
import { BottomPanel } from '../../../../components';
import { plural } from '../../../../utils';
import { SharedKpiModel, IndividualKpi } from '../../../../types';
import { KpiStatus } from '../../../../constants';

interface Props {
  handleSubmit: () => void;
  kpisForSubmission: KpiForSubmissionType[];
  sharedKpis: SharedKpiModel[];
  individualKpis: IndividualKpi[];
}

const SubmissionsFooter: React.FC<Props> = ({
  handleSubmit,
  individualKpis,
  kpisForSubmission,
  sharedKpis,
}) => {
  const combinedKpis = useMemo(
    () => [...individualKpis, ...sharedKpis],
    [individualKpis, sharedKpis],
  );

  const missingValuesCount = useMemo(() => {
    return combinedKpis.reduce((acc, kpi) => {
      if (kpi.value === null) {
        acc++;
      }
      return acc;
    }, 0);
  }, [combinedKpis]);

  const isReadyForSubmit = !_isEmpty(kpisForSubmission);

  const isAllResultsSubmitted =
    _isEmpty(kpisForSubmission) && missingValuesCount === 0;

  const isAllResultsApproved = useMemo(
    () =>
      individualKpis.every((kpi) => kpi.status === KpiStatus.Approved) &&
      sharedKpis.every((sharedKpi) =>
        sharedKpi.userSharedKpis.every(
          (kpi) => kpi.status === KpiStatus.Approved,
        ),
      ),
    [individualKpis, sharedKpis],
  );

  const panelColor = useMemo(() => {
    if (missingValuesCount > 0) {
      return BottomPanel.color.Warning;
    }
    if (isReadyForSubmit) {
      return BottomPanel.color.Info;
    }
    if (isAllResultsApproved) {
      return BottomPanel.color.Success;
    }
    if (isAllResultsSubmitted) {
      return BottomPanel.color.Waiting;
    }
  }, [
    isAllResultsApproved,
    isAllResultsSubmitted,
    isReadyForSubmit,
    missingValuesCount,
  ]);

  return (
    <BottomPanel color={panelColor}>
      {isAllResultsApproved ? (
        <>
          <Stack direction="row">
            <DoneAllRoundedIcon fontSize="small" />
            <Typography pl={1} typography="body1">
              Results are approved and processed by Accounting team
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography pr={1} typography="body1">
              Approved results are locked
            </Typography>
            <LockOutlinedIcon fontSize="small" />
          </Stack>
        </>
      ) : isAllResultsSubmitted ? (
        <>
          <Stack direction="row">
            <DoneAllRoundedIcon fontSize="small" />
            <Typography pl={1} typography="body1">
              Results sent for approval
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography pr={1} typography="body1">
              Awaiting approval
            </Typography>
            <LockOutlinedIcon fontSize="small" />
          </Stack>
        </>
      ) : (
        <>
          {missingValuesCount > 0 && (
            <Stack direction="row">
              <WarningAmberRoundedIcon fontSize="small" />
              <Typography pl={1} typography="body1">
                {missingValuesCount > 0
                  ? `${missingValuesCount} metric${plural(
                      missingValuesCount,
                    )} are missing actual results and can not be submitted`
                  : `Check if all values are valid`}
              </Typography>
            </Stack>
          )}
          {isReadyForSubmit && (
            <Stack direction="row">
              <InfoRoundedIcon />
              <Typography pl={1} typography="body1">
                All good? Results are ready for submission
              </Typography>
            </Stack>
          )}
          <Button
            disabled={!isReadyForSubmit}
            onClick={handleSubmit}
            variant="contained"
            className="contrast"
          >
            Submit for approval
          </Button>
        </>
      )}
    </BottomPanel>
  );
};

export default SubmissionsFooter;
