export const paths = {
  rockyBonuses: '/rocky-bonuses',
  myMetrics: '/my-metrics',
  contractors: '/contractors',
  reports: '/reports',
  clientInvoice: '/client-invoice',
  approvals: '/approvals',
  submissions: '/submissions',
  kpiDefinitions: '/kpi-definitions',
  accounts: '/accounts',
  portfolio: '/portfolio',
  termsOfUse: '/terms-of-use',
  admin: '/admin',
  projectStatus: 'project-status',
  ratesAndMargins: 'rates-and-margins',
  timeAndPeople: 'time-and-people',
  risks: 'risks',
};
