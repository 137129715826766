import React from 'react';
import { Box } from '@mui/material';
import { CustomBudgetBoxProps } from '../types';

export const CustomBudgetBox = ({
  color = 'text.disabled',
  children = '$',
}: CustomBudgetBoxProps) => (
  <Box component="span" color={color}>
    {children}
  </Box>
);
