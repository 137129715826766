import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { ReportType } from '../../types/reports';
import ReportsTable from './ReportsTable';
import { NoReportsComponent } from './components/NoReports';
import { KpiStatus } from '../../constants';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseRounded';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleDownloadCsv: () => void;
  reports: ReportType[];
}

const PreviewModal: React.FC<Props> = ({
  reports,
  handleDownloadCsv,
  isOpen,
  onClose,
}) => {
  const noReports = reports.length === 0;
  const anyUnpprovedReports =
    reports.filter((x) => x.status !== KpiStatus.Approved).length !== 0;
  const renderContent = () =>
    noReports ? <NoReportsComponent /> : <ReportsTable reports={reports} />;

  const content = renderContent();
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={false}>
      <DialogTitle>
        Report preview
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 11,
            color: ({ palette }) => palette.action.active,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 0, py: 0 }} dividers>
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button
          disabled={anyUnpprovedReports || noReports}
          onClick={handleDownloadCsv}
          variant="contained"
        >
          Download .CSV
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewModal;
