import { ToggleButton, styled } from '@mui/material';
import { statusColors } from '../../../constants/globalTracker';
import { blue } from '@mui/material/colors';

export const StyledToggleButton = styled(ToggleButton)`
  color: white;
  flex: 0 0 33.33333%;
  line-height: 0.75;

  &.Mui-selected {
    color: white;
  }

  &.general {
    padding-top: 0.45rem;
    padding-bottom: 0.45rem;
  }

  &.red,
  &:hover.red {
    background-color: ${statusColors[0]};
  }

  &.yellow,
  &:hover.yellow {
    background-color: ${statusColors[1]};
  }

  &.green,
  &:hover.green {
    background-color: ${statusColors[2]};
  }

  &.unselected {
    background-color: ${blue[100]};
    color: ${blue[500]};

    .MuiSvgIcon-root {
      color: ${blue[500]};
    }

    &:hover {
      color: white;

      .MuiSvgIcon-root {
        color: white;
      }
    }
  }
`;
