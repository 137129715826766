import { PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { FetchPeriodsResponseType, PeriodsState } from './periods.types';
import {
  closeCurrentPeriod,
  fetchPeriods,
  fetchCheckApproved,
} from './periods.thunks';
import { periodsStoreKey } from './periods.const';
import { createSliceCustom } from '../../utils/tests/createSliceCustom';

export const initialState: PeriodsState = {
  apiStatus: API_STATUS.IDLE,
  isClosePeriodAvailable: false,
  currentPeriod: null,
  periodsList: [],
};

export const returns = createSliceCustom({
  name: periodsStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPeriods.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(
        fetchPeriods.fulfilled,
        (state, { payload }: PayloadAction<FetchPeriodsResponseType>) => {
          state.apiStatus = API_STATUS.IDLE;
          state.periodsList = payload.periodsList.reverse();
          state.currentPeriod = payload.currentPeriod;
        },
      )
      .addCase(fetchPeriods.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      })
      .addCase(fetchCheckApproved.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(fetchCheckApproved.fulfilled, (state) => {
        state.apiStatus = API_STATUS.IDLE;
        state.isClosePeriodAvailable = true;
      })
      .addCase(fetchCheckApproved.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
        state.isClosePeriodAvailable = false;
      })
      .addCase(closeCurrentPeriod.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(closeCurrentPeriod.fulfilled, (state) => {
        state.apiStatus = API_STATUS.IDLE;
        state.isClosePeriodAvailable = initialState.isClosePeriodAvailable;
      })
      .addCase(closeCurrentPeriod.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });
  },
});

export const periodsSlice = returns.slice;
export const periodsReducers = returns.reducers;
export const periodsExtraReducers = returns.extraReducers;
