import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { PageWrapper } from '../../components';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import {
  accountsSlice,
  fetchAccounts,
  sortAccountsOptions,
  FilterAccountsBy,
  sumUpAccountMembers,
  accountsSelectors,
  SortAccountsBy,
  GetAccountsData,
  selectAccountsPageFilters,
  AccountsPageFiltersInitialState,
} from '../../redux/accounts';
import { accountSlice } from '../../redux/account';
import { DropdownMenu, DropdownMenuOption } from '../../components/dropdown';
import { AccountsTable } from './AccountsTable/AccountsTable';
import { userDataSelectors } from '../../redux/userData';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import FilterListIcon from '@mui/icons-material/FilterList';
import { PageTitle } from '../../utils/pageTitles';
import { useTitle } from '../../hooks/useTitle';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import { AccountReportsPopper } from './AccountReportsPopper/AccountReportsPopper';
import { StyledBudgetsSummary } from '../../pages/myPortfolio/components/MyPortfolioHeaderForDetails';
import { Info } from './BudgetsHeader/Info/Info';
import { MemberInfoCell } from '../../components/tableCellRenders';
import { CustomNumber } from './BudgetsHeader/Number/Number';
import { AccountOverview, AccountStatus, BaseManager } from '../../types';
import { GrossMarginSummary } from './BudgetsHeader/GrossMarginSummary/GrossMarginSummary';
import CustomDatePicker from '../../components/datePicker/CustomDatePicker';
import WorkOffOutlinedIcon from '@mui/icons-material/WorkOffOutlined';
import { FilterDrawer } from './AccountsFilters/AccountsFilters';
import { isEqual } from 'lodash';

export const Budgets = () => {
  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(PageTitle.portfolio);
  }, [setPageTitle]);

  const dispatch = useAppDispatch();
  const loading = useAppSelector(accountsSelectors.getLoading);
  const accounts = useAppSelector(accountsSelectors.getProcessedAccounts);
  const sortBy = useAppSelector(accountsSelectors.getSortBy);
  const accountsPageFilters = useAppSelector(selectAccountsPageFilters);
  const { filterBy, ...accountsPageFiltersWithoutFilterBy } =
    accountsPageFilters;
  const { filterBy: _, ...accountsPageFiltersInitialStateWithoutFilterBy } =
    AccountsPageFiltersInitialState;
  const filterValue = accountsPageFilters.filterValues;

  const user = useAppSelector(userDataSelectors.getUserData);
  const dateFilterValue = useAppSelector(accountsSelectors.getDateFilterValue);
  const dateFilterValueLocal = useAppSelector(
    accountsSelectors.getDateFilterValueLocal,
  );
  const errMessage = useAppSelector(
    accountsSelectors.getAccountsListFetchErrorMessage,
  );
  const isCLevelUser = user?.permissions.seesAllAccounts;
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
  const reportsAnchor = React.useRef<HTMLButtonElement | null>(null);
  const [isReportsPopperOpen, setReportsPopperOpen] = useState<boolean>(false);

  const canGenerateMarginReports = useMemo(
    () =>
      user?.permissions.hasAccounts &&
      user?.permissions.isBillRateManager &&
      user.permissions.isCompensationManager,
    [user],
  );

  useEffect(() => {
    dispatch(
      fetchAccounts(
        getFetchAccountsParams({
          filterValue,
          filterBy,
          accountStatus: accountsPageFilters.accountStatus,
          dateFilterValue,
        }),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    filterValue,
    accountsPageFilters.accountStatus,
    dateFilterValue,
  ]);

  useEffect(() => {
    if (accountsPageFilters.filterValues.length > 0) {
      dispatch(
        accountsSlice.actions.setAccountsPageFilters({ filterValues: [] }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, accountsPageFilters.filterBy]);

  useEffect(() => {
    dispatch(accountSlice.actions.cleanDateFilterValues());
  });

  const handleFiltersOnClick = useCallback(() => {
    setDrawerOpen(true);
  }, [setDrawerOpen]);

  const handleFiltersOnClose = useCallback(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen]);

  const toggleReportsPopper = useCallback(() => {
    setReportsPopperOpen(!isReportsPopperOpen);
  }, [setReportsPopperOpen, isReportsPopperOpen]);

  const onSortByClick = useCallback(
    ({ value }: DropdownMenuOption<SortAccountsBy>) => {
      dispatch(accountsSlice.actions.setSortBy({ sortBy: value }));
    },
    [dispatch],
  );

  const resetFilters = useCallback(() => {
    dispatch(
      accountsSlice.actions.setAccountsPageFilters({
        filterBy: FilterAccountsBy.PortfolioDeliveryManager,
      }),
    );
    dispatch(accountsSlice.actions.cleanDateFilterValues());

    dispatch(
      fetchAccounts(
        getFetchAccountsParams({
          filterValue: AccountsPageFiltersInitialState.filterValues,
          filterBy: AccountsPageFiltersInitialState.filterBy,
          accountStatus: AccountsPageFiltersInitialState.accountStatus,
          dateFilterValue: null,
        }),
      ),
    );
    dispatch(accountsSlice.actions.resetAccountsPageFilters());
  }, [dispatch]);

  const onResetFilterValuesClick = useCallback(() => {
    dispatch(
      accountsSlice.actions.setAccountsPageFilters({
        filterBy: FilterAccountsBy.PortfolioDeliveryManager,
      }),
    );
  }, [dispatch]);

  const handlePopperClose = useCallback(() => {
    setReportsPopperOpen(false);
  }, []);

  const isFiltersActive = !isEqual(
    accountsPageFiltersInitialStateWithoutFilterBy,
    accountsPageFiltersWithoutFilterBy,
  );

  return (
    <PageWrapper isLoading={loading} maxWidth={'1600px'}>
      <FilterDrawer
        isFiltersActive={isFiltersActive}
        isOpen={isDrawerOpen}
        onClose={handleFiltersOnClose}
        resetFilters={resetFilters}
      />

      <Box
        mb={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography variant="h2" mx="2">
          Portfolio
        </Typography>

        {!(!accounts.length && !errMessage) && (
          <Box display="flex" flexDirection="row" gap={3}>
            {/* Sort button */}
            <DropdownMenu
              label="Sort by"
              options={sortAccountsOptions}
              selectedItem={{ label: sortBy, value: sortBy }}
              onClick={onSortByClick}
            />

            {/* Filter button */}
            <Button
              sx={{ color: 'black' }}
              variant="text"
              onClick={handleFiltersOnClick}
            >
              <FilterListIcon />
              &nbsp; Filters
            </Button>
            {(isFiltersActive || dateFilterValue !== null) && (
              <Button variant="text" onClick={resetFilters}>
                <RemoveCircleOutlineIcon fill="#448aff" />
                &nbsp; Reset all
              </Button>
            )}

            {/* Account reports button */}
            {!!canGenerateMarginReports && !errMessage && accounts.length && (
              <>
                <Button
                  variant="text"
                  sx={{ color: 'black' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleReportsPopper();
                  }}
                  ref={reportsAnchor}
                >
                  <CalculateOutlinedIcon />
                  &nbsp; Account reports
                </Button>
                <AccountReportsPopper
                  open={isReportsPopperOpen}
                  handlePopperClose={handlePopperClose}
                  anchorEl={reportsAnchor}
                />
              </>
            )}

            {/* Date picker button */}
            <CustomDatePicker
              setDateFilter={{
                type: 'dispatch',
                func: accountsSlice.actions.setDateFilterValueLocal,
                setGlobalFunc: accountsSlice.actions.setDateFilterValue,
                clean: accountsSlice.actions.cleanDateFilterValues,
              }}
              dateFilterValueLocal={dateFilterValueLocal}
            />
          </Box>
        )}
      </Box>

      {!accounts.length && !errMessage && (
        <Box
          display="flex"
          justifyContent="center"
          alignContent="center"
          sx={{
            height: '90%',
            backgroundColor: 'white',
            display: 'grid',
            verticalAlign: 'middle',
          }}
        >
          <Typography variant="h1" mx="2" color="darkslategray">
            {"You don't have any accounts assigned to you"}
          </Typography>
        </Box>
      )}

      {!!accounts.length && !errMessage && (
        <BudgetsHeaderForList
          title={filterBy ?? ''}
          accounts={accounts}
          user={user}
          onFilterValueClick={handleFiltersOnClick}
          onFilterValueClose={onResetFilterValuesClick}
        />
      )}
      {!(!accounts.length && !errMessage) && (
        <>
          <Box mb="40px">
            <AccountsTable
              accounts={accounts}
              isCLevelUser={isCLevelUser ?? false}
            />
          </Box>
          {accountsPageFilters.accountStatus === AccountStatus.Active && (
            <Stack
              sx={{
                position: 'fixed',
                bottom: 0,
                padding: '16px 0px 16px 16px',
                bgcolor: 'background.default',
                width: '100%',
              }}
            >
              <Stack flexDirection="row" gap="8px">
                <WorkOffOutlinedIcon color="action" />
                <Button
                  variant="text"
                  sx={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: 'black',
                    fontSize: '14px',
                    padding: '0',
                    background: 'none !important',
                  }}
                  onClick={() => {
                    dispatch(
                      accountsSlice.actions.setAccountsPageFilters({
                        accountStatus: null,
                      }),
                    );
                  }}
                >
                  Show closed accounts
                </Button>
              </Stack>
            </Stack>
          )}
        </>
      )}
    </PageWrapper>
  );
};

interface BudgetFilterHeaderProps {
  user?: BaseManager | null;
  accounts: AccountOverview[];
  title: string;
  onFilterValueClick: () => void;
  onFilterValueClose: () => void;
}

const BudgetsHeaderForList = ({
  accounts,
  title,
  onFilterValueClick,
  onFilterValueClose,
}: BudgetFilterHeaderProps) => {
  const projectCount = accounts?.reduce(
    (sum: number, obj: any) => sum + obj.projects?.length,
    0,
  );

  const peopleCount = accounts?.reduce(
    (sum: number, obj: any) => sum + sumUpAccountMembers(obj),
    0,
  );

  if (!accounts?.length) {
    return <p>No accounts info found!</p>;
  }
  return (
    <StyledBudgetsSummary>
      <Box
        sx={{
          display: 'contents',
          flexDirection: 'row',
          maxWidth: '90%',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <Info title={title}>
          <BudgetsHeaderUserChip
            onFilterValueClick={onFilterValueClick}
            onFilterValueClose={onFilterValueClose}
          />
        </Info>
        <Info title="Accounts">
          <CustomNumber value={accounts.length}>{accounts.length}</CustomNumber>
        </Info>
        <Info title="Teams">
          <CustomNumber value={projectCount}>
            {projectCount ? projectCount : 0}
          </CustomNumber>
        </Info>
        <Info title="Headcount">
          <CustomNumber value={peopleCount}>
            {peopleCount ? peopleCount : 0}
          </CustomNumber>
        </Info>
        <GrossMarginSummary
          marginHighlights={accounts[0]?.marginHighlights}
          grossMargin={accounts[0]?.portfolioMargins?.grossMargin}
        />
      </Box>
    </StyledBudgetsSummary>
  );
};

export interface BudgetsHeaderUserChipProps {
  onFilterValueClick: () => void;
  onFilterValueClose: () => void;
}

const BudgetsHeaderUserChip = ({
  onFilterValueClick,
  onFilterValueClose,
}: BudgetsHeaderUserChipProps) => {
  const accountsPageFilters = useAppSelector(selectAccountsPageFilters);
  const filterBy = accountsPageFilters.filterBy;
  const filterValue = accountsPageFilters.filterValues;
  const accounts = useAppSelector(accountsSelectors.getProcessedAccounts);
  const user = useAppSelector(userDataSelectors.getUserData);

  const headerUser = useMemo(() => {
    if (filterValue.length === 0) return user;

    return accounts
      .flatMap((x) => [
        x.portfolioDeliveryManager,
        x.deliveryManager,
        x.clientPartner,
      ])
      .find((x) => {
        return x?.id === filterValue[0];
      });
  }, [accounts, filterValue, user]);

  if (!headerUser) {
    return <div>No user info</div>;
  }

  if (filterValue.length > 0) {
    if (filterValue.length === 1) {
      return <MemberInfoCell userInfo={headerUser} />;
    } else {
      return (
        <Box sx={{ display: 'grid', placeItems: 'center' }}>
          <Chip
            label={`${filterValue.length} ${filterBy}s selected`}
            onClick={onFilterValueClick}
            color="primary"
            variant="outlined"
            onDelete={onFilterValueClose}
          />
        </Box>
      );
    }
  }

  return <MemberInfoCell userInfo={headerUser} />;
};

const getFetchAccountsParams = ({
  filterValue,
  filterBy,
  dateFilterValue,
  accountStatus,
}: {
  filterValue: string[];
  filterBy: FilterAccountsBy;
  dateFilterValue: string | null;
  accountStatus: AccountStatus | null;
}): GetAccountsData => {
  const isResponsiblePeople = filterValue.length > 0;
  return {
    fetchAllMembers: true,
    ...((isResponsiblePeople || accountStatus) && {
      departmentFilters: {
        ...(filterBy === FilterAccountsBy.DeliveryManager &&
          isResponsiblePeople && {
            dmId: filterValue,
          }),
        ...(filterBy === FilterAccountsBy.PortfolioDeliveryManager &&
          isResponsiblePeople && {
            pdmId: filterValue,
          }),
        ...(filterBy === FilterAccountsBy.ClientPartner &&
          isResponsiblePeople && {
            cpId: filterValue,
          }),
        ...(accountStatus && {
          status: accountStatus,
        }),
      },
    }),
    ...(dateFilterValue && {
      periodFiltering: {
        from: dateFilterValue,
        to: dateFilterValue,
      },
    }),
  };
};
