import React, { useEffect, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import _sumBy from 'lodash/sumBy';
import {
  isSharedKpiType,
  checkKpiDeactivatedBeforeFilteredPeriod,
  getMonthsListForFilteredPeriod,
  AllTimeFilter,
  addMonthsToPeriod,
  parseBonusPlan,
} from '../../Contractor.utils';
import { cn, formatYYYYMMDateToMMMMyyyyString, is } from '../../../../utils';
import { priceFormat } from '../../../../components/tableCellRenders';
import { ColumnTextAlign, KPI_FREQUENCY } from '../../../../constants';
import { CustomTableCell } from '../../../../components/customTable';
import {
  Typography,
  Tooltip,
  Link,
  CircularProgress,
  Box,
  useTheme,
} from '@mui/material';
import {
  IndividualKpi,
  SharedKpiModel,
  GeneralKpiModel,
  PlainObject,
  PeriodType,
  KpiMetricStatRow,
} from '../../../../types';
import { StatisticResult, Quarters } from './KpiMetricTableRow.types';
import {
  collectQuarters,
  getDeactivatedDateString,
} from './KpiMetricTableRow.utils';
import { KpiMetricRowContent } from './KpiMetricRowContent';
import { KpiMetricBestWorstCells } from './KpiMetricBestWorstCells';
import { ContractorMenuDropDown } from '../contractorMenuDropDown/ContractorMenuDropDown';
import { FilterPeriodModel } from '../../Contractor.types';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import { useAppSelector } from '../../../../redux/hooks';
import { contractorSelectors } from '../../../../redux/contractor';
import { uniqueId } from 'lodash';

interface Props {
  handleDeleteMetric: (userKpiAssignmentId: string) => void;
  handleEditMetric: (kpi: GeneralKpiModel) => void;
  getAllResults: (kpi: GeneralKpiModel) => void;
  kpi: GeneralKpiModel;
  currentPeriod: PeriodType | null;
  hideMoreActions?: boolean;
  filteredPeriod: FilterPeriodModel;
}

function KpiMetricTableRow({
  handleDeleteMetric,
  handleEditMetric,
  getAllResults,
  kpi,
  currentPeriod,
  hideMoreActions,
  filteredPeriod,
}: Props): JSX.Element {
  const { palette } = useTheme();

  const [viewAllResults, setViewAllResults] = useState<boolean>(false);

  // eslint-disable-next-line
  const loadingAllResultsFor = [
    ...useAppSelector(contractorSelectors.getContractorKpiLoading),
  ];

  const loadingKpiAllResults = useMemo(
    () => loadingAllResultsFor.includes(kpi.id),
    [kpi, loadingAllResultsFor],
  );

  const { deactivated, bonusPlanSegments, endPeriod } = useMemo(() => {
    return parseBonusPlan(currentPeriod, kpi.deletedAt, kpi.bonusPlanEntries);
  }, [currentPeriod, kpi]);

  const isDeactivatedBeforeFilteredPeriod = useMemo(
    () =>
      checkKpiDeactivatedBeforeFilteredPeriod(
        currentPeriod,
        endPeriod,
        filteredPeriod,
      ),
    [filteredPeriod, currentPeriod, endPeriod],
  );

  const metricKpiType = kpi.kpiDefinition.type;
  const isQuarterlyMetric =
    kpi.kpiDefinition.frequency === KPI_FREQUENCY.QUARTERLY;

  useEffect(() => {
    setViewAllResults(false);
  }, [filteredPeriod]);

  const statRows = useMemo(() => {
    const result: KpiMetricStatRow[] = [];
    const months = getMonthsListForFilteredPeriod(
      currentPeriod,
      viewAllResults ? AllTimeFilter : filteredPeriod,
      kpi,
      isQuarterlyMetric,
    );

    months.forEach((month) => {
      const row = kpi.stats?.rows?.find(
        (x) => x.period === addMonthsToPeriod(month, isQuarterlyMetric ? 1 : 0),
      );

      if (row) {
        result.push(row);
      } else {
        result.push({
          period: month,
        });
      }
    });

    return result;
  }, [kpi, filteredPeriod, currentPeriod, viewAllResults, isQuarterlyMetric]);

  const { quarters, total, isStatContainsCurrent }: StatisticResult =
    useMemo((): StatisticResult => {
      const isStatContainsCurrentMonth =
        !!currentPeriod &&
        !!statRows.find(({ period }) => period === currentPeriod.period);

      if (!isQuarterlyMetric) {
        return {
          total: _sumBy(statRows, 'payout') + _sumBy(statRows, 'trueup'),
          quarters: [],
          isStatContainsCurrent: isStatContainsCurrentMonth,
        };
      }

      const quarters: PlainObject<Quarters> = collectQuarters(statRows);

      const isStatContainsCurrentQuarter = false;

      return {
        total:
          _sumBy(Object.values(quarters), 'payout') +
          _sumBy(Object.values(quarters), 'trueup'),
        quarters: Object.values(quarters),
        isStatContainsCurrent: isStatContainsCurrentQuarter,
      };
    }, [currentPeriod, statRows, isQuarterlyMetric]);

  const deleteMetric = (kpi: IndividualKpi | SharedKpiModel) => {
    if (isSharedKpiType(kpi)) {
      if (!kpi.userSharedKpis || !kpi.userSharedKpis[0]) {
        return;
      }

      return handleDeleteMetric(kpi.userSharedKpis[0].id);
    }

    handleDeleteMetric(kpi.id);
  };

  const RenderTitleTableCell = (index: number) => {
    if (index > 1) {
      return <TableCell scope="row" />;
    }
    const dropdown = !deactivated && !hideMoreActions && (
      <ContractorMenuDropDown
        handleEditMetric={() => handleEditMetric(kpi)}
        deleteMetric={() => deleteMetric(kpi)}
      />
    );

    if (index === 1) {
      return (
        <CustomTableCell
          autoWidth
          typographyVariant="body2"
          scope="row"
          sx={{
            paddingTop: 0,
            paddingRight: 0,
          }}
        >
          <Stack flexDirection={'row'}>
            <Box>
              <Typography>Effective periods:</Typography>
              {bonusPlanSegments.map((bonusPlanSegment) =>
                bonusPlanSegment.to ? (
                  <Typography key={bonusPlanSegment.from}>
                    {`${formatYYYYMMDateToMMMMyyyyString(
                      bonusPlanSegment.from,
                      true,
                    )}  - ${formatYYYYMMDateToMMMMyyyyString(
                      bonusPlanSegment.to,
                      true,
                    )}`}
                  </Typography>
                ) : (
                  <Typography key={bonusPlanSegment.from}>
                    {`Ongoing since ${formatYYYYMMDateToMMMMyyyyString(
                      bonusPlanSegment.from,
                      true,
                    )}`}
                  </Typography>
                ),
              )}
            </Box>
          </Stack>
        </CustomTableCell>
      );
    }

    return is.sharedMetric(kpi) ? (
      <CustomTableCell
        autoWidth
        scope="row"
        pure
        sx={{
          paddingRight: 0,
        }}
      >
        <Stack direction="row" alignItems="center">
          <Typography variant="body1">
            <Tooltip title="Shared metric" enterDelay={300} placement="top">
              <span
                style={{
                  color: palette.secondary.contrastText,
                  backgroundColor: palette.secondary.main,
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  margin: '-2px 4px 0 0',
                  width: '14px',
                  height: '14px',
                  borderRadius: '4px',
                  textAlign: 'center',
                  lineHeight: '14px',
                }}
              >
                <GroupRoundedIcon style={{ fontSize: 10 }} />
              </span>
            </Tooltip>
            {kpi.kpiDefinition?.name}
          </Typography>
          {dropdown}
        </Stack>
      </CustomTableCell>
    ) : (
      <CustomTableCell autoWidth scope="row" pure>
        <Stack direction="row" alignItems="center">
          <Typography variant="body1">{kpi.kpiDefinition?.name}</Typography>
          {dropdown}
        </Stack>
      </CustomTableCell>
    );
  };

  const dataRows = useMemo((): JSX.Element[] => {
    const isAllClosed = deactivated || isStatContainsCurrent || !currentPeriod;
    const renderMonths = (
      periods: KpiMetricStatRow[],
      isAllPeriodsClosed: boolean,
    ): JSX.Element[] => {
      return periods.map((period, index) => {
        const isFirst = index === 0 ? kpi : undefined;
        const isLast = index === periods.length - 1;
        const isCurrent = period.period === currentPeriod?.period;

        return (
          <KpiMetricRowContent
            key={period.period}
            name={period.period}
            kpiType={metricKpiType}
            frequency={kpi.kpiDefinition.frequency}
            payout={period.payout}
            result={period.value}
            statItem={
              isFirst && period?.bonusShare === undefined ? kpi : period
            }
            isFirst={isFirst && isAllPeriodsClosed}
            isLast={isLast}
            isCurrent={isCurrent}
            trueup={period.trueup}
          />
        );
      });
    };
    const renderQuarters = (isAllQuartersClosed: boolean): JSX.Element[] => {
      return quarters.reduce((acum, quarter, index): JSX.Element[] => {
        const { name, payout, value, trueup } = quarter;
        const isFirstQuarter = index === 0;
        const isLastQuarter = index === quarters.length - 1;
        const isCurrent = quarter.period?.period === currentPeriod?.period;

        return [
          ...acum,
          <KpiMetricRowContent
            key={name}
            name={name}
            kpiType={metricKpiType}
            frequency={kpi.kpiDefinition.frequency}
            payout={payout}
            result={value}
            statItem={
              isFirstQuarter && !quarter.period?.bonusShare
                ? kpi
                : quarter.period
            }
            isFirst={isFirstQuarter && isAllQuartersClosed}
            isLast={isLastQuarter}
            isCurrent={isCurrent}
            trueup={trueup}
          />,
        ];
      }, [] as JSX.Element[]);
    };

    const elements = isQuarterlyMetric
      ? renderQuarters(isAllClosed)
      : renderMonths(statRows, isAllClosed);

    return elements;
  }, [
    statRows,
    currentPeriod,
    deactivated,
    isQuarterlyMetric,
    isStatContainsCurrent,
    kpi,
    metricKpiType,
    quarters,
  ]);

  const renderedRows = useMemo(() => {
    const handleViewResults = () => {
      setViewAllResults(true);
      getAllResults(kpi);
    };

    const viewAllResultsElement = (
      <>
        <CustomTableCell colSpan={3} autoWidth scope="row" />
        <CustomTableCell
          colSpan={4}
          autoWidth
          typographyVariant="body2"
          scope="row"
          sx={{ paddingTop: 0, verticalAlign: 'baseline' }}
        >
          {loadingKpiAllResults ? (
            <CircularProgress size={12} color="secondary" />
          ) : viewAllResults && !dataRows.length ? (
            'There are no results'
          ) : (
            <Link
              underline="hover"
              sx={{ cursor: 'pointer' }}
              onClick={handleViewResults}
            >
              View all results
            </Link>
          )}
        </CustomTableCell>
      </>
    );

    const deactivatedElement = (
      <>
        <KpiMetricBestWorstCells
          item={kpi}
          isFirst
          isCurrent
          kpiType={metricKpiType}
          frequency={kpi.kpiDefinition.frequency}
        />
        <CustomTableCell colSpan={4} disabled autoWidth scope="row" nowrap>
          {getDeactivatedDateString(endPeriod)}
        </CustomTableCell>
      </>
    );

    const noMetricsToShowElement = (
      <>
        <KpiMetricBestWorstCells
          item={kpi}
          isFirst
          isCurrent
          kpiType={metricKpiType}
          frequency={kpi.kpiDefinition.frequency}
        />
        <CustomTableCell colSpan={4} disabled autoWidth scope="row" nowrap>
          {`Metric doesn't have results for this period`}
        </CustomTableCell>
      </>
    );

    const emptyRow = (
      <>
        <KpiMetricBestWorstCells
          item={kpi}
          isFirst
          isCurrent
          kpiType={metricKpiType}
          frequency={kpi.kpiDefinition.frequency}
        />
        <CustomTableCell colSpan={3} disabled autoWidth scope="row" nowrap />
      </>
    );

    const rows = [
      ...dataRows,
      ...[
        emptyRow,
        <CustomTableCell
          key={uniqueId()}
          autoWidth
          colSpan={6}
          sx={{ whiteSpace: 'nowrap' }}
        />,
      ].slice(dataRows.length),
    ];

    if (isDeactivatedBeforeFilteredPeriod)
      return [deactivatedElement, viewAllResultsElement];

    if (!dataRows.length)
      return [noMetricsToShowElement, viewAllResultsElement];

    return rows;
  }, [
    dataRows,
    isDeactivatedBeforeFilteredPeriod,
    kpi,
    loadingKpiAllResults,
    metricKpiType,
    endPeriod,
    viewAllResults,
    getAllResults,
  ]);

  const payout = (
    <CustomTableCell
      width={90}
      sx={{ whiteSpace: 'nowrap' }}
      fontWeight={600}
      align={ColumnTextAlign.right}
      nowrap
    >
      {priceFormat({ value: total })}
    </CustomTableCell>
  );

  const renderBaseRow = (item: JSX.Element, index: number) => (
    <TableRow
      sx={{
        '.time-icon': {
          color: '#b2b5bf',
        },
      }}
      key={index}
      className={cn(
        index === renderedRows.length - 1 ? 'space-bottom' : 'no-border',
        !index && 'space-top',
      )}
    >
      {RenderTitleTableCell(index)}

      {item}

      {!index &&
        !isDeactivatedBeforeFilteredPeriod &&
        !!dataRows.length &&
        payout}
    </TableRow>
  );

  return <>{renderedRows.map(renderBaseRow)}</>;
}

export default KpiMetricTableRow;
