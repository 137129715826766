import React, { CSSProperties, FC, memo, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';

interface Props {
  isLoading: boolean;
  element: ReactNode;
  style?: CSSProperties;
  count?: number;
}

const RockySkeletonComponent: FC<Props> = ({
  isLoading,
  element,
  style,
  count = 1,
}) => {
  return <>{isLoading ? <Skeleton style={style} count={count} /> : element}</>;
};

export const RockySkeleton = memo(RockySkeletonComponent);
