import { DepartmentType } from '../types';

export function getEmptyDepartment(
  props: Partial<DepartmentType> = {},
): DepartmentType {
  return {
    breadCrumbs: [],
    createdAt: '2022-04-06T03:00:25.787Z',
    deletedAt: null,
    id: 'emptyId',
    members: [],
    name: 'Some name',
    parentId: null,
    sharedKpis: [],
    subDepartments: [],
    updatedAt: '2022-04-06T03:00:25.787Z',
    zohoId: 'emptyZohoId',

    ...props,
  };
}

export const departmentTree = [
  {
    id: 'id_agile_engine',
    name: 'Agile Engine',
    parentId: '',
    createdAt: '2022-03-29T11:43:43.588Z',
    updatedAt: '2022-03-29T11:43:43.588Z',
    deletedAt: '2022-03-29T11:43:43.588Z',
    members: [
      {
        id: 'id-alex_alinovsky@agileengine.com',
        email: 'alex_alinovsky@agileengine.com',
        fullName: 'Alex Kalinovsky',
        country: 'USA',
        city: 'Washington',
        refreshToken: 'token',
        refreshTokenExpireAt: '2022-03-29T11:43:43.588Z',
        departmentId: 'id_agile_engine',
        createdAt: '2022-03-29T11:43:43.588Z',
        updatedAt: '2022-03-29T11:43:43.588Z',
        deletedAt: '2022-03-29T11:43:43.588Z',
      },
    ],
    subDepartments: [
      {
        id: 'id_marketing_department',
        name: 'Marketing Department',
        parentId: 'id_agile_engine',
        createdAt: '2022-03-29T11:43:43.588Z',
        updatedAt: '2022-03-29T11:43:43.588Z',
        deletedAt: '2022-03-29T11:43:43.588Z',
        members: [
          {
            id: 'id_alex_kalinovsky@agileengine.com',
            email: 'alex_kalinovsky@agileengine.com',
            fullName: 'Alex Kalinovsky',
            country: 'USA',
            city: 'Washington',
            refreshToken: 'token',
            refreshTokenExpireAt: '2022-03-29T11:43:43.588Z',
            departmentId: 'id_marketing_department',
            createdAt: '2022-03-29T11:43:43.588Z',
            updatedAt: '2022-03-29T11:43:43.588Z',
            deletedAt: '2022-03-29T11:43:43.588Z',
          },
          {
            id: 'id_alex_alinovsky_2@agileengine.com',
            email: 'alex_alinovsky_2@agileengine.com',
            fullName: 'Alex Kalinovsky 2',
            country: 'USA',
            city: 'Washington',
            refreshToken: 'token',
            refreshTokenExpireAt: '2022-03-29T11:43:43.588Z',
            departmentId: 'id_marketing_department',
            createdAt: '2022-03-2943:43.588Z',
            updatedAt: '2022-03-29T11:43:43.588Z',
            deletedAt: '2022-03-29T11:43:43.588Z',
          },
        ],
        subDepartments: [
          {
            id: 'id_linkedin_department',
            name: 'LinkedIn Department',
            parentId: 'id_marketing_department',
            createdAt: '2022-03-29T11:43:43.588Z',
            updatedAt: '2022-03-29T11:43:43.588Z',
            deletedAt: '2022-03-29T11:43:43.588Z',
            members: [
              {
                id: 'id_alex_alinovsky@agileengine.com',
                email: 'alex_alinovsky@agileengine.com',
                fullName: 'Alex Kalinovsky',
                country: 'USA',
                city: 'Washington',
                refreshToken: 'token',
                refreshTokenExpireAt: '2022-03-29T11:43:43.588Z',
                departmentId: 'id_linkedin_department',
                createdAt: '2022-03-29T11:43:43.588Z',
                updatedAt: '2022-03-29T11:43:43.588Z',
                deletedAt: '2022-03-29T11:43:43.588Z',
              },
              {
                id: 'id_alex_alinovsky_2@agileengine.com',
                email: 'alex_alinovsky_2@agileengine.com',
                fullName: 'Alex Kalinovsky 2',
                country: 'USA',
                city: 'Washington',
                refreshToken: 'token',
                refreshTokenExpireAt: '2022-03-29T11:43:43.588Z',
                departmentId: 'id_linkedin_department',
                createdAt: '2022-03-2943:43.588Z',
                updatedAt: '2022-03-29T11:43:43.588Z',
                deletedAt: '2022-03-29T11:43:43.588Z',
              },
            ],
            subDepartments: [],
          },
        ],
      },
    ],
  },
];
