import React from 'react';
import { Box, Table, TableBody } from '@mui/material';
import {
  DepartmentType,
  SharedKpiModel,
  ContractorType,
  IndividualKpi,
} from '../../../types';
import { TeamTable } from '../TeamTable/TeamTable';
import { SharedKpiRows } from '../SharedKpiRows/SharedKpiRows';
import { MemberKpisRows } from '../MemberKpisRows/MemberKpisRows';
import { ListItemCard } from '../../../components';
import { plural } from '../../../utils';
import {
  PeopleIconWrapper,
  priceFormat,
} from '../../../components/tableCellRenders';
import _uniqBy from 'lodash/uniqBy';
import _flatten from 'lodash/flatten';
import { LIST_GROUPED_BY_TEAMS } from '../Approvals.const';

interface Props {
  departments: DepartmentType[];
}

export function ListGroupedByTeams({ departments }: Props): JSX.Element {
  const { departments: departmentsByTeam, sharedKpis } =
    groupDepartmentsByTeams(departments);

  const { bonusShare } = countMaxWidth(departmentsByTeam);

  return (
    <Box data-testid={LIST_GROUPED_BY_TEAMS}>
      {sharedKpis && !!sharedKpis.length && (
        <ListItemCard
          expanded={false}
          enlargeTitle
          title={'Shared metrics'}
          description={`${sharedKpis.length} metric${plural(
            sharedKpis.length,
          )}`}
          titleWrapper={(title) => (
            <PeopleIconWrapper>{title}</PeopleIconWrapper>
          )}
        >
          <Table>
            <TableBody>
              {sharedKpis.map((sharedKpi) => (
                <SharedKpiRows
                  key={sharedKpi.kpiDefinition.id}
                  data={sharedKpi}
                />
              ))}
            </TableBody>
          </Table>
        </ListItemCard>
      )}

      {departmentsByTeam.map((department) => (
        <TeamTable key={department.id} title={department.name}>
          {department.sharedKpis.map((sharedKpi) => (
            <SharedKpiRows key={sharedKpi.kpiDefinition.id} data={sharedKpi} />
          ))}

          {department.members.map((member) => (
            <MemberKpisRows
              key={member.id}
              member={member}
              minWidth={bonusShare}
            />
          ))}
        </TeamTable>
      ))}
    </Box>
  );
}

interface CountResultModel {
  bonusShare: number;
}

function getMaxSharedMetric(sharedKpis: SharedKpiModel[]): CountResultModel {
  return sharedKpis.reduce(
    (acum, { userSharedKpis }): CountResultModel => ({
      bonusShare: Math.max(
        userSharedKpis[0] && userSharedKpis[0].bonusShare,
        acum.bonusShare,
      ),
    }),
    {
      bonusShare: 0,
    } as CountResultModel,
  );
}

function getMaxIndividualMetric(
  individualKpis: IndividualKpi[],
): CountResultModel {
  return individualKpis.reduce(
    (acum, { bonusShare }): CountResultModel => ({
      bonusShare: Math.max(bonusShare, acum.bonusShare),
    }),
    {
      bonusShare: 0,
    } as CountResultModel,
  );
}

function countMaxValues(departments: DepartmentType[]): CountResultModel {
  const allMembers: ContractorType[] = _flatten(
    departments.map(({ members }) => members),
  );

  return allMembers.reduce(
    (acum, { sharedKpis, individualKpis }): CountResultModel => {
      const maxShared = getMaxSharedMetric(sharedKpis);
      const maxIndividual = getMaxIndividualMetric(individualKpis);

      const maxBonus = Math.max(maxShared.bonusShare, maxIndividual.bonusShare);

      return {
        bonusShare: Math.max(acum.bonusShare, maxBonus),
      };
    },
    {
      bonusShare: 0,
    } as CountResultModel,
  );
}

function countMaxWidth(departments: DepartmentType[]): CountResultModel {
  const { bonusShare } = countMaxValues(departments);

  return {
    bonusShare: String(priceFormat({ value: bonusShare })).length * 9,
  };
}

interface DepartmentsByTeamsResult {
  departments: DepartmentType[];
  sharedKpis: SharedKpiModel[];
}

function groupDepartmentsByTeams(
  deps: DepartmentType[],
): DepartmentsByTeamsResult {
  if (!deps.length) {
    return {
      departments: [],
      sharedKpis: [],
    };
  }

  const res = deps.reduce(
    (acum, department): DepartmentsByTeamsResult => {
      const { departments, sharedKpis } = groupDepartmentsByTeams(
        department.subDepartments || [],
      );

      return {
        departments: [...acum.departments, ...departments],
        sharedKpis: [
          ...acum.sharedKpis,
          ...department.sharedKpis,
          ...sharedKpis,
        ],
      };
    },
    {
      departments: [],
      sharedKpis: [],
    } as DepartmentsByTeamsResult,
  );

  const newDeps = deps.map((dep) => ({
    ...dep,
    subDepartments: [],
    sharedKpis: [],
  }));

  return {
    sharedKpis: _uniqBy(res.sharedKpis, 'id'),
    departments: [...newDeps, ...res.departments].filter(
      (department) =>
        (department.members && department.members.length) ||
        (department.sharedKpis && department.sharedKpis.length),
    ),
  };
}
