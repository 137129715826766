import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { titleSelector } from '../redux/pageTitle/pageTitle.selectors';

const DEFAULT_TITLE = 'Rocky Finance';
const ROCKY_FINANCE_TITLE_POSTFIX = ' | Rocky Finance';

export const useObserveTitle = (): string => {
  const title = useSelector(titleSelector);

  useEffect(() => {
    document.title =
      title !== DEFAULT_TITLE
        ? `${title} ${ROCKY_FINANCE_TITLE_POSTFIX}`
        : DEFAULT_TITLE;
  }, [title]);

  return title;
};
