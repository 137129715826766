import React from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import { drawerWidth } from '../../theme';
import logo from '../../assets/logo.svg';
import Navigation from '../navigation/Navigation';
import UserInfo from './components/userInfo/UserInfo';
import { UsersSearchBox } from '../../components';
import { useAppSelector } from '../../redux/hooks';
import { userDataSelectors } from '../../redux/userData';

const Sidebar: React.FC = () => {
  const usersList = useAppSelector(userDataSelectors.getUsersList);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      PaperProps={{ elevation: 1 }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          pt: 1.25,
          border: 'none',
          borderRadius: 0,
          ['@media (max-width: 1220px)']: {
            position: 'inherit',
          },
        },
      }}
    >
      <Link to="/">
        <img src={logo} alt="Rocky Finance" />
      </Link>
      <Grid container spacing={1} pt={1} pl={1} pr={2} pb={2}>
        {usersList && !!usersList.length && (
          <Grid item xs={12}>
            <UsersSearchBox options={usersList || []} />
          </Grid>
        )}
      </Grid>
      <Navigation />
      <UserInfo />
    </Drawer>
  );
};

export default Sidebar;
