import React from 'react';
import { Box, Table, TableBody, Typography } from '@mui/material';
import { PeriodsTableRow } from '../periodDataWidget/PeriodTableRow';
import { BonusHistory } from '../../../../types/contractor';

interface Props {
  bonusPlan: BonusHistory[];
  showPeriodYear?: boolean;
  label?: string;
}

export const BonusPlanTable = ({ bonusPlan, showPeriodYear, label }: Props) => {
  const periodRows = bonusPlan.map((bonusShareRow, index) => (
    <PeriodsTableRow
      key={index}
      period={bonusShareRow.period}
      payout={bonusShareRow.bonusShare}
      prompt={(bonusShareRow as any).prompt}
      isLast={index === bonusPlan.length - 1}
      isFirst={index === 0}
      showPeriodYear={showPeriodYear}
      bonusDetails={bonusShareRow.bonusDetails}
    />
  ));

  return (
    <Box mt={0.25}>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ ml: 0.75, pb: 0.5, my: 0.5 }}
      >
        {label || 'Bonus plan'}
      </Typography>
      <Table size="small">
        <TableBody>{periodRows}</TableBody>
      </Table>
    </Box>
  );
};
