import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KpiDefinitionItem, ContractorType } from '../../types';
import { kpiDefinitionsStoreKey } from './kpiDefinitions.const';
import {
  fetchKpiDefinitions,
  saveKpiDefinition,
  fetchAvailableKpiOwners,
  createNewKpiDefinition,
  fetchKpiDefinitionsCategories,
} from './kpiDefinitions.thunks';
import {
  KpiDefinitionCategoryItem,
  KpiDefinitionItemWithOwners,
} from './kpiDefinitions.types';

export interface KpiDefinitionDataState {
  kpiDefinitions: KpiDefinitionItemWithOwners[];
  availableKpiOwners?: ContractorType[];
  createdKpiDefinition?: KpiDefinitionItem;
  loading: boolean;
  createdKpiDefinitionLoading: boolean;
  categories: KpiDefinitionCategoryItem[];
}

const initialState: KpiDefinitionDataState = {
  kpiDefinitions: [],
  loading: false,
  createdKpiDefinitionLoading: false,
  categories: [],
};

export const kpiDefinitionsSlice = createSlice({
  name: kpiDefinitionsStoreKey,
  initialState,
  reducers: {
    reset: () => initialState,
    resetKpiDefinitionModal: (state) => {
      delete state.createdKpiDefinition;
    },
  },
  extraReducers: (builder) => {
    // fetch data
    builder
      .addCase(fetchKpiDefinitions.pending, (state) => {
        state.loading = true;
        delete state.createdKpiDefinition;
      })
      .addCase(
        fetchKpiDefinitions.fulfilled,
        (state, { payload }: PayloadAction<KpiDefinitionItem[]>) => {
          state.loading = false;

          state.kpiDefinitions = payload;
        },
      )
      .addCase(fetchKpiDefinitions.rejected, (state) => {
        state.loading = false;
      });

    // save data
    builder
      .addCase(saveKpiDefinition.pending, (state) => {
        state.loading = true;
        delete state.createdKpiDefinition;
      })
      .addCase(
        saveKpiDefinition.fulfilled,
        (state, { payload }: PayloadAction<KpiDefinitionItem>) => {
          state.loading = false;

          state.kpiDefinitions = state.kpiDefinitions.map((item) => {
            if (item.id !== payload.id) {
              return item;
            }

            return { ...item, ...payload };
          });
        },
      )
      .addCase(saveKpiDefinition.rejected, (state) => {
        state.loading = false;
      });

    // fetch list of available KPI owners
    builder.addCase(
      fetchAvailableKpiOwners.fulfilled,
      (state, { payload }: PayloadAction<{ data: ContractorType[] }>) => {
        state.availableKpiOwners = payload.data || [];
      },
    );

    // create new KPI
    builder
      .addCase(createNewKpiDefinition.pending, (state) => {
        state.createdKpiDefinitionLoading = true;
        delete state.createdKpiDefinition;
      })
      .addCase(
        createNewKpiDefinition.fulfilled,
        (state, { payload }: PayloadAction<KpiDefinitionItem>) => {
          state.createdKpiDefinition = payload;
          state.createdKpiDefinitionLoading = false;
        },
      )
      .addCase(createNewKpiDefinition.rejected, (state) => {
        state.createdKpiDefinitionLoading = false;
      });

    // fetch categories
    builder.addCase(
      fetchKpiDefinitionsCategories.fulfilled,
      (state, { payload }: PayloadAction<KpiDefinitionCategoryItem[]>) => {
        state.categories = payload || [];
      },
    );
  },
});
