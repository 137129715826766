import { Typography } from '@mui/material/styles/createTypography';
import * as themeScale from './variables';

export default <Typography>{
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Inter var"',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  h1: {
    fontWeight: themeScale.fontWeightBold,
    fontSize: themeScale.fontSizeH1,
    letterSpacing: '-0.24px',
  },
  h2: {
    fontWeight: themeScale.fontWeightBold,
    fontSize: themeScale.fontSizeH2,
    letterSpacing: '-0.24px',
  },
  h3: {
    fontWeight: themeScale.fontWeightBold,
    fontSize: themeScale.fontSizeH3,
    letterSpacing: '-0.06px',
  },
  h4: {
    fontWeight: themeScale.fontWeightBold,
    fontSize: themeScale.fontSizeH4,
    lineHeight: '150%',
  },
  h5: {
    fontWeight: 400,
    fontSize: themeScale.fontSizeH5,
    lineHeight: '150%',
  },
  h6: {
    fontWeight: themeScale.fontWeightBold,
    fontSize: themeScale.fontSizeH6,
  },
  overline: {
    fontWeight: themeScale.fontWeightBold,
  },
  button: {
    textTransform: 'none',
    fontSize: themeScale.fontSizeRegular,
    fontWeight: themeScale.fontWeightBold,
  },
  body1: {
    fontSize: themeScale.fontSizeRegular,
    fontWeight: 400,
    lineHeight: '150%',
  },
  body2: {
    fontSize: themeScale.fontSizeSmall,
    fontWeight: 400,
    lineHeight: '130%',
  },
  subtitle1: {
    fontWeight: themeScale.fontWeightBold,
    fontSize: themeScale.fontSizeRegular,
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: themeScale.fontSizeSmall,
    lineHeight: '130%',
  },
  caption: {
    fontSize: themeScale.fontSizeSmall,
  },
};
