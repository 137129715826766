import { TableRow, styled } from '@mui/material';

export const ProjectTableRow = styled(TableRow, {
  shouldForwardProp: (propName) =>
    !['isProjectView'].includes(propName as string),
})<{ isProjectView: boolean }>`
  background: ${({ theme }) => theme.palette.background.default};
  &:hover {
    background: ${({ isProjectView, theme }) =>
      isProjectView && theme.palette.action.hover};
  }
`;
