import React, { CSSProperties } from 'react';

interface Props {
  style?: CSSProperties;
}

export function QuarterIcon({ style }: Props): JSX.Element {
  return (
    <svg
      style={style}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8918 3.10749C14.1189 2.33256 13.2004 1.718 12.1891 1.29912C11.1779 0.880243 10.0939 0.665305 8.99935 0.666655C7.90457 0.663748 6.82009 0.877975 5.80865 1.29694C4.79721 1.71591 3.8789 2.33129 3.10685 3.10749C1.53268 4.68165 0.666016 6.77499 0.666016 8.99999C0.666016 11.225 1.53268 13.3183 3.10685 14.8925C4.68102 16.4667 6.77435 17.3333 8.99935 17.3333C11.2243 17.3333 13.3177 16.4667 14.8918 14.8925C15.668 14.1204 16.2834 13.2021 16.7024 12.1907C17.1214 11.1792 17.3356 10.0948 17.3327 8.99999C17.334 7.90544 17.1191 6.82142 16.7002 5.81019C16.2813 4.79897 15.6668 3.88048 14.8918 3.10749ZM13.7135 13.7142C12.4543 14.9733 10.7802 15.6667 8.99935 15.6667C7.21852 15.6667 5.54435 14.9733 4.28518 13.7142C3.02602 12.455 2.33268 10.7808 2.33268 8.99999C2.33268 7.21916 3.02602 5.54499 4.28518 4.28582C4.90283 3.66484 5.63751 3.17254 6.4467 2.83739C7.25588 2.50224 8.12351 2.33091 8.99935 2.33332V8.99999H15.666C15.6684 9.87583 15.4971 10.7435 15.1619 11.5526C14.8268 12.3618 14.3345 13.0965 13.7135 13.7142Z"
        fill="black"
        fillOpacity="0.26"
      />
    </svg>
  );
}
