const bonusHistoryKey = '/bonus-history';

export const fetchUserBonusPlan = (userId: string) => {
  return `${bonusHistoryKey}/${userId}`;
};

export const updateUserBonusPlan = () => {
  return `${bonusHistoryKey}/`;
};

export const getBonusesByUserId = (userId: string) => {
  return `${bonusHistoryKey}/all/${userId}`;
};
