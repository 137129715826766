import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants/api';
import { reportsStoreKey } from './reports.const';
import { FetchReportsBodyType } from './reports.types';

export const fetchReports = createAsyncThunk(
  `${reportsStoreKey}/fetchReports`,
  async (body: FetchReportsBodyType) => {
    const response = await axios.post(`${apiEndpoints.reports()}`, body);
    return response.data;
  },
);
