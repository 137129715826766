import { Theme } from '@mui/material/styles';

import { borderRadius } from '../../../../../theme';

export const BoxStyles = {
  backgroundColor: (theme: Theme) => theme.palette.highlight.neutral,
  borderRadius,
  p: '1rem',
  mt: '1rem',
};
