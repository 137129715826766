export enum KpiStatus {
  NotSubmitted = 'NOT_SUBMITTED',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export const KpiStatusLabels = {
  NOT_SUBMITTED: 'Open',
  SUBMITTED: 'Waiting for approval',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
};

export enum TrueUpPeriodValue {
  Quarterly = 3,
  Yearly = 12,
}

export enum KpiDefinitionTrueupFormula {
  CORRECTED = 'CORRECTED',
  SUMMARIZED_FLOORED = 'SUMMARIZED_FLOORED',
}
