import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants/api';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { submissionsStoreKey } from './submissions.const';
import { SubmitSubmissionsBodyType } from './submissions.types';
import { approvalsStoreKey } from '../approvals';

export const fetchSubmissions = createAsyncThunk(
  `${submissionsStoreKey}/fetchSubmissions`,
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${apiEndpoints.submissions()}`);
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(
          formatErrorMessage(err, 'Fetching submissions failed'),
          { variant: 'error' },
        ),
      );
      throw new Error(String(err));
    }
  },
);

export const submitSubmissions = createAsyncThunk(
  `${submissionsStoreKey}/submitSubmissions`,
  async (body: SubmitSubmissionsBodyType, thunkAPI) => {
    try {
      const response = await axios.post(`${apiEndpoints.submitKpis()}`, body);
      thunkAPI.dispatch(notificationAlert('Submissions updated'));
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);

export const checkNewKpiBonus = createAsyncThunk(
  `${approvalsStoreKey}/checkNewKpiBonus`,
  async (
    kpiData: { id: string; value: string | number; period: number },
    thunkAPI,
  ) => {
    try {
      const resp = await axios.post(apiEndpoints.calculateKpiBonus(), {
        period: kpiData.period,
        kpis: [
          {
            userKpiAssignmentId: kpiData.id,
            value:
              typeof kpiData.value === 'number'
                ? kpiData.value
                : parseFloat(kpiData.value),
          },
        ],
      });

      return resp.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), {
          variant: 'error',
        }),
      );

      throw new Error(String(err));
    }
  },
);
