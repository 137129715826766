import { createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { clientInvoiceStoreKey } from './clientInvoice.const';
import { generateClientInvoice } from './clientInvoice.thunks';
import { ClientInvoiceState } from './clientInvoice.types';

const initialState: ClientInvoiceState = {
  apiStatus: API_STATUS.IDLE,
};

export const clientInvoiceSlice = createSlice({
  name: clientInvoiceStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateClientInvoice.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(generateClientInvoice.fulfilled, (state) => {
        state.apiStatus = API_STATUS.IDLE;
      })
      .addCase(generateClientInvoice.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });
  },
});
