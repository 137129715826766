import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants/api';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { clientInvoiceStoreKey } from './clientInvoice.const';
import { ClientInvoiceGenerate } from './clientInvoice.types';

export const generateClientInvoice = createAsyncThunk(
  `${clientInvoiceStoreKey}`,
  async (body: ClientInvoiceGenerate, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        notificationAlert(
          `This might take some time, pls don't edit the provided spreadsheets until the process is finished.`,
        ),
      );

      const response = await axios.post(
        `${apiEndpoints.clientInvoiceGenerate()}`,
        body,
      );

      thunkAPI.dispatch(notificationAlert('Client Invoices Generated!'));
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );

      throw new Error(String(err));
    }
  },
);
