import { KpiMetricStatRow, PlainObject } from '../../../../types';
import {
  yyyyMMToQuarterName,
  formatYYYYMMDateToMMMMyyyyString,
} from '../../../../utils';
import { Quarters } from './KpiMetricTableRow.types';

export function collectQuarters(rows: KpiMetricStatRow[]) {
  return [...rows]
    .sort((a, b) => b.period - a.period)
    .reduce((acum, stat): PlainObject<Quarters> => {
      const { period, payout, value, trueup } = stat;

      // For yyyyMMToQuarterName - we use Second Argument 'shiftMonths' because
      // first month of the year - represents result data for Q4 of prev year
      // and all periods represent data for previous quarter
      // so - month N4 - data for First Quarter, Q1
      // https://agileengine.atlassian.net/browse/FK-381
      const quarterName = yyyyMMToQuarterName(period, true);

      const quarter = {
        name: quarterName,
        value: value,
        payout:
          acum[quarterName]?.payout && payout
            ? acum[quarterName]?.payout + payout
            : acum[quarterName]?.payout ?? payout,
        trueup:
          !!acum[quarterName]?.trueup && !!trueup
            ? acum[quarterName]?.trueup + trueup
            : acum[quarterName]?.trueup ?? trueup,
        period: stat,
      };

      return {
        ...acum,
        [quarterName]: quarter,
      };
    }, {} as PlainObject<Quarters>);
}

export function getDeactivatedDateString(deactivatedAt: number | null): string {
  const deactivated = formatYYYYMMDateToMMMMyyyyString(deactivatedAt, true);

  return `Deactivated since ${deactivated}`;
}
