import React, { FC, memo } from 'react';

import { LoadingButton } from '@mui/lab';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { BUTTON_GENERATE_REPORT_TEST_ID } from '../constants';
import { Box, Tooltip } from '@mui/material';

interface GenerateReportProps {
  disabled: boolean;
  loading: boolean;
  onClick: () => void;
}

const GenerateReportButton: FC<GenerateReportProps> = ({
  disabled,
  loading,
  onClick,
}) => {
  return (
    <>
      <Tooltip
        title={disabled ? 'Please fill-in assessment to generate report' : ''}
        placement="top"
        disableInteractive
      >
        <Box>
          <LoadingButton
            variant="text"
            data-testid={BUTTON_GENERATE_REPORT_TEST_ID}
            disabled={disabled}
            loading={loading}
            startIcon={<PostAddIcon />}
            onClick={onClick}
          >
            Generate report
          </LoadingButton>
        </Box>
      </Tooltip>
    </>
  );
};

export default memo(GenerateReportButton);
