import MoreVertIcon from '@mui/icons-material/MoreVert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import HistoryIcon from '@mui/icons-material/HistoryRounded';
import ListItemText from '@mui/material/ListItemText';
import TuneIcon from '@mui/icons-material/TuneRounded';
import Divider from '@mui/material/Divider';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { PopoverButton } from '../../../../components';
import React from 'react';

interface Props {
  handleEditMetric: () => void;
  deleteMetric: () => void;
}

export function ContractorMenuDropDown({
  handleEditMetric,
  deleteMetric,
}: Props): JSX.Element {
  return (
    <PopoverButton
      buttonIcon={<MoreVertIcon fontSize="small" />}
      style={{
        margin: '-14px -32px -12px -8px',
        display: 'inline-block',
        verticalAlign: 'middle',
        paddingLeft: 2,
      }}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton disabled>
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText primary="Results history" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={handleEditMetric}>
            <ListItemIcon>
              <TuneIcon />
            </ListItemIcon>
            <ListItemText primary="Edit values" />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={deleteMetric}>
            <ListItemIcon>
              <RemoveCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Deactivate" />
          </ListItemButton>
        </ListItem>
      </List>
    </PopoverButton>
  );
}
