import { TableRow, styled } from '@mui/material';

interface StyledTableRowProps {
  isLastRow: boolean;
  isHidden?: boolean;
}

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (propName) =>
    !['isLastRow', 'isHidden'].includes(propName as string),
})<StyledTableRowProps>`
  box-shadow: ${({ isLastRow }) =>
    isLastRow ? null : 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)'};
  > :not(:last-child) {
    transition: opacity 2s;
    opacity: ${({ isHidden }) => (isHidden ? '0.2' : '1')};
  }
`;
