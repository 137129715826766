export const buildPeriodValue = (year: number, month: number): number => {
  return year * 100 + month;
};

export const parsePeriodValue = (
  period: number,
): [year: number, month: number] => {
  return [Math.floor(period / 100), period % 100];
};

export const getPrevPeriodValue = (period: number): number => {
  const [year, month] = parsePeriodValue(period);
  if (month > 1) {
    return buildPeriodValue(year, month - 1);
  }

  return buildPeriodValue(year - 1, 12);
};

export const getNextPeriodValue = (period: number): number => {
  const [year, month] = parsePeriodValue(period);
  if (month < 12) {
    return buildPeriodValue(year, month + 1);
  }

  return buildPeriodValue(year + 1, 1);
};
