const contractorKey = '/users';

export const contractor = () => {
  return contractorKey;
};

export const contractorsByZoho = () => {
  return `${contractorKey}/zoho`;
};

export const locations = () => {
  return `${contractorKey}/locations`;
};
