import React from 'react';
import { Box, Stack, Tooltip } from '@mui/material';
import { numberFormat } from '../../utils';
import { KPI_TYPES } from '../../constants';
import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';

const EmptyValue = (
  <Box component="span" sx={{ color: 'text.disabled', mr: 0.5 }}>
    &mdash;
  </Box>
);
const priceFromatEmptyValue = (
  <Box component="span" sx={{ color: 'text.disabled', mr: 0.5, pr: 2.5 }}>
    &mdash;
  </Box>
);

export function percentFormat(
  value?: number | string | null,
  defaultEmptyValue: string | JSX.Element = EmptyValue,
): string | JSX.Element {
  if (value === undefined || value === null) {
    return defaultEmptyValue;
  }

  return `${value || 0}%`;
}

export function priceFormat({
  value,
  trueup,
  defaultEmptyValue = priceFromatEmptyValue,
  primaryDollarColor = false,
  hideEmptyCents = false,
  isStrikeThrough = false,
}: {
  value?: number | string | null;
  trueup?: number | string | null;
  defaultEmptyValue?: string | JSX.Element;
  primaryDollarColor?: boolean;
  hideEmptyCents?: boolean | false;
  isStrikeThrough?: boolean;
}): string | JSX.Element {
  if (value === undefined || value === null || isNaN(Number(value))) {
    return defaultEmptyValue;
  }

  const price = numberFormat(Number(value ?? 0) + Number(trueup ?? 0));

  const [dollars, cents] = price.split('.');

  const centsContent = () => {
    if (hideEmptyCents && cents === '00') return null;

    return (
      <Box
        component="span"
        sx={
          cents === '00'
            ? {
                color: 'transparent',
                userSelect: 'none',
                fontWeight: 300,
              }
            : {
                color: 'text.disabled',
                fontWeight: 300,
              }
        }
      >
        .{cents}
      </Box>
    );
  };

  return (
    <Box
      component="span"
      sx={{
        fontVariantNumeric: 'tabular-nums',
        letterSpacing: '-0.02em',
        display: 'inline-flex',
        textDecoration: isStrikeThrough ? 'line-through' : 'auto',
      }}
    >
      {!!trueup && RenderTrueupIcon(value, trueup)}
      <Box
        component="span"
        sx={{
          color: !primaryDollarColor ? 'text.disabled' : 'text.primary',
          fontWeight: !primaryDollarColor ? 300 : 500,
          marginRight: 0.25,
        }}
      >
        $
      </Box>
      {dollars}
      {centsContent()}
    </Box>
  );
}

export const renderAdornment = (type?: KPI_TYPES): string | undefined => {
  if (type === KPI_TYPES.VARIABLE_PERCENTAGE) {
    return '%';
  }
};

export const formatByDefinitionType = (
  type?: KPI_TYPES,
  value?: number | string | null,
  defaultEmptyValue: string | JSX.Element = EmptyValue,
): string | JSX.Element => {
  if (type === KPI_TYPES.VARIABLE_PERCENTAGE) {
    return percentFormat(value, defaultEmptyValue);
  }

  if (value === undefined || value === null) {
    return defaultEmptyValue;
  }

  return String(value);
};

const RenderTrueupIcon = (
  bonus?: number | string | null,
  trueup?: number | string,
) => {
  trueup = Number(trueup);
  const isPositiveTrueup = trueup >= 0;
  return (
    <Stack
      component="span"
      direction="row"
      justifyContent="start"
      mr={1}
      sx={{ color: '#b2b5bf' }}
    >
      <Tooltip
        title={`True-up: $${bonus} ${isPositiveTrueup ? '+' : '-'} $${Math.abs(
          trueup,
        )}`}
        enterDelay={300}
        placement="top"
      >
        <CompareArrowsRoundedIcon className="trueup-icon" fontSize="small" />
      </Tooltip>
    </Stack>
  );
};
