import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlainObject } from '../types';
import { parseLocation } from './parseLocation';

export interface UseLocationQueryResult<T> {
  query: T;
  pathname: string;
  removeSearchKey: (key: keyof T, replace?: boolean) => void;
  setSearchKey: (key: keyof T, value: string | number) => void;
  update: (url: string, keepQueries?: boolean) => void;
}

export function useLocationQuery<T = PlainObject>(): UseLocationQueryResult<
  Partial<T>
> {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const query: Partial<T> = React.useMemo(
    () => parseLocation<T>(search),
    [search],
  );

  const removeKey = React.useCallback(
    (key: keyof T): string => {
      if (!query[key]) {
        return search;
      }

      const searchPart = `${String(key)}=${query[key]}`;
      const newSearch = search.replace(
        new RegExp(`(${searchPart})&?`, 'gi'),
        '',
      );

      if (newSearch.trim() === '?') {
        return '';
      }

      return newSearch;
    },
    [query, search],
  );

  const removeSearchKey = React.useCallback(
    (key: keyof T, replace = false): void => {
      if (!query[key]) {
        return;
      }

      navigate(`${pathname}${removeKey(key)}`, { replace });
    },
    [pathname, query, navigate, removeKey],
  );

  const setSearchKey = React.useCallback(
    (key: keyof T, value: string | number, replace = false): void => {
      const newSearch = removeKey(key);

      const searchPart = `${newSearch ? '&' : '?'}${String(key)}=${value}`;
      const newSearchString = `${newSearch || ''}${searchPart}`;

      navigate(`${pathname}${newSearchString}`, { replace });
    },
    [pathname, navigate, removeKey],
  );

  const update = React.useCallback(
    (url: string, keepQueries = false): void => {
      navigate(keepQueries ? `${url}${search}` : url);
    },
    [search, navigate],
  );

  return {
    pathname,
    query,
    removeSearchKey,
    setSearchKey,
    update,
  };
}
