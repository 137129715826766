import React from 'react';
import { IconButton, TableRow } from '@mui/material';
import { CustomTableCell } from '../../../../components/customTable';
import { priceFormat } from '../../../../components/tableCellRenders';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { NewBonusShare } from './BonusPlan.types';
import { formatYYYYMMDateToMMMMyyString } from '../../../../utils';

interface Props {
  bonusSnippetList?: NewBonusShare[];
}

export const BonusPlanSnippetTable = function ({ bonusSnippetList }: Props) {
  return (
    <>
      {bonusSnippetList?.map((item) => (
        <TableRow key={item.id}>
          <CustomTableCell
            typographyVariant="body1"
            sx={{
              whiteSpace: 'nowrap',
              px: 0.75,
              py: 0.25,
              position: 'relative',
              width: 100,
              textAlign: 'center',
            }}
          >
            {formatYYYYMMDateToMMMMyyString(item.period, true)}
          </CustomTableCell>
          <CustomTableCell
            typographyVariant="body1"
            sx={{
              whiteSpace: 'nowrap',
              px: 0.75,
              py: 0.25,
              position: 'relative',
              textAlign: 'center',
              width: 100,
            }}
          >
            {priceFormat({ value: item.oldBonusShare, isStrikeThrough: true })}
          </CustomTableCell>

          <CustomTableCell
            typographyVariant="body1"
            sx={{
              whiteSpace: 'nowrap',
              px: 0.75,
              py: 0.25,
              position: 'relative',
              width: 100,
              textAlign: 'center',
            }}
          >
            <IconButton
              size="small"
              sx={{
                transform: 'scale(0.75)',
                opacity: 0.5,
                my: -0.5,
                width: 100,
                textAlign: 'center',
              }}
              disabled={true}
            >
              <ArrowForwardIcon />
            </IconButton>
          </CustomTableCell>

          <CustomTableCell
            typographyVariant="body1"
            sx={{
              whiteSpace: 'nowrap',
              px: 0.75,
              py: 0.25,
              position: 'relative',
              textAlign: 'center',
              width: 100,
            }}
          >
            {priceFormat({ value: item.newBonusShare })}
          </CustomTableCell>
        </TableRow>
      ))}
    </>
  );
};
