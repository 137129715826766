import { KpiMetricsState } from './kpiOwners.slice';
import { KpiOwnerType } from '../../types/kpiOwners';
import { kpiOwnersStoreKey } from './kpiOwners.const';

interface State {
  [kpiOwnersStoreKey]: KpiMetricsState;
}

const selectKpiOwnersState = (state: State) => {
  return state[kpiOwnersStoreKey];
};

const getKpiOwnersApiStatus = (state: State): string => {
  return selectKpiOwnersState(state).apiStatus;
};

const getKpiOwnersList = (state: State): KpiOwnerType[] => {
  return selectKpiOwnersState(state).kpiOwnersList;
};

export const kpiOwnersSelectors = {
  getKpiOwnersApiStatus,
  getKpiOwnersList,
};
