import {
  GeneralKpiModel,
  IndividualKpi,
  SharedKpiModel,
  UserSharedKpi,
} from '../types';

const isSharedKpiType = (
  kpi: IndividualKpi | SharedKpiModel,
): kpi is SharedKpiModel => {
  return 'userSharedKpis' in kpi;
};

export function getGeneralKpiMetric(
  kpi: IndividualKpi | SharedKpiModel,
): GeneralKpiModel {
  if (isSharedKpiType(kpi)) {
    const userSharedKpi = kpi.userSharedKpis?.[0] || ({} as UserSharedKpi);

    return {
      ...kpi,
      ...userSharedKpi,
    };
  }

  return kpi;
}
