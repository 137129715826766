import { PayloadAction } from '@reduxjs/toolkit';
import { approvalsStoreKey } from './approvals.const';
import {
  fetchApprovals,
  approveKpis,
  submitKpi,
  submitKpis,
  checkNewKpiBonus,
} from './approvals.thunks';
import { DepartmentType, PlainObject } from '../../types';
import {
  ApprovalsPageMode,
  ApprovalsGroupBy,
} from '../../features/approvals/Approvals.const';
import {
  ApprovalsFormItemModel,
  ApprovalsState,
  NewKpiBonus,
} from './approvals.types';
import { createSliceCustom } from '../../utils/tests/createSliceCustom';

export const initialState: ApprovalsState = {
  pageMode: ApprovalsPageMode.Review,
  groupBy: ApprovalsGroupBy.Teams,
  groupByRootDepartment: ApprovalsGroupBy.Teams,
  formValues: {},
  formErrors: null,
  loading: false,
  approveKpisLoading: false,
  departments: [],
  newBonusShares: {},
};

export const returns = createSliceCustom({
  name: approvalsStoreKey,
  initialState,
  reducers: {
    setLoadingKpiId: (
      state,
      { payload }: PayloadAction<string | undefined>,
    ) => {
      if (!payload) {
        delete state.loadingKpiId;
        return;
      }

      state.loadingKpiId = payload;
    },
    setPageMode: (state, { payload }: PayloadAction<ApprovalsPageMode>) => {
      state.pageMode = payload;

      if (Object.keys(state.formValues).length) {
        state.formValues = {};
        state.newBonusShares = {};
      }
    },
    discardChanges: (state) => {
      state.pageMode = ApprovalsPageMode.Review;
      state.formValues = {};
      state.newBonusShares = {};
    },
    setMetricValue: (
      state,
      { payload }: PayloadAction<ApprovalsFormItemModel>,
    ) => {
      state.formValues[payload.id] = payload.value;
    },
    setMetricErrors: (
      state,
      { payload }: PayloadAction<PlainObject<string> | null>,
    ) => {
      state.formErrors = payload;
    },
    setGroupByType: (state, { payload }: PayloadAction<ApprovalsGroupBy>) => {
      state.groupBy = payload;
    },
  },
  extraReducers: (builder) => {
    // fetch data
    builder
      .addCase(fetchApprovals.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchApprovals.fulfilled,
        (state, { payload }: PayloadAction<DepartmentType[]>) => {
          state.loading = false;
          state.departments = payload;
          state.formValues = {};
          state.newBonusShares = {};

          delete state.loadingKpiId;
        },
      )
      .addCase(fetchApprovals.rejected, (state) => {
        state.loading = false;
      });

    // approve KPIs
    builder
      .addCase(approveKpis.pending, (state) => {
        state.approveKpisLoading = true;
      })
      .addCase(approveKpis.fulfilled, (state) => {
        state.pageMode = ApprovalsPageMode.Review;
        state.approveKpisLoading = false;
      })
      .addCase(approveKpis.rejected, (state) => {
        state.approveKpisLoading = false;
      });

    // submit one KPI
    builder
      .addCase(submitKpi.pending, (state) => {
        state.loading = true;
        state.approveKpisLoading = true;
      })
      .addCase(submitKpi.fulfilled, (state) => {
        state.approveKpisLoading = false;
        state.formValues = {};
        state.newBonusShares = {};
      })
      .addCase(submitKpi.rejected, (state) => {
        state.loading = false;
        state.approveKpisLoading = false;
      });

    // submit all KPIs
    builder
      .addCase(submitKpis.pending, (state) => {
        state.loading = true;
        state.approveKpisLoading = true;
      })
      .addCase(submitKpis.fulfilled, (state) => {
        state.pageMode = ApprovalsPageMode.Review;
        state.approveKpisLoading = false;
      })
      .addCase(submitKpis.rejected, (state) => {
        state.loading = false;
        state.approveKpisLoading = false;
      });

    // check new KPI Bonuses
    builder
      .addCase(
        checkNewKpiBonus.fulfilled,
        (state, { payload }: PayloadAction<NewKpiBonus[]>) => {
          delete state.loadingKpiId;

          if (!Array.isArray(payload)) {
            return;
          }

          payload.map(({ userKpiAssignmentId, payout, trueup }) => {
            state.newBonusShares[userKpiAssignmentId] = { payout, trueup };
          });
        },
      )
      .addCase(checkNewKpiBonus.rejected, (state) => {
        delete state.loadingKpiId;
      });
  },
});

export const approvalsSlice = returns.slice;
export const approvalsReducers = returns.reducers;
export const approvalsExtraReducers = returns.extraReducers;
