import { SubmissionsState } from './submissions.types';
import { KpiForSubmissionType } from '../../types/submissions';
import { submissionsStoreKey } from './submissions.const';
import { SharedKpiModel, IndividualKpi, PlainObject } from '../../types';

interface State {
  [submissionsStoreKey]: SubmissionsState;
}

const selectSubmissionsState = (state: State) => {
  return state[submissionsStoreKey];
};

const getIndividualKpis = (state: State): IndividualKpi[] => {
  return selectSubmissionsState(state).individualKpis;
};

const getSharedKpis = (state: State): SharedKpiModel[] => {
  return selectSubmissionsState(state).sharedKpis;
};

const getSubmissionsApiStatus = (state: State): string => {
  return selectSubmissionsState(state).apiStatus;
};

const getKpisForSubmission = (state: State): KpiForSubmissionType[] => {
  return selectSubmissionsState(state).kpisForSubmission;
};

const getLoadingKpiId = (state: State): string | undefined => {
  return selectSubmissionsState(state).loadingKpiId;
};

const getNewBonusShares = (state: State): PlainObject => {
  return selectSubmissionsState(state).newBonusShares;
};

export const submissionsSelectors = {
  getIndividualKpis,
  getKpisForSubmission,
  getSharedKpis,
  getSubmissionsApiStatus,
  getLoadingKpiId,
  getNewBonusShares,
};
