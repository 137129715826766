import { useMemo } from 'react';
import { ContractorType } from '../../types/contractor';
import { getGeneralKpiMetric } from '../../utils/getGeneralKpiMetric';
import { parseBonusPlan } from './Contractor.utils';
import { formatYYYYMM, formatStringDate } from '../../utils';
import { PeriodType } from '../../types';

export function useCalculateDates(
  { sharedKpis = [], individualKpis = [], deactivatedAt }: ContractorType,
  currentPeriod: PeriodType | null,
): string | null {
  return useMemo(() => {
    if (!deactivatedAt) {
      return null;
    }

    const lastEndDate = [...sharedKpis, ...individualKpis].reduce(
      (lastDate: number | null, item): number | null => {
        const kpi = getGeneralKpiMetric(item);

        const { endPeriod } = parseBonusPlan(
          currentPeriod,
          kpi.deletedAt,
          kpi.bonusPlanEntries,
        );

        if (!endPeriod) {
          return lastDate;
        }

        return lastDate !== null && lastDate >= endPeriod
          ? lastDate
          : endPeriod;
      },
      null,
    );

    const lastPeriodStringDate = formatYYYYMM(lastEndDate);
    const deactivatedStringDate = formatStringDate(deactivatedAt);

    const description = `Deactivated on ${deactivatedStringDate}`;

    if (!lastPeriodStringDate) {
      return description;
    }

    return `${description} • Latest metric end date ${lastPeriodStringDate}`;
  }, [sharedKpis, individualKpis, deactivatedAt, currentPeriod]);
}
