import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { HISTORICAL_ENTRY_FIELDS } from '../../../../../constants/bonuses';
import {
  HistoricalAssignmentEntry,
  UpdateHistoricalAssignment,
} from '../../../../../types/contractor';
import { Box, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HistoricalAssignmentDataRow from './HistoricalAssignmentDataRow';

interface Props {
  historicalAssignments: Record<string, HistoricalAssignmentEntry> | undefined;
  handleHistoricalDataChange: (ae: UpdateHistoricalAssignment) => void;
}

const HistoricalDataTable: React.FC<Props> = ({
  historicalAssignments,
  handleHistoricalDataChange,
}) => {
  return (
    <TableContainer sx={{ overflowX: 'initial' }}>
      <Table stickyHeader size="small" aria-label="bonuses-table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
              {HISTORICAL_ENTRY_FIELDS.metricName}
            </TableCell>
            <TableCell
              align={'left'}
              sx={{ pr: 0, mr: 1, whiteSpace: 'nowrap' }}
            >
              {HISTORICAL_ENTRY_FIELDS.period}
            </TableCell>
            <TableCell align={'center'} sx={{ pr: 0, whiteSpace: 'nowrap' }}>
              {HISTORICAL_ENTRY_FIELDS.worstCase}
            </TableCell>
            <TableCell align={'center'} sx={{ pr: 0, whiteSpace: 'nowrap' }}>
              {HISTORICAL_ENTRY_FIELDS.bestCase}
            </TableCell>
            <TableCell align={'center'} sx={{ pr: 0, whiteSpace: 'nowrap' }}>
              <Box display={'flex'} justifyContent={'center'} gap={0.5}>
                {HISTORICAL_ENTRY_FIELDS.result}

                <Tooltip
                  title={"Results can't be updated for shared metrics"}
                  placement={'top'}
                >
                  <InfoOutlinedIcon
                    color="secondary"
                    // fontSize="small"
                    sx={{ fontSize: '1rem', marginTop: '.25rem' }}
                  />
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell align={'right'} sx={{ pr: 4.5, whiteSpace: 'nowrap' }}>
              {HISTORICAL_ENTRY_FIELDS.bonusShare}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {!!historicalAssignments && (
            <>
              {Object.keys(historicalAssignments)
                .filter((key) => !!historicalAssignments[key])
                .map((key) => {
                  return (
                    <HistoricalAssignmentDataRow
                      key={key}
                      assignmentEntry={historicalAssignments[key]}
                      handleHistoricalDataChange={handleHistoricalDataChange}
                    />
                  );
                })}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HistoricalDataTable;
