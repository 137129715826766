import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants/api';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { kpiMetricsStoreKey } from './kpiMetrics.const';

export const fetchKpiMetrics = createAsyncThunk(
  `${kpiMetricsStoreKey}/fetchKpiMetrics`,
  async (
    { userId, byZoho }: { userId: string; byZoho?: boolean },
    thunkAPI,
  ) => {
    try {
      const response = await axios.get(
        byZoho
          ? apiEndpoints.kpiMetricsByZoho(userId)
          : apiEndpoints.kpiMetrics(userId),
      );
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(
          formatErrorMessage(err, 'Fetching KPI metrics failed'),
          {
            variant: 'error',
          },
        ),
      );
      throw new Error(String(err));
    }
  },
);
