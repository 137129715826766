import { useCallback, useEffect, useMemo, useState } from 'react';

import { AssessmentDateContent, ScopeTimelineAssessment } from '../types';
import {
  checkHasAssessmentData,
  formatMonday,
  getPreviousMonday,
  parseAssessments,
} from '../utils';

interface UseAssessmentsDataParams {
  assessments: ScopeTimelineAssessment[];
}

interface UseAssessmentsDataReturn {
  hasSelectedAssessmentContent: boolean;
  isLatestPeriod: boolean;
  handlePeriodSelect: (date: string) => void;
  selectedAssessment: AssessmentDateContent;
  selectedPeriod: string;
  parsedAssessments: Map<string, AssessmentDateContent>;
}

export const useAssessmentData = ({
  assessments,
}: UseAssessmentsDataParams): UseAssessmentsDataReturn => {
  const [selectedPeriod, setSelectedPeriod] = useState('');

  const parsedAssessments = useMemo(
    () => parseAssessments(assessments),
    [assessments],
  );

  const getLatestPeriod = useCallback(
    (assessments: Map<string, AssessmentDateContent>) => {
      const periods = Array.from(assessments.keys());

      return periods[periods.length - 1];
    },
    [],
  );

  useEffect(() => {
    if (parsedAssessments?.size) {
      setSelectedPeriod(getLatestPeriod(parsedAssessments));
    }
  }, [parsedAssessments, getLatestPeriod]);

  const selectedAssessment = useMemo(
    () => parsedAssessments?.get(selectedPeriod) as AssessmentDateContent,
    [selectedPeriod, parsedAssessments],
  );

  const handlePeriodSelect = useCallback((date: string) => {
    setSelectedPeriod(date);
  }, []);

  const hasSelectedAssessmentContent = useMemo(
    () => checkHasAssessmentData(selectedAssessment),
    [selectedAssessment],
  );

  const isLatestPeriod = useMemo(
    () => formatMonday(getPreviousMonday(new Date())) === selectedPeriod,
    [selectedPeriod],
  );

  return {
    selectedAssessment,
    parsedAssessments,
    hasSelectedAssessmentContent,
    isLatestPeriod,
    selectedPeriod,
    handlePeriodSelect,
  };
};
