import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssessmentState } from './assessment.types';
import { assessmentKey } from './assessment.const';
import { createAssessment } from './assessment.thunks';

const initialState: AssessmentState = {
  loading: false,
  isRefetchingAssessment: false,
  error: null,
};

export const assessmentSlice = createSlice({
  name: assessmentKey,
  initialState,
  reducers: {
    setIsRefetchingAssessment: (state, action: PayloadAction<boolean>) => {
      state.isRefetchingAssessment = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAssessment.pending, (state) => {
        state.loading = true;
        state.error = initialState.error;
      })
      .addCase(createAssessment.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createAssessment.rejected, (state) => {
        state.loading = false;
        state.error = new Error('Oops. Something went wrong. :(');
      });
  },
});

export const { setIsRefetchingAssessment } = assessmentSlice.actions;
