import React, { useCallback } from 'react';
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import { TeamMemberViewHeadProps } from './types';
import { columns, sortableColumns } from './TeamMemberViewHead.const';
import {
  accountSlice,
  getSortBy,
  getSortOrder,
  SortAccountMembersBy,
} from '../../redux/account';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { AbsoluteTableSortLabel } from './components/styledComponents/AbsoluteTableSortLabel';
import { SortOrder } from '../../types';

export const TeamMemberViewHead = ({}: TeamMemberViewHeadProps) => {
  const dispatch = useAppDispatch();
  const sortBy = useAppSelector(getSortBy);
  const sortOrder = useAppSelector(getSortOrder);

  const handleColumnSort = useCallback(
    (columnName: string) => {
      const newSortBy = sortableColumns[columnName];

      if (newSortBy) {
        const newSortOrder =
          newSortBy === sortBy ? 1 - sortOrder : SortOrder.Ascending;
        dispatch(
          accountSlice.actions.setSortBy({
            sortBy: newSortBy,
            sortOrder: newSortOrder,
          }),
        );
      }
    },
    [dispatch, sortOrder, sortBy],
  );

  const firstColumnName = 'Team member';
  const columnWidth = (theme: Theme) => theme.spacing(15);
  const nameColumnWidth = 370;

  const isSortableColumn = (name: SortAccountMembersBy) =>
    Object.values(sortableColumns).includes(name);

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ minWidth: nameColumnWidth }}>
          <AbsoluteTableSortLabel
            direction={sortOrder === SortOrder.Ascending ? 'asc' : 'desc'}
            active={sortBy === SortAccountMembersBy.Name}
            onClick={() => handleColumnSort(firstColumnName)}
          >
            <Typography variant="h4" component="p">
              {firstColumnName}
            </Typography>
          </AbsoluteTableSortLabel>
        </TableCell>
        {columns.map((columnName: string) => (
          <TableCell
            sx={{ width: columnWidth }}
            key={columnName}
            variant="head"
            align="center"
          >
            {!isSortableColumn(columnName as SortAccountMembersBy) ? (
              <Typography
                textAlign="center"
                sx={{ cursor: 'default' }}
                variant="h4"
                component="p"
              >
                {columnName}
              </Typography>
            ) : (
              <AbsoluteTableSortLabel
                direction={sortOrder === SortOrder.Ascending ? 'asc' : 'desc'}
                active={sortBy === sortableColumns[columnName]}
                onClick={() => handleColumnSort(columnName)}
                sx={{ mr: 0 }}
              >
                <Typography whiteSpace="nowrap" variant="h4" component="p">
                  {columnName}
                </Typography>
              </AbsoluteTableSortLabel>
            )}
          </TableCell>
        ))}
        <TableCell width={150}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <HourglassEmptyRoundedIcon color="disabled" sx={{ fontSize: 18 }} />
            <Typography color="text.disabled" fontSize={11}>
              Hourly
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
