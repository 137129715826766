import React from 'react';
import { ListItemCard } from '../../../components';
import { concatCardDescription } from '../Approvals.utils';
import { Box } from '@mui/material';
import { DepartmentType, SharedKpiModel, ContractorType } from '../../../types';
import { TeamTable } from '../TeamTable/TeamTable';
import { SharedKpiRows } from '../SharedKpiRows/SharedKpiRows';
import { MemberKpisRows } from '../MemberKpisRows/MemberKpisRows';
import { plural } from '../../../utils';
import { PeopleIconWrapper } from '../../../components/tableCellRenders';
import {} from '../../../types';
import _uniqBy from 'lodash/uniqBy';
import { LIST_GROUPED_BY_ROOT_DEPARTMENTS } from '../Approvals.const';

interface Props {
  departments: DepartmentType[];
  expandAll: boolean;
}

export function ListGroupedByRootDepartments({
  departments,
  expandAll,
}: Props): JSX.Element {
  const { departments: rootDepartments, sharedKpis } =
    groupByRootDepartments(departments);

  return (
    <Box data-testid={LIST_GROUPED_BY_ROOT_DEPARTMENTS}>
      {sharedKpis && !!sharedKpis.length && (
        <ListItemCard
          expanded={expandAll}
          enlargeTitle
          title={'Shared metrics'}
          description={`${sharedKpis.length} metric${plural(
            sharedKpis.length,
          )}`}
          titleWrapper={(title) => (
            <PeopleIconWrapper>{title}</PeopleIconWrapper>
          )}
        >
          {sharedKpis.map((sharedKpi) => (
            <SharedKpiRows key={sharedKpi.kpiDefinition.id} data={sharedKpi} />
          ))}
        </ListItemCard>
      )}

      {rootDepartments
        .map((department) =>
          department.members.length || department.sharedKpis.length ? (
            <ListItemCard
              expanded={expandAll}
              enlargeTitle
              stickyTitle
              onApprovals
              key={department.id}
              title={department.name}
              description={concatCardDescription(department)}
            >
              <Box paddingX={2}>
                <TeamTable key={department.id}>
                  {department.sharedKpis.map((sharedKpi) => (
                    <SharedKpiRows
                      key={sharedKpi.kpiDefinition.id}
                      data={sharedKpi}
                    />
                  ))}

                  {department.members.map((member) => (
                    <MemberKpisRows key={member.id} member={member} />
                  ))}
                </TeamTable>
              </Box>
            </ListItemCard>
          ) : null,
        )
        .filter((v) => v)}
    </Box>
  );
}

interface ExtractionResult {
  sharedKpis: SharedKpiModel[];
  members: ContractorType[];
}

interface DepartmentsByTeamsResult {
  departments: DepartmentType[];
  sharedKpis: SharedKpiModel[];
}

function extractOnTop({
  members,
  sharedKpis,
  subDepartments,
}: DepartmentType): ExtractionResult {
  return (subDepartments || []).reduce(
    (acum, subDepartment) => {
      const res = extractOnTop(subDepartment);

      return {
        sharedKpis: [...acum.sharedKpis, ...res.sharedKpis],
        members: [...acum.members, ...res.members],
      };
    },
    {
      sharedKpis,
      members,
    } as ExtractionResult,
  );
}

function extractOnTopUniq(department: DepartmentType): ExtractionResult {
  const { members, sharedKpis } = extractOnTop(department);

  return {
    members: _uniqBy(members, 'id'),
    sharedKpis: _uniqBy(sharedKpis, 'id'),
  };
}

function groupByDepartments(
  departments: DepartmentType[],
): DepartmentsByTeamsResult {
  return departments.reduce(
    (acum, department) => {
      const { members, sharedKpis } = extractOnTopUniq(department);

      return {
        sharedKpis: [...acum.sharedKpis, ...sharedKpis],
        departments: [
          ...acum.departments,
          {
            ...department,
            members,
            sharedKpis: [],
            subDepartments: [],
          },
        ],
      };
    },
    {
      departments: [],
      sharedKpis: [],
    } as DepartmentsByTeamsResult,
  );
}

export function groupByRootDepartments(
  departments: DepartmentType[],
): DepartmentsByTeamsResult {
  if (!departments.length) {
    return {
      departments: [],
      sharedKpis: [],
    };
  }

  const res = departments.reduce(
    (acum, department) => {
      const { sharedKpis, departments: subDepartments } = groupByDepartments(
        department.subDepartments || [],
      );

      return {
        departments: [
          ...acum.departments,
          {
            ...department,
            sharedKpis: [],
            subDepartments: [],
          },
          ...subDepartments,
        ],
        sharedKpis: [
          ...acum.sharedKpis,
          ...department.sharedKpis,
          ...sharedKpis,
        ],
      };
    },
    {
      departments: [],
      sharedKpis: [],
    } as DepartmentsByTeamsResult,
  );

  return {
    ...res,
    sharedKpis: _uniqBy(res.sharedKpis, 'id'),
  };
}
