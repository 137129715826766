import React, { useEffect, useState, useMemo } from 'react';
import Papaparse from 'papaparse';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import MonitorRoundedIcon from '@mui/icons-material/MonitorRounded';
import Typography from '@mui/material/Typography';
import { API_STATUS, KpiStatus, REPORT_KEYS } from '../../constants';
import { fadeInDuration } from '../../theme';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import {
  clearReports,
  fetchReports,
  reportsSelectors,
} from '../../redux/reports';
import {
  closeCurrentPeriod,
  fetchCheckApproved,
  fetchPeriods,
  periodsSelectors,
} from '../../redux/periods';
import { DepartmentBaseType, PeriodType } from '../../types';
import { formatYYYYMMDateToMMMMYString, numberFormat, is } from '../../utils';
import { BottomPanel, Loader, PageWrapper } from '../../components';
import Filters from './Filters';
import PreviewModal from './PreviewModal';
import { downloadCsv, getFinalBonus } from './Reports.utils';
import { Badge, Box, useTheme } from '@mui/material';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import { userDataSelectors } from '../../redux/userData';
import { PageTitle } from '../../utils/pageTitles';
import { useTitle } from '../../hooks/useTitle';
import { capitalizeFirstLetter } from '../globalTracker/utils';

const Reports = (): JSX.Element => {
  const setPageTitle = useTitle();
  useEffect(() => {
    setPageTitle(PageTitle.reports);
  }, [setPageTitle]);
  const dispatch = useAppDispatch();

  const { spacing } = useTheme();

  const reports = useAppSelector(reportsSelectors.getReports);
  const currentPeriod = useAppSelector(periodsSelectors.getCurrentPeriod);
  const reportsApiStatus = useAppSelector(reportsSelectors.getReportsApiStatus);
  const periodsApiStatus = useAppSelector(periodsSelectors.getPeriodsApiStatus);
  const isPeriodsLoading = periodsApiStatus === API_STATUS.LOADING;
  const isLoading = reportsApiStatus === API_STATUS.LOADING;
  const isFailed = reportsApiStatus === API_STATUS.FAILED;

  const [period, setPeriod] = useState<PeriodType | null>(null);
  const [locations, setLocations] = useState<string[]>([]);
  const [rootDepartments, setRootDepartments] = useState<DepartmentBaseType[]>(
    [],
  );
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [totalUnapprovedReports, setTotalUnapprovedReports] =
    useState<number>(0);

  const handleTogglePreview = () => setShowPreview((prevState) => !prevState);

  const hasTrueUp = useMemo(() => {
    return !!(reports || []).find(({ trueup }) => !is.nullOrUndefined(trueup));
  }, [reports]);

  const handleDownloadCsv = () => {
    const trueUpColumns = hasTrueUp
      ? [REPORT_KEYS.trueUp, REPORT_KEYS.finalBonus]
      : [];

    const reportData = reports.map((report) => {
      const finalBonus = getFinalBonus(report);

      return {
        [REPORT_KEYS.bonus]: `$${numberFormat(report.payout)}`,
        [REPORT_KEYS.trueUp]: is.nullOrUndefined(report.trueup)
          ? numberFormat(report.trueup)
          : `$${numberFormat(report.trueup)}`,
        [REPORT_KEYS.finalBonus]: is.nullOrUndefined(finalBonus)
          ? numberFormat(finalBonus)
          : `$${numberFormat(finalBonus)}`,
        [REPORT_KEYS.bonusType]: report.bonusType,
        [REPORT_KEYS.department]: report.department.replaceAll('&amp;', '&'),
        [REPORT_KEYS.fullName]: report.fullName,
        [REPORT_KEYS.metricName]: report.metricName,
        [REPORT_KEYS.location]: report.location,
        [REPORT_KEYS.period]: formatYYYYMMDateToMMMMYString(report.period),
        [REPORT_KEYS.frequency]: capitalizeFirstLetter(report.frequency),
        [REPORT_KEYS.city]: report.city || 'N/A',
      };
    });
    const reportFieldsOrder = [
      REPORT_KEYS.department,
      REPORT_KEYS.bonusType,
      REPORT_KEYS.fullName,
      REPORT_KEYS.metricName,
      REPORT_KEYS.period,
      REPORT_KEYS.bonus,
      ...trueUpColumns,
      REPORT_KEYS.location,
      REPORT_KEYS.frequency,
      REPORT_KEYS.city,
    ];
    const csvData = Papaparse.unparse(reportData, {
      quotes: true,
      columns: reportFieldsOrder,
    });
    const csvFilename = `KPI report for ${formatYYYYMMDateToMMMMYString(
      period?.period,
    )}.csv`;
    downloadCsv(csvData, csvFilename);
  };

  const isClosePeriodAllowed = useAppSelector(
    periodsSelectors.getIsClosePeriodAvailable,
  );

  const user = useAppSelector(userDataSelectors.getUserData);

  const handleCloseCurrentPeriod = async () => {
    await dispatch(closeCurrentPeriod());
  };

  useEffect(() => {
    setPeriod(currentPeriod);
  }, [currentPeriod, isPeriodsLoading]);

  useEffect(() => {
    dispatch(fetchPeriods());
    dispatch(fetchCheckApproved());
  }, [dispatch]);

  useEffect(() => {
    if (period) {
      dispatch(
        fetchReports({
          period: period.period,
          locations: !locations.length ? undefined : locations, // backend will not filter by locations if we send an empty one
          departmentsIds: rootDepartments.length
            ? rootDepartments.map((x) => x.id)
            : undefined,
        }),
      );
    }
    return () => {
      dispatch(clearReports());
    };
  }, [dispatch, locations, period, rootDepartments]);

  useEffect(() => {
    setTotalUnapprovedReports(
      reports.filter((x) => x.status !== KpiStatus.Approved).length,
    );
  }, [reports]);

  return (
    <PageWrapper>
      <Card
        variant="outlined"
        sx={{
          maxWidth: 400,
          marginX: 'auto',
          padding: 3,
          borderRadius: `${spacing(1)} ${spacing(1)} 0 0`,
        }}
      >
        <Grid container spacing={2}>
          <Typography variant="h2" mx={2} mt={2}>
            Reports
          </Typography>
          <Filters
            period={period}
            locations={locations}
            rootDepartments={rootDepartments}
            setPeriod={setPeriod}
            setLocations={setLocations}
            setRootDepartments={setRootDepartments}
          />
          {isLoading && (
            <Grid
              item
              xs={12}
              sx={{
                position: 'relative',
                minHeight: (theme) => theme.spacing(6),
              }}
            >
              <Loader />
            </Grid>
          )}
          <Fade
            in={!isLoading}
            timeout={{
              enter: fadeInDuration,
              exit: 0,
            }}
            mountOnEnter
            unmountOnExit
          >
            {isFailed ? (
              <Grid item xs={12}>
                <Alert severity="error">
                  Reports can not be generated
                  <br /> until all submissions are approved
                </Alert>
              </Grid>
            ) : (
              <Grid item xs={12} display="flex">
                <Button
                  fullWidth
                  onClick={handleDownloadCsv}
                  startIcon={<DownloadRoundedIcon />}
                  sx={{ minWidth: (theme) => theme.spacing(24), mr: 2 }}
                  variant="contained"
                  disabled={!!totalUnapprovedReports}
                >
                  Download .CSV
                </Button>
                <Badge badgeContent={totalUnapprovedReports} color="error">
                  <Button
                    fullWidth
                    onClick={handleTogglePreview}
                    startIcon={<MonitorRoundedIcon />}
                  >
                    Preview
                  </Button>
                </Badge>
              </Grid>
            )}
          </Fade>
          {!!totalUnapprovedReports && (
            <Fade
              in={!isLoading}
              timeout={{
                enter: fadeInDuration,
                exit: 0,
              }}
              mountOnEnter
              unmountOnExit
            >
              <Grid item xs={12}>
                <Alert severity="error">
                  Reports can not be generated
                  <br /> until all submissions are approved
                </Alert>
              </Grid>
            </Fade>
          )}
        </Grid>
      </Card>
      {isClosePeriodAllowed && user?.permissions?.canClosePeriod && (
        <Card
          variant="outlined"
          sx={{
            maxWidth: 400,
            marginX: 'auto',
            borderRadius: `0 0 ${spacing(1)} ${spacing(1)}`,
          }}
        >
          <BottomPanel color={BottomPanel.color.Success} isFixed={false}>
            <Box sx={{ display: 'flex' }}>
              <DoneAllRoundedIcon fontSize="small" />
              <Typography pl={1} variant="body1">
                All results approved
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={handleCloseCurrentPeriod}
                size="small"
                variant="text"
                sx={{ ml: 1 }}
              >
                Close period
              </Button>
            </Box>
          </BottomPanel>
        </Card>
      )}

      {showPreview && (
        <PreviewModal
          handleDownloadCsv={handleDownloadCsv}
          isOpen={showPreview}
          onClose={handleTogglePreview}
          reports={reports}
        />
      )}
    </PageWrapper>
  );
};

export default Reports;
