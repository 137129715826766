import { AppConfig, NodeEnvs } from '../types';

export const appConfig: AppConfig = {
  apiBaseUrl: '/api',
  host: location.host,

  lpDevUrl: 'https://lpd-dev.agileengine.com',
  lpStageUrl: 'https://lpd-dev.agileengine.com',
  lpProdUrl: 'https://launchpod-dashboard.agileengine.com',

  rockyDevUrl: 'https://rocky-dev.agileengine.com',
  rockyStageUrl: 'https://rocky-staging.agileengine.com',
  rockyProdUrl: 'https://rocky.agileengine.com',

  rockyBeDevUrl: 'https://rockyemail-dev.agileengine.com',
  rockyBeStageUrl: 'https://rockyemail-staging.agileengine.com',
  rockyBeProdUrl: 'https://rockyemail.agileengine.com',

  ...(process.env as unknown as NodeEnvs),
};
