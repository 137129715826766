import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  fetchProjectStatus,
  getCurrentProjectId,
  getLoading,
  getProjectAssessments,
  getStatusReportsFolderLink,
} from '../../../../redux/projectStatus';
import { Loader } from '../../../../components';
import GenerateReportPanel from '../GenerateReportPanel';
import AssessmentsGraph from '../Assessments/AssessmentsGraph';
import AssessmentContent from '../Assessments/AssessmentContent';
import { useAssessmentData } from '../../hooks';
import { getIsRefetchingAssessment } from '../../../../redux/assessment';
import { ProjectDetail } from '../../../../types';

interface Props {
  projectId: string;
  proejct: ProjectDetail | null;
  mainView?: boolean;
}

const ProjectDetails: React.FC<Props> = ({ projectId, proejct, mainView }) => {
  const dispatch = useAppDispatch();
  const isProjectStatusLoading = useAppSelector(getLoading);
  const isRefetchingAssessment = useAppSelector(getIsRefetchingAssessment);
  const projectAssessments = useAppSelector(getProjectAssessments);
  const statusReportsFolderLink = useAppSelector(getStatusReportsFolderLink);
  const currentProjectId = useAppSelector(getCurrentProjectId);

  const {
    isLatestPeriod,
    hasSelectedAssessmentContent,
    selectedPeriod,
    selectedAssessment,
    parsedAssessments,
    handlePeriodSelect,
  } = useAssessmentData({
    assessments: projectAssessments,
  });

  const isLoading = isProjectStatusLoading || isRefetchingAssessment;

  useEffect(() => {
    if (currentProjectId === projectId) {
      return;
    }

    dispatch(fetchProjectStatus({ projectId }));
  }, [dispatch, projectId, currentProjectId]);

  const isGenerateReportPanelAvailable =
    !isProjectStatusLoading && statusReportsFolderLink;

  return (
    <Paper
      sx={{
        width: '100%',
        display: 'grid',
        ...(!mainView
          ? {}
          : {
              border: '1px solid #e0e0e0',
              borderTop: 'none',
              borderRadius: '0px',
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
            }),
      }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            padding: '22px 16px',
          }}
        >
          <Typography variant="h2" mb="22px">
            Project status
          </Typography>

          {parsedAssessments && (
            <>
              <AssessmentsGraph
                onSelectPeriod={handlePeriodSelect}
                selectedPeriod={selectedPeriod}
                assessments={parsedAssessments}
              />
              <AssessmentContent
                projectId={projectId}
                period={selectedPeriod}
                assessment={selectedAssessment}
                hasAssessmentContent={hasSelectedAssessmentContent}
                isLatestPeriod={isLatestPeriod}
              />
            </>
          )}
        </Box>
      )}

      {isGenerateReportPanelAvailable && (
        <GenerateReportPanel
          assessment={selectedAssessment}
          isLatestPeriod={isLatestPeriod}
          period={selectedPeriod}
          title={proejct?.name ?? ''}
        />
      )}
    </Paper>
  );
};

export default ProjectDetails;
