/**
 * @description - An utility type checking object that meets the code readability
 * requirements and provides an extensive use of type guards.
 * @example
 * ```typescript
 * const empty = !array.length; // Avoid using a lot of built in operators explicitly
 * const empty = is.falsy(array.length); // Much better
 *
 * if (!map.isPresent() && !fieldIsArrayOperator && !propertyPath.startsWith('$')) {
 *   // do something...
 * }
 *
 * const absent = is.falsy(map.isPresent());
 * const fieldIsNotArrayOperator = is.faly(fieldIsArrayOperator);
 * const noDollarAtTheBeginning = is.falsy(propertyPath.startsWith('$'));
 *
 * if (absent && fieldIsNotArrayOperator && noDollarAtTheBeginning) {
 *   // do something...
 * }
 * ```
 */
import {
  DEV_HOST_IDENTIFIER,
  LOCALHOST,
  PREPROD_HOST_IDENTIFIER,
  STAGE_HOST_IDENTIFIER,
} from '../constants';
import { Browsers } from '../constants/browsers';
import { KPI_TYPES } from '../constants/kpi';
import {
  IndividualKpi,
  SharedKpiModel,
  GeneralKpiModel,
} from '../types/contractors';

export const isFirefox =
  navigator.userAgent.indexOf(Browsers.Firefox) > -1 &&
  navigator.userAgent.indexOf(Browsers.Chrome) <= -1;

export const is = {
  localhost: window.location.hostname.includes(LOCALHOST),
  dev: window.location.hostname.includes(DEV_HOST_IDENTIFIER),
  stage: window.location.hostname.includes(STAGE_HOST_IDENTIFIER),
  development:
    window.location.hostname.includes(LOCALHOST) ||
    window.location.hostname.includes(DEV_HOST_IDENTIFIER) ||
    window.location.hostname.includes(STAGE_HOST_IDENTIFIER),
  preprod: window.location.hostname.includes(PREPROD_HOST_IDENTIFIER),
  null: (value: unknown): value is null => value === null,
  undefined: (value: unknown): value is undefined =>
    typeof value === 'undefined' || value === undefined,
  nullOrUndefined: (value: unknown): value is null | undefined => value == null,
  truthy: (value: unknown): value is true => !!value,
  falsy: (value: unknown): value is false => !value,
  string: (value: unknown): value is string => typeof value === 'string',
  number: (value: unknown): value is number => typeof value === 'number',
  boolean: (value: unknown): value is boolean => typeof value === 'boolean',
  object: (value: unknown): value is Record<string, unknown> =>
    typeof value === 'object',
  array: <T>(value: unknown): value is T[] => Array.isArray(value),
  empty: (value: unknown): boolean => {
    if (is.nullOrUndefined(value)) {
      return true;
    }

    if (Array.isArray(value) || is.string(value)) {
      return value.length === 0;
    }

    return is.object(value) && Object.keys(value).length === 0;
  },
  fixedMetric: (type: KPI_TYPES): boolean =>
    type === KPI_TYPES.FIXED_ABSOLUTE || type === KPI_TYPES.FIXED_PERCENTAGE,
  sharedMetric: (
    kpi: IndividualKpi | SharedKpiModel | GeneralKpiModel,
  ): boolean => {
    return 'userSharedKpis' in kpi;
  },
  sharedKpiType: (
    kpi: IndividualKpi | SharedKpiModel | GeneralKpiModel,
  ): kpi is SharedKpiModel => {
    return 'userSharedKpis' in kpi;
  },
};

export function isValidDate(date: Date): boolean {
  return String(date) !== 'Invalid Date';
}
