import { createAsyncThunk } from '@reduxjs/toolkit';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { assessmentKey } from './assessment.const';
import axios from 'axios';
import { apiEndpoints } from '../../constants/api';
import { AccountsStatus } from '../../features/globalTracker/types';
import { CreateAssessmentPayload } from './assessment.types';

export const createAssessment = createAsyncThunk(
  `${assessmentKey}/createAssessment`,
  async (
    payload: CreateAssessmentPayload,
    thunkAPI,
  ): Promise<AccountsStatus[]> => {
    try {
      const response = await axios.post(
        `${apiEndpoints.assessmentCreate()}`,
        payload,
      );
      thunkAPI.dispatch(
        notificationAlert('Assessment saved, thanks!', { variant: 'success' }),
      );

      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(
          formatErrorMessage(err, 'Saving assessment has failed'),
          {
            variant: 'error',
          },
        ),
      );
      throw new Error(String(err));
    }
  },
);
