import React, { useCallback, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  formatYYYYMMDateToMMMMYString,
  formatYYYYMMDateToMMMMString,
  formatYYYYMMDateToYString,
} from '../../utils';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import {
  closeCurrentPeriod,
  periodsSelectors,
  fetchPeriods,
  fetchCheckApproved,
} from '../../redux/periods';
import { API_STATUS } from '../../constants';
import { Guard } from '../../components/guard/guard';
import { MenuItem } from '@mui/material';
import { ButtonMenuGroup } from '../../components';
import { PeriodType } from '../../types';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import { userDataSelectors } from '../../redux/userData';
import { PERIODS_TEST_ID } from './constants';
import { LoadingButton } from '@mui/lab';

const Periods: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPeriods());
    dispatch(fetchCheckApproved());
  }, [dispatch]);

  const periodsList = useAppSelector(periodsSelectors.getPeriodsList);
  const currentPeriod = useAppSelector(periodsSelectors.getCurrentPeriod);
  const periodsApiStatus = useAppSelector(periodsSelectors.getPeriodsApiStatus);
  const isClosePeriodAllowed = useAppSelector(
    periodsSelectors.getIsClosePeriodAvailable,
  );
  const user = useAppSelector(userDataSelectors.getUserData);

  const isLoading = periodsApiStatus === API_STATUS.LOADING;

  const renderOptionFunc = useCallback(
    (props: any, periodItem: any) => {
      const handleCloseCurrentPeriod = async () => {
        await dispatch(closeCurrentPeriod());
      };

      return (
        <MenuItem
          disabled={periodItem.closedAt !== null}
          key={periodItem.id}
          {...props}
          sx={{
            display: 'flex',
            justifyContent: 'space-between !important',
          }}
        >
          <Typography>
            {formatYYYYMMDateToMMMMString(periodItem.period)}
          </Typography>
          {periodItem.closedAt ? (
            <LockOutlinedIcon color="disabled" />
          ) : (
            <Guard permissions={['canClosePeriod']} noPage>
              <LoadingButton
                loading={periodsApiStatus === API_STATUS.LOADING}
                size="small"
                variant="text"
                sx={{ ml: 1 }}
                disabled={
                  !(isClosePeriodAllowed && user?.permissions?.canClosePeriod)
                }
                onClick={handleCloseCurrentPeriod}
              >
                Close period
              </LoadingButton>
            </Guard>
          )}
        </MenuItem>
      );
    },
    [
      isClosePeriodAllowed,
      user?.permissions?.canClosePeriod,
      periodsApiStatus,
      dispatch,
    ],
  );

  return (
    <ButtonMenuGroup<PeriodType>
      dataTestId={PERIODS_TEST_ID}
      color="highlight"
      typographyVariant="body1"
      fullWidth
      size="large"
      sx={{ height: '32px' }}
      startIcon={
        <EventNoteRoundedIcon
          style={{ opacity: '0.6', marginLeft: '-4px', marginRight: '-12px' }}
        />
      }
      id="currentPeriod"
      options={periodsList}
      value={currentPeriod || undefined}
      loading={isLoading}
      getOptionLabel={(option) => formatYYYYMMDateToMMMMYString(option.period)}
      isOptionEqualToValue={(value, option) => option.id === value.id}
      groupBy={(option) => formatYYYYMMDateToYString(option.period)}
      sortGroupedByDesc={true}
      renderOption={renderOptionFunc}
    />
  );
};

export default Periods;
