import { createAsyncThunk } from '@reduxjs/toolkit';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { projectStatusKey } from './projectStatus.const';
import axios from 'axios';
import { apiEndpoints } from '../../constants/api';
import {
  ProjectStatusAssessments,
  GetProjectStatusData,
} from './projectStatus.types';

export const fetchProjectStatus = createAsyncThunk(
  `${projectStatusKey}/fetchProjectStatus`,
  async (
    { projectId }: GetProjectStatusData,
    thunkAPI,
  ): Promise<ProjectStatusAssessments> => {
    try {
      const { data } = await axios.get(
        `${apiEndpoints.projectStatus(projectId)}`,
      );

      return { projectId, ...data };
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(
          formatErrorMessage(err, 'Fetching project status has failed'),
          {
            variant: 'error',
          },
        ),
      );
      throw new Error(String(err));
    }
  },
);
