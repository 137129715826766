import React, { useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { plural } from '../../utils';
import { ListItemCard } from '../../components';
import ContractorCard from './ContractorCard';
import { ContractorType, DepartmentType } from '../../types/contractors';
import {
  calculateDepartmentMembers,
  findDepartment,
} from '../contractor/Contractor.utils';
import { debounce } from '../../utils/debounce';

interface Props {
  departmentTree: DepartmentType;
  expandAll?: boolean;
  topLevel?: boolean;
  disabled?: boolean;
  handleContractorClick: (departmentId: string, contractorId: string) => void;
  departmentToExpand?: string | null;
}

const ContractorsList: React.FC<Props> = ({
  departmentTree,
  expandAll = false,
  topLevel = false,
  disabled = false,
  handleContractorClick,
  departmentToExpand,
}) => {
  const breadCrumbs = departmentTree.breadCrumbs || [];
  const title =
    breadCrumbs.length === 0
      ? departmentTree.name
      : [...breadCrumbs, departmentTree.name].join(' > ');
  const membersCount = calculateDepartmentMembers(departmentTree);
  const subteamsCount = departmentTree?.subDepartments?.length;
  const description = `${membersCount} member${plural(membersCount)}`;

  const expandDepartmentTree = findDepartment(
    departmentTree,
    departmentToExpand || '',
  );

  useEffect(() => {
    if (departmentToExpand === departmentTree.id) {
      debounce(() => {
        const element = document.getElementById(departmentToExpand);
        element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 300);
    }

    return () => {
      if (departmentToExpand === departmentTree.id) {
        debounce.decline();
      }
    };
  }, [departmentToExpand, departmentTree.id]);

  const sortedMembers = useMemo(
    () =>
      departmentTree?.members?.sort((a, b) =>
        a.fullName > b.fullName ? 1 : -1,
      ) || [],
    [departmentTree],
  );

  if (!membersCount && !subteamsCount) {
    return (
      <ListItemCard
        key={departmentTree.id}
        id={departmentTree.id}
        title={title.replaceAll('&amp;', '&')}
        description={`No members`}
        disabled
        isClickOpenCard={false}
      ></ListItemCard>
    );
  }

  const renderListItemCard = () =>
    membersCount === 0 ? null : (
      <ListItemCard
        key={departmentTree.id}
        id={departmentTree.id}
        title={title.replaceAll('&amp;', '&')}
        description={description}
        expanded={expandAll || expandDepartmentTree}
        enlargeTitle
        stickyTitle
        disabled={disabled}
      >
        {sortedMembers?.map((contractor: ContractorType, index: number) => (
          <Box key={contractor.id}>
            <ContractorCard
              key={contractor.id}
              contractor={contractor}
              handleClick={handleContractorClick}
            />
            {membersCount > index + 1 && <Divider />}
          </Box>
        ))}
        {Boolean(subteamsCount) && (
          <Box pr={1} pl={1} mt={1}>
            {departmentTree?.subDepartments?.map(
              (department: DepartmentType) => (
                <ContractorsList
                  key={department.id}
                  departmentTree={department}
                  handleContractorClick={handleContractorClick}
                  departmentToExpand={departmentToExpand}
                />
              ),
            )}
          </Box>
        )}
      </ListItemCard>
    );

  return topLevel ? (
    <>
      <Typography
        variant="subtitle1"
        fontWeight="normal"
        color="text.secondary"
        gutterBottom
      >
        {title.replaceAll('&amp;', '&')}
      </Typography>
      {sortedMembers?.map((contractor: ContractorType) => (
        <ContractorCard
          key={contractor.id}
          contractor={contractor}
          topLevel
          handleClick={handleContractorClick}
        />
      ))}
      {departmentTree?.subDepartments?.map((department: DepartmentType) => (
        <ContractorsList
          key={department.id}
          departmentTree={department}
          expandAll={expandAll}
          handleContractorClick={handleContractorClick}
          disabled={disabled}
          departmentToExpand={departmentToExpand}
        />
      ))}
    </>
  ) : (
    renderListItemCard()
  );
};

export default ContractorsList;
