import Axios from 'axios';
import { AuthService } from './auth-service';
import { AppConfig } from '../types';
import { ServerStatusCode } from '../constants';
import { getApiHostname } from '../utils/getLocalHostname';
import { REQUEST_TOKEN_TYPE } from '../constants/rocky';

export class RockyInterceptorService {
  public static init(appConfig: AppConfig): void {
    Axios.defaults.baseURL = `${getApiHostname()}${appConfig.apiBaseUrl}`;

    RockyInterceptorService.addRequestInterceptor();
    RockyInterceptorService.addResponseInterceptor();
  }

  private static requestInterceptorId = -1;
  private static responseInterceptorId = -1;

  private static addRequestInterceptor(): void {
    if (RockyInterceptorService.requestInterceptorId === -1) {
      Axios.interceptors.request.eject(0);

      RockyInterceptorService.requestInterceptorId =
        Axios.interceptors.request.use((config) => {
          const token = AuthService.getRockyToken();

          const newConfig = {
            ...config,
            headers: {
              ...config.headers,
              ...(token ? { 'x-user-token': token } : {}),
            },
          };

          return newConfig;
        });
    }
  }

  private static addResponseInterceptor(): void {
    if (RockyInterceptorService.responseInterceptorId === -1) {
      Axios.interceptors.response.eject(0);

      RockyInterceptorService.responseInterceptorId =
        Axios.interceptors.response.use(
          (response) => response,
          (error) => {
            if (error.response.status === ServerStatusCode.UNAUTHORIZED) {
              AuthService.clearRockyToken();

              window.top?.postMessage(
                {
                  type: REQUEST_TOKEN_TYPE,
                  refresh: true,
                },
                '*',
              );

              window.location.reload();
            }

            return Promise.reject(error);
          },
        );
    }
  }
}
