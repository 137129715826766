import { TextField, Typography, InputAdornment, Box } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React, { ChangeEvent } from 'react';
import { InputBaseProps } from '@mui/material/InputBase';

interface EditableCellProps {
  name?: string;
  adornment?: string | null;
  editable?: boolean;
  value: number | string | null;
  error?: string;
  disabled?: boolean;
  valueColor?: string;
  inputProps?: InputBaseProps['inputProps'];
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function EditableCell({
  name,
  adornment,
  value,
  error,
  editable,
  disabled,
  valueColor,
  inputProps = {},
  onBlur,
  onChange,
}: EditableCellProps): JSX.Element {
  if (!editable) {
    return (
      <Typography variant="body1" color={valueColor} fontWeight={600}>
        {value}
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        width: '98px',
      }}
    >
      {disabled && (
        <LockOutlinedIcon
          fontSize="small"
          color="disabled"
          sx={{ marginRight: 1 }}
        />
      )}
      <TextField
        autoComplete="off"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        hiddenLabel
        id={name}
        name={name}
        variant="filled"
        size="small"
        error={!!error}
        helperText={error}
        sx={{ width: '70px' }}
        inputProps={inputProps}
        FormHelperTextProps={{
          sx: { mr: 1, ml: 1 },
        }}
        InputProps={{
          autoComplete: 'off',
          endAdornment: adornment && (
            <InputAdornment position="end">{adornment}</InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
