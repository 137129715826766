import React, { CSSProperties, FC } from 'react';

interface Props {
  style?: CSSProperties;
}

const GoogleDriveIcon: FC<Props> = ({ style }) => {
  return (
    <svg
      style={style}
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.75 5H14.14L17 10H14.05L11.5 5.46M16.3 13H10.75L12.15 10.5H17.27L17.53 10.96M9.5 13L8.4 10.86L11.24 5.9L12.74 8.56L10.25 13M18 2H10L8 0H2C0.89 0 0 0.89 0 2V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H18C18.5304 16 19.0391 15.7893 19.4142 15.4142C19.7893 15.0391 20 14.5304 20 14V4C20 3.46957 19.7893 2.96086 19.4142 2.58579C19.0391 2.21071 18.5304 2 18 2Z"
        fill="white"
      />
    </svg>
  );
};

export default GoogleDriveIcon;
