import React from 'react';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

interface Props {
  to: string;
  icon: JSX.Element;
  title: string;
}

const NavigationLink: React.FC<Props> = ({ to, icon, title }) => {
  const navigate = useNavigate();
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <ListItemButton
      selected={Boolean(match)}
      onClick={() => {
        navigate(to);
      }}
      sx={{ pl: 0, pt: 1, pb: 1, borderRadius: 1 }}
    >
      <ListItemIcon sx={{ justifyContent: 'center' }}>{icon}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  );
};

export default NavigationLink;
