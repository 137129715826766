import { PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { MarginReportState } from './marginReports.types';
import { fetchAccounts, fetchMarginReports } from './marginReports.thunks';
import { marginReportsStoreKey } from './marginReports.const';
import {
  MarginReportAccount,
  MarginReportResponseDto,
} from '../../types/marginReports';
import { createSliceCustom } from '../../utils/tests/createSliceCustom';

export const initialState: MarginReportState = {
  fetchAccountStatus: API_STATUS.IDLE,
  fetchReportStatus: API_STATUS.IDLE,
  marginReport: null,
  accounts: [],
};

export const returns = createSliceCustom({
  name: marginReportsStoreKey,
  initialState,
  reducers: {
    clearReports: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMarginReports.pending, (state) => {
        state.fetchReportStatus = API_STATUS.LOADING;
      })
      .addCase(
        fetchMarginReports.fulfilled,
        (state, action: PayloadAction<MarginReportResponseDto>) => {
          state.fetchReportStatus = API_STATUS.IDLE;
          state.marginReport = action.payload;
        },
      )
      .addCase(fetchMarginReports.rejected, (state) => {
        state.fetchReportStatus = API_STATUS.FAILED;
        state.marginReport = initialState.marginReport;
      });

    builder
      .addCase(fetchAccounts.pending, (state) => {
        state.fetchAccountStatus = API_STATUS.LOADING;
      })
      .addCase(
        fetchAccounts.fulfilled,
        (state, action: PayloadAction<{ accounts: MarginReportAccount[] }>) => {
          state.fetchAccountStatus = API_STATUS.IDLE;
          state.accounts = action.payload.accounts;
        },
      )
      .addCase(fetchAccounts.rejected, (state) => {
        state.fetchAccountStatus = API_STATUS.FAILED;
        state.accounts = initialState.accounts;
      });
  },
});

export const marginReportsSlice = returns.slice;
export const marginReportsReducers = returns.reducers;
export const marginReportsExtraReducers = returns.extraReducers;
