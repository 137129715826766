import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarMessage, OptionsObject } from 'notistack';
import { v4 as uuidV4 } from 'uuid';
import { NotificationType } from '../../types';
import { notificationsStoreKey } from './notifications.const';

export interface NotificationsState {
  notifications: NotificationType[];
}

const initialState: NotificationsState = {
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: notificationsStoreKey,
  initialState,
  reducers: {
    notificationAlert: {
      reducer: (state, action: PayloadAction<NotificationType>) => {
        state.notifications.push(action.payload);
      },
      prepare: (message: SnackbarMessage, options?: OptionsObject) => {
        const key = options?.key ? String(options.key) : uuidV4();
        return {
          payload: { message, options, key },
        } as PayloadAction<NotificationType>;
      },
    },
    clearNotificationAlert: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.map((notification) =>
        notification.key === action.payload
          ? { ...notification, dismissed: true }
          : { ...notification },
      );
    },
  },
});

export const { notificationAlert, clearNotificationAlert } =
  notificationsSlice.actions;
