import React, { useCallback, useEffect } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppDispatch } from '../../redux/hooks';
import { PageWrapper } from '../../components';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Button,
  Chip,
  TextField,
  useTheme,
} from '@mui/material';
import { PageTitle } from '../../utils/pageTitles';
import { useTitle } from '../../hooks/useTitle';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { generateClientInvoice } from '../../redux/clientInvoice';

const ClientInvoice = (): JSX.Element => {
  const setPageTitle = useTitle();
  useEffect(() => {
    setPageTitle(PageTitle.reports);
  }, [setPageTitle]);
  const dispatch = useAppDispatch();

  const { spacing } = useTheme();

  const initialValues = {
    jiraSpreadsheetId: '',
    jiraSheetName: '',
    invoiceSpreadsheetId: '',
    invoiceSheetName: '',
    internalProjects: [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      jiraSpreadsheetId: yup.string().required('Cannot be empty'),
      jiraSheetName: yup.string().required('Cannot be empty'),
      invoiceSpreadsheetId: yup.string().required('Cannot be empty'),
      invoiceSheetName: yup.string().required('Cannot be empty'),
      internalProjects: yup
        .array()
        .of(yup.string())
        .required('Cannot be empty'),
    }),
    onSubmit: async (values) => {
      dispatch(generateClientInvoice(values));
    },
  });

  const handleInternalDepartmentsChange = useCallback<
    (
      event: React.SyntheticEvent<Element, Event>,
      value: string[],
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<never> | undefined,
    ) => void
  >(
    (_, value) => {
      const newValue = value.reduce((acc: string[], curr) => {
        acc.push(...curr.split(','));
        return acc;
      }, [] as string[]);

      formik.setFieldValue('internalProjects', newValue);
    },
    [formik],
  );

  return (
    <PageWrapper>
      <Card
        variant="outlined"
        sx={{
          maxWidth: 400,
          marginX: 'auto',
          padding: 3,
          borderRadius: `${spacing(1)} ${spacing(1)} 0 0`,
        }}
      >
        <Grid container spacing={2}>
          <Typography variant="h2" mx={2} mt={2}>
            Client Invoices
          </Typography>
          <Grid item xs={12}>
            <TextField
              id="jiraSpreadsheetId"
              name="jiraSpreadsheetId"
              label="JIRA Spreadsheet Id"
              value={formik.values.jiraSpreadsheetId}
              error={Boolean(formik.errors.jiraSpreadsheetId)}
              onChange={formik.handleChange}
              variant="filled"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="jiraSheetName"
              name="jiraSheetName"
              label="JIRA Sheet Name"
              value={formik.values.jiraSheetName}
              error={Boolean(formik.errors.jiraSheetName)}
              onChange={formik.handleChange}
              variant="filled"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="invoiceSpreadsheetId"
              name="invoiceSpreadsheetId"
              label="Invoice Spreadsheet Id"
              value={formik.values.invoiceSpreadsheetId}
              error={Boolean(formik.errors.invoiceSpreadsheetId)}
              onChange={formik.handleChange}
              variant="filled"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="invoiceSheetName"
              name="invoiceSheetName"
              label="Invoice Sheet Name"
              value={formik.values.invoiceSheetName}
              error={Boolean(formik.errors.invoiceSheetName)}
              onChange={formik.handleChange}
              variant="filled"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              clearIcon={false}
              options={[]}
              freeSolo
              multiple
              value={formik.values.internalProjects}
              onChange={handleInternalDepartmentsChange}
              renderTags={(value, props) =>
                value.map((option, index) => (
                  <Chip label={option} {...props({ index })} key={index} />
                ))
              }
              renderInput={(params) => (
                <TextField label="Add Tags" {...params} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={!formik.isValid}
              onClick={formik.submitForm}
              variant="contained"
            >
              Generate Invoice
            </Button>
          </Grid>
        </Grid>
      </Card>
    </PageWrapper>
  );
};

export default ClientInvoice;
