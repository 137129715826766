import React, { useMemo, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { SortDirection } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KpiMetricTableRow from '../kpiMetricTableRow/KpiMetricTableRow';
import { ColumnTextAlign } from '../../../../constants';
import {
  IndividualKpi,
  SharedKpiModel,
  GeneralKpiModel,
  PeriodType,
} from '../../../../types';
import { getGeneralKpiMetric } from '../../../../utils/getGeneralKpiMetric';
import { createDateFromYYYYMMFormat } from '../../../../utils';
import { TableSortLabel, Typography } from '@mui/material';
import { FilterPeriodModel } from '../../Contractor.types';

interface Props {
  individualKpis: IndividualKpi[];
  sharedKpis: SharedKpiModel[];
  currentPeriod: PeriodType | null;
  handleDeleteMetric: (userKpiAssignmentId: string) => void;
  handleEditMetric: (kpi: GeneralKpiModel) => void;
  getAllResults: (kpi: GeneralKpiModel) => void;
  filteredPeriod: FilterPeriodModel;
  hideMoreActions?: boolean;
}

const KpiMetricTable: React.FC<Props> = ({
  individualKpis,
  sharedKpis,
  handleDeleteMetric,
  handleEditMetric,
  getAllResults,
  currentPeriod,
  hideMoreActions,
  filteredPeriod,
}) => {
  const [sort] = useState<SortDirection>('desc');

  const rows: GeneralKpiModel[] = useMemo(() => {
    return [...sharedKpis, ...individualKpis].map((kpi) =>
      getGeneralKpiMetric(kpi),
    );
  }, [individualKpis, sharedKpis]);

  const sortedRows: GeneralKpiModel[] = useMemo(() => {
    if (!sort) {
      return rows;
    }

    const getRate = (item: GeneralKpiModel): number => {
      if (item.deletedAt) {
        return 1;
      }

      if (
        item.endPeriod &&
        createDateFromYYYYMMFormat(item.endPeriod) < new Date()
      ) {
        return 2;
      }

      if (
        item.startPeriod &&
        createDateFromYYYYMMFormat(item.startPeriod) > new Date()
      ) {
        return 3;
      }

      return 4;
    };

    return [...rows].sort((a, b) =>
      sort === 'asc' ? getRate(a) - getRate(b) : getRate(b) - getRate(a),
    );
  }, [rows, sort]);

  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      sx={{ overflowX: 'initial' }}
    >
      <Table stickyHeader aria-label="KPI-table" size="small">
        <TableHead>
          <TableRow>
            <TableCell size="medium">Metrics</TableCell>
            <TableCell
              size="medium"
              sx={{ whiteSpace: 'nowrap', pr: 4.5 }}
              align={ColumnTextAlign.right}
            >
              Bonus
            </TableCell>
            <TableCell
              size="medium"
              sx={{ whiteSpace: 'nowrap' }}
              align={ColumnTextAlign.right}
            >
              Worst
            </TableCell>
            <TableCell
              size="medium"
              sx={{ whiteSpace: 'nowrap' }}
              align={ColumnTextAlign.right}
            >
              Best
            </TableCell>
            <TableCell
              size="medium"
              sx={{ whiteSpace: 'nowrap', pr: 1 }}
              sortDirection={sort}
              width={75}
            >
              <TableSortLabel
                active={!!sort}
                direction={sort || undefined}
                sx={{
                  flexDirection: 'row-reverse',
                  cursor: 'inherit',
                  ml: -3,
                  '&.Mui-active .MuiTableSortLabel-icon': {
                    opacity: 0.5,
                    width: '16px',
                  },
                }}
              >
                <Typography variant="inherit">Month</Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell
              size="medium"
              sx={{ whiteSpace: 'nowrap' }}
              align={ColumnTextAlign.right}
              width={75}
            >
              Result
            </TableCell>
            <TableCell
              size="medium"
              sx={{ whiteSpace: 'nowrap', pr: 4.5 }}
              align={ColumnTextAlign.right}
              width={75}
            >
              Payout
            </TableCell>
            <TableCell
              size="medium"
              sx={{ whiteSpace: 'nowrap', pr: 4.5 }}
              align={ColumnTextAlign.right}
              width={90}
            >
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="noOverrides">
          {_isEmpty(sortedRows) ? (
            <TableRow>
              <TableCell rowSpan={10}>No metrics assigned</TableCell>
            </TableRow>
          ) : (
            (!sort ? rows : sortedRows).map((kpi) => (
              <KpiMetricTableRow
                kpi={kpi}
                key={kpi.id}
                handleEditMetric={handleEditMetric}
                handleDeleteMetric={handleDeleteMetric}
                currentPeriod={currentPeriod}
                hideMoreActions={hideMoreActions}
                filteredPeriod={filteredPeriod}
                getAllResults={getAllResults}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default KpiMetricTable;
