import { createAsyncThunk } from '@reduxjs/toolkit';
import { accountsStoreKey } from './accounts.const';
import axios from 'axios';
import { apiEndpoints } from '../../constants/api';
import { AccountOverview } from '../../types';
import { GetAccountsData, saveLinkPayloadType } from './accounts.types';
import { accountsSlice } from './accounts.slice';
import { notificationAlert } from '../notifications';
import { formatErrorMessage } from '../../utils';

export const fetchAccounts = createAsyncThunk(
  `${accountsStoreKey}/fetchAccounts`,
  async (
    { fetchAllMembers, departmentFilters, periodFiltering }: GetAccountsData,
    thunkAPI,
  ) => {
    try {
      const response = await axios.post(apiEndpoints.fetchAccounts(), {
        ...departmentFilters,
        periodFiltering,
        ...{ fetchAllMembers },
      });

      return response.data.accounts.map((acc: AccountOverview) => {
        acc.grossMarginTargets = response.data.grossMarginTargets
          ? { ...response.data.grossMarginTargets }
          : undefined;
        acc.marginHighlights = Array.isArray(response.data.marginHighlights)
          ? [...response.data.marginHighlights]
          : undefined;
        acc.portfolioMargins = response.data.portfolioMargins
          ? { ...response.data.portfolioMargins }
          : undefined;
        return acc;
      });
    } catch (err) {
      thunkAPI.dispatch(
        accountsSlice.actions.cleanAccountsTableRow({
          errMessage: 'No Accounts found on this day :(',
        }),
      );
      throw new Error(String(err));
    }
  },
);

export const saveLinks = createAsyncThunk(
  `${accountsStoreKey}/saveLinks`,
  async (body: saveLinkPayloadType, thunkAPI) => {
    try {
      const response = await axios.post(`${apiEndpoints.saveLink()}`, body);
      thunkAPI.dispatch(notificationAlert('Links saved.'));
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);
