import { DepartmentView } from '../../types/contractor';

const departmentsKey = '/departments';

export const departments = (view: DepartmentView) => {
  return `${departmentsKey}?view=${view}`;
};

export const rootDepartments = () => {
  return `${departmentsKey}/root`;
};

export const financeDepartmentId = () => {
  return `${departmentsKey}/finance-department-id`;
};
