import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { KpiOwnerType } from '../../types/kpiOwners';
import { fetchKpiOwners } from './kpiOwners.thunks';
import { kpiOwnersStoreKey } from './kpiOwners.const';

export interface KpiMetricsState {
  apiStatus: API_STATUS;
  kpiOwnersList: KpiOwnerType[];
}

const initialState: KpiMetricsState = {
  apiStatus: API_STATUS.IDLE,
  kpiOwnersList: [],
};

export const kpiOwnersSlice = createSlice({
  name: kpiOwnersStoreKey,
  initialState,
  reducers: {
    clearKpiOwners: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchKpiOwners.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(
        fetchKpiOwners.fulfilled,
        (state, action: PayloadAction<KpiOwnerType[]>) => {
          state.apiStatus = API_STATUS.IDLE;
          state.kpiOwnersList = action.payload;
        },
      )
      .addCase(fetchKpiOwners.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });
  },
});

export const { clearKpiOwners } = kpiOwnersSlice.actions;
