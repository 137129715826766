import React, { FC } from 'react';
import { FallbackComponent } from '../../components/fallbackComponent/FallbackComponent';
import { ServerStatusCode } from '../../constants';
import { FallbackWrapper } from '../../utils/errorPage.styles';

export const Unauthorized: FC = () => {
  return (
    <FallbackWrapper>
      <FallbackComponent
        fullPage
        showBrandLogo
        statusCode={ServerStatusCode.UNAUTHORIZED}
      />
    </FallbackWrapper>
  );
};
