import {
  DepartmentType,
  GeneralKpiModel,
  IndividualKpi,
  PeriodType,
  SharedKpiModel,
} from '../../types';
import { FilterPeriodModel, FilterPeriodType } from './Contractor.types';
import {
  formatDateToYYYYMM,
  formatYYYYMMDateToMMMMyyString,
  yyyyMMToQuarterName,
} from '../../utils';
import { range, uniq } from 'lodash';
import {
  BaseBonusPlanModel,
  BonusPlanSegment,
  BreadcrumbDto,
} from '../../types/contractor';
import { Breadcrumb } from '../../components/breadcrumbs/types';
import { paths } from '../../constants/paths';
import { getNextPeriodValue } from '../../utils/period';
import * as _ from 'lodash';

export const isIndividualKpiType = (
  kpi: IndividualKpi | SharedKpiModel,
): kpi is IndividualKpi => {
  return !('userSharedKpis' in kpi);
};

export const isSharedKpiType = (
  kpi: IndividualKpi | SharedKpiModel,
): kpi is SharedKpiModel => {
  return 'userSharedKpis' in kpi;
};

const isMonthWithinKPIPeriodRangeWithBonus = (
  month: number,
  startPeriod: number,
  endPeriod?: number | null,
  isQuarterlyMetric?: boolean,
  bonusPlanEntries?: BaseBonusPlanModel[],
) => {
  const isMonthWithinKPIStartPeriod =
    month >= subMonthsFromPeriod(startPeriod, isQuarterlyMetric ? 1 : 0);
  const isMonthWithinKPIEndPeriod = !endPeriod || month <= endPeriod;
  const monthContainsBonusPlan = !!bonusPlanEntries?.find(
    (bp) => bp.period === month,
  );

  return (
    isMonthWithinKPIStartPeriod &&
    isMonthWithinKPIEndPeriod &&
    monthContainsBonusPlan
  );
};

export const parseBonusPlan = (
  currentPeriod: PeriodType | null,
  deletedAt: string | null,
  bonusPlanEntries: BaseBonusPlanModel[],
): {
  bonusPlanSegments: BonusPlanSegment[];
  deactivated?: boolean;
  endPeriod: number | null;
  startPeriod: number;
} => {
  const bonusPlanSegments: BonusPlanSegment[] = [];
  const sortedBonusPlanEntries = _.sortBy(bonusPlanEntries, 'period');

  let lastFrom: number = sortedBonusPlanEntries[0].period;
  for (let i = 0; i < sortedBonusPlanEntries.length; i++) {
    const bonusPlanEntry = sortedBonusPlanEntries[i];
    const nextBonusPlanEntry: BaseBonusPlanModel | undefined =
      sortedBonusPlanEntries[i + 1];

    if (
      nextBonusPlanEntry === undefined ||
      nextBonusPlanEntry.period !== getNextPeriodValue(bonusPlanEntry.period)
    ) {
      const isLastElem = i === sortedBonusPlanEntries.length - 1;

      bonusPlanSegments.push({
        from: lastFrom,
        // if it's last period - show it, otherwise set null to show some prompt that this metric is ongoing
        to:
          !isLastElem || bonusPlanEntry.isLast === true
            ? bonusPlanEntry.period
            : null,
      });

      lastFrom = nextBonusPlanEntry?.period;
    }
  }

  const endPeriod = bonusPlanSegments[bonusPlanSegments.length - 1].to;
  const startPeriod = bonusPlanSegments[0].from;

  return {
    deactivated: Boolean(
      deletedAt ||
        (endPeriod && currentPeriod && endPeriod < currentPeriod.period),
    ),
    startPeriod,
    endPeriod,
    bonusPlanSegments,
  };
};

export const checkKpiDeactivatedBeforeFilteredPeriod = (
  currentPeriod: PeriodType | null,
  endPeriod: number | null,
  filteredPeriod: FilterPeriodModel,
): boolean => {
  const [dateFrom] = getPeriodBySelectedFilter(
    currentPeriod,
    filteredPeriod,
  ) || ['', ''];

  const fromPeriod = Number(dateFrom);
  return !!endPeriod && fromPeriod > endPeriod;
};

export const getMonthsListForFilteredPeriod = (
  currentPeriod: PeriodType | null,
  filteredPeriod: FilterPeriodModel,
  kpi: GeneralKpiModel,
  isQuarterlyMetric?: boolean,
): number[] => {
  if (!currentPeriod) {
    return [];
  }

  const [currentPeriodYear] = getPeriodYearMonth(currentPeriod.period);
  const historicalDataEndYear = 2014;

  const { startPeriod, endPeriod } = parseBonusPlan(
    currentPeriod,
    kpi.deletedAt,
    kpi.bonusPlanEntries,
  );

  let results = [];

  switch (filteredPeriod.value) {
    case FilterPeriodType.AllTime:
      const allMonths = [
        ...range(currentPeriod?.period, Number(`${currentPeriodYear}00`)),
        ...range(currentPeriodYear - 1, historicalDataEndYear - 1).flatMap(
          (year) => range(Number(`${year}12`), Number(`${year}00`)),
        ),
      ];

      results = allMonths.filter((month) =>
        isMonthWithinKPIPeriodRangeWithBonus(
          month,
          startPeriod,
          endPeriod,
          isQuarterlyMetric,
          kpi.bonusPlanEntries,
        ),
      );
      break;
    case FilterPeriodType.CurrentYear:
      const currentPeriodYearStart = Number(`${currentPeriodYear}00`);
      const currentPeriodYearEnd = Number(`${currentPeriodYear}12`);

      const showMonthsTill =
        startPeriod >= currentPeriodYearStart &&
        startPeriod <= currentPeriodYearEnd
          ? startPeriod - 1
          : currentPeriodYearStart;

      const showMonthsFrom =
        endPeriod &&
        endPeriod < currentPeriodYearEnd &&
        endPeriod > currentPeriodYearStart
          ? Math.min(currentPeriod?.period, endPeriod)
          : Math.min(currentPeriod?.period, currentPeriodYearEnd);

      const months = range(showMonthsFrom, showMonthsTill);

      results = months.filter((month) =>
        isMonthWithinKPIPeriodRangeWithBonus(
          month,
          startPeriod,
          endPeriod,
          isQuarterlyMetric,
          kpi.bonusPlanEntries,
        ),
      );

      break;

    case FilterPeriodType.LastYear:
      const lastYearMonths = [
        isQuarterlyMetric ? Number(`${Number(currentPeriodYear)}01`) : 0,
        ...range(
          Number(`${currentPeriodYear - 1}12`),
          Number(`${currentPeriodYear - 1}${isQuarterlyMetric ? '01' : '00'}`),
        ),
      ];

      results = lastYearMonths
        .filter((x) => x)
        .filter((month) =>
          isMonthWithinKPIPeriodRangeWithBonus(
            month,
            startPeriod,
            endPeriod,
            isQuarterlyMetric,
            kpi.bonusPlanEntries,
          ),
        );
      break;

    case FilterPeriodType.LastMonth:
      results = [Number(getPrevMonth(currentPeriod)[0])];
      break;

    case FilterPeriodType.LastQuarter:
      const [showQuartersTo, showQuartersFrom] = getLastQuarter(
        currentPeriod?.period,
      );
      results = range(
        Number(showQuartersFrom),
        subMonthsFromPeriod(Number(showQuartersTo), isQuarterlyMetric ? 0 : 1),
      );

      break;
  }

  return uniq(results).filter((x) => {
    const [, month] = getPeriodYearMonth(x);
    return !isQuarterlyMetric || month % 3 === 0;
  });
};

const getFullMonth = (month: string) =>
  month.length === 1 ? `0${month}` : month;

const getPrevMonth = (currentPeriod: PeriodType | null): [string, string] => {
  const [currentPeriodYear, currentPeriodMonth] = getPeriodYearMonth(
    currentPeriod?.period,
  );
  const date = new Date(currentPeriodYear, currentPeriodMonth - 1);

  const year =
    date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear();
  const month = date.getMonth() === 0 ? '12' : String(date.getMonth());
  const fullMonth = getFullMonth(month);

  return [`${year}${fullMonth}`, `${year}${fullMonth}`];
};

export const isPeriodAfterQuarterly = (period?: number | null): boolean => {
  const periodToCheck = period ?? formatDateToYYYYMM(new Date()) ?? 0;
  return [1, 4, 7, 10].includes(periodToCheck % 100);
};

export const getLastQuarter = (
  currentPeriod?: number | null,
): [string, string] => {
  const period = currentPeriod ?? formatDateToYYYYMM(new Date());
  const [currentPeriodYear, currentPeriodMonth] = getPeriodYearMonth(period);
  const date = new Date(currentPeriodYear, currentPeriodMonth - 1);

  const year = date.getFullYear();

  if (date.getMonth() < 3) {
    return [`${year - 1}10`, `${year - 1}12`];
  }
  if (date.getMonth() < 6) {
    return [`${year}01`, `${year}03`];
  }
  if (date.getMonth() < 9) {
    return [`${year}04`, `${year}06`];
  }

  return [`${year}07`, `${year}09`];
};

export const getCurrentQuarter = (
  currentPeriod?: number | null,
): [string, string] => {
  const period = currentPeriod ?? formatDateToYYYYMM(new Date());
  const [currentPeriodYear, currentPeriodMonth] = getPeriodYearMonth(period);
  const date = new Date(currentPeriodYear, currentPeriodMonth - 1);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  if (month > 9) {
    return [`${year}10`, `${year}12`];
  }
  if (month > 6) {
    return [`${year}07`, `${year}09`];
  }
  if (month > 3) {
    return [`${year}04`, `${year}06`];
  }

  return [`${year}01`, `${year}03`];
};

export const getPeriodBySelectedFilter = (
  currentPeriod: PeriodType | null,
  { value }: FilterPeriodModel,
): [string, string] | null => {
  const [currentPeriodYear] = getPeriodYearMonth(currentPeriod?.period);

  switch (value) {
    case FilterPeriodType.AllTime:
      return null;
    case FilterPeriodType.CurrentYear:
      return [`${currentPeriodYear}01`, `${currentPeriodYear}12`];
    case FilterPeriodType.LastYear:
      return [`${currentPeriodYear - 1}01`, `${currentPeriodYear - 1}12`];
    case FilterPeriodType.LastMonth:
      return getPrevMonth(currentPeriod);
    case FilterPeriodType.LastQuarter:
      return getLastQuarter(currentPeriod?.period);
  }
};

export const calculateDepartmentMembers = (depatment: DepartmentType): number =>
  depatment.subDepartments.length === 0
    ? depatment.members.length
    : depatment.members.length +
      depatment.subDepartments
        ?.map((dep) => calculateDepartmentMembers(dep))
        .reduce((subDepSum, subDepCount) => subDepSum + subDepCount, 0);

export const findDepartment = (
  department: DepartmentType,
  departmentId: string,
): boolean => {
  if (!departmentId) {
    return false;
  }
  return department.subDepartments.length === 0
    ? department.id === departmentId
    : department.id === departmentId ||
        department.subDepartments.some((dept) =>
          findDepartment(dept, departmentId),
        );
};

export const getPeriodYearMonth = (
  period?: number | null,
): [number, number] => {
  const date = new Date();

  return period
    ? [parseInt(`${period}`.slice(0, 4)), parseInt(`${period}`.slice(4, 6))]
    : [date.getFullYear(), date.getMonth()];
};

export const getFilterPeriodOptions = (
  currentPeriod: PeriodType | null,
): FilterPeriodModel[] => {
  const currentYear = getPeriodYearMonth(currentPeriod?.period)[0];

  return [
    AllTimeFilter,
    {
      label: `Current year`,
      value: FilterPeriodType.CurrentYear,
      subtext: `${currentYear}`,
    },
    {
      label: `Last year`,
      value: FilterPeriodType.LastYear,
      subtext: `${currentYear - 1}`,
    },
    {
      label: 'Last quarter',
      value: FilterPeriodType.LastQuarter,
      subtext: yyyyMMToQuarterName(
        getLastQuarter(currentPeriod?.period)[0],
        false,
      ),
    },
    {
      label: 'Last month',
      value: FilterPeriodType.LastMonth,
      subtext: formatYYYYMMDateToMMMMyyString(
        getPrevMonth(currentPeriod)[0],
        true,
      ),
    },
  ];
};

export const AllTimeFilter: FilterPeriodModel = {
  label: `All time`,
  value: FilterPeriodType.AllTime,
};

export const getDefaultPeriodFilter = (
  currentPeriod: PeriodType | null,
): FilterPeriodModel => {
  const filters = getFilterPeriodOptions(currentPeriod);
  return (
    filters.find((x) => x.value === FilterPeriodType.CurrentYear) ?? filters[1]
  );
};

export const addMonthsToPeriod = (period: number, months: number) => {
  let [year, month] = getPeriodYearMonth(period);

  month += months;
  while (month > 12) {
    month -= 12;
    year++;
  }

  return Number(`${year}${getFullMonth(month.toString())}`);
};

export const subMonthsFromPeriod = (period: number, months: number) => {
  let [year, month] = getPeriodYearMonth(period);

  month -= months;
  while (month < 1) {
    month += 12;
    year--;
  }

  return Number(`${year}${getFullMonth(month.toString())}`);
};

export const getContractorBreadcrumbs = (
  breadcrumbs: BreadcrumbDto[],
): Breadcrumb[] => {
  return breadcrumbs.map((crumb) => ({
    id: crumb.id,
    name: crumb.name,
    isAccessible: crumb.isAccessible,
    path: `${paths.contractors}/${crumb.id}`,
  }));
};
