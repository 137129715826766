import { Button, styled } from '@mui/material';

export const StyledDateButtonAssessmentsGraph = styled(Button)`
  font-size: 11px;

  &.selected {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.brand.primary};
    background-color: ${({ theme }) => theme.palette.brand.highlight};
  }
`;
