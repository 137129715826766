import React from 'react';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useAppDispatch } from '../../redux/hooks';
import { reportIssue, reportIncorrectReport } from '../../redux/reportIssue';
import {
  validationSchema,
  reportIncorrectSchemaValidationSchema,
} from './validationSchema';
import { Alert, AlertTitle } from '@mui/material';
import { MemberInfoCell } from '../../components/tableCellRenders';
import Autocomplete from '@mui/material/Autocomplete';
import { useAppSelector } from '../../redux/hooks';
import { userDataSelectors } from '../../redux/userData';

export enum ReportIssueVariant {
  ReportIssue = 'ReportIssue',
  ReportIssueIncorrectFields = 'ReportIssueIncorrectFields',
}

const incorrectOptions = [
  { title: 'Project allocation' },
  { title: 'Incorrect rates' },
];

interface CurrentUser {
  fullName: string | undefined;
  stream: string | undefined;
  seniority?: string;
  avatarUrl?: string;
  city?: string;
  country?: string;
  accountName?: string;
}

interface Props {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  reportIssueVariant?: ReportIssueVariant;
  currentUserDetails?: CurrentUser;
}

interface FormValues {
  feedback: string;
  page: string;
  incorrectFields: string[];
  newFeedbackValue: string;
}

const ReportIssueModal: React.FC<Props> = ({
  isModalOpen,
  handleCloseModal,
  reportIssueVariant,
  currentUserDetails,
}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(userDataSelectors.getUserData);

  const initialValues: FormValues = {
    feedback: '',
    page: window.location.href,
    incorrectFields: ['Project allocation'],
    newFeedbackValue: '',
  };

  const validationSchemaToUse = reportIssueVariant
    ? reportIncorrectSchemaValidationSchema
    : validationSchema;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaToUse,
    onSubmit: async (values) => {
      let action;
      let dispatchValues;

      if (
        reportIssueVariant === ReportIssueVariant.ReportIssueIncorrectFields
      ) {
        action = reportIncorrectReport;
        dispatchValues = {
          notes: values.newFeedbackValue,
          teamMember: currentUserDetails?.fullName || '',
          reportedBy: user?.email || '',
          incorrectFields: values.incorrectFields.join(','),
          accountName: currentUserDetails?.accountName || '',
        };

        await dispatch(action(dispatchValues));
      } else {
        action = reportIssue;
        dispatchValues = {
          feedback: values.feedback,
          page: values.page,
        };
        await dispatch(action(dispatchValues));
      }

      handleCloseModal();
    },
  });

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleCloseModal}
      open={isModalOpen}
      scroll="body"
    >
      <DialogTitle mb={1}>
        {reportIssueVariant === ReportIssueVariant.ReportIssueIncorrectFields
          ? 'Share feedback or issue'
          : 'Share feedback'}
      </DialogTitle>
      <DialogContent>
        <>
          <Grid container spacing={2}>
            {reportIssueVariant ===
            ReportIssueVariant.ReportIssueIncorrectFields ? (
              <>
                <Grid item xs={12} mt={1} mb={1}>
                  <MemberInfoCell
                    userInfo={{
                      fullName: `${currentUserDetails?.fullName}`,
                      stream: `${currentUserDetails?.seniority} ${currentUserDetails?.stream}`,
                      avatarUrl: `${currentUserDetails?.avatarUrl}`,
                      country: `${currentUserDetails?.country}`,
                      city: `${currentUserDetails?.city}`,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id="tags-readOnly"
                    options={incorrectOptions.map((option) => option.title)}
                    defaultValue={[incorrectOptions[0].title]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Incorrect fields"
                        variant="filled"
                        error={Boolean(formik.errors.incorrectFields)}
                        helperText={formik.errors.incorrectFields}
                      />
                    )}
                    onChange={(e, values) => {
                      formik.setFieldValue('incorrectFields', values);
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="feedback updated"
                    name="newFeedbackValue"
                    label="Feedback"
                    placeholder="Report a bug or suggest an improvement"
                    helperText="Dev team may email you to clarify details"
                    value={formik.values.newFeedbackValue}
                    onChange={formik.handleChange}
                    InputLabelProps={{ shrink: true }}
                    minRows={10}
                    variant="filled"
                    multiline
                    fullWidth
                    error={Boolean(formik.errors.newFeedbackValue)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Alert severity="warning" icon={'🤫'}>
                    <AlertTitle>
                      Any sensitive financial info must not be reported here
                    </AlertTitle>
                    If you have any questions, concerns, or discrepancies
                    related to $ numbers – please report them directly to the
                    Finance team.
                  </Alert>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <Alert severity="warning" icon={'🤫'}>
                    <AlertTitle>
                      Any sensitive financial info must not be reported here
                    </AlertTitle>
                    If you have any questions, concerns, or discrepancies
                    related to $ numbers – please report them directly to the
                    Finance team.
                  </Alert>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="feedback"
                    name="feedback"
                    label="Feedback"
                    placeholder="Report a bug or suggest an improvement"
                    helperText="Dev team may email you to clarify details"
                    value={formik.values.feedback}
                    error={Boolean(formik.errors.feedback)}
                    onChange={formik.handleChange}
                    InputLabelProps={{ shrink: true }}
                    minRows={10}
                    variant="filled"
                    multiline
                    fullWidth
                  />
                </Grid>
              </>
            )}
          </Grid>
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal}>Cancel</Button>
        <Button
          disabled={!formik.isValid}
          onClick={formik.submitForm}
          variant="contained"
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportIssueModal;
