import React, { MouseEvent, useState, useMemo, useCallback } from 'react';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Menu, MenuItem, Box, ListItemText, useTheme } from '@mui/material';
import { PlainObject } from '../../types';
import { ExtendButtonBase } from '@mui/material/ButtonBase';
import { MenuItemTypeMap } from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

interface Props<T> {
  id?: string;
  dataTestId?: string;
  suffix?: string;
  options: T[];
  loading?: boolean;
  value?: T;
  sortGroupedByDesc?: boolean;

  startIcon?: React.ReactNode;
  sx?: PlainObject;
  fullWidth?: boolean;
  menuWidth?: string;
  variant?: 'contained' | 'text';
  color?: 'highlight';
  size?: 'small' | 'medium' | 'large';
  typographyVariant?: 'body1';

  onChange?: (e: MouseEvent<HTMLLIElement>, value: T) => void;
  getOptionLabel?: (option: T) => string;
  getOptionSubtext?: (option: T) => string | undefined;
  groupBy?: (option: T) => string;
  isOptionEqualToValue?: (value: T, option: T) => boolean;
  renderOption?: (
    props: Partial<ExtendButtonBase<MenuItemTypeMap>>,
    option: T,
    index: number,
  ) => JSX.Element;
}

export function ButtonMenuGroup<T = string>({
  id,
  dataTestId,
  suffix,
  options,
  loading,
  value,
  sortGroupedByDesc = false,

  startIcon,
  sx,
  fullWidth,
  menuWidth = '223px',
  variant = 'contained',
  color,
  size = 'large',
  typographyVariant,

  getOptionLabel,
  getOptionSubtext,
  groupBy,
  isOptionEqualToValue,
  renderOption,
  onChange,
}: Props<T>): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { spacing } = useTheme();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (loading) return;
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl, loading],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onSelect = useCallback(
    (e: MouseEvent<HTMLLIElement>, newValue: T) => {
      handleClose();
      if (!onChange) return;

      onChange(e, newValue);
    },
    [onChange, handleClose],
  );

  const defaultRenderOption = (
    props: Partial<ExtendButtonBase<MenuItemTypeMap>>,
    option: T,
    index: number,
  ) => (
    <MenuItem key={index} {...props} onClick={(e) => onSelect(e, option)}>
      <ListItemText>
        {option && getOptionLabel
          ? getOptionLabel(option)
          : String(option || '')}
      </ListItemText>
      {option && getOptionSubtext && (
        <Typography variant="body2" color="text.secondary" align="right">
          {getOptionSubtext(option)}
        </Typography>
      )}
    </MenuItem>
  );

  const renderOptionFunc = renderOption || defaultRenderOption;

  const groupedOptions = useMemo(() => {
    const isOptionEqualFunc =
      isOptionEqualToValue || ((value: T, option: T) => value === option);

    const renderOptionsList = (
      list: T[],
      itemProps: Partial<ExtendButtonBase<MenuItemTypeMap>>,
    ) => {
      return (list || []).map((option, index) => {
        const props = {
          ...itemProps,
          selected: value !== undefined && isOptionEqualFunc(value, option),
        };

        return renderOptionFunc(props, option, index);
      });
    };

    if (!groupBy) {
      return renderOptionsList(options, {});
    }

    const grouped = (options || []).reduce((acum, option) => {
      const label = groupBy(option);

      return {
        ...acum,
        [label]: [...(acum[label] || []), option],
      };
    }, {} as PlainObject<T[]>);

    const groupedKeys = sortGroupedByDesc
      ? Object.keys(grouped).sort((a, b) => (a > b ? -1 : 1))
      : Object.keys(grouped);

    return groupedKeys.map((label) => (
      <Box key={label} sx={{ mb: '12px' }}>
        <MenuItem disabled>
          <Typography variant="subtitle2">{label}</Typography>
        </MenuItem>
        {renderOptionsList(grouped[label], {})}
      </Box>
    ));
  }, [
    options,
    groupBy,
    sortGroupedByDesc,
    renderOptionFunc,
    isOptionEqualToValue,
    value,
  ]);

  const contentStyles = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
  };

  const buttonContent = typographyVariant ? (
    <Typography
      variant={typographyVariant}
      sx={{
        ...contentStyles,
        width: '100%',
        textAlign: 'left',
      }}
    >
      {suffix}
      {value && getOptionLabel ? getOptionLabel(value) : String(value || '')}
    </Typography>
  ) : (
    <Box sx={{ ...contentStyles }}>
      {`${suffix}${
        value && getOptionLabel ? getOptionLabel(value) : String(value || '')
      }`}
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'inline-flex',
        ...(fullWidth && { width: '100%' }),
      }}
      data-testid={dataTestId}
    >
      <Button
        id={id}
        fullWidth={fullWidth}
        variant={variant}
        color={color}
        size={size}
        onClick={handleClick}
        sx={{
          maxWidth: '100%',
          textTransform: 'none',
          justifyContent: 'space-between',
          paddingLeft: spacing(2.5),
          paddingRight: spacing(2),
          '.MuiButton-startIcon': {
            marginRight: spacing(2),
            marginLeft: `-${spacing(0.5)}`,
          },
          ...(sx || {}),
        }}
        endIcon={
          loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <KeyboardArrowDownIcon />
          )
        }
        startIcon={startIcon}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        {buttonContent}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: { width: menuWidth },
        }}
      >
        {groupedOptions}
      </Menu>
    </Box>
  );
}
