import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { DepartmentBaseType } from '../../types';
import { rootDepartmentsStoreKey } from './rootDepartments.const';
import {
  fetchFinanceDepartmentId,
  fetchRootDepartments,
} from './rootDepartments.thunk';

export interface RootDepartmentsState {
  apiStatus: API_STATUS;
  rootDepartments: DepartmentBaseType[];
  financeDepartmentId: string;
}

const initialState: RootDepartmentsState = {
  apiStatus: API_STATUS.IDLE,
  rootDepartments: [],
  financeDepartmentId: '',
};

export const rootDepatmentsSlice = createSlice({
  name: rootDepartmentsStoreKey,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRootDepartments.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(
        fetchRootDepartments.fulfilled,
        (state, { payload }: PayloadAction<DepartmentBaseType[]>) => {
          state.rootDepartments = payload;
          state.apiStatus = API_STATUS.IDLE;
        },
      )
      .addCase(fetchRootDepartments.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });

    builder.addCase(
      fetchFinanceDepartmentId.fulfilled,
      (state, { payload }: PayloadAction<string>) => {
        state.financeDepartmentId = payload;
      },
    );
  },
});
