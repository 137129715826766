import React, { Fragment, PropsWithChildren } from 'react';
import AccessTimeIconRounded from '@mui/icons-material/AccessTimeRounded';
import Stack from '@mui/material/Stack';
import { formatYYYYMMDateToMMMMyyString } from '../../../../utils';
import {
  priceFormat,
  formatByDefinitionType,
} from '../../../../components/tableCellRenders';
import {
  ColumnTextAlign,
  KPI_TYPES,
  KPI_FREQUENCY,
} from '../../../../constants';
import { CustomTableCell } from '../../../../components/customTable';
import { KpiMetricStatKeyFields } from '../../../../types';
import { KpiMetricBestWorstCells } from './KpiMetricBestWorstCells';
import { Box, Tooltip } from '@mui/material';
import { getKpiValueColor } from '../../../../utils/kpis';

interface Props {
  name: string | number;
  payout?: number;
  result?: number;
  statItem?: KpiMetricStatKeyFields;
  isFirst?: boolean;
  isLast?: boolean;
  isCurrent?: boolean;
  kpiType: KPI_TYPES;
  frequency: KPI_FREQUENCY;
  trueup?: number;
}

export function KpiMetricRowContent({
  name,
  payout,
  result,
  statItem,
  isFirst,
  isLast,
  isCurrent,
  kpiType,
  frequency,
  trueup,
}: Props): JSX.Element {
  const WrapTimeIcon = ({ children }: PropsWithChildren) => (
    <Stack direction="row" justifyContent="end">
      <Tooltip title="Pending" enterDelay={300} placement="top">
        <AccessTimeIconRounded className="time-icon" fontSize="small" />
      </Tooltip>
      <Box sx={{ ml: 0.5 }}>{children}</Box>
    </Stack>
  );

  const renderPeriodData = (
    name: string | number,
    payout?: number,
    result?: number,
    isLast?: boolean,
    isCurrent?: boolean,
    trueup?: number,
  ) => {
    const borderClassName = isLast ? '' : 'with-border';
    const isQuarter = frequency === KPI_FREQUENCY.QUARTERLY;

    const periodCell = isQuarter ? (
      <CustomTableCell
        width={75}
        className={borderClassName}
        typographyVariant="body2"
        sx={{ whiteSpace: 'nowrap' }}
      >
        {name}
      </CustomTableCell>
    ) : (
      <CustomTableCell
        width={75}
        className={borderClassName}
        typographyVariant="body2"
        sx={{ whiteSpace: 'nowrap' }}
      >
        {formatYYYYMMDateToMMMMyyString(name, true)}
      </CustomTableCell>
    );

    const Wrapper = isCurrent ? WrapTimeIcon : Fragment;

    return (
      <>
        {periodCell}
        <CustomTableCell
          width={75}
          className={borderClassName}
          sx={{ whiteSpace: 'nowrap' }}
          align={ColumnTextAlign.right}
          pure={isCurrent}
          color={
            !isCurrent && statItem
              ? getKpiValueColor(result, statItem.worstCase, statItem.bestCase)
              : undefined
          }
        >
          <Wrapper>{formatByDefinitionType(kpiType, result)}</Wrapper>
        </CustomTableCell>
        <CustomTableCell
          width={75}
          className={borderClassName}
          fontWeight={isQuarter ? 600 : 400}
          align={ColumnTextAlign.right}
          sx={
            isCurrent
              ? { whiteSpace: 'nowrap', pr: 2.25 }
              : { whiteSpace: 'nowrap' }
          }
          pure={isCurrent}
        >
          <Wrapper>{priceFormat({ value: payout, trueup })}</Wrapper>
        </CustomTableCell>
      </>
    );
  };

  return (
    <>
      <KpiMetricBestWorstCells
        frequency={frequency}
        item={statItem}
        isFirst={isFirst}
        isCurrent={isCurrent}
        kpiType={kpiType}
      />
      {renderPeriodData(name, payout, result, isLast, isCurrent, trueup)}
    </>
  );
}
