import {
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  getRedirectResult,
} from 'firebase/auth';
import { useCallback, useEffect, useState } from 'react';
import { auth } from '../config/firebase';
import { paths } from '../constants/paths';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { RootState } from '../redux/store';
import {
  fetchRockyUserData,
  fetchUserAccountsCounts,
  fetchUserData,
  fetchUsersList,
} from '../redux/userData';
import { AuthService, CUSTOM_LOGIN_EMAIL } from '../services/auth-service';
import { CookieService } from '../services/cookies';
import { SessionStorageService } from '../services/session-storage-service';
import { UserWithPermissions } from '../types';
import { isFirefox, parseLocation } from '../utils';
import useOnlineStatus from './useOnlineStatus';

const NUMBERS_OF_RELOAD_KEY = 'numbersOfReload';
const LOGIN_POPUP_KEY = 'loginPopupKey';

export const getCustomEmail = () => {
  let { customEmail } = parseLocation<{ customEmail?: string }>();
  const customEmailFromCookie = CookieService.getCookie(CUSTOM_LOGIN_EMAIL);
  customEmail = customEmail ?? customEmailFromCookie;
  return customEmail;
};

export const useAuth = (): {
  user: UserWithPermissions | null;
  showInstructions: boolean;
} => {
  const [updatedToken, setUpdatedToken] = useState(false);
  const [updatedCustomToken, setUpdatedCustomToken] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector(authUserSelector);
  const isOffline = useOnlineStatus();
  const isRockyBonusesPage = window.location.pathname.includes(
    paths.rockyBonuses,
  );

  const [showInstructions, setShowInstructions] = useState(false);

  const isUserInitialized = !!AuthService.getToken();

  const numbersOfReload = SessionStorageService.getState<string>(
    NUMBERS_OF_RELOAD_KEY,
  );

  const increaseNumbersOfReload = () => {
    if (isFirefox) {
      SessionStorageService.setState(
        NUMBERS_OF_RELOAD_KEY,
        (Number(numbersOfReload ?? 0) + 1).toString(),
      );
    }
  };

  const fetchData = useCallback(() => {
    const customEmail = getCustomEmail();
    const shouldFetchData =
      isUserInitialized &&
      ((customEmail && updatedCustomToken) || (!customEmail && updatedToken));

    if (shouldFetchData) {
      dispatch(fetchUserData());
      dispatch(fetchUserAccountsCounts());
      dispatch(fetchUsersList());
      dispatch(fetchRockyUserData());
    }
  }, [dispatch, isUserInitialized, updatedToken, updatedCustomToken]);

  const signIn = useCallback(() => {
    if (!SessionStorageService.getState(LOGIN_POPUP_KEY)) {
      signInWithPopup(auth, new GoogleAuthProvider()).then(() => {
        location.reload();
      });
      SessionStorageService.setState(LOGIN_POPUP_KEY, true);
    }

    increaseNumbersOfReload();
    // eslint-disable-next-line
  }, []);

  onAuthStateChanged(auth, async (userCred) => {
    const customEmail = getCustomEmail();
    if (!auth || isRockyBonusesPage) return;
    else if (isFirefox && Number(numbersOfReload) > 2 && !userCred) {
      setShowInstructions(true);
      return;
    } else if (
      !userCred &&
      (!isFirefox || Number(numbersOfReload) <= 2) &&
      !customEmail
    ) {
      signIn();
      return;
    }
  });

  useEffect(() => {
    const customEmail = getCustomEmail();
    if (customEmail) {
      AuthService.customLogin(customEmail)?.then(() => {
        const accessToken = AuthService.getToken();
        if (accessToken) {
          setUpdatedCustomToken(true);
        }
        fetchData();
      });
      return;
    }

    auth.currentUser?.getIdToken(true).then((res) => {
      AuthService.setToken(res);
      const accessToken = AuthService.getToken();

      if (accessToken) {
        setUpdatedToken(true);
      }
    });

    getRedirectResult(auth).then(() => {
      if (isRockyBonusesPage) {
        return;
      }
      if (isFirefox && Number(numbersOfReload) > 2 && !auth.currentUser) {
        setShowInstructions(true);
        return;
      } else if (
        !auth.currentUser &&
        (!isFirefox || Number(numbersOfReload) <= 2)
      ) {
        signIn();
        return;
      } else {
        auth.currentUser?.getIdToken().then((res) => {
          AuthService.setToken(res);
          const accessToken = AuthService.getToken();

          if (accessToken) {
            setUpdatedToken(true);
          }

          if (isUserInitialized) {
            !isOffline && fetchData();
          }
        });
      }
    });

    // eslint-disable-next-line
  }, [
    isRockyBonusesPage,
    numbersOfReload,
    signIn,
    isOffline,
    updatedToken,
    auth,
    isUserInitialized,
    fetchData,
    dispatch,
  ]);

  useEffect(() => {
    if (isUserInitialized && !getCustomEmail()) {
      !isOffline && fetchData();
    }
    // eslint-disable-next-line
  }, [isOffline, isUserInitialized, updatedToken, updatedCustomToken]);

  return {
    user,
    showInstructions,
  };
};

const authUserSelector = (state: RootState): UserWithPermissions | null =>
  state.userData.user;
