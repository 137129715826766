import {
  MarginResultContractor,
  GroupedMembers,
  Member,
  DummyMember,
  ProjectDetail,
  MarginResultProject,
  SeniorityOrder,
  DummmyProjectDetail,
} from '../../types';
import { GroupAccountMembersBy, SortAccountMembersBy } from './account.types';
import { v4 as uuidV4 } from 'uuid';

export const accountStoreKey = 'account';

export const sortAccountMembersMap: Record<
  SortAccountMembersBy,
  (a: Member, b: Member) => number
> = {
  [SortAccountMembersBy.Name]: (a, b) => a.fullName.localeCompare(b.fullName),
  [SortAccountMembersBy.Margin]: (a, b) =>
    (a.grossMargin || 0) - (b.grossMargin || 0),
  [SortAccountMembersBy.PayRate]: (a, b) =>
    (a.compensationRate || 0) - (b.compensationRate || 0),
  [SortAccountMembersBy.BillRate]: (a, b) =>
    (a.resource?.billRate || 0) - (b.resource?.billRate || 0),
  [SortAccountMembersBy.Seniority]: (a, b) =>
    SeniorityOrder[a.seniority ?? ''] - SeniorityOrder[b.seniority ?? ''],
};

export const sortAccountDummyMembersMap: Record<
  SortAccountMembersBy,
  (a: DummyMember, b: DummyMember) => number
> = {
  [SortAccountMembersBy.Name]: () => 0,
  [SortAccountMembersBy.Margin]: (a, b) =>
    (a.grossMargin || 0) - (b.grossMargin || 0),
  [SortAccountMembersBy.PayRate]: (a, b) =>
    (a.compensationRate || 0) - (b.compensationRate || 0),
  [SortAccountMembersBy.BillRate]: (a, b) =>
    (a?.billRate || 0) - (b.billRate || 0),
  [SortAccountMembersBy.Seniority]: (a, b) =>
    SeniorityOrder[a.seniority ?? ''] - SeniorityOrder[b.seniority ?? ''],
};

export const sortAccountMap: Record<
  SortAccountMembersBy,
  (a: GroupedMembers, b: GroupedMembers) => number
> = {
  [SortAccountMembersBy.Name]: (a, b) => {
    const firstAccount1stMemberName = a.members?.length
      ? a.members[0].fullName
      : '';
    const secondAccount1stMemberName = b.members?.length
      ? b.members[0].fullName
      : '';
    return firstAccount1stMemberName.localeCompare(secondAccount1stMemberName);
  },
  [SortAccountMembersBy.Margin]: (a, b) => {
    const firstAccount1stMemberGross =
      a.margin ?? (a.members?.length ? a.members[0].grossMargin : 0);
    const secondAccount1stMemberGross =
      b.margin ?? (b.members?.length ? b.members[0].grossMargin : 0);
    return (
      (firstAccount1stMemberGross ?? 0) - (secondAccount1stMemberGross ?? 0)
    );
  },
  [SortAccountMembersBy.PayRate]: (a, b) => {
    const firstAccount1stMemberPayRate = a.members?.length
      ? a.members[0].compensationRate
      : 0;
    const secondAccount1stMemberPayRate = b.members?.length
      ? b.members[0].compensationRate
      : 0;
    return (
      (firstAccount1stMemberPayRate || 0) - (secondAccount1stMemberPayRate || 0)
    );
  },
  [SortAccountMembersBy.BillRate]: (a, b) => {
    const firstAccount1stMemberBillRate = a.members?.length
      ? a.members[0].resource?.billRate
      : 0;
    const secondAccount1stMemberBillRate = b.members?.length
      ? b.members[0].resource?.billRate
      : 0;
    return (
      (firstAccount1stMemberBillRate || 0) -
      (secondAccount1stMemberBillRate || 0)
    );
  },
  [SortAccountMembersBy.Seniority]: (a, b) => {
    const firstAccount1stMemberSeniority = a.members?.length
      ? SeniorityOrder[a.members[0].seniority ?? '']
      : 0;
    const secondAccount1stMemberSeniority = b.members?.length
      ? SeniorityOrder[b.members[0].seniority ?? '']
      : 0;
    return firstAccount1stMemberSeniority - secondAccount1stMemberSeniority;
  },
};

export const groupAccountMembersByMap: Record<
  GroupAccountMembersBy,
  (projects: ProjectDetail[]) => GroupedMembers[]
> = {
  [GroupAccountMembersBy.Team]: (projects: ProjectDetail[]) => {
    const teams = new Set(
      projects.map((project) => ({
        id: project.id,
        name: project.name,
        margin: project.grossMargin,
      })),
    );

    return Array.from(teams).map((team) => ({
      projectId: team.id,
      groupName: team.name,
      margin: team.margin,
      members: projects
        .filter((project) => project.name === team.name)
        .map((project) => (project.members ? project.members : []))
        .flat(),
    }));
  },
  [GroupAccountMembersBy.Seniority]: (projects: ProjectDetail[]) => {
    const members: Member[] = [];

    const seniorities = new Set(
      projects
        .map((project) => {
          if (project.members) {
            members.push(...project.members);
            return project.members.map((member) => member.seniority);
          } else return '';
        })
        .flat(),
    );

    return Array.from(seniorities)
      ?.map((seniority) => ({
        groupName: seniority,
        members: members.filter((member) => member?.seniority === seniority),
      }))
      .sort(
        (a, b) =>
          SeniorityOrder[a.members[0]?.seniority ?? ''] -
          SeniorityOrder[b.members[0]?.seniority ?? ''],
      );
  },
  [GroupAccountMembersBy.Stream]: (projects: ProjectDetail[]) => {
    const members: Member[] = [];

    const roles = new Set(
      projects
        .map((project) => {
          if (project.members) {
            members.push(...project.members);
            return project.members.map((member) => member.stream);
          } else return '';
        })
        .flat(),
    );

    return Array.from(roles).map((role) => ({
      groupName: role,
      members: members.filter((member) => member.stream === role),
    }));
  },
  [GroupAccountMembersBy.Location]: (projects: ProjectDetail[]) => {
    const members: Member[] = [];

    const locations = new Set(
      projects
        .map((project) => {
          if (project.members) {
            members.push(...project.members);
            return project.members.map((member) => member.legalLocation);
          } else return '';
        })
        .flat(),
    );

    return Array.from(locations)
      .sort()
      .map((location) => ({
        groupName: location,
        members: members.filter((member) => member.legalLocation === location),
      }));
  },
};

export const mapWithDummyMargin = (item: Member) => {
  return {
    ...item,
    dummy: {
      netMargin: item.netMargin,
      grossMargin: item.grossMargin,
      compensationRate: item.compensationRate,
      billRate: item.resource?.billRate ?? 0,
      allocation: item.allocation ?? 100,
    },
  };
};

export const mapWithContractorMargin =
  (contractors: MarginResultContractor[], projectId?: string) =>
  (member: Member) => {
    const contractor = contractors.find(
      (item) => item.id === member.id && item.projectId === projectId,
    );
    if (!contractor) return member;

    return {
      ...member,
      grossMargin: contractor.grossMargin,
      netMargin: contractor.netMargin,
    };
  };

export const mapDummyMembers = (
  accountDummyMembers: DummyMember[],
  contractors: MarginResultContractor[],
) => {
  return accountDummyMembers.map((dummyMember) => {
    const contractor = contractors.find(
      (contractor) => contractor.id === dummyMember.id,
    );
    return {
      ...dummyMember,
      grossMargin: contractor?.grossMargin,
      netMargin: contractor?.netMargin,
    };
  });
};

export const mapMarignToDummyProject = (
  accountDummyProjects: DummmyProjectDetail[],
  projects: MarginResultProject[],
) => {
  return accountDummyProjects.map((dummyProject) => {
    const project = projects.find((project) => project.id === dummyProject.id);
    return {
      ...dummyProject,
      grossMargin: project?.grossMargin,
      netMargin: project?.netMargin,
    };
  });
};

export const setProjectMargin: (
  projectId: string | undefined,
  projects: MarginResultProject[],
) => number | null | undefined = (projectId, projects) => {
  if (!projectId) return undefined;
  const project = projects.find((proj) => proj.id === projectId);
  if (!project) return undefined;

  return project.grossMargin;
};

export const getNewDummyProject = (
  projects?: DummmyProjectDetail[],
): DummmyProjectDetail => {
  const projectId = uuidV4();
  return {
    id: projectId,
    name: `Team ${projects?.length ? projects?.length + 1 : 1}`.trim(),
    membersCount: 1,
  };
};

export const getDefaultDummyMember = (projectId: string): DummyMember => ({
  id: uuidV4(),
  isHidden: false,
  billRate: 0,
  payRange: [0, 0],
  projectId,
  allocation: 100,
});
