import React from 'react';
import { Typography } from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell';
import { KPI_TYPES } from '../../constants';
import { CustomTableCell } from './CustomTableCell';
import { formatByDefinitionType } from '../tableCellRenders';

interface Props extends TableCellProps {
  type: KPI_TYPES;
  worstCase?: number;
  bestCase?: number;
}

export const TableCellsByKpiType = ({
  type,
  worstCase,
  bestCase,
  ...otherProps
}: Props) => {
  if (
    type === KPI_TYPES.FIXED_ABSOLUTE ||
    type === KPI_TYPES.FIXED_PERCENTAGE
  ) {
    return (
      <CustomTableCell
        colSpan={2}
        {...otherProps}
        width={otherProps.width && Number(otherProps.width) * 2}
        pure
      >
        <Typography variant="body1" color="text.disabled">
          Fixed
        </Typography>
      </CustomTableCell>
    );
  }

  return (
    <>
      <CustomTableCell colSpan={1} {...otherProps}>
        {formatByDefinitionType(type, worstCase)}
      </CustomTableCell>
      <CustomTableCell colSpan={1} {...otherProps}>
        {formatByDefinitionType(type, bestCase)}
      </CustomTableCell>
    </>
  );
};
