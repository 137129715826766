import { MarginReportAccount } from '../types/marginReports';

export const MARGIN_REPORT_KEYS = {
  AccountName: 'Account Name',
  ProjectName: 'Project Name',
  MemberName: 'Member Name',
  Stream: 'Stream',
  Seniority: 'Seniority',
  BillRate: 'Bill Rate',
  PayRate: 'Pay Rate',
  CompensationRangeMin: 'Compensation Range Min',
  CompensationRangeMax: 'Compensation Range Max',
  CompensationRangeApproved: 'Compensation Range Approved',
  Ratecard: 'Ratecard',
  Location: 'Location',
  AvgMonthlyHours: 'Average working hours per location',
};

export const marginReportFieldsOrder = [
  MARGIN_REPORT_KEYS.AccountName,
  MARGIN_REPORT_KEYS.ProjectName,
  MARGIN_REPORT_KEYS.MemberName,
  MARGIN_REPORT_KEYS.Stream,
  MARGIN_REPORT_KEYS.Seniority,
  MARGIN_REPORT_KEYS.BillRate,
  MARGIN_REPORT_KEYS.PayRate,
  MARGIN_REPORT_KEYS.CompensationRangeMin,
  MARGIN_REPORT_KEYS.CompensationRangeMax,
  MARGIN_REPORT_KEYS.CompensationRangeApproved,
  MARGIN_REPORT_KEYS.Ratecard,
  MARGIN_REPORT_KEYS.Location,
  MARGIN_REPORT_KEYS.AvgMonthlyHours,
];

export const ALL_ACCOUNTS_ITEM: MarginReportAccount = {
  id: '',
  name: 'All accounts',
};
