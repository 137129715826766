import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { adminStoreKey } from '.';
import { API_STATUS } from '../../constants';
import { ReportType } from '../../types/reports';
import { AdminState } from './admin.types';
import { fetchAdminReports } from './admin.thunks';

const initialState: AdminState = {
  apiStatus: API_STATUS.IDLE,
  adminReports: [],
};

export const adminSlice = createSlice({
  name: adminStoreKey,
  initialState,
  reducers: {
    clearReports: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminReports.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(
        fetchAdminReports.fulfilled,
        (state, action: PayloadAction<ReportType[]>) => {
          state.apiStatus = API_STATUS.IDLE;
          state.adminReports = action.payload;
        },
      )
      .addCase(fetchAdminReports.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
        state.adminReports = [];
      });
  },
});

export const { clearReports } = adminSlice.actions;
