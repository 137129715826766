import { KPI_SCOPES, KPI_FREQUENCY } from '../../../../constants';
import { KpiDefinitionCreateFormModel } from './kpiDefinitionModal.types';
import { KPI_F_TYPE, KPI_SUB_TYPES } from '../../kpiDefinitions.const';
import { KpiDefinitionItem } from '../../../../types';

const defaultKpiDefinitionValues = <KpiDefinitionCreateFormModel>{
  name: '',
  defaultWorstCase: 0,
  defaultBestCase: 0,
  defaultBonusShare: 0,
  kpiOwner: null,
  description: '',
  category: null,
  scope: KPI_SCOPES.INDIVIDUAL,
  frequency: KPI_FREQUENCY.MONTHLY,
  departments: [],
  type: KPI_F_TYPE.VARIABLE,
  subType: KPI_SUB_TYPES.PERCENTAGE,
  trueupPeriod: null,
  trueupOffset: null,
  trueupFormula: null,
};

export const getKpiDefinitionValues = (
  kpiDefinition: KpiDefinitionItem | null,
  categories: any[],
): KpiDefinitionCreateFormModel =>
  kpiDefinition === null
    ? defaultKpiDefinitionValues
    : {
        name: kpiDefinition.name,
        defaultWorstCase: kpiDefinition.defaultWorstCase,
        defaultBestCase: kpiDefinition.defaultBestCase,
        defaultBonusShare: kpiDefinition.defaultBonusShare,
        kpiOwner: kpiDefinition.kpiOwner || null,
        description: kpiDefinition.description,
        category:
          categories.find((x) => x.id === kpiDefinition.categoryId) || null,
        scope: kpiDefinition.scope as KPI_SCOPES,
        frequency: kpiDefinition.frequency,
        departments: kpiDefinition.departments,
        type: kpiDefinition.type.split('_')[0] as KPI_F_TYPE,
        subType: kpiDefinition.type.split('_')[1] as KPI_SUB_TYPES,
        trueupPeriod: kpiDefinition.trueupPeriod,
        trueupOffset: kpiDefinition.trueupOffset,
        trueupFormula: kpiDefinition.trueupFormula,
      };
