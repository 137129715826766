export * from './app-config';
export * from './columnTextAlign';
export * from './globalTracker';
export * from './kpi';
export * from './kpiStatus';
export * from './messages';
export * from './node-env';
export * from './reports';
export * from './separators';
export * from './server-status-code';
export * from './user';

export enum API_STATUS {
  IDLE = 'idle',
  LOADING = 'loading',
  FAILED = 'failed',
}

export const DEV_HOST = 'https://rocky-finance-dev.agileengine.com';

export const emptyValue = '-';

export const ROCKY_LOCALHOST = 'http://localhost:3001';

export const LOCALHOST = 'localhost';
export const localhostPorts = {
  BACKEND: 8000,
  FRONTEND: 3000,
};

export const DEV_HOST_IDENTIFIER = 'rocky-finance-dev';
export const STAGE_HOST_IDENTIFIER = 'rocky-finance-stage';
export const PREPROD_HOST_IDENTIFIER = 'rocky-finance-preprod';
