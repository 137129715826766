import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { ReportType } from '../../types/reports';
import { ReportsState } from './reports.types';
import { fetchReports } from './reports.thunks';
import { reportsStoreKey } from './reports.const';

const initialState: ReportsState = {
  apiStatus: API_STATUS.IDLE,
  reports: [],
};

export const reportsSlice = createSlice({
  name: reportsStoreKey,
  initialState,
  reducers: {
    clearReports: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReports.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(
        fetchReports.fulfilled,
        (state, action: PayloadAction<ReportType[]>) => {
          state.apiStatus = API_STATUS.IDLE;
          state.reports = action.payload;
        },
      )
      .addCase(fetchReports.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
        state.reports = initialState.reports;
      });
  },
});

export const { clearReports } = reportsSlice.actions;
