import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import { NumericFormat } from 'react-number-format';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import {
  Backdrop,
  FormHelperText,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import EventBusyRoundedIcon from '@mui/icons-material/EventBusyRounded';
import {
  formatDateToYYYYMM,
  createDateFromYYYYMMFormat,
  formatYYYYMMDateToMMMMyyyyString,
} from '../../utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { KpiOwnerType } from '../../types/kpiOwners';
import { AutocompleteDefaultOptionType, PeriodType } from '../../types';
import { KpiMetricOptionType, KpiMetricType } from '../../types/kpiMetric';
import {
  KPI_TYPES,
  KPI_SCOPES,
  API_STATUS,
  KPI_FREQUENCY,
} from '../../constants';
import { fetchKpiMetrics, kpiMetricsSelectors } from '../../redux/kpiMetrics';
import { getUpdatedBonuslist } from '../../redux/contractor';
import { kpiOwnersSelectors, fetchKpiOwners } from '../../redux/kpiOwners';
import { validationSchema } from './validationSchema';
import { useSnackbar } from 'notistack';
import { debounce } from '../../utils/debounce';
import { periodsSelectors } from '../../redux/periods';
import {
  getCurrentQuarter,
  getLastQuarter,
  isPeriodAfterQuarterly,
  parseBonusPlan,
} from '../contractor/Contractor.utils';
import { contractorSelectors } from '../../redux/contractor';
import { BonusShareField } from './components/BonusShareField';
import { BonusPlanSnippetTable } from '../contractor/components/bonusPlanWidget/BonusPlanSnippetTable';

interface Props {
  handleAddKpiAssignment: (values: any) => void;
  handleCloseModal: () => void;
  handleDeleteKpiAssignment: (kpiId: string) => void;
  handleUpdateKpiAssignment: (values: any) => void;
  isModalOpen: boolean;
  showCustomBackdrop?: boolean;
  stickDialogToTop?: boolean;
  userId: string;
}

const withCustomProps = (isCustom: boolean) => {
  // eslint-disable-next-line
  const CustomBackdrop = useMemo(() => {
    // eslint-disable-next-line
    const MemoizedBackdrop = React.memo(
      (props: React.ComponentProps<typeof Backdrop>) => (
        <Backdrop
          {...props}
          sx={{
            // eslint-disable-next-line
            ...props.sx,
            ...(isCustom && { background: '#ffffffa0' }),
          }}
        />
      ),
    );
    return MemoizedBackdrop;
  }, [isCustom]);

  return CustomBackdrop;
};

const KpiAssignModal: React.FC<Props> = ({
  handleAddKpiAssignment,
  handleCloseModal,
  handleDeleteKpiAssignment,
  handleUpdateKpiAssignment,
  isModalOpen,
  userId,
  showCustomBackdrop = false,
  stickDialogToTop = false,
}) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showEffectiveFromField, toggleEffectiveFromField] = useState(false);
  const [changedBonusShareValue, setChangedBonusShareValue] = useState(false);
  const [removeFocusBonusShare, setRemoveFocusBonusShare] = useState(false);
  const [monthlyBonusShare, setMonthlyBonusShare] = useState('');
  const kpi = useAppSelector(contractorSelectors.getAssignmentToUpdate);
  const bonusList = useAppSelector(contractorSelectors.getUpdatedBonusList);

  useEffect(() => {
    dispatch(fetchKpiMetrics({ userId }));
    if (kpi) {
      dispatch(fetchKpiOwners({ kpiId: kpi.id, userId }));
    }
  }, [userId, dispatch, kpi]);

  const kpiMetricsList = useAppSelector(kpiMetricsSelectors.getKpiMetricsList);
  const kpiMetricsListApiStatus = useAppSelector(
    kpiMetricsSelectors.getKpiMetricsApiStatus,
  );

  const kpiOwnersList = useAppSelector(kpiOwnersSelectors.getKpiOwnersList);
  const kpiOwnersApiStatus = useAppSelector(
    kpiOwnersSelectors.getKpiOwnersApiStatus,
  );

  const currentPeriod: PeriodType | null = useAppSelector(
    periodsSelectors.getCurrentPeriod,
  );

  const bonusPlanSegments = useMemo(() => {
    if (kpi?.bonusPlanEntries.length) {
      return parseBonusPlan(
        currentPeriod,
        kpi?.deletedAt ?? null,
        kpi.bonusPlanEntries,
      ).bonusPlanSegments?.reverse();
    }
  }, [kpi, currentPeriod]);

  const lastQuarter = Array.from(
    { length: 3 },
    (_, i) => i + Number(getLastQuarter(currentPeriod?.period)[0]),
  );

  const currentQuarter = Array.from(
    { length: 3 },
    (_, i) => i + Number(getCurrentQuarter(currentPeriod?.period)[0]),
  );

  const MAX_DATE = 999999;
  const MAX_BONUS_SHARE = 99999;

  const isKpiOwnersLoading = kpiOwnersApiStatus === API_STATUS.LOADING;
  const isKpiMetricsLoading = kpiMetricsListApiStatus === API_STATUS.LOADING;

  const formik = useFormik({
    initialValues: {
      ...({
        ...kpi,
        ...{
          effectiveFrom: currentPeriod?.period,
        },
      } || {}),
    },
    validationSchema,
    validateOnMount: false,
    onSubmit: async (values) => {
      if (kpi?.id) {
        let from: number | null = null;
        let to: number | null = null;
        if (bonusPlanSegments) {
          ({ from, to } = bonusPlanSegments[0]);
        }
        const updateBody = {
          ...values,
          ...{
            startPeriod:
              from === values.startPeriod ? undefined : values.startPeriod,
          },
          ...{
            endPeriod: to === values.endPeriod ? undefined : values.endPeriod,
          },
        };
        await handleUpdateKpiAssignment(updateBody);
      } else {
        await handleAddKpiAssignment(values);
      }
    },
  });

  useEffect(() => {
    if (kpi) {
      formik.setValues({
        ...formik.values,
        ...kpi,
      });
      if (bonusPlanSegments?.length) {
        const { from: startPeriod, to: endPeriod } = bonusPlanSegments[0];
        formik.setFieldValue('startPeriod', startPeriod);
        formik.setFieldValue('endPeriod', endPeriod);
      }
    }
    // eslint-disable-next-line
  }, [kpi]);

  useEffect(() => {
    if (
      formik.values.bestCase === formik.values.worstCase &&
      !!formik.values.bestCase &&
      formik.dirty
    ) {
      debounce(() => {
        enqueueSnackbar('Best case and worst case should not be same', {
          key: 'best_worst_same',
          variant: 'error',
        });
      }, 300);
    } else if (formik.dirty) {
      debounce.decline();
      closeSnackbar('best_worst_same');
    }
  }, [
    formik.values.bestCase,
    formik.values.worstCase,
    closeSnackbar,
    enqueueSnackbar,
    formik.dirty,
  ]);

  useEffect(() => {
    if (changedBonusShareValue && formik.isValid && removeFocusBonusShare) {
      dispatch(
        getUpdatedBonuslist({
          userId: userId,
          kpiDefinitionId: formik.values.kpiDefinitionId,
          bonusShare: formik.values.bonusShare,
          currentPeriod: currentPeriod?.period,
          effectiveDate: formik.values.effectiveFrom,
          endPeriod: formik.values.endPeriod,
        }),
      );
    }
  }, [
    formik.values.kpiDefinitionId,
    userId,
    formik.values.bonusShare,
    closeSnackbar,
    enqueueSnackbar,
    formik.dirty,
    dispatch,
    currentPeriod?.period,
    formik.values.effectiveFrom,
    formik.values.endPeriod,
    changedBonusShareValue,
    formik.isValid,
    removeFocusBonusShare,
  ]);

  const isKpiOwnerDisabled =
    formik.values.kpiDefinition?.scope === KPI_SCOPES.SHARED;

  const kpiOwnerTooltipTitle = isKpiOwnerDisabled
    ? 'Shared metric owner can not be changed per each assignment'
    : '';

  const inputVisibility = formik.values.kpiDefinitionId ? 'initial' : 'hidden';

  const kpiMetricsOptions = useMemo<KpiMetricOptionType[]>(() => {
    const extendedMetricsList = kpi?.kpiDefinition
      ? [...kpiMetricsList, kpi.kpiDefinition]
      : kpiMetricsList;

    const sortedMetricsList = _sortBy(extendedMetricsList, ['scope', 'name']);
    return sortedMetricsList.map((kpiMetric) => ({
      value: kpiMetric.id,
      label: kpiMetric.name,
      scope: kpiMetric.scope,
      type: kpiMetric.type,
      frequency: kpiMetric.frequency,
      deactivated: !kpiMetric.isActive,
    }));
  }, [kpiMetricsList, kpi]);

  const kpiOwnersOptions = useMemo<AutocompleteDefaultOptionType[]>(() => {
    const sortedKpiOwnersList = _sortBy(kpiOwnersList, 'fullName');
    return sortedKpiOwnersList.map((kpiOwner) => ({
      value: kpiOwner.id,
      label: kpiOwner.fullName,
    }));
  }, [kpiOwnersList]);

  const kpiDefinitionIdValue = useMemo<KpiMetricOptionType | null>(() => {
    return (
      kpiMetricsOptions.find(
        (option) => option.value === formik.values.kpiDefinitionId,
      ) || null
    );
  }, [formik.values.kpiDefinitionId, kpiMetricsOptions]);

  const isQuarterlyMetric =
    kpiDefinitionIdValue &&
    kpiDefinitionIdValue.frequency === KPI_FREQUENCY.QUARTERLY;

  const kpiOwnerIdValue = useMemo<AutocompleteDefaultOptionType | null>(() => {
    return (
      kpiOwnersOptions.find(
        (option) => option.value === formik.values.kpiOwnerId,
      ) || null
    );
  }, [formik.values.kpiOwnerId, kpiOwnersOptions]);

  const [monthWiseShare, setMonthWiseShare] = useState(false);

  const toggleQuarterEdit = () => {
    if (isQuarterlyMetric) {
      const shownBonusShares = !monthWiseShare
        ? (isPeriodAfterQuarterly(currentPeriod?.period)
            ? lastQuarter
            : currentQuarter
          ).map((q) => ({
            period: q,
            bonusShare:
              kpi?.bonusPlanEntries.find((bp) => bp.period === q)?.bonusShare ??
              0,
          }))
        : kpi?.bonusShare;

      formik.setValues({
        ...formik.values,
        ...{ monthWiseShare: !monthWiseShare, bonusShare: shownBonusShares },
      });
      setMonthWiseShare(!monthWiseShare);
    } else {
      formik.setValues({
        ...formik.values,
        ...{ bonusShare: kpi?.bonusShare },
      });
    }
  };

  const isBonusInaccessible = (period: number) => {
    const afterCurrentPeriod = period > (currentPeriod?.period ?? 0);
    const beforeStartPeriod = period < (formik.values.startPeriod ?? MAX_DATE);
    return afterCurrentPeriod && beforeStartPeriod;
  };

  const handleKpiMetricChange = async (
    _e: React.SyntheticEvent,
    option: KpiMetricOptionType | null,
  ) => {
    if (option === null) {
      return;
    }
    const kpiDefinition = kpiMetricsList.find(
      (kpiMetric: KpiMetricType) => kpiMetric.id === option.value,
    );
    if (kpiDefinition) {
      await formik.setValues({
        ...formik.values,
        kpiDefinitionId: option.value,
        kpiDefinition: kpiDefinition,
        startPeriod: kpi?.id ? kpi?.startPeriod : currentPeriod?.period,
        endPeriod: kpi?.id ? kpi?.endPeriod : null,
        bestCase: kpiDefinition.defaultBestCase,
        worstCase: kpiDefinition.defaultWorstCase,
        bonusShare: kpiDefinition.defaultBonusShare,
        kpiOwnerId: kpiDefinition.kpiOwnerId || undefined,
      });
      const { payload } = await dispatch(
        fetchKpiOwners({ kpiId: option.value, userId }),
      );
      const isKpiOwnerAvailable = _find(
        payload as KpiOwnerType[] | undefined,
        (option) => option.id === kpiDefinition.kpiOwnerId,
      );
      if (isKpiOwnerAvailable) {
        await formik.setFieldValue('kpiOwnerId', kpiDefinition.kpiOwnerId);
      }
      if (!isKpiOwnerAvailable || kpiDefinition.kpiOwnerId === null) {
        await formik.setFieldValue('kpiOwnerId', null);
      }
      formik.validateForm();
    }
  };

  const handleKpiOwnerChange = (
    e: React.SyntheticEvent,
    option: AutocompleteDefaultOptionType | null,
  ) => {
    if (option === null) return;
    formik.setFieldValue('kpiOwnerId', option.value);
  };

  const handleChange = useCallback(
    (name: string, value: string | undefined) => {
      if (isNaN(Number(value) as number)) {
        return;
      }

      formik.setFieldValue(name, Number(value));
    },
    [formik],
  );

  const handleDateChange = async (name: string, date: Date | null) => {
    await formik.setFieldValue(name, formatDateToYYYYMM(date));
    if (name === 'startPeriod') {
      await formik.setFieldValue('effectiveFrom', formatDateToYYYYMM(date));
    }
  };

  const handleBonusShareChange = ({
    value,
    period,
  }: {
    value: number;
    period?: number;
  }) => {
    if (typeof formik.values.bonusShare !== 'number' && period) {
      formik.setFieldValue(
        'bonusShare',
        formik.values.bonusShare?.map((bs) =>
          bs.period === period ? { ...bs, ...{ bonusShare: value } } : bs,
        ),
      );
    } else {
      formik.setFieldValue('bonusShare', value);
      formik.setFieldValue('effectiveFrom', currentPeriod?.period);
    }
  };

  const renderWorstBestCases = () => {
    if (
      kpiDefinitionIdValue &&
      (kpiDefinitionIdValue.type === KPI_TYPES.FIXED_ABSOLUTE ||
        kpiDefinitionIdValue.type === KPI_TYPES.FIXED_PERCENTAGE)
    ) {
      return <Grid item xs={12} sx={{ visibility: inputVisibility }} />;
    }

    return (
      <>
        <Grid item xs={6} sx={{ visibility: inputVisibility }}>
          <NumericFormat
            allowNegative={false}
            customInput={TextField}
            disabled={
              formik.values?.kpiDefinition?.type === KPI_TYPES.FIXED_ABSOLUTE
            }
            error={Boolean(formik.errors.worstCase)}
            fullWidth
            label="Worst case"
            id="worstCase"
            InputProps={{
              endAdornment:
                formik.values?.kpiDefinition?.type ===
                KPI_TYPES.VARIABLE_PERCENTAGE ? (
                  <InputAdornment position="start">%</InputAdornment>
                ) : null,
            }}
            name="worstCase"
            inputMode="decimal"
            InputLabelProps={{
              shrink: true,
            }}
            onValueChange={({ value }) => handleChange('worstCase', value)}
            value={formik.values.worstCase}
            variant="filled"
            helperText={formik.errors.worstCase}
          />
        </Grid>
        <Grid item xs={6} sx={{ visibility: inputVisibility }}>
          <NumericFormat
            allowNegative={false}
            customInput={TextField}
            disabled={
              formik.values?.kpiDefinition?.type === KPI_TYPES.FIXED_ABSOLUTE
            }
            error={Boolean(formik.errors.bestCase)}
            fullWidth
            id="bestCase"
            InputProps={{
              endAdornment:
                formik.values?.kpiDefinition?.type ===
                KPI_TYPES.VARIABLE_PERCENTAGE ? (
                  <InputAdornment position="start">%</InputAdornment>
                ) : null,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            label="Best case"
            name="bestCase"
            inputMode="decimal"
            onValueChange={({ value }) => handleChange('bestCase', value)}
            value={formik.values.bestCase}
            variant="filled"
            helperText={formik.errors.bestCase}
          />
        </Grid>
      </>
    );
  };

  const getBonusErrorInQuarterEdit = (index: number) => {
    const errors = formik.errors.bonusShare as unknown as {
      bonusShare: string;
      period: string;
    }[];
    return errors ? errors[index]?.bonusShare : undefined;
  };

  const renderBonusShareFields = () => {
    if (!monthWiseShare) {
      return (
        <Grid item xs={12} sx={{ visibility: inputVisibility }}>
          <BonusShareField
            id={`bonus-${formik.values.kpiDefinition?.name}-${
              currentPeriod?.period ?? ''
            }`}
            name="bonusShare"
            value={
              typeof formik.values?.bonusShare === 'number'
                ? formik.values?.bonusShare
                : formik.values?.bonusShare?.[2].bonusShare
            }
            error={Boolean(formik.errors.bonusShare)}
            helperText={formik.errors.bonusShare as string}
            handleChange={({ value }: { value: any }) => {
              if (value !== kpi?.bonusShare?.toString())
                toggleEffectiveFromField(true);
              else toggleEffectiveFromField(false);

              handleBonusShareChange({ value: Number(value) });
              setMonthlyBonusShare(value);
              setChangedBonusShareValue(true);
              setRemoveFocusBonusShare(false);
            }}
            isQuarterlyMetric={isQuarterlyMetric}
            label="Monthly bonus share"
            onBlurHandler={() => {
              if (Number(monthlyBonusShare) <= MAX_BONUS_SHARE) {
                setRemoveFocusBonusShare(true);
              } else {
                setRemoveFocusBonusShare(false);
              }
            }}
          />
        </Grid>
      );
    } else {
      return (
        <Stack direction="row" justifyContent="space-between">
          {(isPeriodAfterQuarterly(currentPeriod?.period)
            ? lastQuarter
            : currentQuarter
          ).map((key, i) => {
            const inaccessibleBonus = isBonusInaccessible(
              typeof formik.values?.bonusShare === 'number'
                ? currentPeriod?.period ?? 0
                : key,
            );

            const bonusShare =
              typeof formik.values?.bonusShare === 'number'
                ? formik.values?.bonusShare
                : formik.values?.bonusShare?.[i].bonusShare;

            return (
              <Grid
                item
                xs={3.75}
                key={key}
                sx={{ visibility: inputVisibility }}
              >
                <BonusShareField
                  id={`bonus-${formik.values.kpiDefinition?.name}-${key}`}
                  name="bonusShareMonthWise"
                  value={inaccessibleBonus ? 0 : bonusShare}
                  error={Boolean(formik.errors.bonusShare)}
                  helperText={getBonusErrorInQuarterEdit(i)}
                  handleChange={({ value }: { value: any }) => {
                    handleBonusShareChange({
                      value: Number(value),
                      period: key,
                    });
                  }}
                  isQuarterlyMetric={isQuarterlyMetric}
                  label={`${formatYYYYMMDateToMMMMyyyyString(key, true)}`}
                  disabled={inaccessibleBonus}
                />
              </Grid>
            );
          })}
        </Stack>
      );
    }
  };

  return (
    <Dialog
      components={{ Backdrop: withCustomProps(showCustomBackdrop) }}
      open={isModalOpen}
      scroll="body"
      PaperProps={
        stickDialogToTop ? { sx: { mt: 6, verticalAlign: 'top' } } : {}
      }
      onClose={handleCloseModal}
    >
      <DialogTitle>{kpi?.id ? 'Edit metric' : 'Assign metric'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              id="kpiDefinitionId"
              onChange={handleKpiMetricChange}
              clearIcon={null}
              componentsProps={{ clearIndicator: { disabled: true } }}
              options={kpiMetricsOptions}
              groupBy={(option) => option.scope}
              value={kpiDefinitionIdValue}
              disabled={!kpiMetricsOptions.length && !isKpiMetricsLoading}
              getOptionDisabled={(option) => option.deactivated}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(formik.errors.kpiDefinitionId)}
                  label="Select target metric..."
                  variant="filled"
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Typography>{option.label}</Typography>
                  {option.deactivated && (
                    <Typography ml="auto">Deactivated</Typography>
                  )}
                </li>
              )}
            />
            {!kpiMetricsOptions.length && !isKpiMetricsLoading && (
              <FormHelperText
                component={'div'}
                error={true}
                sx={{ paddingX: 1.5, paddingY: 1 }}
              >
                <Typography variant="body2" color="error">
                  There are no available metrics for this department or
                  contractor. Please contact Finance team to add new metrics.
                </Typography>
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sx={{ visibility: inputVisibility }}>
            <Tooltip title={kpiOwnerTooltipTitle}>
              <Autocomplete
                id="kpiOwnerId"
                disabled={isKpiOwnerDisabled}
                clearIcon={null}
                componentsProps={{ clearIndicator: { disabled: true } }}
                onChange={handleKpiOwnerChange}
                options={kpiOwnersOptions}
                loading={isKpiOwnersLoading}
                value={kpiOwnerIdValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(formik.errors.kpiOwnerId)}
                    label="KPI owner"
                    variant="filled"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isKpiOwnersLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} mt={2} sx={{ visibility: inputVisibility }}>
            <FormLabel>Adjust values</FormLabel>
          </Grid>
          <Grid item xs={12} sx={{ visibility: inputVisibility }}>
            {renderBonusShareFields()}

            {kpi?.id && isQuarterlyMetric && (
              <Grid item xs={12} sx={{ visibility: inputVisibility }}>
                <Stack direction="row">
                  <Switch
                    size="small"
                    sx={{ mx: 0, mt: 1 }}
                    checked={formik.values.monthWiseShare}
                    onChange={() => toggleQuarterEdit()}
                  />
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ ml: 0.5, mt: 1.25 }}
                  >
                    {`Edit ${
                      isPeriodAfterQuarterly(currentPeriod?.period)
                        ? 'last'
                        : 'current'
                    } quarter bonus shares`}
                  </Typography>
                </Stack>
              </Grid>
            )}
          </Grid>

          {renderWorstBestCases()}

          <Grid item xs={6} sx={{ visibility: inputVisibility }}>
            <DatePicker
              views={['year', 'month']}
              onChange={(date: Date | null) =>
                handleDateChange('startPeriod', date)
              }
              value={createDateFromYYYYMMFormat(formik.values.startPeriod)}
              format="MM/yyyy"
              label="Start date"
              slotProps={{
                textField: {
                  error: Boolean(formik.errors.startPeriod),
                  id: 'startPeriod',
                  label: 'Start date',
                  name: 'startPeriod',
                  variant: 'filled',
                },
                popper: {
                  placement: stickDialogToTop ? 'top' : 'bottom',
                },
              }}
              disabled={!!monthWiseShare}
            />
          </Grid>
          <Grid item xs={6} sx={{ visibility: inputVisibility }}>
            <DatePicker
              views={['year', 'month']}
              onChange={(date: Date | null) =>
                handleDateChange('endPeriod', date)
              }
              value={createDateFromYYYYMMFormat(formik.values.endPeriod)}
              format="MM/yyyy"
              slots={{
                openPickerIcon: EventBusyRoundedIcon,
              }}
              label="End date"
              slotProps={{
                textField: {
                  error: Boolean(formik.errors.endPeriod),
                  helperText: 'Leave blank for non-expiring',
                  id: 'endPeriod',
                  label: 'End date',
                  name: 'endPeriod',
                  variant: 'filled',
                },
                popper: {
                  placement: stickDialogToTop ? 'top' : 'bottom',
                },
              }}
              disabled={!!monthWiseShare}
            />
          </Grid>

          {!!showEffectiveFromField && !monthWiseShare && kpi?.id && (
            <Grid item xs={6} sx={{ visibility: inputVisibility }}>
              <DatePicker
                views={['year', 'month']}
                onChange={(date: Date | null) => {
                  handleDateChange('effectiveFrom', date);
                }}
                value={createDateFromYYYYMMFormat(formik.values.effectiveFrom)}
                format="MM/yyyy"
                label="Effective from"
                slotProps={{
                  textField: {
                    error: Boolean(formik.errors.effectiveFrom),
                    helperText: 'Effective date for new bonus',
                    id: 'effectiveFrom',
                    label: 'Effective from',
                    name: 'effectiveFrom',
                    variant: 'filled',
                  },
                  popper: {
                    placement: stickDialogToTop ? 'top' : 'bottom',
                  },
                }}
                disabled={!!monthWiseShare}
              />
            </Grid>
          )}
        </Grid>

        {!!showEffectiveFromField && formik.isValid && removeFocusBonusShare ? (
          <Grid container spacing={2} marginTop={1} xs={12}>
            <Grid item xs={12}>
              {bonusList.length > 0 ? (
                <>
                  <FormLabel>Bonus Plan changes</FormLabel>
                  <p>{''}</p>
                  <BonusPlanSnippetTable bonusSnippetList={bonusList || []} />
                </>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between', mt: 3 }}>
        <Box>
          {kpi?.id && (
            <Button onClick={() => handleDeleteKpiAssignment(kpi.id)}>
              Deactivate
            </Button>
          )}
        </Box>
        <Box>
          <Button onClick={handleCloseModal} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button
            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
            onClick={formik.submitForm}
            variant="contained"
          >
            {kpi?.id ? 'Update' : 'Assign'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default KpiAssignModal;
