import React, { FC, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';

import { AssessmentDateContent } from '../../types';
import AssessmentForm from './AssessmentForm';
import AssessmentNotProvided from './AssessmentsNotProvided';
import AssessmentsDetails from './AssessmentsDetails';

interface Props {
  period: string;
  assessment: AssessmentDateContent;
  hasAssessmentContent: boolean;
  isLatestPeriod: boolean;
  projectId: string;
}

const AssessmentContent: FC<Props> = ({
  period,
  assessment,
  hasAssessmentContent,
  isLatestPeriod,
  projectId,
}) => {
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setEditMode(false);
  }, [period]);

  const toggleEditMode = useCallback(() => {
    setEditMode((prevValue) => !prevValue);
  }, []);

  const isEditFormShown =
    (!hasAssessmentContent && isLatestPeriod) ||
    (isLatestPeriod && hasAssessmentContent && editMode);
  const isAssessmentDetailsShown = hasAssessmentContent && !editMode;

  return (
    <Box>
      {isAssessmentDetailsShown && (
        <AssessmentsDetails
          period={period}
          assessment={assessment}
          isEditable={isLatestPeriod}
          toggleEditMode={toggleEditMode}
        />
      )}
      {isEditFormShown && (
        <AssessmentForm
          period={period}
          assessment={assessment}
          isEditMode={editMode}
          toggleEditMode={toggleEditMode}
          projectId={projectId}
        />
      )}
      {!hasAssessmentContent && !isLatestPeriod && (
        <AssessmentNotProvided period={period} />
      )}
    </Box>
  );
};

export default AssessmentContent;
