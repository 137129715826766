import React from 'react';
import { formatDateToYYYYMM } from '../../../../../utils';
import { ClickAwayListener, Paper, Popper } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';

interface Props {
  anchorEl: React.MutableRefObject<HTMLElement | null>;
  open: boolean;
  handlePopperClose: () => void;
  isQuarterly: boolean;
  addNewKpi: (period: number) => void;
}

const PeriodPicker: React.FC<Props> = ({
  anchorEl,
  open,
  handlePopperClose,
  isQuarterly,
  addNewKpi,
}) => {
  return (
    <ClickAwayListener onClickAway={() => handlePopperClose()}>
      <Popper
        anchorEl={anchorEl.current}
        open={open}
        placement={'bottom-end'}
        sx={{ zIndex: 9999 }}
      >
        <Paper elevation={3}>
          <DateCalendar
            views={['year', 'month']}
            disableFuture={true}
            shouldDisableMonth={(month: any) =>
              isQuarterly ? ![0, 3, 6, 9].includes(month.getMonth()) : false
            }
            onMonthChange={(value) => {
              const periodToAdd = formatDateToYYYYMM(value as Date);
              if (periodToAdd) addNewKpi(periodToAdd);
              handlePopperClose();
            }}
          />
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
};

export default PeriodPicker;
