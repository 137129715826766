import {
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormHelperText,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import React, { ChangeEvent } from 'react';
import {
  TrueUpPeriodValue,
  KpiDefinitionTrueupFormula,
} from '../../../../../../constants';
import { KpiDefinitionCreateFormModel } from '../../kpiDefinitionModal.types';
import {
  trueUpPeriods,
  trueUpFormulaLabels,
  trueUpOffests,
} from './TrueUpForm.const';

interface Props {
  disabled: boolean;
  trueUpPeriodValue: TrueUpPeriodValue | null;
  trueUpOffsetValue: number | null;
  trueUpFormulaValue: KpiDefinitionTrueupFormula | null;
  handleChange: (e: SelectChangeEvent<number>) => void;
  onSetNewValues: (values: Partial<KpiDefinitionCreateFormModel>) => void;
}

export function TrueUpForm({
  trueUpPeriodValue,
  trueUpOffsetValue,
  trueUpFormulaValue,
  handleChange,
  onSetNewValues,
  disabled,
}: Props): JSX.Element {
  const onChange = (value: number) => {
    if (trueUpPeriodValue === null) {
      return onSetNewValues({
        trueupPeriod: value,
        trueupOffset: 1,
        trueupFormula: KpiDefinitionTrueupFormula.CORRECTED,
      });
    }

    if (!Number(value)) {
      return onSetNewValues({
        trueupPeriod: null,
        trueupOffset: null,
        trueupFormula: null,
      });
    }

    onSetNewValues({
      trueupPeriod: value,
    });
  };

  const trueUpOffsetValueSubtext =
    trueUpOffsetValue === 1
      ? 'April results come in May'
      : 'April results come in April';

  return (
    <Grid
      item
      xs={12}
      sx={{
        mt: 2,
        mb: -1,
        '.MuiFormHelperText-root': {
          fontSize: 12,
          marginLeft: 0,
        },
      }}
    >
      <FormLabel>True-up</FormLabel>

      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={1}>
          <RadioGroup
            row
            name="trueupPeriod"
            value={trueUpPeriodValue || 0}
            onChange={(e: ChangeEvent<HTMLInputElement>, v: string) =>
              onChange(v as unknown as number)
            }
          >
            {trueUpPeriods.map((period) => (
              <FormControlLabel
                key={period.value}
                value={period.value || 0}
                control={<Radio disabled={disabled} />}
                label={period.label}
              />
            ))}
          </RadioGroup>
        </Stack>
      </Stack>

      {trueUpPeriodValue && (
        <Stack
          direction="row"
          justifyContent="space-between"
          marginTop={1}
          spacing={2}
        >
          <FormControl variant="filled" sx={{ minWidth: 208 }}>
            <InputLabel id="submission-offset-label">
              Submission offset
            </InputLabel>
            <Select
              labelId="submission-offset-label"
              id="submission-offset-select-filled"
              name="trueupOffset"
              value={trueUpOffsetValue as number}
              onChange={handleChange}
              disabled={disabled}
            >
              {trueUpOffests.map((offset) => (
                <MenuItem key={offset.value} value={offset.value}>
                  {offset.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{trueUpOffsetValueSubtext}</FormHelperText>
          </FormControl>

          <FormControl variant="filled" sx={{ minWidth: 208 }}>
            <InputLabel id="formula-label">Formula</InputLabel>
            <Select
              labelId="formula-label"
              id="formula-select-filled"
              name="trueupFormula"
              value={trueUpFormulaValue as unknown as number}
              onChange={handleChange}
              disabled={disabled}
            >
              {Object.keys(trueUpFormulaLabels).map((key: string) => (
                <MenuItem value={key} key={key}>
                  {
                    trueUpFormulaLabels[
                      key as unknown as KpiDefinitionTrueupFormula
                    ]
                  }
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      )}
    </Grid>
  );
}
