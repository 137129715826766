import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Table,
  Typography,
} from '@mui/material';
import CalculateIcon from '@mui/icons-material/Calculate';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

import { TeamListMemberView, TeamMemberViewHead } from '../teamMemberView';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  calculateAccountMargin,
  calculateProjectMargin,
  fetchAccount,
  getAccountDummyMembers,
  getEditableMode,
  getLoading,
  getResetAllMode,
  getSelectedAccount,
  getSelectedAccountMembers,
  getSelectedProject,
  setAccountDummyMembers,
  toggleHideDummyMembers,
  updateAccountDummyMembers,
  updateAccountMember,
  updateMemberDummyMarginRates,
  setSelectedProjectId,
  getDefaultDummyMember,
  setDummyProjects,
  getAccountDummyProjects,
  toggleEditableMode,
  setGroupBy,
  resetDummyProjects,
  toggleResetAll,
  initMembersWithDummyMarginData,
  GroupAccountMembersBy,
  getSelectedAccountProject,
  getDateFilterValue,
  hideLoading,
} from '../../redux/account';
import { userDataSelectors } from '../../redux/userData';
import { BottomPanel, PageWrapper } from '../../components';
import {
  prepareMarginAccountData,
  prepareMarginProjectData,
  toMarginContractor,
} from './Budgets.utils';
import {
  MemberRatesUpdate,
  UpdateMemberProps,
  UpdateDummyMemberProps,
  HandleHideDummyProps,
} from '../teamMemberView/types';
import { GroupedMembers, MarginContractor, MarginProject } from '../../types';
import { CsvDownloadButton } from './DownloadCsv/DownloadCsv';
import { useTitle } from '../../hooks/useTitle';
import { PageTitle } from '../../utils/pageTitles';

const StyledBudgetStack = styled(Stack)`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme }) => theme.spacing(1)};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0;
`;

const StyledBudgetTable = styled(Table)`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: 0 0 ${({ theme }) => theme.spacing(1)}
    ${({ theme }) => theme.spacing(1)};
`;

export const BudgetDetails = () => {
  const dispatch = useAppDispatch();
  const [openAddDummyMemberDialog, setOpenAddDummyMemberDialog] =
    useState(false);
  const { accountId, projectId } = useParams();
  const loading = useAppSelector(getLoading);
  const account = useAppSelector(getSelectedAccount);
  const project = useAppSelector(getSelectedProject);
  const editableMode = useAppSelector(getEditableMode);
  const resetAllMode = useAppSelector(getResetAllMode);
  const accountMembers = useAppSelector(getSelectedAccountMembers);
  const accountDummyProjects = useAppSelector(getAccountDummyProjects);
  const accountDummyMembers = useAppSelector(getAccountDummyMembers);
  const dateFilterValue = useAppSelector(getDateFilterValue);
  const user = useAppSelector(userDataSelectors.getUserData);
  const hasAccounts = Boolean(user?.permissions?.hasAccounts);
  const abortControllerRef = useRef<AbortController | null>(null);
  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(
      `${account?.name ?? PageTitle.portfolio} | ${PageTitle.ratesAndMargin}`,
    );
  }, [setPageTitle, account]);

  const allMembers = useMemo(() => {
    return accountMembers.flatMap((group) => {
      const memberWithProjectId = group.members.map((memDetails) => ({
        ...memDetails,
        projectId: group.projectId,
      }));
      return memberWithProjectId;
    });
  }, [accountMembers]);

  const handleRatesChange = useCallback(
    async (data: MemberRatesUpdate) => {
      try {
        dispatch(updateMemberDummyMarginRates(data));

        const mappedFilteredAccountDummyMembers = accountDummyMembers
          ?.filter((x) => x.country)
          .map((filteredDummyMember) => {
            const isHidden =
              filteredDummyMember.id === data.memberId
                ? data.isHidden
                : filteredDummyMember.isHidden;

            const hiddenContractor = toMarginContractor({
              id: filteredDummyMember.id,
              compensationRate: filteredDummyMember.compensationRate ?? 0,
              billRate: filteredDummyMember.billRate ?? 0,
              projectId: filteredDummyMember.projectId,
              allocation: filteredDummyMember.allocation ?? 100,
              legalLocation: filteredDummyMember.country,
            });

            return isHidden
              ? {
                  ...hiddenContractor,
                  ...{
                    compensationRate: 0,
                    resource: { billRate: 0 },
                    isHidden: true,
                  },
                }
              : hiddenContractor;
          });

        if (abortControllerRef.current instanceof AbortController) {
          abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();

        const dateFilterValueString = dateFilterValue;

        if (project) {
          const projectContractors = prepareMarginProjectData({
            allMembers,
            ...data,
          });

          const contractors: MarginContractor[] =
            mappedFilteredAccountDummyMembers?.length
              ? [...projectContractors, ...mappedFilteredAccountDummyMembers]
              : [...projectContractors];

          const resultAction = await dispatch(
            calculateProjectMargin({
              id: project.id,
              contractors,
              ...(dateFilterValueString && {
                periodFiltering: {
                  from: dateFilterValueString,
                  to: dateFilterValueString,
                },
              }),
              signal: abortControllerRef.current.signal,
            }),
          );
          unwrapResult(resultAction);
        } else {
          if (!account) {
            abortControllerRef.current = null;
            return;
          }

          const projects: MarginProject[] = prepareMarginAccountData({
            accountMembers,
            accountDummyProjects,
            ...data,
          });

          if (mappedFilteredAccountDummyMembers?.length) {
            mappedFilteredAccountDummyMembers.forEach((dummyMemberData) => {
              projects.forEach((projectData) => {
                if (projectData.id === dummyMemberData.projectId) {
                  projectData.contractors.push(dummyMemberData);
                }
              });
            });
          }
          const resultAction = await dispatch(
            calculateAccountMargin({
              id: account.id,
              projects,
              ...(dateFilterValueString && {
                periodFiltering: {
                  from: dateFilterValueString,
                  to: dateFilterValueString,
                },
              }),
              signal: abortControllerRef.current.signal,
            }),
          );
          unwrapResult(resultAction);
        }

        abortControllerRef.current = null;
      } catch (e) {
        console.error(e);
      }
    },
    [
      dispatch,
      project,
      account,
      accountMembers,
      accountDummyMembers,
      accountDummyProjects,
      allMembers,
      dateFilterValue,
    ],
  );

  const handleSetDummyMembers = useCallback(
    (teams: any) => {
      dispatch(setAccountDummyMembers(getDefaultDummyMember(teams)));
    },
    [dispatch],
  );

  const addDummyTeam = () => {
    dispatch(setDummyProjects());
  };

  const isProjectView = useMemo(
    () => Boolean(projectId && project),
    [projectId, project],
  );

  const handleUpdateDummyMembers = useCallback(
    (data: UpdateDummyMemberProps) => {
      dispatch(
        updateAccountDummyMembers({
          id: data.memberId,
          fieldName: data.fieldName,
          value: data.value,
          projectId: data.projectId,
        }),
      );
    },
    [dispatch],
  );

  const handleUpdateMembers = useCallback(
    (data: UpdateMemberProps) => {
      dispatch(
        updateAccountMember({
          id: data.memberId,
          fieldName: data.fieldName,
          value: data.value,
          projectId: data.projectId,
        }),
      );
    },
    [dispatch],
  );

  const handleHideDummyMember = (data: HandleHideDummyProps) => {
    const { id, isHidden, compensationRate, billRate, payRange, allocation } =
      data;

    dispatch(
      toggleHideDummyMembers({
        id,
        isHidden,
      }),
    );

    compensationRate &&
      projectId &&
      billRate &&
      handleRatesChange({
        memberId: id,
        compensationRate,
        billRate,
        allocation,
        isHidden: isHidden ?? false,
        payRange,
        projectId,
      });
  };

  const handleFetchAccountsNoLoading = useCallback(() => {
    if (projectId) dispatch(setSelectedProjectId({ projectId }));
    else dispatch(setSelectedProjectId({ projectId: null }));
    if (accountId) {
      dispatch(hideLoading());
      dispatch(fetchAccount({ accountId, fetchAllMembers: true }));
    }
  }, [dispatch, projectId, accountId]);

  return (
    <PageWrapper isLoading={loading}>
      {account && (
        <>
          <StyledBudgetStack>
            <StyledBudgetTable stickyHeader>
              <TeamMemberViewHead />
              <TeamListMemberView
                dummyMembers={accountDummyMembers}
                dummyProjects={accountDummyProjects}
                groupedMembers={accountMembers}
                marginHighlights={account.marginHighlights}
                hasAccounts={hasAccounts}
                isProjectView={isProjectView}
                isEditableMode={Boolean(editableMode)}
                resetAllMode={Boolean(resetAllMode)}
                handleRatesChange={handleRatesChange}
                handleHideDummyMember={handleHideDummyMember}
                handleUpdateDummyMembers={handleUpdateDummyMembers}
                handleUpdateMembers={handleUpdateMembers}
                accountName={account.name}
              />
            </StyledBudgetTable>
          </StyledBudgetStack>
          {openAddDummyMemberDialog && (
            <AddDummyMember
              handleSave={(teams: any) => {
                handleSetDummyMembers(teams);
              }}
              isOpen={openAddDummyMemberDialog}
              onClose={() => setOpenAddDummyMemberDialog(false)}
              groupedMembers={accountMembers}
            />
          )}
          {hasAccounts && (
            <BudgetsFooter
              isProjectView={isProjectView}
              onAddDummyMember={() => setOpenAddDummyMemberDialog(true)}
              onAddDummyTeam={addDummyTeam}
              fetchAccountsWithNoLoading={handleFetchAccountsNoLoading}
            />
          )}
        </>
      )}
    </PageWrapper>
  );
};

interface BudgetsFooterProps {
  onAddDummyMember?: () => void;
  onAddDummyTeam?: () => void;
  isProjectView?: boolean;
  fetchAccountsWithNoLoading: () => void;
}

const BudgetsFooter = ({
  onAddDummyMember,
  onAddDummyTeam,
  isProjectView,
  fetchAccountsWithNoLoading,
}: BudgetsFooterProps) => {
  const editableMode = useAppSelector(getEditableMode);
  const dispatch = useAppDispatch();
  const ResetAllEventName = 'ResetAll';
  const ExitEventName = 'Exit';

  const handlePlanBudget = (event: any) => {
    if (event !== ResetAllEventName) {
      dispatch(toggleEditableMode());
      dispatch(setGroupBy(GroupAccountMembersBy.Team));
    } else if (event === ExitEventName) {
      fetchAccountsWithNoLoading();
    } else {
      dispatch(setAccountDummyMembers());
      dispatch(resetDummyProjects());
    }
    dispatch(toggleResetAll());
    dispatch(initMembersWithDummyMarginData());
  };

  const planBudget = (
    <BottomPanel
      color={BottomPanel.color.Actionable}
      align={BottomPanel.align.Left}
    >
      <Button
        variant="contained"
        startIcon={<CalculateIcon />}
        onClick={handlePlanBudget}
      >
        Calculate margins
      </Button>
      <CsvDownloadButton />
    </BottomPanel>
  );

  const exitPlanBudget = (
    <BottomPanel
      color={BottomPanel.color.Info}
      align={BottomPanel.align.SpaceBetween}
    >
      <Box ml={6} display="flex" justifyContent="center">
        <Button
          onClick={onAddDummyMember}
          startIcon={<PersonAddIcon />}
          color="inherit"
        >
          Add a team member
        </Button>
        {!isProjectView && (
          <Button
            onClick={onAddDummyTeam}
            startIcon={<GroupAddIcon />}
            color="inherit"
          >
            Add a team
          </Button>
        )}
      </Box>
      <Box
        mr={6}
        display="flex"
        justifyContent="center"
        alignItems="baseline"
        gap={2}
      >
        <Typography variant="subtitle2" sx={{ opacity: 0.7 }}>
          This is a playground calculation, no changes will be saved
        </Typography>
        <CsvDownloadButton />
        <Button
          color="inherit"
          onClick={() => handlePlanBudget(ResetAllEventName)}
        >
          Reset
        </Button>
        <Button
          variant="contained"
          className="contrast"
          onClick={() => handlePlanBudget(ExitEventName)}
        >
          Exit
        </Button>
      </Box>
    </BottomPanel>
  );

  return !editableMode ? planBudget : exitPlanBudget;
};

interface AddDummyMemberProps {
  isOpen: boolean;
  handleSave: (arg: any) => void;
  onClose: () => void;
  groupedMembers: GroupedMembers[];
}

const AddDummyMember: React.FC<AddDummyMemberProps> = ({
  isOpen,
  handleSave,
  onClose,
}) => {
  const projects = useAppSelector(getSelectedAccountProject);
  const dummyProjects = useAppSelector(getAccountDummyProjects);
  const [radioValue, setradioValue] = useState('');

  return (
    <Dialog fullWidth={true} maxWidth={'xs'} open={isOpen}>
      <DialogTitle>Add a team member</DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Select Team</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={radioValue}
            onChange={(e: any) => {
              setradioValue(e.target.value);
            }}
          >
            {dummyProjects?.map(({ name, id }) => (
              <FormControlLabel
                value={id}
                control={<Radio />}
                label={name}
                key={id}
              />
            ))}

            {projects?.map(({ name, id }) => (
              <FormControlLabel
                value={id}
                control={<Radio />}
                label={name}
                key={id}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', mt: 3 }}>
        <Box display={'flex'} justifyContent={'flex-end'} width={'100%'}>
          <Button sx={{ mr: 1 }} onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!radioValue}
            variant="contained"
            onClick={() => {
              if (radioValue) handleSave(radioValue);
              onClose();
            }}
          >
            Add
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
