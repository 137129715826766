import React, { MutableRefObject } from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';

interface LoaderProps {
  nodeRef?: MutableRefObject<null>;
  fullPage?: boolean;
  withBg?: boolean;
  title?: string;
}

export const LOADER_TEST_ID = 'loader';

export const Loader = ({
  fullPage = false,
  withBg = false,
  title,
  nodeRef,
}: LoaderProps): JSX.Element => {
  const { palette } = useTheme();

  const titleRender = title && (
    <Typography typography="h3" pt={3}>
      {title}
    </Typography>
  );

  return (
    <Box
      ref={nodeRef}
      data-testid={LOADER_TEST_ID}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        maxHeight: '100vh',

        ...((fullPage || withBg) && {
          backgroundColor: palette.background.default,
          zIndex: 999,
        }),

        ...(fullPage && {
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 999,
        }),
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress color="secondary" />
        {titleRender}
        {fullPage && (
          <Typography typography="body1" color="text.disabled" mt={2} mb={-2}>
            Loading
          </Typography>
        )}
      </Box>
    </Box>
  );
};
