import { accountStoreKey } from './account.const';
import { AppStateWithAccountData as State } from './account.types';

const select = (state: State) => state[accountStoreKey];

export const getLoading = (state: State) => select(state).loading;
export const getGroupBy = (state: State) => select(state).groupBy;
export const getSortBy = (state: State) => select(state).sortBy;
export const getSortOrder = (state: State) => select(state).sortOrder;
export const getEditableMode = (state: State) => select(state).editableMode;
export const getResetAllMode = (state: State) => select(state).resetAllMode;
export const getSelectedAccount = (state: State) => select(state).account;
export const getSelectedProject = (state: State) => select(state).project;
export const getSelectedAccountProject = (state: State) =>
  select(state).account?.projects;
export const getSelectedAccountMembers = (state: State) =>
  select(state).accountMembers;
export const getAccountDummyMembers = (state: State) =>
  select(state).accountDummyMembers;

export const getVisibleAccountDummyMembers = (state: State) =>
  select(state).accountDummyMembers?.filter((dummy) => !dummy.isHidden);

export const selectHiddenAccountMembers = (state: State) =>
  select(state)
    .accountMembers.flatMap((members) => members.members)
    .filter((member) => member.isHidden);

export const getAccountDummyProjects = (state: State) =>
  select(state).accountDummyProjects;

export const getDateFilterValue = (state: State) =>
  select(state).dateFilterValue;

export const getDateFilterValueLocal = (state: State) =>
  select(state).dateFilterValueLocal;

export const getAccountListFetchErrorMessage = (state: State) =>
  select(state).accountListFetchErrorMessage;
