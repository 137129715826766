import React, { forwardRef } from 'react';
import { ListChildComponentProps, FixedSizeList } from 'react-window';
import { ListItem } from '@mui/material';
import { PlainObject } from '../../../types';

const PADDING_SIZE = 8;
const ITEM_SIZE = 56;

function RenderRow({ data, index, style }: ListChildComponentProps) {
  const { children, ...props } = data[index].props;

  return (
    <ListItem
      {...props}
      key={data[index].key || index}
      style={{
        ...(props.style || {}),
        ...style,
        top: `${parseFloat(String(style?.top || 0)) + PADDING_SIZE}px`,
      }}
    >
      {children}
    </ListItem>
  );
}

const OuterElementContext = React.createContext<PlainObject>({});

const InnerElementType = forwardRef<
  HTMLUListElement,
  ListChildComponentProps | PlainObject
>(({ style, ...props }, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return (
    <ul
      ref={ref}
      {...props}
      {...outerProps}
      style={{
        ...(outerProps.style || {}),
        height: (style?.height || 0) + PADDING_SIZE * 2,
      }}
    />
  );
});

export const SearchListBoxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: JSX.Element[] = [];
  (children as JSX.Element[]).forEach(
    (item: JSX.Element & { children?: JSX.Element[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    },
  );

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={itemData}
          height={500}
          width="100%"
          itemSize={ITEM_SIZE}
          itemCount={itemData.length}
          innerElementType={InnerElementType}
        >
          {RenderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

InnerElementType.displayName = 'InnerElementType';
