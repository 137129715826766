import {
  Box,
  IconButton,
  LinearProgress,
  Link,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { MemberInfoCell } from '../../../components/tableCellRenders';
import { FinKitAvatar } from '../../../components/finKitAvatar';
import { ProjectDetail } from '../../../types';
import {
  fetchProjectsAndMembersData,
  selectTimeAndPeoplePageData,
} from '../../../redux/timeAndPeople';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useAppDispatch } from '../../../redux/hooks';
import { TimeSum } from './timeSum';
import { Link as ReactRouterLink } from 'react-router-dom';
import { paths } from '../../../constants/paths';
import { DataState } from '../../../types/asyncData';

interface Props {
  project: ProjectDetail;
  timeAndPeoplePageData: ReturnType<typeof selectTimeAndPeoplePageData>;
  isProjectPage?: boolean;
}
export const TimeAndPeopleGroup: FC<Props> = ({
  project,
  timeAndPeoplePageData,
  isProjectPage = false,
}) => {
  const projectDataFull = timeAndPeoplePageData.projects[project.id]?.data;
  const isProjectDataLoading =
    timeAndPeoplePageData.projects[project.id]?.state === DataState.Pending;
  const isTimeTrackingLoading =
    timeAndPeoplePageData.timetrackings.state === DataState.Pending;
  const timeTrackingData = timeAndPeoplePageData.timetrackings.data;
  const [open, setOpen] = useState(isProjectPage);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (project.id && !timeAndPeoplePageData.projects[project.id] && open) {
      dispatch(fetchProjectsAndMembersData({ projectId: project.id }));
    }
  }, [project.id, dispatch, timeAndPeoplePageData.projects, open]);

  return (
    <>
      <Box
        component="tr"
        sx={{
          height: '37px',
          background: '#F6F9FD',
          width: '100%',
        }}
      >
        <TableCell
          colSpan={5}
          sx={{
            border: 'none',
            paddingBottom: '10px',
            paddingTop: '10px',
          }}
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack flexDirection="row" gap="6px" alignItems="center">
              {!isProjectPage && (
                <IconButton
                  aria-label="expand row"
                  sx={{ padding: '0px' }}
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              )}
              <Link
                component={ReactRouterLink}
                to={`../${project.id}/${paths.timeAndPeople}`}
                sx={{
                  textDecoration: 'none',
                  color: 'inherit',

                  ...(!isProjectPage
                    ? {
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }
                    : { cursor: 'default' }),
                }}
                onClick={(e) => isProjectPage && e.preventDefault()}
              >
                <Stack flexDirection="row" alignItems="center" gap="12px">
                  <FolderSharedIcon color="secondary" />
                  <Typography variant="subtitle2" fontWeight="500">
                    {project.name}
                  </Typography>
                </Stack>
              </Link>
            </Stack>
            <Typography
              sx={{
                color: '#00000099',
              }}
            >
              <TimeSum
                isTimeTrackingLoading={isTimeTrackingLoading}
                time={
                  timeTrackingData && timeTrackingData[project.id]
                    ? Object.values(timeTrackingData[project.id]).reduce(
                        (acc, curr) => acc + curr,
                        0,
                      )
                    : null
                }
              />
            </Typography>
          </Stack>
        </TableCell>
      </Box>

      {open ? (
        isProjectDataLoading ? (
          <TableRow>
            <TableCell colSpan={5}>
              <LinearProgress color="success" />
            </TableCell>
          </TableRow>
        ) : (
          projectDataFull?.members.map((member) => (
            <TableRow key={member.email}>
              <TableCell>
                <MemberInfoCell
                  userInfo={{
                    fullName: member.fullName,
                    stream: member.designation,
                    avatarUrl: member.avatarUrl,
                    country: member.legalLocation,
                  }}
                />
              </TableCell>
              <TableCell>{member.seniority}</TableCell>
              <TableCell>{member.legalLocation}</TableCell>
              <TableCell>
                {' '}
                {member.reportingTo ? (
                  <Stack direction="row" alignItems="center" gap="4px">
                    <FinKitAvatar
                      extraSmall
                      fullName={member.reportingTo.fullName}
                      imageUrl={member.reportingTo.avatarUrl}
                    />
                    <Typography variant="body1">
                      {member.reportingTo.fullName}
                    </Typography>
                  </Stack>
                ) : (
                  'No Manager'
                )}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'right',
                }}
              >
                <TimeSum
                  isTimeTrackingLoading={isTimeTrackingLoading}
                  time={timeTrackingData?.[project.id]?.[member.email] ?? null}
                />
              </TableCell>
            </TableRow>
          ))
        )
      ) : null}
    </>
  );
};
