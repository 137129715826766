import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants/api';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { locationsStoreKey } from './locations.const';

export const fetchLocations = createAsyncThunk(
  `${locationsStoreKey}/fetchLocations`,
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(apiEndpoints.locations());
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err, 'Fetch locations failed'), {
          variant: 'error',
        }),
      );
      throw new Error(String(err));
    }
  },
);
