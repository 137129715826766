import React, { FC, useState } from 'react';
import { Box } from '@mui/system';
import { Button, Checkbox, Stack } from '@mui/material';
import { COLORS } from '../../utils/colors';

interface Props {
  onAcceptClick: () => void;
}

export const TermsOfUseAccept: FC<Props> = ({ onAcceptClick }) => {
  const [isAcceptCheckboxChecked, setIsAcceptCheckboxChecked] = useState(false);

  return (
    <Box
      component={'section'}
      height={170}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      pb={2}
      sx={{
        backgroundColor: isAcceptCheckboxChecked
          ? COLORS.BLUE.LIGHTER
          : COLORS.BRAND.HIGHLIGHT,
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
      }}
    >
      <Stack alignItems="center" justifyContent="center">
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Checkbox
            id="accept-terms-of-use-checkbox"
            checked={isAcceptCheckboxChecked}
            onChange={() => setIsAcceptCheckboxChecked((value) => !value)}
          />
          <label htmlFor="accept-terms-of-use-checkbox">
            I have read and accept terms of use
          </label>
        </Box>
        <Button
          sx={{
            minWidth: 266,
            margin: '4px 0 0 16px',
          }}
          variant="contained"
          disabled={!isAcceptCheckboxChecked}
          onClick={onAcceptClick}
        >
          Continue
        </Button>
      </Stack>
    </Box>
  );
};
