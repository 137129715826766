import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BottomPanel,
  ButtonMenuGroup,
  PageWrapper,
  TooltipToggleButton,
} from '../../components';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { approvalsValidationSchema } from './Approvals.schema';
import {
  ApprovalsPageMode,
  ApprovalsGroupBy,
  sortByRootDepartmentAll as groupByRootDepartmentAll,
  approvalsSortOptions,
  approvalsSortValues,
  ADJUST_TOGGLE_TEST_ID,
  REVIEW_TOGGLE_TEST_ID,
} from './Approvals.const';
import { ApprovalsFormItemModel } from '../../redux/approvals/approvals.types';
import { DepartmentType } from '../../types';
import {
  fetchApprovals,
  approvalsSelectors,
  approveKpis,
  approvalsSlice,
  submitKpis,
} from '../../redux/approvals';
import { ListGroupedByRootDepartments } from './ListGroupedByRootDepartments/ListGroupedByRootDepartments';
import { ListGroupedByTeams } from './ListGroupedByTeams/ListGroupedByTeams';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { userDataSelectors } from '../../redux/userData';
import { ListGroupedByStatus } from './ListGroupedByStatus/ListGroupedByStatus';
import { DepartmentView } from '../../types/contractor';
import { plural, useLocationQuery } from '../../utils';
import {
  useCheckDepartments,
  extarctSubmittedKpiIds,
  filterRootDepartmentsById,
  getTotalMetricsInDepartments,
} from './Approvals.utils';
import { closeCurrentPeriod, periodsSelectors } from '../../redux/periods';
import { PageTitle } from '../../utils/pageTitles';
import { LoadingButton } from '@mui/lab';
import { useTitle } from '../../hooks/useTitle';
import {
  Box,
  Button,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import chainIcon from '../../assets/chain.svg';
import {
  approvalsHeaderHeight,
  approvalsMetricHeaderHeight,
} from '../../theme';
import { ApprovalHeaderOption } from './Approvals.types';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import Periods from '../periods/Periods';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { API_STATUS } from '../../constants';

export const Approvals = (): JSX.Element => {
  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(PageTitle.approval);
  }, [setPageTitle]);

  const dispatch = useAppDispatch();
  const [expandAll, setExpandAll] = useState<boolean>(false);

  const loading = useSelector(approvalsSelectors.getApprovalsLoading);
  const isClosingPeriod = useSelector(periodsSelectors.getPeriodsApiStatus);
  const values = useSelector(approvalsSelectors.getApprovalsValues);
  const pageMode = useSelector(approvalsSelectors.getApprovalsPageMode);
  const departments: DepartmentType[] = useSelector(
    approvalsSelectors.getApprovalsDepartments,
  );
  const groupByType = useSelector(approvalsSelectors.getApprovalsGroupBy);

  const { query, setSearchKey, removeSearchKey } = useLocationQuery<{
    departmentId: string;
  }>();

  const formik = useFormik<ApprovalsFormItemModel[]>({
    initialValues: [],
    validationSchema: approvalsValidationSchema,
    onSubmit: async () => {
      const newValues = Object.keys(values).map(
        (id): ApprovalsFormItemModel => ({
          id,
          value: parseFloat(String(values[id])),
        }),
      );

      if (!newValues || !newValues.length) {
        return;
      }

      dispatch(submitKpis(newValues));
    },
  });

  useEffect(() => {
    window.scroll({ top: 0 });

    return () => {
      dispatch(approvalsSlice.actions.setPageMode(ApprovalsPageMode.Review));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchApprovals(
        pageMode === ApprovalsPageMode.Edit
          ? DepartmentView.ADJUST
          : DepartmentView.APPROVE,
      ),
    );
  }, [pageMode, dispatch]);

  const handleCloseCurrentPeriod = useCallback(async () => {
    await dispatch(closeCurrentPeriod());
  }, [dispatch]);

  const handleExpandAllToggle = useCallback(() => {
    setExpandAll((state) => !state);
  }, [setExpandAll]);

  const discardChanges = useCallback(() => {
    dispatch(approvalsSlice.actions.discardChanges());
  }, [dispatch]);

  const onChangeGroupByType = useCallback(
    (groupByType: ApprovalsGroupBy) => {
      if (groupByType === ApprovalsGroupBy.Departments) {
        removeSearchKey('departmentId');
      }

      dispatch(approvalsSlice.actions.setGroupByType(groupByType));
    },
    [dispatch, removeSearchKey],
  );

  const onChangeGroupByDepartment = useCallback(
    (sortBy?: string) => {
      if (!sortBy) {
        removeSearchKey('departmentId');
        return;
      }

      setSearchKey('departmentId', sortBy);
    },
    [removeSearchKey, setSearchKey],
  );

  const filteredDepartments = useMemo(() => {
    if (!departments || !query || !query.departmentId) {
      return departments;
    }

    return filterRootDepartmentsById(departments, query.departmentId);
  }, [departments, query]);

  const hasNoMetric = useMemo(() => {
    const totalMetrics = getTotalMetricsInDepartments(filteredDepartments);
    return totalMetrics === 0;
  }, [filteredDepartments]);

  const filteredSubmittedKpis: string[] | undefined = useMemo(() => {
    return query &&
      query.departmentId &&
      filteredDepartments.length &&
      groupByType !== ApprovalsGroupBy.Departments
      ? extarctSubmittedKpiIds(filteredDepartments)
      : undefined;
  }, [filteredDepartments, groupByType, query]);

  const approve = useCallback(() => {
    dispatch(approveKpis(filteredSubmittedKpis));
  }, [dispatch, filteredSubmittedKpis]);

  return (
    <PageWrapper isLoading={loading} showContent={!loading} maxWidth={'1500px'}>
      <form onSubmit={formik.handleSubmit}>
        <ApprovalHeader
          onChangeGroupByType={onChangeGroupByType}
          onChangeGroupByDepartment={onChangeGroupByDepartment}
          expandAll={expandAll}
          handleExpandAllToggle={handleExpandAllToggle}
          departments={departments}
          mode={pageMode}
          groupByType={groupByType}
          groupByRootDepartmentId={query.departmentId}
        />

        <ApprovalContent
          hasNoMetric={hasNoMetric}
          pageMode={pageMode}
          expandAll={expandAll}
          groupByType={groupByType}
          departments={filteredDepartments}
        />

        <ApprovalFooter
          mode={pageMode}
          onApprove={approve}
          onDiscard={discardChanges}
          loading={loading}
          departments={filteredDepartments}
          handleCloseCurrentPeriod={handleCloseCurrentPeriod}
          isClosingPeriod={isClosingPeriod === API_STATUS.LOADING}
        />
      </form>
    </PageWrapper>
  );
};

interface ApprovalHeaderProps {
  departments?: DepartmentType[];
  mode: ApprovalsPageMode;
  groupByType: ApprovalsGroupBy;
  groupByRootDepartmentId?: string;
  expandAll: boolean;
  onChangeGroupByType: (groupByType: ApprovalsGroupBy) => void;
  onChangeGroupByDepartment: (sortBy?: string) => void;
  handleExpandAllToggle: () => void;
}

function ApprovalHeader({
  mode,
  groupByType,
  expandAll,
  onChangeGroupByType,
  handleExpandAllToggle,
  departments,
  groupByRootDepartmentId,
  onChangeGroupByDepartment,
}: ApprovalHeaderProps) {
  const dispatch = useAppDispatch();
  const [scrolled, setScrolled] = useState<boolean>(false);
  const { palette, spacing } = useTheme();
  const { canAdjustKpiValues } = useAppSelector(
    userDataSelectors.getUserPermissions,
  );

  const showExpand =
    ApprovalsGroupBy.Status === groupByType ||
    ApprovalsGroupBy.Departments === groupByType;

  const onChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, newMode: ApprovalsPageMode) => {
      if (newMode === null || !canAdjustKpiValues) {
        return;
      }

      dispatch(approvalsSlice.actions.setPageMode(newMode));
    },
    [dispatch, canAdjustKpiValues],
  );

  const handleScroll = useCallback(() => {
    setScrolled(window.scrollY > 24);
  }, [setScrolled]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const rootDepartments: ApprovalHeaderOption<string>[] = useMemo(() => {
    if (!departments) {
      return [groupByRootDepartmentAll];
    }

    return departments.reduce(
      (acum, { subDepartments }) => {
        const opts = (subDepartments || []).map(
          ({ name, id }): ApprovalHeaderOption<string> => ({
            label: name,
            value: id,
          }),
        );

        return [...acum, ...opts];
      },
      [groupByRootDepartmentAll] as ApprovalHeaderOption<string>[],
    );
  }, [departments]);

  const rootDepartmentValue = useMemo(() => {
    if (!rootDepartments.length || !groupByRootDepartmentId) {
      return groupByRootDepartmentAll;
    }

    return (
      rootDepartments.find((dep) => dep.value === groupByRootDepartmentId) ||
      groupByRootDepartmentAll
    );
  }, [groupByRootDepartmentId, rootDepartments]);

  const color = canAdjustKpiValues ? 'text.secondary' : 'text.disabled';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        background: palette.background.default,
        zIndex: scrolled ? 10 : 0,
        margin: `${spacing(-4)} ${spacing(-1)} ${spacing(0.5)}`,
        padding: `${spacing(2)} ${spacing(1)}`,
        ...(scrolled ? { boxShadow: `inset 0 -1px 0 ${palette.divider}` } : {}),
      }}
    >
      <Typography variant="h2" sx={{ mr: 2, ml: 2 }}>
        Approved metrics
      </Typography>

      <Box sx={{ marginLeft: 'auto' }}>
        {showExpand && (
          <Button
            onClick={handleExpandAllToggle}
            endIcon={expandAll ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
            size="small"
            sx={{ color: 'text.primary' }}
          >
            {expandAll ? 'Collapse' : 'Expand'} all
          </Button>
        )}
        {groupByType !== ApprovalsGroupBy.Departments && (
          <ButtonMenuGroup<ApprovalHeaderOption<string>>
            id="sortByRootDep"
            suffix="Department: "
            options={rootDepartments}
            value={rootDepartmentValue}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(value, option) =>
              option.value === value.value
            }
            variant="text"
            size="small"
            sx={{ color: 'text.primary', marginLeft: 2 }}
            onChange={(e, value) => onChangeGroupByDepartment(value.value)}
            menuWidth="auto"
          />
        )}
        <ButtonMenuGroup<ApprovalHeaderOption>
          id="sortBy"
          suffix="Group by: "
          options={approvalsSortOptions}
          value={approvalsSortValues[groupByType]}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(value, option) => option.value === value.value}
          variant="text"
          size="small"
          sx={{ color: 'text.primary', marginX: 2 }}
          onChange={(e, value) => onChangeGroupByType(value.value)}
          menuWidth="190px"
        />
      </Box>

      <Box>
        <Periods />
      </Box>

      <Box sx={{ marginLeft: 2 }}>
        <ToggleButtonGroup
          value={mode}
          size="small"
          exclusive
          aria-label="Page mode"
          onChange={onChange}
          disabled={!canAdjustKpiValues}
        >
          <TooltipToggleButton
            dataTestId={REVIEW_TOGGLE_TEST_ID}
            title="Review"
            value={ApprovalsPageMode.Review}
            disabled={!canAdjustKpiValues}
          >
            <VisibilityRoundedIcon sx={{ color }} fontSize="small" />
          </TooltipToggleButton>
          <TooltipToggleButton
            dataTestId={ADJUST_TOGGLE_TEST_ID}
            title="Adjust values"
            value={ApprovalsPageMode.Edit}
            disabled={!canAdjustKpiValues}
          >
            <EditRoundedIcon sx={{ color }} fontSize="small" />
          </TooltipToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
}

function ApprovalContent({
  hasNoMetric,
  pageMode,
  expandAll,
  groupByType,
  departments,
}: {
  hasNoMetric: boolean;
  pageMode: ApprovalsPageMode;
  expandAll: boolean;
  groupByType: ApprovalsGroupBy;
  departments: DepartmentType[];
}) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const backToReviewMode = useCallback(() => {
    dispatch(approvalsSlice.actions.setPageMode(ApprovalsPageMode.Review));
  }, [dispatch]);

  const list = useMemo(() => {
    switch (groupByType) {
      case ApprovalsGroupBy.Teams:
        return <ListGroupedByTeams departments={departments} />;
      case ApprovalsGroupBy.Departments:
        return (
          <ListGroupedByRootDepartments
            expandAll={expandAll}
            departments={departments}
          />
        );
      case ApprovalsGroupBy.Status:
        return (
          <ListGroupedByStatus
            departments={departments}
            expandAll={expandAll}
          />
        );
    }
  }, [groupByType, expandAll, departments]);

  if (hasNoMetric) {
    return (
      <Paper
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          height: 'inherit',
          minHeight: 693,
        }}
      >
        <img src={chainIcon} alt="no memtrics to adjust"></img>
        <Typography variant="h2" color="text.secondary" mt={2}>
          {pageMode === ApprovalsPageMode.Review
            ? 'There are no metrics available which you can review'
            : 'There are no metrics available which you can adjust'}
        </Typography>
        {pageMode !== ApprovalsPageMode.Review && (
          <Button variant="contained" sx={{ mt: 2 }} onClick={backToReviewMode}>
            Back to overview
          </Button>
        )}
      </Paper>
    );
  }

  return (
    <>
      <Box
        component={Table}
        sx={{
          display: 'table',
          alignItems: 'center',
          position: 'sticky',
          top: approvalsHeaderHeight,
          height: approvalsMetricHeaderHeight,
          width: '100%',
          minWidth: 900,
          zIndex: 3,
          background: theme.palette.background.default,
          'thead > tr > th': {
            padding: '8px 16px',
            background: 'inherit',
            color: theme.palette.text.secondary,
          },
          fontWeight: 600,
          fontSize: 16,
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell width={380}>
              <Typography variant="h4">Team member</Typography>
            </TableCell>
            <TableCell width={550}>
              <Typography variant="h4">Metric</Typography>
            </TableCell>
            <TableCell width={115} align="right" style={{ minWidth: '135px' }}>
              <Typography variant="h4">Payout</Typography>
            </TableCell>
            <TableCell width={90} align="right">
              <Typography variant="h4">Worst</Typography>
            </TableCell>
            <TableCell width={90} align="right">
              <Typography variant="h4">Best</Typography>
            </TableCell>
            <TableCell width={165} align="right" style={{ maxWidth: '127px' }}>
              <Typography variant="h4">Result</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
      </Box>

      {list}
    </>
  );
}

interface ApprovalFooterProps {
  mode: ApprovalsPageMode;
  onApprove: () => void;
  onDiscard: () => void;
  handleCloseCurrentPeriod: () => void;
  departments?: DepartmentType[];
  loading?: boolean;
  isClosingPeriod?: boolean;
}

export function ApprovalFooter({
  mode,
  departments,
  onApprove,
  onDiscard,
  handleCloseCurrentPeriod,
  loading,
  isClosingPeriod,
}: ApprovalFooterProps) {
  const user = useAppSelector(userDataSelectors.getUserData);

  const isClosePeriodAllowed = Boolean(
    useAppSelector(periodsSelectors.getIsClosePeriodAvailable) &&
      user?.permissions?.canClosePeriod,
  );

  const { isApproved, isReady, notSubmitted } = useCheckDepartments(
    departments || [],
  );

  if (!departments || !departments.length) {
    return null;
  }

  if (mode === ApprovalsPageMode.Edit) {
    return (
      <BottomPanel
        color={BottomPanel.color.Info}
        align={BottomPanel.align.Center}
      >
        <>
          <Button
            sx={{ mr: 1 }}
            variant="outlined"
            className="contrast"
            onClick={onDiscard}
          >
            Discard
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            className="contrast"
            type="submit"
          >
            Save
          </LoadingButton>
        </>
      </BottomPanel>
    );
  }

  if (isApproved) {
    return (
      <BottomPanel color={BottomPanel.color.Warning}>
        <>
          <Box sx={{ display: 'flex' }}>
            <DoneAllRoundedIcon fontSize="small" />
            <Typography pl={1} variant="body1">
              All results approved
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography pr={1} variant="body1" color="success.contrastText">
              Approved results are locked
            </Typography>
            <LockOutlinedIcon
              sx={{ color: 'success.contrastText' }}
              fontSize="small"
            />
            {isClosePeriodAllowed && (
              <LoadingButton
                loading={isClosingPeriod}
                onClick={handleCloseCurrentPeriod}
                size="small"
                variant="text"
                sx={{ ml: 1 }}
              >
                Close period
              </LoadingButton>
            )}
          </Box>
        </>
      </BottomPanel>
    );
  }

  if (isReady) {
    return (
      <BottomPanel color={BottomPanel.color.Info}>
        <>
          <Box sx={{ display: 'flex' }}>
            <InfoRoundedIcon />
            <Typography pl={1} typography="body1">
              All metrics results are ready
            </Typography>
          </Box>

          <LoadingButton
            loading={loading}
            variant="contained"
            className="contrast"
            onClick={onApprove}
          >
            Approve
          </LoadingButton>
        </>
      </BottomPanel>
    );
  }

  return (
    <BottomPanel color={BottomPanel.color.Warning}>
      <>
        <Box sx={{ display: 'flex' }}>
          <WarningAmberRoundedIcon fontSize="small" />
          <Typography pl={1} typography="body1">
            {notSubmitted} metric{plural(notSubmitted)} missing actual results
            and can not be approved
          </Typography>
        </Box>
        <Button variant="contained" className="contrast" disabled>
          Approve
        </Button>
      </>
    </BottomPanel>
  );
}
