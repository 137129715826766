import React, { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { KPI_TYPES } from '../../../../constants';
import { UserKpiAssignment } from '../../../../types';
import { KpiContractorsTable } from './kpiContractorsTable';

interface Props {
  userKpiAssignments: UserKpiAssignment[];
  onClose: () => void;
  kpiType: KPI_TYPES;
  isOpen?: boolean;
}

export const KpiContractorsModal: FC<Props> = ({
  userKpiAssignments,
  kpiType,
  onClose,
  isOpen,
}: Props) => {
  return (
    <Dialog open={!!isOpen} onClose={onClose} maxWidth="xl" scroll="body">
      <DialogTitle>
        Assigned to {userKpiAssignments.length} contractors
      </DialogTitle>
      <DialogContent sx={{ px: 0, py: 0 }}>
        <KpiContractorsTable
          userKpiAssignments={userKpiAssignments}
          kpiType={kpiType}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
