import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CssBaseline from '@mui/material/CssBaseline';
import { store } from './redux/store';
import * as serviceWorker from './serviceWorker';
import { NodeEnv } from './constants';
import { FallbackComponent } from './components/fallbackComponent/FallbackComponent';
import { is } from './utils';
import { muiTheme } from './theme';
import App from './App';

const nonDevEnvironment = is.preprod ? NodeEnv.PreProd : NodeEnv.Production;

Sentry.init({
  dsn: 'https://4ed8bf3cf42a40b0aebda327f143964d@o1193549.ingest.sentry.io/6316390',
  integrations: [new BrowserTracing()],
  environment: is.development ? NodeEnv.Development : nonDevEnvironment,
  tracesSampleRate: 1.0,
});

const container = document.getElementById('root');
const root = createRoot(container as unknown as DocumentFragment);

root.render(
  <Sentry.ErrorBoundary
    fallback={<FallbackComponent fullPage showBrandLogo />}
    showDialog
  >
    <React.StrictMode>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            <SnackbarProvider
              autoHideDuration={6000}
              maxSnack={1}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </Provider>
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
