import { AssessmentsScoreColorEnum, AssessmentsTypesEnum } from './types';

export const ASSESSMENT_PLACEHOLDER = {
  [AssessmentsTypesEnum.GENERAL]:
    'Please specify if there are any other high/low-fidelity issues, risks, or constraints that can hinder the project performance in the next 2 weeks or more.',
  [AssessmentsTypesEnum.SCOPE]:
    'The assessment is subjective, please use your common sense and add comments to explain your assessment. ' +
    'Add your plan on how you expect to change the assessment towards Green status.',
  [AssessmentsTypesEnum.TIMELINE]:
    'Write comments to indicate what date you are measuring against and provide any other necessary details. ' +
    'Add your plan on how you expect to change the assessment towards Green status.',
};

const GENERAL_TOOLTIP = {
  [AssessmentsScoreColorEnum.RED]:
    'General project status is Red (even if scope and timeline can be yellow).',
  [AssessmentsScoreColorEnum.YELLOW]:
    'We know there are some issues and we are working on them.',
  [AssessmentsScoreColorEnum.GREEN]: 'In general project is doing ok.',
};

const SCOPE_TOOLTIP = {
  [AssessmentsScoreColorEnum.RED]:
    "We don't even know if there will be any work in next 30 days.",
  [AssessmentsScoreColorEnum.YELLOW]:
    'We know that something is 100% there for us for the next 3 months.',
  [AssessmentsScoreColorEnum.GREEN]:
    'There is 100% something for us for the next 3+ months.',
};

const TIMELINE_TOOLTIP = {
  [AssessmentsScoreColorEnum.RED]:
    'The team is likely to miss the expected milestone/release.',
  [AssessmentsScoreColorEnum.YELLOW]:
    'The chances of missing the expected milestone/release are high but there is still a chance the team will deliver on time.',
  [AssessmentsScoreColorEnum.GREEN]:
    "There is a high chance the team won't miss the expected milestone/release.",
};

export const ASSESSMENT_TYPE_SCORE_TO_TOOLTIP = {
  [AssessmentsTypesEnum.GENERAL]: GENERAL_TOOLTIP,
  [AssessmentsTypesEnum.SCOPE]: SCOPE_TOOLTIP,
  [AssessmentsTypesEnum.TIMELINE]: TIMELINE_TOOLTIP,
};
