export interface ErrorInfo {
  code?: number;
  message: string;
}

export enum DataState {
  Idle = 'Idle',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Fulfilled = 'Fulfilled',
}

export interface AsyncData<T> {
  data: T | null;
  state: DataState;
  error?: ErrorInfo;
}
