import {
  Seniority,
  LegalLocationType,
  LegalLocation,
  EmploymentType,
  Stream,
} from '../../../types';

export const getSeniorities = (stream?: Stream) => {
  if (!stream) return [];
  if ([Stream.DESIGNER].includes(stream)) {
    return [
      Seniority.JUNIOR,
      Seniority.MIDDLE,
      Seniority.SENIOR,
      Seniority.LEAD,
    ];
  } else if ([Stream.PM].includes(stream)) {
    return [Seniority.MIDDLE, Seniority.SENIOR, Seniority.LEAD, Seniority.DM];
  } else {
    return [
      Seniority.JUNIOR1,
      Seniority.JUNIOR2,
      Seniority.MIDDLE1,
      Seniority.MIDDLE2,
      Seniority.SENIOR1,
      Seniority.SENIOR2,
      Seniority.LEAD,
    ];
  }
};

export const streams = Object.values(Stream);

export const legalLocations: LegalLocationType[] = [
  {
    id: 'ARGENTINA',
    country: LegalLocation.ARGENTINA,
  },
  {
    id: 'BRAZIL',
    country: LegalLocation.BRAZIL,
  },
  {
    id: 'COLOMBIA',
    country: LegalLocation.COLOMBIA,
  },
  {
    id: 'GUATEMALA',
    country: LegalLocation.GUATEMALA,
  },
  {
    id: 'INDIA',
    country: LegalLocation.INDIA,
  },
  {
    id: 'MEXICO_CONTRACTOR',
    country: LegalLocation.MEXICO,
    employmentType: EmploymentType.CONTRACTOR,
  },
  {
    id: 'MEXICO_EMPLOYEE',
    country: LegalLocation.MEXICO,
    employmentType: EmploymentType.EMPLOYEE,
  },
  {
    id: 'POLAND',
    country: LegalLocation.POLAND,
  },
  {
    id: 'PORTUGAL',
    country: LegalLocation.PORTUGAL,
  },
  {
    id: 'REMOTE',
    country: LegalLocation.REMOTE,
  },
  {
    id: 'UKRAINE',
    country: LegalLocation.UKRAINE,
  },
  {
    id: 'UNITED_STATES_CONTRACTOR',
    country: LegalLocation.UNITED_STATES,
    employmentType: EmploymentType.CONTRACTOR,
  },
  {
    id: 'UNITED_STATES_EMPLOYEE',
    country: LegalLocation.UNITED_STATES,
    employmentType: EmploymentType.EMPLOYEE,
  },
];
