import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/hooks';
import { userDataSelectors } from '../../../../redux/userData';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styles } from './UserInfo.styles';
import { FinKitAvatar } from '../../../../components/finKitAvatar';
import { paths } from '../../../../constants/paths';

const UserInfo: React.FC = () => {
  const user = useAppSelector(userDataSelectors.getUserData);
  const navigate = useNavigate();

  return (
    <List sx={styles}>
      <ListItemButton
        onClick={() => {
          navigate(paths.myMetrics, { replace: true });
        }}
      >
        <ListItemIcon>
          <FinKitAvatar
            medium
            fullName={user?.fullName ?? ''}
            imageUrl={user?.avatarUrl}
            country={user?.country}
            city={user?.city}
          />
        </ListItemIcon>
        <ListItemText primary={user?.fullName} secondary={user?.designation} />
      </ListItemButton>
    </List>
  );
};

export default UserInfo;
