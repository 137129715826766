import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack, Breadcrumbs, Typography } from '@mui/material';
import BreadcrumbLink from '@mui/material/Link';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Breadcrumb } from './types';

interface Props {
  hideBackIcon?: boolean;
  showPageLink?: boolean;
  breadcrumbs: Breadcrumb[];
  rootPath: string;
  pageName: string;
}

const CustomBreadCrumbs: FC<Props> = ({
  hideBackIcon,
  breadcrumbs,
  showPageLink = true,
  rootPath,
  pageName,
}) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2, mt: -2 }}>
      {(showPageLink || typeof breadcrumbs === 'string') && (
        <BreadcrumbLink
          color="primary"
          component={Link}
          to={rootPath}
          sx={{
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' },
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {!hideBackIcon && <ArrowBackRoundedIcon sx={{ width: '16px' }} />}
            <Box component="span">{pageName}</Box>
          </Stack>
        </BreadcrumbLink>
      )}
      {typeof breadcrumbs !== 'string' &&
        breadcrumbs.map((crumb) =>
          crumb.isAccessible ? (
            <BreadcrumbLink
              key={crumb.id}
              color="primary"
              component={Link}
              to={crumb.path}
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <span>{crumb.name.replaceAll('&amp;', '&')}</span>
              </Stack>
            </BreadcrumbLink>
          ) : (
            <Stack
              key={crumb.id}
              direction="row"
              alignItems="center"
              color="text.secondary"
              spacing={1}
            >
              <Typography color="text.secondary">
                {crumb.name.replaceAll('&amp;', '&')}
              </Typography>
            </Stack>
          ),
        )}
    </Breadcrumbs>
  );
};

export default CustomBreadCrumbs;
