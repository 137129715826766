import React, { useCallback } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { Link as ReactRouterLink } from 'react-router-dom';
import { COLORS } from '../../utils/colors';
import { Stack } from '@mui/material';
import Link from '@mui/material/Link';

export interface Tab {
  linkTo: string;
  linkName: string;
}

interface Props {
  tabs: Tab[] | null;
}
const TabSwitcher: React.FC<Props> = ({ tabs }) => {
  const location = useLocation();

  const getActiveStyle = useCallback(
    (linkTo: string) => {
      const isActive = matchPath({ path: linkTo }, location.pathname);
      const activeStyle = isActive
        ? {
            color: COLORS.BRAND.PRIMARY,
            backgroundColor: COLORS.BRAND.HIGHLIGHT,
            position: 'relative',
            boxShadow: '0 12px 12px 8px #ffffff',
          }
        : {};

      return activeStyle;
    },
    [location],
  );

  return (
    <>
      {tabs && (
        <Stack flexDirection="row" gap="4px">
          {tabs
            .filter(({ linkTo, linkName }) => linkName && linkTo)
            .map(({ linkTo, linkName }, i) => {
              return (
                <Link
                  component={ReactRouterLink}
                  sx={{
                    whiteSpace: 'nowrap',
                    textDecoration: 'none',
                    fontSize: '13px',
                    fontWeight: '500',
                    padding: '4px 10px 4px 10px',
                    transition: 'all 0.2s',

                    ...getActiveStyle(linkTo),
                    color: COLORS.TEXT.PRIMARY,
                    borderRadius: '4px',
                    marginRight: 0,
                    '&:hover': {
                      backgroundColor: COLORS.BRAND.HIGHLIGHT,
                    },
                  }}
                  to={`../${linkTo}`}
                  key={i}
                >
                  {linkName}
                </Link>
              );
            })}
        </Stack>
      )}
    </>
  );
};

export default TabSwitcher;
