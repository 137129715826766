import React from 'react';
import WorkOffRoundedIcon from '@mui/icons-material/WorkOffRounded';
import Stack from '@mui/material/Stack';
import { Typography, Tooltip, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FinKitAvatar } from '../finKitAvatar';

interface MemberInfoCellProps {
  hideAvatar?: boolean;
  disabled?: boolean;

  link?: string;

  userInfo: {
    avatarUrl?: string;
    fullName: string;
    stream?: string;
    country?: string;
    city?: string;
  };
}

export function MemberInfoCell({
  hideAvatar,
  disabled,

  link,

  userInfo,
}: MemberInfoCellProps): JSX.Element {
  const navigate = useNavigate();
  const textStyles = disabled ? { opacity: 0.7 } : {};

  const avatar = !hideAvatar && (
    <FinKitAvatar
      medium
      fullName={userInfo.fullName}
      imageUrl={userInfo.avatarUrl}
      country={userInfo.country}
      city={userInfo.city}
    />
  );

  const content = (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        maxWidth: '100%',
        width: '100%',
      }}
    >
      {avatar}
      <Stack
        ml={2}
        sx={{
          width: 'calc(100% - 56px)',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {userInfo.fullName ? (
            <Typography
              variant="body1"
              sx={{
                marginRight: 1,
                ...textStyles,
                width: disabled ? 'calc(100% - 30px)' : '100%',
              }}
            >
              {userInfo.fullName}
            </Typography>
          ) : (
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                marginRight: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ...textStyles,
              }}
            >
              {'(name is missing)'}
            </Typography>
          )}
          {disabled && (
            <Tooltip title="Deactivated" enterDelay={300} placement="top">
              <WorkOffRoundedIcon color="disabled" sx={{ fontSize: '16px' }} />
            </Tooltip>
          )}
        </Stack>
        <Typography
          variant="body2"
          sx={{
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ...textStyles,
          }}
        >
          {userInfo.stream}
        </Typography>
      </Stack>
    </Stack>
  );

  return link ? (
    <Link
      sx={{
        whiteSpace: 'nowrap',
        textAlign: 'left',
        textDecoration: 'none',
        '&:hover': { textDecoration: 'underline' },
      }}
      component="button"
      variant="body1"
      onClick={() => navigate(link)}
    >
      {content}
    </Link>
  ) : (
    content
  );
}
