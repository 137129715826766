import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants/api';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { reportIssueStoreKey } from './reportIssue.const';
import {
  ReportIssueBodyType,
  ReportIncorrectFeedback,
} from './reportIssue.types';

export const reportIssue = createAsyncThunk(
  `${reportIssueStoreKey}/reportIssue`,
  async (body: ReportIssueBodyType, thunkAPI) => {
    try {
      const response = await axios.post(`${apiEndpoints.reportIssue()}`, body);
      thunkAPI.dispatch(notificationAlert('Thank you for your feedback!'));
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);

export const reportIncorrectReport = createAsyncThunk(
  `${reportIssueStoreKey}/reportIncorrectReport`,
  async (body: ReportIncorrectFeedback, thunkAPI) => {
    try {
      const response = await axios.post(
        `${apiEndpoints.reportIncorrectReport()}`,
        body,
      );
      thunkAPI.dispatch(notificationAlert('Thank you for your feedback!'));
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);
