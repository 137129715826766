import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchKpiDefinitions,
  kpiDefinitionsSelectors,
  saveKpiDefinition,
  kpiDefinitionsSlice,
} from '../../redux/kpiDefinitions';
import { Paper, Typography } from '@mui/material';
import { KpiDefinitionItem } from '../../types';
import { ListItemCard, PageWrapper } from '../../components';
import {
  KpiDefinitionItemWithOwners,
  KpiDefinitionUpdateData,
} from '../../redux/kpiDefinitions/kpiDefinitions.types';
import { KpiDefinitionModal } from './components/kpiDefinitionModal/kpiDefinitionModal';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {
  fetchRootDepartments,
  rootDepatmentsSlice,
} from '../../redux/rootDepartments';
import { useAppDispatch } from '../../redux/hooks';
import { KpiDefintionsTable } from './components/kpiDefinitionsTable/kpiDefinitionsTable';
import { Box } from '@mui/system';
import { plural } from '../../utils';
import { debounce } from 'lodash';
import { fetchPeriods } from '../../redux/periods';
import { PageTitle } from '../../utils/pageTitles';
import { useTitle } from '../../hooks/useTitle';

export function KpiDefinitions(): JSX.Element {
  const setPageTitle = useTitle();
  useEffect(() => {
    setPageTitle(PageTitle.kpiDefinitions);
  }, [setPageTitle]);

  const dispatch = useAppDispatch();

  const [mountKpidefinitionModal, setKpidefinitionModalMounted] =
    useState<boolean>(false);

  const [editableKpiDefinition, setEditableKpiDefinition] =
    useState<KpiDefinitionItem | null>(null);

  const kpiDefinitions: KpiDefinitionItemWithOwners[] = useSelector(
    kpiDefinitionsSelectors.getKpiDefinitions,
  );
  const loading: boolean = useSelector(
    kpiDefinitionsSelectors.getKpiDefinitionsLoading,
  );

  const [activeKpis, setActiveKpis] = useState<KpiDefinitionItem[]>([]);
  const [deactivatedKpis, setDeactivatedKpis] = useState<KpiDefinitionItem[]>(
    [],
  );

  useEffect(() => {
    dispatch(fetchRootDepartments());
    dispatch(fetchKpiDefinitions());
    dispatch(fetchPeriods());

    return () => {
      dispatch(kpiDefinitionsSlice.actions.reset());
      dispatch(rootDepatmentsSlice.actions.reset());
    };
  }, [dispatch]);

  useEffect(() => {
    setActiveKpis(kpiDefinitions.filter((x) => x.isActive));
    setDeactivatedKpis(kpiDefinitions.filter((x) => !x.isActive));
  }, [kpiDefinitions]);

  const updateKpiDefinition = (
    { id, departments, kpiOwnerId }: KpiDefinitionItem,
    newValues: Partial<KpiDefinitionUpdateData>,
  ) => {
    dispatch(
      saveKpiDefinition({
        id,
        data: {
          kpiOwnerId,
          departmentIds: (departments || []).map(({ id }) => id),

          ...newValues,
        },
      }),
    );
  };

  const handleKpiStatusToggle = (row: KpiDefinitionItem) => {
    updateKpiDefinition(row, {
      isActive: !row.isActive,
    });
  };

  const onCreateNewKPI = () => {
    setEditableKpiDefinition(null);
    setKpidefinitionModalMounted(true);
  };

  const handleEditKpiDefinition = (row: KpiDefinitionItemWithOwners) => {
    setKpidefinitionModalMounted(true);
    setEditableKpiDefinition(row as KpiDefinitionItem);
  };

  const handleSave = debounce(() => dispatch(fetchKpiDefinitions()), 300);

  return (
    <PageWrapper
      isLoading={loading}
      showContent={kpiDefinitions && !!kpiDefinitions.length}
      maxWidth={'1500px'}
    >
      <Stack direction="row" justifyContent="space-between" mb={4}>
        <Stack direction="row" spacing={4}>
          <Typography variant="h2">KPI definitions</Typography>
        </Stack>
        <Stack justifyContent="end">
          <Button variant="contained" onClick={onCreateNewKPI}>
            Create new
          </Button>
        </Stack>
      </Stack>
      <Paper variant="outlined">
        <KpiDefintionsTable
          kpis={activeKpis}
          handleEditKpiDefinition={handleEditKpiDefinition}
          handleKpiStatusToggle={handleKpiStatusToggle}
        />
      </Paper>

      {!!deactivatedKpis.length && (
        <Box pt={3}>
          <Typography variant="body1" pb={1}>
            Deactivated
          </Typography>
          <ListItemCard
            title={'Deactivated metrics'}
            description={`${deactivatedKpis.length} metric${plural(
              deactivatedKpis.length,
            )}`}
          >
            <KpiDefintionsTable
              kpis={deactivatedKpis}
              handleEditKpiDefinition={handleEditKpiDefinition}
              handleKpiStatusToggle={handleKpiStatusToggle}
            />
          </ListItemCard>
        </Box>
      )}

      {mountKpidefinitionModal && (
        <KpiDefinitionModal
          onClosed={() => {
            setKpidefinitionModalMounted(false);
          }}
          onSaved={handleSave}
          handleActivateDeactivate={() =>
            editableKpiDefinition &&
            handleKpiStatusToggle(editableKpiDefinition)
          }
          kpiDefinition={editableKpiDefinition}
        />
      )}
    </PageWrapper>
  );
}
