import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { API_STATUS } from '../../constants';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import {
  fetchDepartments,
  contractorsSelectors,
  clearContractors,
} from '../../redux/contractors';
import { PageWrapper } from '../../components';
import ContractorsList from './ContractorsList';
import {
  useIsContractorsExpandable,
  useSeparateActiveInactiveUsers,
} from './Contractors.hooks';
import { Box } from '@mui/material';
import { DepartmentView } from '../../types/contractor';
import { paths } from '../../constants/paths';
import { PageTitle } from '../../utils/pageTitles';
import { useTitle } from '../../hooks/useTitle';

const Contractors = () => {
  const setPageTitle = useTitle();
  useEffect(() => {
    setPageTitle(PageTitle.teams);
  }, [setPageTitle]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { departmentId: departmentToExpand } = useParams();

  const [expandAll, setExpandAll] = useState<boolean>(false);

  const departmentTree = useAppSelector(contractorsSelectors.getDepartmentTree);

  const contractorsApiStatus = useAppSelector(
    contractorsSelectors.getContractorsApiStatus,
  );

  const { departmentsWithInactiveMembers, departmentsWithActiveMembers } =
    useSeparateActiveInactiveUsers(departmentTree || []);

  const isExpandable = useIsContractorsExpandable(departmentTree);

  const isLoading = contractorsApiStatus === API_STATUS.LOADING;

  useEffect(() => {
    dispatch(fetchDepartments(DepartmentView.TEAMS));
    return () => {
      dispatch(clearContractors());
    };
  }, [dispatch]);

  const handleContractorClick = useCallback(
    (departmentId: string, contractorId: string) => {
      navigate(`${paths.contractors}/${departmentId}/${contractorId}`);
    },
    [navigate],
  );

  const handleExpandAllToggle = useCallback(() => {
    setExpandAll((state) => !state);
  }, [setExpandAll]);

  const expandButton = isExpandable && (
    <Button
      onClick={handleExpandAllToggle}
      endIcon={expandAll ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
      size="small"
      sx={{ color: 'text.primary', mt: -1 }}
    >
      {expandAll ? 'Collapse' : 'Expand'} all
    </Button>
  );

  return (
    <PageWrapper isLoading={isLoading} maxWidth={'1500px'}>
      <Typography variant="h2" display="inline-block" mb={2.5} mr={3}>
        Departments
      </Typography>
      {expandButton}
      <Box sx={{ paddingBottom: 2 }}>
        {departmentsWithActiveMembers.map((tree) => (
          <ContractorsList
            key={tree.id}
            expandAll={expandAll}
            departmentTree={tree}
            handleContractorClick={handleContractorClick}
            topLevel
            departmentToExpand={departmentToExpand}
          />
        ))}
      </Box>

      {!!departmentsWithInactiveMembers.length && (
        <Box>
          {departmentsWithInactiveMembers.map((tree) => (
            <ContractorsList
              key={tree.id}
              expandAll={expandAll}
              departmentTree={tree}
              handleContractorClick={handleContractorClick}
              topLevel
              disabled
              departmentToExpand={departmentToExpand}
            />
          ))}
        </Box>
      )}
    </PageWrapper>
  );
};

export default Contractors;
