import { Box, styled } from '@mui/system';

interface StyledListItemCardProps {
  stickyTitle: boolean;
  open: boolean;
  onApprovals: boolean;
  approvalsMetricStickyTopOffset: number;
}

export const StyledListItemCard = styled(Box, {
  shouldForwardProp: (propName) =>
    ![
      'stickyTitle',
      'open',
      'onApprovals',
      'approvalsMetricStickyTopOffset',
    ].includes(propName as string),
})<StyledListItemCardProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => theme.spacing(1)};
  position: ${({ open, stickyTitle }) =>
    stickyTitle && open ? 'sticky' : 'initial'};
  top: ${({ open, onApprovals, approvalsMetricStickyTopOffset }) =>
    onApprovals && open ? approvalsMetricStickyTopOffset : '0'};
  box-shadow: ${({ open, theme }) =>
    open
      ? `0 ${theme.spacing(1)} 0 0 ${
          theme.palette.background.paper
        }, 0 12px 3px -4px #0000002b`
      : 'none'};
  padding-bottom: ${({ theme, open }) =>
    open ? theme.spacing(1) : theme.spacing(2)};
  z-index: 2;

  &:hover .MuiIconButton-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
