import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AddCommentIcon from '@mui/icons-material/AddComment';
import {
  AssessmentDateContent,
  AssessmentTypeContent,
  assessmentsTypes,
  AssessmentsTypesEnum,
} from '../../types';
import { statusColors } from '../../../../constants';
import { capitalizeFirstLetter } from '../../utils';
import { blue } from '@mui/material/colors';
import { StyledDateButtonAssessmentsGraph } from '../../styledComponents/StyledDateButtonAssessmentsGraph';

const generalStatusIcons = [
  <ErrorRoundedIcon
    key="general-status-icon-0"
    sx={{ fontSize: '1.2rem', color: statusColors[0], margin: '0 auto' }}
  />,
  <WarningRoundedIcon
    key="general-status-icon-1"
    sx={{
      fontSize: '1.2rem',
      color: statusColors[1],
    }}
  />,
  <CheckCircleRoundedIcon
    key="general-status-icon-2"
    sx={{
      fontSize: '1.2rem',
      color: statusColors[2],
    }}
  />,
  <AddCommentIcon
    key="general-status-icon-3"
    sx={{
      fontSize: '1.5rem',
      color: blue[500],
    }}
  />,
  <FiberManualRecordIcon
    key="general-status-icon-4"
    sx={{
      fontSize: '1rem',
      color: statusColors[3],
    }}
  />,
];

interface Props {
  onSelectPeriod: (date: string) => void;
  selectedPeriod: string;
  assessments: Map<string, AssessmentDateContent> | null;
}

const AssessmentsGraph = ({
  onSelectPeriod,
  selectedPeriod,
  assessments,
}: Props) => {
  const assessmentsContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (assessmentsContainer.current) {
      assessmentsContainer.current.scrollLeft =
        assessmentsContainer.current?.scrollWidth;
    }
  }, [assessments]);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '110px minmax(10px, 1fr)',
        columnGap: '0.6rem',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: '40px 30px 30px 30px',
          gridTemplateColumns: '1fr',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" sx={{ fontSize: '1rem' }}>
          Weekly:
        </Typography>
        {assessmentsTypes.map((type: AssessmentsTypesEnum) => (
          <Box key={type}>{capitalizeFirstLetter(type)}</Box>
        ))}
      </Box>
      {assessments && (
        <Stack
          ref={assessmentsContainer}
          direction="row"
          sx={{
            columnGap: '0.6rem',
            overflow: 'auto',
          }}
        >
          {Array.from(assessments).map(
            (
              [period, types]: [string, AssessmentDateContent],
              assessmentIndex,
            ) => (
              <Box
                key={period}
                sx={{
                  width: '100%',
                  display: 'grid',
                  gridTemplateRows: '40px 30px 30px 30px',
                  gridTemplateColumns: '1fr',
                  alignItems: 'center',
                  textAlign: 'center',
                  flex: '0 0 60px',
                  cursor: 'pointer',
                }}
                onClick={() => onSelectPeriod(period)}
              >
                <StyledDateButtonAssessmentsGraph
                  size="small"
                  variant="text"
                  color="secondary"
                  className={`${selectedPeriod === period ? 'selected' : ''}`}
                  sx={{
                    padding: 0,
                    backgroundColor: 'transparent',
                    color: blue[500],
                    textDecoration: 'underline',
                    minWidth: 'auto',
                  }}
                >
                  {period}
                </StyledDateButtonAssessmentsGraph>
                {Object.entries(types).map(
                  (
                    [typeName, typeData]: [string, AssessmentTypeContent],
                    index,
                  ) =>
                    // It's General assessment.
                    index === 0 ? (
                      <Stack key={`${period}-${typeName}`} alignItems="center">
                        {
                          generalStatusIcons[
                            typeof typeData.score === 'number' // It has a score (0, 1, 2).
                              ? typeData.score
                              : assessmentIndex === assessments.size - 1 // It's the latest, therefore can be edited.
                              ? 3
                              : 4 // It's from the past, therefore can no longer be edited.
                          ]
                        }
                      </Stack>
                    ) : (
                      // It's either Scope or Timeline, render their bars.
                      <Box
                        key={`${period}-${typeName}`}
                        sx={{
                          backgroundColor:
                            statusColors[
                              typeof typeData.score === 'number'
                                ? typeData.score
                                : 3
                            ],
                          width: '100%',
                          height: '0.5rem',
                          borderRadius: '4px',
                        }}
                      />
                    ),
                )}
              </Box>
            ),
          )}
        </Stack>
      )}
    </Box>
  );
};

export default AssessmentsGraph;
