export enum FilterPeriodType {
  AllTime = 'AllTime',
  CurrentYear = 'CurrentYear',
  LastYear = 'LastYear',
  LastQuarter = 'LastQuarter',
  LastMonth = 'LastMonth',
}

export interface FilterPeriodModel {
  label: string;
  value: FilterPeriodType;
  subtext?: string;
  year?: string;
}
