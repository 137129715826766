import React, { CSSProperties, useCallback } from 'react';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';

interface Props {
  buttonIcon: JSX.Element;
  children: JSX.Element;
  style?: CSSProperties;
  size?: 'small' | 'medium' | 'large';
}

export const PopoverButton: React.FC<Props> = ({
  buttonIcon,
  children,
  style,
  size,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const open = Boolean(anchorEl);
  const id = open ? 'popover-button' : undefined;

  return (
    <Box component="span" style={style}>
      <IconButton aria-describedby={id} onClick={handleClick} size={size}>
        {buttonIcon}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {children}
      </Popover>
    </Box>
  );
};
