import React, { FC, ReactNode } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { NumericFormat } from 'react-number-format';
import { Tooltip } from '@mui/material';
import { QuarterIcon } from '../../../components/svg';

interface BonusShareFieldProps {
  id: string;
  name: string;
  value: number | undefined;
  handleChange: (data: { value: string }) => void;
  error?: boolean;
  helperText?: ReactNode;
  isQuarterlyMetric: boolean | null;
  label: string;
  disabled?: boolean;
  onBlurHandler?: () => void;
}

export const BonusShareField: FC<BonusShareFieldProps> = ({
  id,
  name,
  value,
  handleChange,
  error,
  helperText,
  isQuarterlyMetric,
  label,
  disabled = false,
  onBlurHandler,
}) => {
  return (
    <NumericFormat
      allowNegative={false}
      customInput={TextField}
      error={error}
      fullWidth
      id={id}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        endAdornment: isQuarterlyMetric && (
          <InputAdornment position="end">
            <Tooltip title="Quarterly" enterDelay={300} placement="top">
              <div style={{ display: 'flex' }}>
                <QuarterIcon />
              </div>
            </Tooltip>
          </InputAdornment>
        ),
        autoFocus: true,
      }}
      inputMode="decimal"
      label={label ?? ''}
      name={name}
      onValueChange={handleChange}
      value={value}
      variant="filled"
      helperText={helperText ?? ''}
      disabled={disabled}
      onBlur={onBlurHandler}
    />
  );
};
