import { DepartmentType, ContractorType } from '../../types';
import { useMemo } from 'react';
import { getEmptyDepartment } from '../../mocks/departmentTree';

interface SeparatedAccountsResult {
  inactiveMembers: ContractorType[];
  departmentsWithActiveMembers: DepartmentType[];
}

interface SeparateHookResult {
  departmentsWithInactiveMembers: DepartmentType[];
  departmentsWithActiveMembers: DepartmentType[];
}

export function useIsContractorsExpandable(
  departments: DepartmentType[],
): boolean {
  return useMemo(() => {
    const expandableItems = (departments || []).reduce(
      (count, { sharedKpis, subDepartments }) =>
        count + (sharedKpis || []).length + (subDepartments || []).length,
      0,
    );

    return expandableItems > 0;
  }, [departments]);
}

function separateAccounts(
  departments: DepartmentType[],
): SeparatedAccountsResult {
  const inactiveMembers: ContractorType[] = [];

  const departmentsWithActiveMembers = departments.map((department) => {
    const { members, subDepartments } = department;

    const active = members.filter(({ deactivatedAt }) => !deactivatedAt);
    const inactive = members.filter(({ deactivatedAt }) => !!deactivatedAt);

    const {
      inactiveMembers: inactiveSubMembers,
      departmentsWithActiveMembers,
    } = separateAccounts(subDepartments || []);

    inactiveMembers.push(...inactive);
    inactiveMembers.push(...inactiveSubMembers);

    return {
      ...department,
      members: active,
      subDepartments: departmentsWithActiveMembers,
    };
  });

  return {
    inactiveMembers,
    departmentsWithActiveMembers,
  };
}

export function useSeparateActiveInactiveUsers(
  departments: DepartmentType[],
): SeparateHookResult {
  return useMemo(() => {
    const { inactiveMembers, departmentsWithActiveMembers } =
      separateAccounts(departments);

    return {
      departmentsWithActiveMembers,

      departmentsWithInactiveMembers: !!inactiveMembers.length
        ? [
            getEmptyDepartment({
              name: 'Deactivated',
              zohoId: 'deactivated',
              subDepartments: [
                getEmptyDepartment({
                  name: 'Deactivated profiles',
                  zohoId: 'deactivatedSub',
                  members: inactiveMembers,
                }),
              ],
            }),
          ]
        : [],
    };
  }, [departments]);
}
