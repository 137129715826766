export enum KPI_FREQUENCY {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
}

export enum KPI_SCOPES {
  INDIVIDUAL = 'INDIVIDUAL',
  SHARED = 'SHARED',
}

export enum KPI_TYPES {
  VARIABLE_ABSOLUTE = 'VARIABLE_ABSOLUTE',
  VARIABLE_PERCENTAGE = 'VARIABLE_PERCENTAGE',
  FIXED_ABSOLUTE = 'FIXED_ABSOLUTE',
  FIXED_PERCENTAGE = 'FIXED_PERCENTAGE',
}
