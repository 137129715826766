const kpiMetricsKey = '/kpi-definition';

export const kpiMetrics = (userId: string) => {
  return `${kpiMetricsKey}/${userId}`;
};

export const kpiOwners = (userId: string) => {
  return `${kpiMetricsKey}/get-assignable-owners?userId=${userId}`;
};

export const kpiMetricsByZoho = (userId: string) => {
  return `${kpiMetricsKey}/zoho/${userId}`;
};
