import React, { FC, memo, useCallback, useState } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import GenerateReportModal from './GenerateReportModal';
import { BottomPanel } from '../../../../components';
import GoogleDriveIcon from '../../../../components/svg/GoogleDriveIcon';
import { useAppSelector } from '../../../../redux/hooks';
import {
  getStatusReportIsLoading,
  getStatusReportLink,
} from '../../../../redux/statusReport';
import { getStatusReportsFolderLink } from '../../../../redux/projectStatus';
import {
  BUTTON_OPEN_GENERATED_TEST_ID,
  BUTTON_PREV_REPORTS_TEST_ID,
} from './constants';
import { AssessmentDateContent } from '../../types';
import GenerateReportButton from './GenerateReportButton/GenerateReportButton';

interface Props {
  assessment: AssessmentDateContent | null;
  isLatestPeriod: boolean;
  period: string;
  title: string;
}

const GenerateReportPanel: FC<Props> = ({
  assessment,
  isLatestPeriod,
  period,
  title,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const isReportLoading = useAppSelector(getStatusReportIsLoading);
  const statusReportLink = useAppSelector(getStatusReportLink);
  const statusReportsFolderLink = useAppSelector(getStatusReportsFolderLink);

  const isReportGenerated = statusReportLink && isLatestPeriod;
  const isAvailableToGenerateReport = !statusReportLink && isLatestPeriod;

  const getReport = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const navigateToFile = useCallback((fileLink: string | undefined) => {
    if (!fileLink) return;

    window.open(fileLink, '_blank', 'noopener,noreferrer');
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  return (
    <>
      <BottomPanel
        isSticky
        color={BottomPanel.color.Info}
        align={BottomPanel.align.SpaceBetween}
      >
        <Box sx={{ gap: 1, display: 'flex' }}>
          {isAvailableToGenerateReport && (
            <GenerateReportButton
              disabled={isReportLoading || !!!assessment?.GENERAL.id}
              loading={isReportLoading}
              onClick={getReport}
            />
          )}
          {isReportGenerated && (
            <Button
              variant="text"
              data-testid={BUTTON_OPEN_GENERATED_TEST_ID}
              startIcon={<InsertDriveFileIcon />}
              onClick={() => navigateToFile(statusReportLink)}
            >
              Open report
            </Button>
          )}
          <Button
            variant="contained"
            data-testid={BUTTON_PREV_REPORTS_TEST_ID}
            disabled={!statusReportsFolderLink}
            startIcon={<GoogleDriveIcon />}
            onClick={() => navigateToFile(statusReportsFolderLink)}
          >
            Browse previous reports
          </Button>
        </Box>
      </BottomPanel>
      <GenerateReportModal
        assessment={assessment}
        isModalOpen={isModalOpen}
        period={period}
        title={title}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

export default memo(GenerateReportPanel);
