export enum PageTitle {
  accountReports = 'Account reports',
  loginPage = 'Login',
  pageNotFound = 'Page not found',
  accounts = 'Accounts',
  approval = 'Approvals',
  teams = 'Teams',
  bonuses = 'bonuses',
  monthlyResults = 'Monthly results',
  reports = 'Reports',
  kpiDefinitions = 'KPI definitions',
  unauthorizedPage = 'Access denied',
  admin = 'Admin',
  portfolio = 'Portfolio',
  risks = 'Risks',
  projectStatuses = 'Project Statuses',
  ratesAndMargin = 'Rates & Margins',
  timeAndPeople = 'Time & People',
}
