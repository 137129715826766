const rootKey = '/projects';

export const projectMargins = (projectId: string) => {
  return `${rootKey}/${projectId}/margins`;
};

export const projectStatus = (projectId: string) => {
  return `${rootKey}/${projectId}/status`;
};

export const projectTimetracking = () => {
  return `${rootKey}/timetracking`;
};

export const projectAndMembers = (projectId: string) => {
  return `${rootKey}/${projectId}/simple`;
};
