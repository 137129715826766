import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiEndpoints } from '../../constants/api';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { rootDepartmentsStoreKey } from './rootDepartments.const';

export const fetchRootDepartments = createAsyncThunk(
  `${rootDepartmentsStoreKey}/fetchRootDepartments`,
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(apiEndpoints.rootDepartments());
      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);

export const fetchFinanceDepartmentId = createAsyncThunk(
  `${rootDepartmentsStoreKey}/fetchFinanceDepartmentId`,
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(apiEndpoints.financeDepartmentId());
      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);
