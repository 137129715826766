import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiEndpoints } from '../../constants/api';
import { DepartmentView } from '../../types/contractor';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { fetchCheckApproved } from '../periods/periods.thunks';
import { approvalsStoreKey } from './approvals.const';
import { approvalsSelectors } from './approvals.selectors';
import {
  AppStateWithApprovalsData,
  ApprovalsFormItemModel,
} from './approvals.types';

export const fetchApprovals = createAsyncThunk(
  `${approvalsStoreKey}/fetchKpiDefinitions`,
  async (view: DepartmentView, thunkAPI) => {
    try {
      const departmentsResponse = await axios.get(
        apiEndpoints.departments(view),
      );

      return departmentsResponse.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), {
          variant: 'error',
        }),
      );
      throw new Error(String(err));
    }
  },
);

export const approveKpis = createAsyncThunk(
  `${approvalsStoreKey}/approveKpis`,
  async (kpiIds: string[] | undefined, thunkAPI) => {
    try {
      const resp = await axios.post(apiEndpoints.approveKpis(), kpiIds);

      thunkAPI.dispatch(fetchApprovals(DepartmentView.APPROVE));
      thunkAPI.dispatch(fetchCheckApproved());
      return resp.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert('One or more KPIs are not yet submitted', {
          variant: 'error',
        }),
      );
      throw new Error(String(err));
    }
  },
);

export const submitKpi = createAsyncThunk(
  `${approvalsStoreKey}/submitKpi`,
  async (kpiId: string, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const values = approvalsSelectors.getApprovalsValues(
        state as AppStateWithApprovalsData,
      );
      const value = values[kpiId];
      const data = [
        {
          id: kpiId,
          value,
        },
      ];

      const resp = await axios.post(apiEndpoints.adjustKpis(), {
        kpiValues: data,
      });

      thunkAPI.dispatch(fetchApprovals(DepartmentView.APPROVE));
      thunkAPI.dispatch(fetchCheckApproved());

      return resp.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), {
          variant: 'error',
        }),
      );

      throw new Error(String(err));
    }
  },
);

export const submitKpis = createAsyncThunk(
  `${approvalsStoreKey}/submitKpis`,
  async (kpiValues: ApprovalsFormItemModel[], thunkAPI) => {
    try {
      const resp = await axios.post(apiEndpoints.adjustKpis(), {
        kpiValues,
      });

      thunkAPI.dispatch(fetchApprovals(DepartmentView.APPROVE));
      thunkAPI.dispatch(fetchCheckApproved());

      return resp.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), {
          variant: 'error',
        }),
      );

      throw new Error(String(err));
    }
  },
);

export const checkNewKpiBonus = createAsyncThunk(
  `${approvalsStoreKey}/checkNewKpiBonus`,
  async (
    kpiData: { id: string; value: string | number; period: number },
    thunkAPI,
  ) => {
    try {
      const resp = await axios.post(apiEndpoints.calculateKpiBonus(), {
        period: kpiData.period,
        kpis: [
          {
            userKpiAssignmentId: kpiData.id,
            value:
              typeof kpiData.value === 'number'
                ? kpiData.value
                : parseFloat(kpiData.value),
          },
        ],
      });

      return resp.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), {
          variant: 'error',
        }),
      );

      throw new Error(String(err));
    }
  },
);
