import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserWithPermissions, UserPermissions } from '../types';
import { userDataSelectors } from '../redux/userData';
import { rootRoutes } from './Routes.constants';
import { ServerStatusCode } from '../constants';
import { PageWrapper } from '../components';

const ResolveDefaultPage: React.FC = () => {
  const user: UserWithPermissions | null = useSelector(
    userDataSelectors.getUserData,
  );

  const userPermissions = (user?.permissions || {}) as UserPermissions;

  const route = rootRoutes.find((route) => {
    const permission = route.permissions.find(
      (perm: keyof UserPermissions) => userPermissions[perm],
    );

    return permission;
  });

  if (route) {
    return <Navigate to={route.path} replace />;
  }

  return <PageWrapper statusCode={ServerStatusCode.FORBIDDEN} />;
};

export default ResolveDefaultPage;
