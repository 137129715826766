export class SessionStorageService {
  static clearState(key?: string): void {
    if (key) {
      return window.sessionStorage.removeItem(key);
    }

    window.sessionStorage.clear();
  }

  static setState<T = unknown>(key: string, value: T): void {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }

  static getState<T = unknown>(key: string): T | null {
    const jsonState = window.sessionStorage.getItem(key);

    if (!jsonState) {
      return null;
    }

    return JSON.parse(jsonState);
  }
}
