import React from 'react';
import { KpiStatus } from '../../constants';
import { User } from '../../types';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded';
import { Avatar, Tooltip, Stack } from '@mui/material';
import { avatarSmallSize } from '../../theme';

interface Props {
  status: KpiStatus;
  actionTaker: User;
}

export const ActionTakerBadge = ({ actionTaker, status }: Props) => {
  const actionLabel = status === KpiStatus.Submitted ? 'approve' : 'submit';
  const actionIcon =
    status === KpiStatus.Submitted ? (
      <EventAvailableRoundedIcon color="action" fontSize="small" />
    ) : (
      <AddchartRoundedIcon color="action" fontSize="small" />
    );

  return (
    <Tooltip
      title={`${actionTaker.fullName} needs to ${actionLabel} results`}
      placement="top"
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        {actionIcon}
        <Avatar
          src={actionTaker?.avatarUrl}
          alt={actionTaker?.fullName}
          sx={{
            width: avatarSmallSize,
            height: avatarSmallSize,
            ml: 1,
          }}
        />
      </Stack>
    </Tooltip>
  );
};
