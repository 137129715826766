import {
  appConfig,
  NodeEnv,
  DEV_HOST,
  LOCALHOST,
  localhostPorts,
  ROCKY_LOCALHOST,
} from '../constants';
import { is } from './is';

export function getApiHostname(): string {
  if (!is.localhost) {
    return '';
  }

  return appConfig.REACT_APP_ENV === NodeEnv.Localhost
    ? `http://${LOCALHOST}:${localhostPorts.BACKEND}`
    : DEV_HOST;
}

export function getRockyHostNames(): string[] {
  return is.localhost
    ? [ROCKY_LOCALHOST]
    : is.dev || is.stage
    ? [appConfig.rockyDevUrl, appConfig.rockyStageUrl]
    : [appConfig.rockyProdUrl];
}
