import React from 'react';
import { TableCell, TableRow } from '@mui/material';

const defaultSizes = [100, 100, 100, 100, 100, 100, 100, 50, 50, 50, 50, 100];

interface FakeTableRowProps {
  sizes?: (number | string | undefined)[];
}

export const FakeTableRow = ({
  sizes = defaultSizes,
}: FakeTableRowProps): JSX.Element => {
  return (
    <TableRow className="fake-table-row no-height">
      {sizes.map((size, index) => (
        <TableCell
          key={index}
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            borderBottom: 0,
            height: 0,
          }}
          colSpan={1}
          width={size}
        />
      ))}
    </TableRow>
  );
};
