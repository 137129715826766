import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants/api';
import { statusReportStoreKey } from './statusReport.const';
import { FetchStatusReportBody } from './statusReport.types';
import { notificationAlert } from '../notifications';
import { formatErrorMessage } from '../../utils';

export const fetchStatusReport = createAsyncThunk(
  `${statusReportStoreKey}/fetchStatusReport`,
  async (body: FetchStatusReportBody, thunkAPI) => {
    try {
      const { data } = await axios.post(`${apiEndpoints.statusReport()}`, body);
      return data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(
          formatErrorMessage(err, 'Status Report generation failed'),
          {
            variant: 'error',
          },
        ),
      );
      throw new Error(String(err));
    }
  },
);
