import { createAsyncThunk } from '@reduxjs/toolkit';
import { errorAbortHandler, formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { cleanAccountsTableRow } from './account.slice';
import { accountStoreKey } from './account.const';
import {
  CalculateMarginAccountData,
  CalculateMarginAccountResult,
  CalculateMarginProjectData,
  CalculateMarginProjectResult,
  GetAccountData,
} from './account.types';
import { AbortedRequest } from '../../types/redux';
import axios from 'axios';
import { apiEndpoints } from '../../constants/api';
import { ProjectDetail } from '../../types';

export const fetchAccount = createAsyncThunk(
  `${accountStoreKey}/fetchAccount`,
  async (
    { accountId, fetchAllMembers, periodFiltering }: GetAccountData,
    thunkAPI,
  ) => {
    try {
      const response = await axios.post(
        `${apiEndpoints.accounts()}/${accountId}`,
        { fetchAllMembers, periodFiltering },
      );
      const account = response.data.account;
      account.grossMarginTargets = response.data.grossMarginTargets;
      account.netMarginTargets = response.data.netMarginTargets;
      account.marginHighlights = response.data.marginHighlights;
      account.projects = account.projects.map((proj: ProjectDetail) => {
        proj.grossMarginTargets = response.data.grossMarginTargets;
        proj.netMarginTargets = response.data.netMarginTargets;
        proj.marginHighlights = response.data.marginHighlights;
        return proj;
      });
      return account;
    } catch (err) {
      const errMessage = periodFiltering
        ? 'No SoWs active on this day :('
        : formatErrorMessage(err, 'Fetching account has failed');

      thunkAPI.dispatch(
        notificationAlert(errMessage, {
          variant: 'error',
        }),
      );

      periodFiltering &&
        thunkAPI.dispatch(cleanAccountsTableRow({ errMessage }));
      throw new Error(String(err));
    }
  },
);

export const calculateAccountMargin = createAsyncThunk(
  `${accountStoreKey}/calculateAccountMargin`,
  async (
    { signal, ...data }: CalculateMarginAccountData & AbortedRequest,
    { dispatch },
  ) => {
    return await errorAbortHandler(
      async () => {
        const endpoint = apiEndpoints.accountMargins(data.id);
        const response = await axios.post<CalculateMarginAccountResult>(
          endpoint,
          data,
          { signal },
        );
        return response.data as CalculateMarginAccountResult;
      },
      {
        signal,
        dispatch,
      },
    );
  },
);

export const calculateProjectMargin = createAsyncThunk(
  `${accountStoreKey}/calculateProjectMargin`,
  async (
    { signal, ...data }: CalculateMarginProjectData & AbortedRequest,
    { dispatch },
  ) => {
    return await errorAbortHandler(
      async () => {
        const endpoint = apiEndpoints.projectMargins(data.id);
        const response = await axios.post<CalculateMarginProjectResult>(
          endpoint,
          data,
          { signal },
        );

        return response.data as CalculateMarginProjectResult;
      },
      {
        signal,
        dispatch,
      },
    );
  },
);

export const fetchCompensationRange = createAsyncThunk(
  `${accountStoreKey}/fetchCompensationRange`,
  async (
    data: {
      seniority: string;
      stream: string;
      legalLocation: string;
      employmentType?: string;
      memberId: string;
    },
    thunkAPI,
  ) => {
    try {
      // TODO return real account data when API is ready
      const endpoint = apiEndpoints.fetchCompensationRange();
      const response = await axios.post(endpoint, {
        seniority: data.seniority,
        stream: data.stream,
        legalLocation: data.legalLocation,
        employmentType: data.employmentType,
      });

      return { data: response.data, memberId: data.memberId };
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(
          formatErrorMessage(err, 'Fetching accounts has failed'),
          {
            variant: 'error',
          },
        ),
      );
      throw new Error(String(err));
    }
  },
);
