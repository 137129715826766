import React, { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import ProjectDetails from './components/ProjectDetails/ProjectDetails';
import { useAppSelector } from '../../redux/hooks';
import { getSelectedAccount, getSelectedProject } from '../../redux/account';
import { useParams } from 'react-router-dom';
import { AccordionsProjects } from './components/AccordionsProjects/AccordionsProjects';
import { useTitle } from '../../hooks/useTitle';
import { PageTitle } from '../../utils/pageTitles';

export const GLOBAL_TRACKER_TEST_ID = 'global-tracker';

const GlobalTracker = (): JSX.Element => {
  const account = useAppSelector(getSelectedAccount);
  const project = useAppSelector(getSelectedProject);
  const { projectId } = useParams();
  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(
      `${account?.name ?? PageTitle.portfolio} | ${PageTitle.projectStatuses}`,
    );
  }, [setPageTitle, account]);

  return (
    <Stack data-testid={GLOBAL_TRACKER_TEST_ID}>
      {projectId ? (
        <ProjectDetails projectId={projectId} proejct={project} mainView />
      ) : account ? (
        <Stack
          width="100%"
          sx={{
            border: '1px solid #e0e0e0',
            height: 'fit-content',
            borderTop: 'none',
            borderRadius: '0px',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
          }}
        >
          <AccordionsProjects projects={account.projects} />
        </Stack>
      ) : null}
    </Stack>
  );
};

export default GlobalTracker;
