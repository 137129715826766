import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountsStatusState } from './accountsStatus.types';
import { accountsStatusKey } from './accountsStatus.const';
import { fetchAccountsStatus } from './accountsStatus.thunks';
import { AccountsStatus } from '../../features/globalTracker/types';
import { sortAccountsAlphabetically } from '../../features/globalTracker/utils';

const initialState: AccountsStatusState = {
  loading: false,
  error: null,
  accountsStatus: [],
};

export const accountsStatusSlice = createSlice({
  name: accountsStatusKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch data
    builder
      .addCase(fetchAccountsStatus.pending, (state) => {
        state.loading = true;
        state.error = initialState.error;
      })
      .addCase(
        fetchAccountsStatus.fulfilled,
        (state, { payload }: PayloadAction<AccountsStatus[]>) => {
          state.loading = false;
          state.accountsStatus = sortAccountsAlphabetically(payload);
        },
      )
      .addCase(fetchAccountsStatus.rejected, (state) => {
        state.loading = false;
        state.error = new Error('Oops. Something went wrong. :(');
      });
  },
});
