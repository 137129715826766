import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import formatDate from 'date-fns/format';

import {
  AccountsStatus,
  AccountsStatusOrProject,
  AssessmentDateContent,
  GeneralAssessment,
  Project,
  ScopeTimelineAssessment,
  assessmentsTypes,
  AssessmentsTypesEnum,
} from './types';

function sortProjectsWithCache(): (client: AccountsStatus) => AccountsStatus {
  const cacheClientIdsWithSortedProjects: string[] = [];

  return (client: AccountsStatus) => {
    if (cacheClientIdsWithSortedProjects.includes(client.id)) {
      return client;
    }

    const sortedProjects = sortDataByGeneralStatus(
      client.projects,
    ) as Project[];
    const clientWithProjectsSorted: AccountsStatus = {
      ...client,
      projects: sortedProjects,
    };

    cacheClientIdsWithSortedProjects.push(client.id);

    return clientWithProjectsSorted;
  };
}

export const sortDataByGeneralStatus = (
  accountsStatus: AccountsStatusOrProject[],
): AccountsStatusOrProject[] =>
  accountsStatus.sort(
    (a, b) =>
      (a.latestGeneralAssessment?.score || 0) -
      (b.latestGeneralAssessment?.score || 0),
  );

export const sortProjectsByGeneralStatus = sortProjectsWithCache();

export const capitalizeFirstLetter = (text: string) =>
  text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);

export const getPreviousMonday = (date: Date) => {
  const prevMonday = new Date(date);

  prevMonday.setDate(date.getDate() - ((date.getDay() + 6) % 7));

  return prevMonday;
};

export const isDateInCurrentWeek = (date: Date) => {
  return (
    formatMonday(getPreviousMonday(date)) ===
    formatMonday(getPreviousMonday(new Date()))
  );
};

// e.g. May 20
export const formatMonday = (monday: Date) => {
  return monday.toLocaleString('default', {
    month: 'short',
    day: 'numeric',
  });
};

function getMondays(
  startDate: Date,
  endDate: Date,
): Map<string, AssessmentDateContent> {
  const mondays = new Map<string, AssessmentDateContent>();
  const prevMonday = getPreviousMonday(new Date(startDate));

  while (prevMonday <= endDate) {
    const mondayFormatted = formatMonday(prevMonday);

    mondays.set(mondayFormatted, {
      GENERAL: {},
      TIMELINE: {},
      SCOPE: {},
    } as AssessmentDateContent);
    prevMonday.setDate(prevMonday.getDate() + 7);
  }

  return mondays;
}

const sortAssessmentsByDateAndType = (
  assessments: (GeneralAssessment | ScopeTimelineAssessment)[],
): (GeneralAssessment | ScopeTimelineAssessment)[] => {
  // Copy assessments parameter is required since it belongs to
  // app's state and the sort method manipulates it directly.
  const assessmentsForSort = [...assessments];

  return assessmentsForSort.sort((a, b) => {
    if (a.forDate === b.forDate) {
      return (
        assessmentsTypes.indexOf(a.type) - assessmentsTypes.indexOf(b.type)
      );
    }

    return new Date(a.forDate).valueOf() - new Date(b.forDate).valueOf();
  });
};

export const sortAccountsAlphabetically = (
  accounts: AccountsStatus[],
): AccountsStatus[] => {
  if (!accounts) return [];

  const accountsWithSortedProjects = accounts.map(
    (account: AccountsStatus) => ({
      ...account,
      projects: sortBy(account.projects, 'name'),
    }),
  );

  return sortBy(accountsWithSortedProjects, 'name');
};

/*
 * This function uses Map to group assessments by period.
 * The date when each period starts is the key and the value
 * is an object with all assessments within that period.
 * E.g.
 * 'Apr 10': {
 *   GENERAL:  { id: 'some id', score: 2, comment: 'some comment' },
 *   TIMELINE: { id: 'some id', score: 1, comment: 'some comment' },
 *   SCOPE:    { id: 'some id', score: 2, comment: 'some comment' },
 * },
 * 'Apr 17': {...},
 * 'Apr 24': {...},
 */
export const parseAssessments = (
  assessments: (GeneralAssessment | ScopeTimelineAssessment)[],
): Map<string, AssessmentDateContent> => {
  const sortedAssessments = sortAssessmentsByDateAndType(assessments);
  const startDate = sortedAssessments[0]?.forDate || new Date();
  const endDate = new Date();

  const isTodayMonday = endDate.getDay() === 1;

  if (isTodayMonday) {
    // add +1 day to current to prevent incorrect previous Monday calculation
    endDate.setDate(endDate.getDate() + 1);
  }

  const parsedAssessments = getMondays(startDate, endDate);

  for (let i = 0; i < sortedAssessments.length; i++) {
    const assessment = sortedAssessments[i];
    const { id, score, comment, forDate } = assessment;
    const previousMondayFormatted = formatMonday(
      getPreviousMonday(new Date(forDate)),
    );
    const currentAssessments = parsedAssessments.get(
      previousMondayFormatted,
    ) as AssessmentDateContent;
    const newAssessment = {
      [assessment.type]: {
        id,
        score,
        comment,
      },
    };

    parsedAssessments.set(previousMondayFormatted, {
      ...currentAssessments,
      ...newAssessment,
    });
  }

  return parsedAssessments;
};

export const checkHasAssessmentData = (assessment: AssessmentDateContent) => {
  for (const assessmentType in assessment) {
    if (isEmpty(assessment[assessmentType as AssessmentsTypesEnum])) {
      return false;
    }
  }
  return true;
};

/**
 * Return two dates, first day of month and last day of month. Month depends on period arg (current, previous, etc.)
 * @param period {TimetrackingPeriod}
 * @returns
 */
export enum TimetrackingPeriod {
  CURRENT_MONTH = 'CURRENT_MONTH',
  PREVIOUS_MONTH = 'PREVIOUS_MONTH',
}

export const getDateRange = (
  period: TimetrackingPeriod,
): {
  startDate: string;
  endDate: string;
} => {
  const today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();

  if (period === TimetrackingPeriod.PREVIOUS_MONTH) {
    if (month === 0) {
      month = 11;
      year--;
    } else {
      month--;
    }
  }

  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);

  const startDate = formatDate(firstDay, 'yyyy-MM-dd');
  const endDate = formatDate(lastDay, 'yyyy-MM-dd');

  return { startDate, endDate };
};
