import {
  GroupAccountMembersBy,
  SortAccountMembersBy,
} from '../../redux/account/account.types';

export const sortAccountMembersOptions = Object.entries(
  SortAccountMembersBy,
).map(([, value]) => ({
  label: value,
  value,
}));

export const groupAccountMembersOptions = Object.entries(
  GroupAccountMembersBy,
).map(([, value]) => ({
  label: value,
  value,
}));

export const sortOptionsForClientPartner = sortAccountMembersOptions.filter(
  (option) =>
    [
      SortAccountMembersBy.Name,
      SortAccountMembersBy.PayRate,
      SortAccountMembersBy.BillRate,
      SortAccountMembersBy.Seniority,
      SortAccountMembersBy.Margin,
    ].includes(option.label),
);
