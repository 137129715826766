import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Link,
  List,
  ListItem,
  Popover,
  PopoverOrigin,
  Typography,
} from '@mui/material';
import { MarginHighlight } from '../../../../types';
import {
  GrossMarginHandlingGuidelineDoc,
  MarginHighlightActions,
  MarginHighlightColor,
} from '../../../../constants/marginTracker';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { titleCase } from '../../../../utils/titleCase';

interface SummaryMarginProps {
  value: number;
  marginHighlights: MarginHighlight[];
}

export const Margin: React.FC<SummaryMarginProps> = ({
  value,
  marginHighlights,
}) => {
  const marginAnchor = React.useRef<HTMLElement | null>(null);
  const gradientHover = React.useRef<HTMLElement | null>(null);
  const [openPopover, setOpenPopover] = React.useState<boolean>(false);
  const [openGradientPopover, setOpenGradientPopover] =
    React.useState<boolean>(false);
  const [gradientMarginHover, setGradientMarginHover] =
    React.useState<MarginHighlight | null>(null);

  const { currentMarginHighlight, sortedMarginHighlights } = useMemo(() => {
    const marginHighlightIndex = marginHighlights.findIndex(
      (marginHighlight) =>
        value != null &&
        (marginHighlight.min ?? -Infinity) <= value &&
        (marginHighlight.max ?? Infinity) >= value,
    );

    const sortedMarginHighlights = Array.from(marginHighlights).sort(
      (a, b) => (a.max ?? Infinity) - (b.max ?? Infinity),
    );

    return {
      currentMarginHighlight: marginHighlights[marginHighlightIndex] ?? {},
      sortedMarginHighlights,
    };
  }, [value, marginHighlights]);

  const themeColor: string =
    MarginHighlightColor[currentMarginHighlight.type || ''] ?? 'text.primary';

  const handlePopoverOpen = useCallback(() => {
    setOpenPopover(true);
  }, [setOpenPopover]);

  const handlePopoverClose = useCallback(() => {
    setOpenPopover(false);
  }, [setOpenPopover]);

  const handleGradientPopoverOpen = useCallback(
    (hoveredBlock?: MarginHighlight) => {
      if (hoveredBlock) {
        setGradientMarginHover(hoveredBlock);
      }
      setOpenGradientPopover(true);
    },
    [setOpenGradientPopover, setGradientMarginHover],
  );

  const handleGradientPopoverClose = useCallback(() => {
    setOpenGradientPopover(false);
  }, [setOpenGradientPopover]);

  return (
    <>
      <Box margin="0px 5px" display="inline-flex" gap="0.5rem">
        <Box display="flex" flexDirection="column" gap="0.25rem">
          <Box
            ref={marginAnchor}
            padding="0.375rem 0.5rem"
            display="inline-flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="0.5rem"
            minWidth="5.25rem"
            bgcolor={themeColor}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={{ cursor: 'default' }}
          >
            <Typography
              variant="h3"
              color="common.white"
              sx={{
                textShadow: '0px 1px 0px #00000040',
              }}
            >
              {value}%
            </Typography>
          </Box>
          <Box
            display="inline-flex"
            margin="0 0.375rem 0"
            borderRadius="0.125rem"
            height="0.25rem"
            sx={{
              '& :first-of-type(div)': {
                borderTopLeftRadius: '0.25rem',
                borderBottomLeftRadius: '0.25rem',
              },
              '& :last-of-type(div)': {
                borderTopRightRadius: '0.25rem',
                borderBottomRightRadius: '0.25rem',
              },
              backgroundImage: `linear-gradient(to right, ${sortedMarginHighlights
                .map((smh) => MarginHighlightColor[smh.type])
                .join(', ')})`,
              '&:hover': {
                transform: 'scaleY(1.5)',
                transition: 'all 0.15s ease-in-out',
              },
            }}
          >
            {sortedMarginHighlights.map((smh) => (
              <Box
                ref={gradientHover}
                key={smh.type}
                flex="1"
                onMouseEnter={() => handleGradientPopoverOpen(smh)}
                onMouseLeave={handleGradientPopoverClose}
              />
            ))}
          </Box>
        </Box>
        <InfoOutlinedIcon
          color="secondary"
          sx={{ fontSize: '1.25rem', marginTop: '0.4rem' }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      </Box>
      <MarginPopover
        anchorEl={marginAnchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        marginHighlight={currentMarginHighlight}
        open={openPopover}
        transformOrigin={{
          vertical: 75,
          horizontal: -8,
        }}
        handlePopoverOpen={handlePopoverOpen}
        handlePopoverClose={handlePopoverClose}
      />
      <MarginPopover
        anchorEl={gradientHover}
        anchorOrigin={{
          vertical: 10,
          horizontal: 'center',
        }}
        marginHighlight={gradientMarginHover}
        open={openGradientPopover}
        transformOrigin={{
          vertical: 'top',
          horizontal: 100,
        }}
        handlePopoverOpen={handleGradientPopoverOpen}
        handlePopoverClose={handleGradientPopoverClose}
      />
    </>
  );
};

interface Props {
  anchorEl: React.MutableRefObject<HTMLElement | null>;
  anchorOrigin: PopoverOrigin;
  marginHighlight: MarginHighlight | null;
  open: boolean;
  transformOrigin: PopoverOrigin;
  handlePopoverOpen: () => void;
  handlePopoverClose: () => void;
}

const MarginPopover: React.FC<Props> = ({
  anchorEl,
  anchorOrigin,
  marginHighlight,
  open,
  transformOrigin,
  handlePopoverOpen,
  handlePopoverClose,
}) => {
  const { header } = useMemo(() => {
    let header = `${marginHighlight?.min} .. ${marginHighlight?.max}%`;

    if (marginHighlight?.min == null) {
      header = `${marginHighlight?.max}% and below`;
    } else if (marginHighlight?.max == null) {
      header = `${marginHighlight?.min}% and above`;
    }

    return { header };
  }, [marginHighlight]);

  const themeColor: string =
    MarginHighlightColor[marginHighlight?.type || ''] ?? 'text.primary';

  return (
    <Popover
      style={{ pointerEvents: 'none' }}
      open={open}
      anchorEl={anchorEl.current}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={{
        onMouseEnter: () => handlePopoverOpen(),
        onMouseLeave: () => handlePopoverClose(),
        sx: {
          pointerEvents: 'auto',
          display: 'inline-flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '1.5rem 1.5rem 2.5rem 1.5rem',
          gap: '1.5rem',
          borderTop: `0.25rem solid ${themeColor}`,
          width: '18.75rem',
        },
      }}
    >
      <Box
        display="flex"
        padding="0 0.5rem"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Typography variant="body2" color="text.secondary">
          {header}
        </Typography>
        <Typography variant="h3">
          “{titleCase((marginHighlight?.type ?? '').toLowerCase())}” zone
        </Typography>
      </Box>
      <Box
        display="flex"
        gap="0.75rem"
        padding="0 0.5rem"
        flexDirection="column"
        alignItems="flex-start"
        alignSelf="stretch"
      >
        <Typography color="text.secondary" fontSize="0.68rem">
          Possible actions
        </Typography>
        <List
          sx={{
            padding: '0 0 0 0.875rem',
            listStyle: 'disc',
            '& :last-child': {
              marginBottom: 0,
            },
          }}
        >
          {(MarginHighlightActions[marginHighlight?.type ?? ''] ?? []).map(
            (action, index) => (
              <ListItem
                key={`margin-action-${index}`}
                sx={{
                  display: 'list-item',
                  marginBottom: '0.75rem',
                  padding: 0,
                }}
              >
                <Typography display="inline" variant="body1">
                  {action}
                </Typography>
              </ListItem>
            ),
          )}
        </List>
      </Box>
      <Box
        display="flex"
        padding="0 0.5rem"
        flexDirection="column"
        alignItems="flex-start"
        alignSelf="stretch"
      >
        <Typography color="text.secondary" fontSize="0.68rem">
          Learn more
        </Typography>
        <Link
          href={GrossMarginHandlingGuidelineDoc}
          variant="body1"
          target="_blank"
        >
          Gross margin handling guidelines↗
        </Link>
      </Box>
    </Popover>
  );
};
