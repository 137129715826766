import { SortAccountMembersBy } from '../../redux/account';

export const columns = [
  'Margin',
  'Pay rate',
  'Pay range',
  'Bill rate',
  'Ratecard',
  'Allocation',
];

export const compensationManagerColumns = ['Base', 'Expected', 'Company range'];

export const sortableColumns: Record<string, SortAccountMembersBy> = {
  'Team member': SortAccountMembersBy.Name,
  'Pay rate': SortAccountMembersBy.PayRate,
  Margin: SortAccountMembersBy.Margin,
  'Bill rate': SortAccountMembersBy.BillRate,
  Seniority: SortAccountMembersBy.Seniority,
};
