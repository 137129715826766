import React, { useEffect, useMemo } from 'react';
import AddIconRounded from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ContractorType } from '../../types/contractor';
import { Button, Box } from '@mui/material';
import { FilterPeriodModel } from './Contractor.types';
import { ButtonMenuGroup } from '../../components';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import { getFilterPeriodOptions } from './Contractor.utils';
import { PeriodType } from '../../types';
import { FinKitAvatar } from '../../components/finKitAvatar';
import { PageTitle } from '../../utils/pageTitles';
import { useTitle } from '../../hooks/useTitle';

interface Props {
  contractorData: ContractorType;
  handleAssignMetric?: () => void;
  filteredPeriod: FilterPeriodModel;
  setFilteredPeriod: (value: FilterPeriodModel | null) => void;
  hideAssignMetric?: boolean;
  hidePeriodFilter?: boolean;
  hideHistoricalEdit?: boolean;
  currentPeriod: PeriodType | null;
  userCanEditHistoricalData: boolean | undefined;
  toggleEditHistoricalData: () => void;
}

const ContractorDetails: React.FC<Props> = ({
  contractorData,
  handleAssignMetric,
  filteredPeriod,
  setFilteredPeriod,
  hideAssignMetric,
  hidePeriodFilter,
  hideHistoricalEdit,
  currentPeriod,
  userCanEditHistoricalData,
  toggleEditHistoricalData,
}) => {
  const setPageTitle = useTitle();
  useEffect(() => {
    setPageTitle(`${contractorData?.fullName} ${PageTitle.bonuses}`);
  }, [setPageTitle, contractorData?.fullName]);
  const filterPeriodOptions = useMemo(
    () => getFilterPeriodOptions(currentPeriod),
    [currentPeriod],
  );

  const menuWidth = '240px';

  return (
    <Stack direction="row" justifyContent="space-between" mb={2}>
      <Stack direction="row" spacing={4}>
        <Stack direction="row" spacing={2}>
          <FinKitAvatar
            medium
            fullName={contractorData?.fullName ?? ''}
            imageUrl={contractorData?.avatarUrl}
            country={contractorData?.country}
            city={contractorData?.city}
          />
          <Stack justifyContent="end">
            {contractorData.fullName ? (
              <Typography variant="h2" mb={0.25}>
                {contractorData.fullName}
              </Typography>
            ) : (
              <Typography variant="h2" color="text.secondary">
                {'(name is missing)'}
              </Typography>
            )}
            <Typography variant="body2">
              {contractorData.designation}
            </Typography>
          </Stack>
        </Stack>
        <Stack justifyContent="end">
          <Typography variant="body2">Legal location</Typography>
          <Typography>{contractorData.legalLocation}</Typography>
        </Stack>
        <Stack justifyContent="end">
          <Typography variant="body2">Physical location</Typography>
          <Typography>
            {contractorData.city && `${contractorData.city}, `}
            {contractorData.country}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        justifyContent="end"
        direction="row"
        alignItems="end"
        sx={{ marginBottom: '-6px' }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{ height: 'fit-content' }}
        >
          {!hideHistoricalEdit && userCanEditHistoricalData && (
            <Box sx={{ marginRight: '8px' }}>
              <Button
                variant="contained"
                startIcon={<EditRoundedIcon fontSize="small" />}
                onClick={toggleEditHistoricalData}
              >
                Edit historical bonuses
              </Button>
            </Box>
          )}
          {!hideAssignMetric && (
            <Box sx={{ marginRight: '8px' }}>
              <Button
                variant="contained"
                startIcon={<AddIconRounded fontSize="small" />}
                onClick={handleAssignMetric}
              >
                Assign metric
              </Button>
            </Box>
          )}

          {!hidePeriodFilter && (
            <Box sx={{ width: menuWidth }}>
              <ButtonMenuGroup<FilterPeriodModel>
                id="selectedPeriod"
                options={filterPeriodOptions}
                value={filteredPeriod}
                getOptionLabel={(option) => option.label}
                getOptionSubtext={(option) => option.subtext}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
                onChange={(_, value) => setFilteredPeriod(value)}
                color="highlight"
                typographyVariant="body1"
                fullWidth
                menuWidth={menuWidth}
                startIcon={<EventNoteRoundedIcon style={{ opacity: '0.6' }} />}
              />
            </Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ContractorDetails;
