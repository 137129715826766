export const SCREEN_WIDTH = {
  MOBILE: 859,
  DESKTOP: 1024,
  DESKTOP13: 1280,
};

export const MAXWIDTH = {
  CONTAINER: 1400,
};

export const BREAKPOINTS = {
  DESKTOP: `@media (minWidth: ${SCREEN_WIDTH.DESKTOP}px)`,
  TABLET: `@media (maxWidth: ${SCREEN_WIDTH.DESKTOP - 1}px)`,
  MOBILE: `@media (maxWidth: ${SCREEN_WIDTH.MOBILE}px)`,
  DESKTOP13: `@media (maxWidth: ${SCREEN_WIDTH.DESKTOP13}px)`,
};
