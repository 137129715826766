import React, { FC, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProjectDetails from '../ProjectDetails/ProjectDetails';
import { ProjectDetail } from '../../../../types';
import { Link as ReactRouterLink } from 'react-router-dom';

interface Props {
  projects: ProjectDetail[];
}

export const AccordionsProjects: FC<Props> = ({ projects }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    if (projects.length === 1) {
      setExpanded(projects[0].id);
    }
  }, [projects]);

  return (
    <>
      {projects.map((project) => (
        <Accordion
          key={project.id}
          expanded={expanded === project.id}
          onChange={handleChange(project.id)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Link
              component={ReactRouterLink}
              to={`../${project.id}`}
              sx={{
                fontSize: '16px',
                textDecoration: 'none',
              }}
            >
              {project.name}
            </Link>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '0px' }}>
            {expanded === project.id && (
              <ProjectDetails projectId={project.id} proejct={project} />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
