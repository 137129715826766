import * as yup from 'yup';

export const approvalsValidationSchema = yup
  .array(
    yup.object({
      id: yup.string().required(),
      value: yup
        .number()
        .typeError('must be a `number`')
        .min(0, 'min 0')
        .max(100, 'max 100')
        .nullable(),
    }),
  )
  .strict();
