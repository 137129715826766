import React from 'react';
import { NumericFormat } from 'react-number-format';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { plural, is } from '../../../../utils';
import { tableInputWidth } from '../../../../theme';
import { KPI_TYPES, KpiStatus } from '../../../../constants';
import {
  PeopleIconWrapper,
  MemberInfoCell,
} from '../../../../components/tableCellRenders';
import { TableCellsByKpiType } from '../../../../components/customTable';
import { BonusTableCell } from '../../../../components/tableCellRenders/bonusTableCell';
import { GeneralKpiModel } from '../../../../types';
import {
  checkNewKpiBonus,
  submissionsSelectors,
  submissionsSlice,
} from '../../../../redux/submissions';
import { debounce } from '../../../../utils/debounce';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { CircularProgress, TableHead } from '@mui/material';
import { periodsSelectors } from '../../../../redux/periods';

interface Props {
  handleKpiValuesUpdate: (value: number | undefined, index: number) => void;
  kpis: GeneralKpiModel[];
  hideHeader?: boolean;
}

const SubmissionsTable: React.FC<Props> = ({
  handleKpiValuesUpdate,
  kpis,
  hideHeader = false,
}) => {
  const dispatch = useAppDispatch();

  const isPercentageValue = (kpi: GeneralKpiModel) =>
    kpi.kpiDefinition.type === KPI_TYPES.VARIABLE_PERCENTAGE ||
    kpi.kpiDefinition.type === KPI_TYPES.FIXED_PERCENTAGE;

  const isUpdateDisabled = (kpi: GeneralKpiModel) =>
    kpi.status === KpiStatus.Submitted || kpi.status === KpiStatus.Approved;

  const isWarning = (kpi: GeneralKpiModel) => typeof kpi.value !== 'number';

  const loadingKpiId = useAppSelector(submissionsSelectors.getLoadingKpiId);
  const newBonusShares = useAppSelector(submissionsSelectors.getNewBonusShares);
  const currentPeriod = useAppSelector(periodsSelectors.getCurrentPeriod);

  const checkNewBonusShare = (id: string, newValue: string | number) => {
    if (newValue === '') {
      debounce.decline();
      dispatch(submissionsSlice.actions.setLoadingKpiId());
      return;
    }

    if (loadingKpiId !== id) {
      dispatch(submissionsSlice.actions.setLoadingKpiId(id));
    }

    debounce(() => {
      dispatch(
        checkNewKpiBonus({
          id,
          value: newValue,
          period: currentPeriod?.period ?? 0,
        }),
      );
    }, 500);
  };

  const loader = (
    <CircularProgress
      color="inherit"
      size={20}
      sx={{ position: 'absolute', top: '28px', left: '-10px' }}
    />
  );

  return (
    <Paper variant="outlined" sx={{ mt: 1, mb: 3 }}>
      <Table stickyHeader aria-label="submissions-table">
        {!hideHeader && (
          <TableHead>
            <TableRow>
              <TableCell>Team member</TableCell>
              <TableCell>Metric</TableCell>
              <TableCell align="right">Payout</TableCell>
              <TableCell>Worst</TableCell>
              <TableCell>Best</TableCell>
              <TableCell align="right">Result</TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {kpis.map((kpi: GeneralKpiModel, index: number) => (
            <TableRow key={`${kpi.kpiDefinition.id}_${index}`}>
              {!is.sharedMetric(kpi) && 'user' in kpi ? (
                <TableCell>
                  <MemberInfoCell
                    userInfo={{ ...kpi.user, stream: kpi.user.designation }}
                    disabled={!!kpi.user.deactivatedAt}
                  />
                </TableCell>
              ) : null}
              <TableCell>
                {is.sharedKpiType(kpi) ? (
                  <PeopleIconWrapper isImage>
                    <Typography variant="body1" fontWeight={500}>
                      {kpi.kpiDefinition.name}
                    </Typography>
                    {'userSharedKpis' in kpi && (
                      <Typography variant="body2">
                        {kpi.userSharedKpis.length} member
                        {plural(kpi.userSharedKpis.length)}
                      </Typography>
                    )}
                  </PeopleIconWrapper>
                ) : (
                  <Typography variant="body1">
                    {kpi.kpiDefinition.name}
                  </Typography>
                )}
              </TableCell>
              {!is.sharedMetric(kpi) && (
                <>
                  <TableCell
                    sx={{
                      width: '1px',
                      whiteSpace: 'nowrap',
                      position: 'relative',
                    }}
                  >
                    <BonusTableCell
                      disabled={kpi.id === loadingKpiId}
                      value={
                        newBonusShares[kpi.id] === undefined
                          ? kpi.payout ?? kpi.bonusShare
                          : newBonusShares[kpi.id].payout
                      }
                      variant="body1"
                      defaultEmptyValue=""
                      frequency={kpi.kpiDefinition.frequency}
                      nowrap
                      alignRight
                      trueup={
                        newBonusShares[kpi.id] === undefined
                          ? kpi.trueup
                          : newBonusShares[kpi.id].trueup
                      }
                    >
                      {kpi.id === loadingKpiId && loader}
                    </BonusTableCell>
                  </TableCell>

                  <TableCellsByKpiType
                    type={kpi.kpiDefinition.type}
                    bestCase={kpi.bestCase}
                    worstCase={kpi.worstCase}
                    sx={{ width: '1px', whiteSpace: 'nowrap' }}
                    align={'right'}
                  />
                </>
              )}
              <TableCell>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="end"
                  spacing={1}
                >
                  {isUpdateDisabled(kpi) && (
                    <LockOutlinedIcon color="disabled" />
                  )}
                  <NumericFormat
                    allowNegative={false}
                    decimalScale={2}
                    autoComplete="off"
                    customInput={TextField}
                    disabled={isUpdateDisabled(kpi)}
                    id="value"
                    name="value"
                    hiddenLabel
                    size="small"
                    value={kpi.value ?? null}
                    variant="filled"
                    onValueChange={({ floatValue }) => {
                      checkNewBonusShare(kpi.id, floatValue ?? '');
                      handleKpiValuesUpdate(floatValue, index);
                    }}
                    FormHelperTextProps={{
                      sx: { mr: 0 },
                    }}
                    InputProps={{
                      endAdornment: isPercentageValue(kpi) && (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      sx: {
                        bgcolor: isWarning(kpi) ? 'highlight.main' : undefined,
                        '&:before': {
                          borderBottomColor: isWarning(kpi)
                            ? 'highlight.dark'
                            : undefined,
                        },
                      },
                    }}
                    sx={{ width: tableInputWidth }}
                  />
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default SubmissionsTable;
