import React, { FC } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import Tooltip from '@mui/material/Tooltip';

import { SCORE_TO_COLOR } from '../../../../../constants';
import {
  AssessmentsScoreColorEnum,
  AssessmentsTypesEnum,
} from '../../../types';
import { capitalizeFirstLetter } from '../../../utils';
import { IconStyle, StyledStatusDiv } from './styles';
import { ASSESSMENT_TYPE_SCORE_TO_TOOLTIP } from '../../../constants';

interface Props {
  comment: string | null;
  score: AssessmentsScoreColorEnum;
  type: AssessmentsTypesEnum;
}

export const ASSESSMENT_DETAIL_ITEM_TEST_ID = 'assessment-detail-item';

const AssessmentDetailItem: FC<Props> = ({ comment, score, type }) => {
  const getIcon = () => {
    switch (score) {
      case AssessmentsScoreColorEnum.GREEN:
        return <CheckCircleRoundedIcon sx={IconStyle} />;
      case AssessmentsScoreColorEnum.YELLOW:
        return <WarningRoundedIcon sx={IconStyle} />;
      case AssessmentsScoreColorEnum.RED:
        return <ErrorRoundedIcon sx={IconStyle} />;
    }
  };

  const hasComment = !!comment;

  return (
    <Box data-testid={ASSESSMENT_DETAIL_ITEM_TEST_ID}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={0.5}
        sx={{ mb: '1rem', cursor: 'pointer' }}
      >
        <Typography sx={{ width: '15%' }}>
          {capitalizeFirstLetter(type)}:
        </Typography>
        <Tooltip
          arrow
          placement="top"
          title={ASSESSMENT_TYPE_SCORE_TO_TOOLTIP[type][score]}
        >
          <StyledStatusDiv
            className={`${SCORE_TO_COLOR[score]} ${type.toLowerCase()}`}
          >
            {type === AssessmentsTypesEnum.GENERAL && getIcon()}
            {capitalizeFirstLetter(SCORE_TO_COLOR[score])}
          </StyledStatusDiv>
        </Tooltip>
      </Stack>
      <Typography
        color="text.secondary"
        sx={hasComment ? { mb: 1 } : { mb: 4 }}
      >
        {hasComment ? 'Notes' : 'Notes not provided'}
      </Typography>
      {hasComment && (
        <Typography sx={{ whiteSpace: 'pre-wrap', mb: '1rem' }}>
          {comment}
        </Typography>
      )}
    </Box>
  );
};

export default AssessmentDetailItem;
