import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNextRounded';
import { ContractorType } from '../../types';
import { MemberInfoCell } from '../../components/tableCellRenders';
import { useTheme } from '@mui/material';

interface Props {
  contractor: ContractorType;
  handleClick: (departmentId: string, contractorId: string) => void;
  topLevel?: boolean;
}

const ContractorCard: React.FC<Props> = ({
  contractor,
  handleClick,
  topLevel = false,
}) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        mb: 1,
        '&:hover': {
          cursor: 'pointer',
          background: palette.action.hover,
        },
        ...(topLevel
          ? { mb: 0.25, border: 'none', background: palette.background.paper }
          : {}),
      }}
      onClick={() => handleClick(contractor.departmentId, contractor.id)}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        pl={topLevel ? 2 : 8}
      >
        <MemberInfoCell
          userInfo={{ ...contractor, stream: contractor.designation }}
        />
        <NavigateNextIcon sx={{ color: 'text.secondary' }} />
      </Stack>
    </Box>
  );
};

export default ContractorCard;
