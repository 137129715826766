import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { kpiDefinitionsStoreKey } from './kpiDefinitions.const';
import { apiEndpoints } from '../../constants/api';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { KpiDefinitionUpdateData } from './kpiDefinitions.types';
import { KpiDefinitionRequestModel } from '../../types';

export const fetchKpiDefinitions = createAsyncThunk(
  `${kpiDefinitionsStoreKey}/fetchKpiDefinitions`,
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(apiEndpoints.kpiDefinition());

      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);

export const saveKpiDefinition = createAsyncThunk(
  `${kpiDefinitionsStoreKey}/saveKpiDefinition`,
  async (
    { id, data }: { id: string; data: KpiDefinitionUpdateData },
    thunkAPI,
  ) => {
    try {
      const response = await axios.patch(
        apiEndpoints.kpiDefinitionId(id),
        data,
      );
      thunkAPI.dispatch(
        notificationAlert(`"${response.data?.name}" was updated`),
      );
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);

export const fetchAvailableKpiOwners = createAsyncThunk(
  `${kpiDefinitionsStoreKey}/fetchAvailableKpiOwners`,
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(apiEndpoints.kpiDefinitionOwners());
      return {
        data: response.data,
      };
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);

export const createNewKpiDefinition = createAsyncThunk(
  `${kpiDefinitionsStoreKey}/createNewKpiDefinition`,
  async (body: KpiDefinitionRequestModel, thunkAPI) => {
    try {
      const response = await axios.post(apiEndpoints.kpiDefinition(), body);

      thunkAPI.dispatch(notificationAlert(`New metric created`));

      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);

export const fetchKpiDefinitionsCategories = createAsyncThunk(
  `${kpiDefinitionsStoreKey}/fetchKpiDefinitionsCategories`,
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(apiEndpoints.kpiDefinitionCategories());

      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);
