import React, { FC, ReactNode } from 'react';

import {
  Typography,
  Link,
  ListItem,
  Box,
  List,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { TERMS_OF_USE_VERSION } from './constants';
import { COLORS } from '../../utils/colors';

function CustomListItem({ children }: { children: ReactNode }) {
  return (
    <ListItem
      sx={{
        marginLeft: '14px',
        display: 'list-item',
        padding: 0,
        paddingLeft: '8px',
        lineHeight: '140%',
        marginBottom: '12px',
      }}
    >
      {children}
    </ListItem>
  );
}

/**
 * Generated from https://docs.google.com/document/d/1uOk1-tHvZTOrfWF_VEZmKqOvsMFqfP4l9FmnKO7kabg
 * Download as .html & adapted for React
 */
export const TermsOfUseText: FC = () => {
  return (
    <Box
      component={'section'}
      pb={6}
      sx={{
        fontWeight: 400,
        letterSpacing: 0,
        fontSize: '18px',
        lineHeight: 1.5,
      }}
    >
      <Typography variant="h1" mb={2}>
        Terms of use
      </Typography>
      <p>
        AgileEngine internal websites, Rocky Finance
        <br />
        Version {TERMS_OF_USE_VERSION} November 1, 2023
      </p>
      <List
        sx={{
          listStyle: 'upper-roman',
          paddingLeft: '6px',
          my: 4,
          pb: 4,
        }}
      >
        <CustomListItem>
          <Link color={COLORS.TEXT.PRIMARY} href="#preamble">
            Preamble
          </Link>
        </CustomListItem>
        <CustomListItem>
          <Link color={COLORS.TEXT.PRIMARY} href="#definitions">
            Definitions
          </Link>
        </CustomListItem>
        <CustomListItem>
          <Link color={COLORS.TEXT.PRIMARY} href="#rights-and-obligations">
            Rights and obligations of the website users
          </Link>
        </CustomListItem>
        <CustomListItem>
          <Link color={COLORS.TEXT.PRIMARY} href="#communication">
            Communication with AgileEngine
          </Link>
        </CustomListItem>
        <CustomListItem>
          <Link color={COLORS.TEXT.PRIMARY} href="#messages">
            Messages and newsletters from AgileEngine
          </Link>
        </CustomListItem>
        <CustomListItem>
          <Link color={COLORS.TEXT.PRIMARY} href="#intellectual-rights">
            Intellectual property rights
          </Link>
        </CustomListItem>
        <CustomListItem>
          <Link color={COLORS.TEXT.PRIMARY} href="#data">
            Personal data
          </Link>
        </CustomListItem>
        <CustomListItem>
          <Link color={COLORS.TEXT.PRIMARY} href="#cookies">
            Cookies policy
          </Link>
        </CustomListItem>
        <CustomListItem>
          <Link color={COLORS.TEXT.PRIMARY} href="#datastorage">
            Personal data storage
          </Link>
        </CustomListItem>
        <CustomListItem>
          <Link color={COLORS.TEXT.PRIMARY} href="#responsibility">
            Responsibility
          </Link>
        </CustomListItem>
        <CustomListItem>
          <Link color={COLORS.TEXT.PRIMARY} href="#misc">
            Miscellaneous
          </Link>
        </CustomListItem>
      </List>
      <Typography variant="h2" id="preamble">
        I. Preamble
      </Typography>
      <p>
        Terms of&nbsp;Use (hereinafter – ToU) provides information on&nbsp;the
        rules applicable to&nbsp;use of&nbsp;the Websites published
        by&nbsp;AgileEngine companies and the the rules applicable
        to&nbsp;processing of&nbsp;your personal data.
      </p>
      <p>
        ToU are not applicable to&nbsp;relationships between AgileEngine and the
        Users related to&nbsp;provision and order of&nbsp;AgileEngine positions
        and/or Services (the respective information is&nbsp;provided
        on&nbsp;AgileEngine Websites).
      </p>
      <p>
        ToU shall be&nbsp;followed both by&nbsp;AgileEngine and the Users.
        AgileEngine retains discretion to&nbsp;amend ToU in&nbsp;part
        or&nbsp;in&nbsp;full. ToU, its new version and the respective amendments
        are effective commencing publication on&nbsp;the Websites.
      </p>
      <p>
        By&nbsp;accessing the Websites the Users agree to&nbsp;comply with ToU
        presented in&nbsp;the version, which is&nbsp;effective at&nbsp;the
        moment of&nbsp;such access. Please comply with ToU, otherwise please
        terminate immediately the use of&nbsp;the Websites of&nbsp;AgileEngine.
      </p>
      <p>
        The rightsholder of&nbsp;all rights related to&nbsp;the Websites and the
        respective Content is&nbsp;AgileEngine.
      </p>
      <p>
        The Websites do&nbsp;not contain a&nbsp;comment posting option.
        To&nbsp;communicate with AgileEngine the Users may use the communicating
        options available on&nbsp;the AgileEngine Websites for this purpose.
      </p>
      <p>
        Access to&nbsp;AgileEngine Websites and the respective Services
        is&nbsp;provided exclusively for the Users over the age of&nbsp;16
        years-old.
      </p>
      <Typography variant="h2" id="definitions" mt={6}>
        II. Definitions
      </Typography>
      <p>
        <strong>AgileEngine</strong> means all companies related
        to&nbsp;AgileEngine LLC.
      </p>
      <p>
        <strong>Content</strong>
        &nbsp;means all static and dynamic elements, information, texts, images,
        audio and video files, databases, trademarks and logos, technical and
        graphic solutions inserted to&nbsp;the Websites.
      </p>
      <p>
        <strong>Cookies</strong>
        <span>
          &nbsp;mean text files sent from the Websites to&nbsp;the Users’
          electronic devices and saved with web-browsers. Such files do&nbsp;not
          identify the Users, but fix information on&nbsp;their actions related
          to use of&nbsp;the Websites. Such information is&nbsp;collected for
          improvement of operation of&nbsp;the Websites and customization
          of&nbsp;the respective settings: monitoring preferences of&nbsp;the
          Users, “auto-fill” option, sharing information in&nbsp;social media,
          customization of&nbsp;Services to&nbsp;the Users’ preferences,
          collection of&nbsp;general statistic information on&nbsp;use
          of&nbsp;the Websites.
        </span>
      </p>
      <p>
        <strong>Personal data</strong> means the personal data collected via the
        Websites, i.e. the information that directly or indirectly identifies
        the Users.
      </p>
      <p>
        <strong>Service(s)</strong>
        <span>
          &nbsp;means any service and related functions which are provided by
          AgileEngine in favor of the Users complimentary/for some fees
          available for order on the Websites (if applicable) and or business
          relations.
        </span>
      </p>
      <p>
        <strong>Terms of Use</strong>
        <span>&nbsp;means the rules governing use of the Websites.</span>
      </p>
      <p>
        <strong>User</strong>
        <span>
          &nbsp;means any natural person or legal entity gaining access,
          browsing or visiting the Websites with or without order of the
          Services.
        </span>
      </p>
      <p>
        <strong>Use of AgileEngine Websites</strong>
        <span>
          &nbsp;means any web-browsing, downloading or any other use of the
          Content of AgileEngine Websites, use of the Services provided by
          AgileEngine.
        </span>
      </p>
      <p>
        <strong>Websites</strong>
        <span>
          &nbsp;mean the websites published by AgileEngine (inclusively&nbsp;
          <Link href="https://rocky.agileengine.com">
            rocky.agileengine.com
          </Link>
        </span>
        ).
      </p>
      <Typography variant="h2" id="rights-and-obligations" mt={6}>
        III. Rights and obligations of the users of the websites
      </Typography>
      <p>
        The Websites can be&nbsp;used exclusively for the following purposes:
      </p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch', listStyleType: '"•"' }}>
        <CustomListItem>
          to&nbsp;browse-up with the presented Content of&nbsp;AgileEngine;
        </CustomListItem>
        <CustomListItem>
          to&nbsp;get the Services of&nbsp;AgileEngine;
        </CustomListItem>
        <CustomListItem>
          to&nbsp;manage specifics on&nbsp;the Services of&nbsp;AgileEngine;
        </CustomListItem>
        <CustomListItem>
          to&nbsp;submit necessary information for Website access;
        </CustomListItem>
        <CustomListItem>
          to&nbsp;communicate AgileEngine with requests, comments, complaints
          and suggestions sent by&nbsp;messages, via feedback form on&nbsp;the
          Websites, and further response from AgileEngine;
        </CustomListItem>
        <CustomListItem>
          for other purposes provided via the Websites of&nbsp;AgileEngine;
        </CustomListItem>
        <CustomListItem>
          other communication related to the functionality of the Websites with
          the approved access to it.
        </CustomListItem>
      </List>
      <p>
        It&nbsp;is&nbsp;illegitimate to&nbsp;use the Websites for the other
        purposes than mentioned above.
      </p>
      <p>The Users are obliged to&nbsp;the following:</p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch', listStyleType: '"•"' }}>
        <CustomListItem>
          to&nbsp;follow ToU and the legislative provisions of&nbsp;the
          applicable law;
        </CustomListItem>
        <CustomListItem>
          to&nbsp;use the Websites exclusively for the purposes mentioned
          hereto;
        </CustomListItem>
        <CustomListItem>
          not to&nbsp;use in&nbsp;any way the Content of&nbsp;the Websites for
          commercial or non-commercial purposes, not to&nbsp;decompile the
          Websites, not to&nbsp;resort to&nbsp;reverse-engineering,
          to&nbsp;disassociate or&nbsp;attempts to&nbsp;interfere with the
          source code of&nbsp;the Websites without prior permission of
          AgileEngine;
        </CustomListItem>
        <CustomListItem>
          not to&nbsp;use the Websites for sending any messages of&nbsp;violent,
          abusive, disparaging nature or&nbsp;those that in&nbsp;any other way
          infringing the honor, dignity and business reputation
          of&nbsp;AgileEngine or&nbsp;third parties, as&nbsp;well
          as&nbsp;transmitting unsolicited commercial messages (“spam”);
        </CustomListItem>
        <CustomListItem>
          not to&nbsp;use software to&nbsp;interfere or&nbsp;attempt
          to&nbsp;interfere in&nbsp;the operation of&nbsp;the Websites.
        </CustomListItem>
      </List>
      <Typography variant="h2" id="communication" mt={6}>
        IV. Communication with AgileEngine
      </Typography>
      <p>The Users may contact AgileEngine:</p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>
          via sending message (request or complaint) through the feedback form
          on the Websites;
        </ListItem>
        <ListItem>
          via sending emails on{' '}
          <Link href="mailto:policy@agileengine.com">
            policy@agileengine.com
          </Link>{' '}
          or relevant emails on the Websites.
        </ListItem>
      </List>
      <p>
        To&nbsp;contact AgileEngine and get feedback the Users provide
        AgileEngine with the following scope of&nbsp;Personal data: name,
        surname, email address, telephone number, city and state
        of&nbsp;residence. Detailed information on collection and processing
        of&nbsp;Personal data as&nbsp;well as&nbsp;terms of&nbsp;transfer
        of&nbsp;Personal data to&nbsp;third parties is&nbsp;provided in&nbsp;the
        relevant section of the ToU and additionally on&nbsp;the main Website:{' '}
        <Link href="https://agileengine.com/privacy-policy/">
          agileengine.com/privacy-policy
        </Link>
      </p>
      <p>
        It&nbsp;is&nbsp;prohibited to&nbsp;file the following information via
        the Websites:
      </p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>
          any messages of&nbsp;a&nbsp;violent, abusive, disrespectful nature
          or&nbsp;any other messages, violating of&nbsp;the right to&nbsp;the
          honor, dignity and business reputation of&nbsp;AgileEngine
          or&nbsp;third parties;
        </ListItem>
        <ListItem>unsolicited commercial messages (“spam”).</ListItem>
      </List>
      <p>
        In&nbsp;case of&nbsp;sending such messages by&nbsp;the Users AgileEngine
        is&nbsp;entitled not to&nbsp;respond to&nbsp;such messages, as&nbsp;well
        as&nbsp;to&nbsp;deprive the Users to send such messages to&nbsp;use the
        feedback option further.
      </p>
      <p>
        Communication between the Users and AgileEngine is&nbsp;processed with
        technical fixing tools. The records of&nbsp;message exchange conducted
        between the Users and AgileEngine, are stored by&nbsp;AgileEngine to
        monitor the quality of&nbsp;services as&nbsp;well as&nbsp;to&nbsp;make
        subsequent changes to&nbsp;operations and Services of&nbsp;AgileEngine.
        The Users own any messages, information and materials sent via feedback
        forms on&nbsp;the Websites.
      </p>
      <Typography variant="h2" id="messages" mt={6}>
        V. Messages and newsletters from AgileEngine
      </Typography>
      <p>
        <strong>Messages from AgileEngine</strong>
      </p>
      <p>
        The Users may receive the following messages from AgileEngine responding
        to the requests or complaints sent via feedback forms on the Websites.
      </p>
      <p>
        AgileEngine sends SMS, emails, messages in&nbsp;messengers, messages
        in&nbsp;the feedback forms.
      </p>
      <p>
        The Users can manage the option of&nbsp;messages sending with
        AgileEngine Websites settings (depending on&nbsp;the functionality and
        the internal Websites settings).
      </p>
      <p>
        AgileEngine also provides newsletters to&nbsp;the Users of&nbsp;the
        Websites with information related to&nbsp;AgileEngine.
      </p>
      <p>
        <strong>Newsletters</strong>
      </p>
      <p>
        Newsletters are sent exclusively to the contacts included in the
        database of the Users’ Personal data previously provided to AgileEngine
        on the basis of consent on the Websites via newsletter subscription, via
        agreement or in another legal way.
      </p>
      <p>
        AgileEngine sends newsletters via SMS, emails, messengers if they are
        provided, and messages in&nbsp;User account chat.
      </p>
      <p>
        Newsletters content is&nbsp;formed by&nbsp;AgileEngine
        in&nbsp;accordance with the Users’ preferences and based on&nbsp;their
        profile data.
      </p>
      <p>
        There is&nbsp;an&nbsp;option to&nbsp;unsubscribe from AgileEngine
        newsletters by&nbsp;following the link inserted to&nbsp;the message with
        such a newsletter.
      </p>
      <Typography variant="h2" id="intellectual-rights" mt={6}>
        VI. Intellectual property rights
      </Typography>
      <p>
        AgileEngine is&nbsp;an&nbsp;exclusive rightsholder of&nbsp;intellectual
        property rights and the related rights to&nbsp;the Content of&nbsp;the
        Websites (or&nbsp;the rights to&nbsp;use the Content were duly provided
        to&nbsp;AgileEngine by&nbsp;any third parties).
      </p>
      <p>
        It&nbsp;is&nbsp;prohibited to&nbsp;use the Content for commercial
        or&nbsp;non-commercial purposes fully or&nbsp;partially in&nbsp;any way
        (meaning reproduction, representation, adaptation, modification,
        publication, transmission, misrepresentation, integration
        to&nbsp;another web-site). If&nbsp;such use of the Content
        is&nbsp;necessary, AgileEngine should be&nbsp;requested via the feedback
        forms on&nbsp;the Websites.
      </p>
      <p>
        In&nbsp;case intellectual property rights to&nbsp;the Content
        of&nbsp;the Websites are violated by&nbsp;any third party, AgileEngine
        will take all necessary remedies including court action.
      </p>
      <Typography variant="h2" id="data" mt={6}>
        VII. Personal data
      </Typography>
      <p>
        AgileEngine collects and processes the Users’ Personal data for the
        purposes of due access to the Websites as well as using the
        functionality of Websites by the Users and AgileEngine.
      </p>
      <p>
        Depending on&nbsp;the circumstances of&nbsp;data collection and
        processing, different companies affiliated to&nbsp;AgileEngine are
        controllers and processors of&nbsp;the Users’ Personal data.
        To&nbsp;receive information on&nbsp;&nbsp; AgileEngine company acting
        as&nbsp;data controller or&nbsp;processor please send a&nbsp;request
        to&nbsp;AgileEngine data protection personnel at{' '}
        <Link href="mailto:policy@agileengine.com">policy@agileengine.com</Link>
        (the response may be&nbsp;provided exclusively in respect of&nbsp;the
        requesting individual).
      </p>
      <p>
        AgileEngine does not collect or&nbsp;process Personal data
        of&nbsp;minors under 16 years old.
      </p>
      <p>
        AgileEngine does not sell, rent, release, disclose, disseminate, make
        available, transfer, or&nbsp;otherwise communicate orally,
        in&nbsp;writing, or by&nbsp;electronic or&nbsp;other means,
        a&nbsp;Users’ Personal information to another business
        or&nbsp;a&nbsp;third party for monetary or&nbsp;other valuable
        consideration.
      </p>
      <p>
        AgileEngine does not collect or&nbsp;process the following categories of
        Personal data: racial or&nbsp;ethnic origin, political opinions,
        religious or&nbsp;philosophical beliefs, membership in&nbsp;political
        parties and trade unions, convictions, as&nbsp;well as&nbsp;health
        related data, sex life or&nbsp;sex orientation data, biometric
        or&nbsp;genetic data of&nbsp;Users (“sensitive personal data”).
      </p>
      <p>
        AgileEngine ensures to&nbsp;take all appropriate measures
        on&nbsp;Personal data protection considering provisions
        of&nbsp;applicable law on&nbsp;personal data protection (including GDPR,
        CCPA provisions, if&nbsp;applicable) and the specifics
        of&nbsp;AgileEngine business.
      </p>
      <p>
        <strong>Contacts</strong>
      </p>
      <p>
        For any questions on&nbsp;Personal data protection please send request
        or complaint to&nbsp;AgileEngine data protection personnel at{' '}
        <Link href="mailto:policy@agileengine.com">policy@agileengine.com</Link>{' '}
        or&nbsp;in&nbsp;the applicable form on&nbsp;the Websites.
      </p>
      <p>
        <strong>
          Categories of Personal data collected and processed by AgileEngine,
          purposes and legal grounds of Personal data collecting and processing
        </strong>
      </p>
      <p>
        The scope, method, purpose and legal ground for collecting and
        processing of&nbsp;Personal Data by&nbsp;AgileEngine may vary depending
        on&nbsp;the way of&nbsp;use of&nbsp;AgileEngine Websites by&nbsp;the
        Users as&nbsp;well as&nbsp;on&nbsp;the scope of&nbsp;Services requested
        by&nbsp;the Users.
      </p>
      <p>
        AgileEngine collects and processes the following categories of Personal
        data of&nbsp;the Users:
      </p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>
          name, surname, email address, mobile telephone number, city and state
          of residence, resume data, legal and physical address (if applicable);
        </ListItem>
        <ListItem>account data for the Websites (logins, passwords);</ListItem>
        <ListItem>
          emergency contacts of Users, pictures provided by Users as avatars of
          profile (if applicable);
        </ListItem>
        <ListItem>
          “cookies”, technical data for AgileEngine Websitess, geolocation data.
        </ListItem>
      </List>
      <p>
        AgileEngine also collects anonymized data, which is&nbsp;not Personal
        data (AgileEngine cannot identify any Users with such data). Anonymized
        data is&nbsp;collected and processed in&nbsp;consolidated form and
        is&nbsp;used for the purposes of&nbsp;marketing researches.
      </p>
      <p>
        There are the following legal grounds for collecting and processing of
        Personal data by&nbsp;AgileEngine via use of&nbsp;the Websites
        by&nbsp;the Users:
      </p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>
          consent to collection and processing of Personal data;
        </ListItem>
        <ListItem>fulfillment of AgileEngine contractual obligations;</ListItem>
        <ListItem>
          compliance with legal requirements applicable to AgileEngine;
        </ListItem>
        <ListItem>AgileEngine legal interest.</ListItem>
      </List>
      <p>
        AgileEngine collects and processes Personal data of the Users&rsquo; of
        the Websites for the following purposes:
      </p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>
          creating the Users&#39; accounts on AgileEngine Websites;
        </ListItem>
        <ListItem>
          providing access for the Users to AgileEngine Websites;
        </ListItem>
        <ListItem>
          tracking the preferences of the Users to provide customized
          information about AgileEngine and/or its Services;
        </ListItem>
        <ListItem>
          tracking legal and contract compliance with geolocation of access to
          the AgileEngine Websites and/or Services;
        </ListItem>
        <ListItem>
          providing responses to search and information requests by Users;
        </ListItem>
        <ListItem>
          managing subscriptions to newsletter on AgileEngine positions and/or
          Services;
        </ListItem>
        <ListItem>use of feedback forms on AgileEngine Websites;</ListItem>
        <ListItem>
          providing information exchange from the Websites via e-mail or other
          networks provided by User;
        </ListItem>
        <ListItem>
          conducting marketing researches and analysis of trends for improvement
          of AgileEngine positions and/or Services;
        </ListItem>
        <ListItem>improving operations of AgileEngine Websites;</ListItem>
        <ListItem>
          providing information on AgileEngine positions and/or Services
          (newsletters, information on promotions and other marketing events).
        </ListItem>
      </List>
      <Table
        sx={{
          mb: 6,
          '& td': {
            verticalAlign: 'top',
            fontFamily: 'inherit',
            fontWeight: '400',
            fontSize: '14px',
          },
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell colSpan={3}>
              <strong>
                Websites enabling order of AgileEngine positions/Services
              </strong>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Categories of Personal Data</strong>
            </TableCell>
            <TableCell>
              <strong>Purposes</strong>
            </TableCell>
            <TableCell>
              <strong>Legal grounds</strong>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              name, surname, email address, mobile telephone number, city and
              state of residence, legal and physical address
            </TableCell>
            <TableCell>
              <List sx={{ p: 0 }}>
                <ListItem>
                  creating the Users&apos; accounts on&nbsp;AgileEngine
                  Websites;
                </ListItem>
                <ListItem>
                  providing access for the Users to&nbsp;AgileEngine Websites;
                </ListItem>
                <ListItem>
                  ordering AgileEngine Services, managing such orders, providing
                  AgileEngine Services;
                </ListItem>
                <ListItem>submitting information related to Services;</ListItem>
                <ListItem>
                  tracking the preferences of the Users to provide customized
                  information about AgileEngine and/or its Services;
                </ListItem>
                <ListItem>
                  use of&nbsp;feedback forms on&nbsp;AgileEngine Websites
                </ListItem>
              </List>
            </TableCell>
            <TableCell>
              Consent to&nbsp;collection and processing of&nbsp;Personal data,
              fulfillment of&nbsp;contractual obligations of&nbsp;AgileEngine
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Picture provided by User for Avatar</TableCell>
            <TableCell>
              <List sx={{ p: 0 }}>
                <ListItem>
                  creating the Users&apos; accounts on AgileEngine Websites (if
                  provided by User)
                </ListItem>
              </List>
            </TableCell>
            <TableCell>
              consent to collection and processing of Personal data
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Emergency contact (third-party contact provided by the User via
              consent provided by such party, if applicable)
            </TableCell>
            <TableCell>
              <List sx={{ p: 0 }}>
                <ListItem>
                  submitting information related to Services where applicable
                </ListItem>
              </List>
            </TableCell>
            <TableCell>
              Consent to&nbsp;collection and processing of&nbsp;Personal data,
              fulfillment of&nbsp;contractual obligations of&nbsp;AgileEngine
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Account data</TableCell>
            <TableCell>
              <List sx={{ p: 0 }}>
                <ListItem>
                  providing access for the Users to AgileEngine Websites
                </ListItem>
              </List>
            </TableCell>
            <TableCell>
              Consent to&nbsp;collection and processing of&nbsp;Personal data,
              fulfillment of&nbsp;contractual obligations of&nbsp;AgileEngine
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>“cookies”, technical data</TableCell>
            <TableCell>
              <List sx={{ p: 0 }}>
                <ListItem>
                  tracking the preferences of the Users to provide customized
                  information about AgileEngine and/or Services;
                </ListItem>
                <ListItem>
                  providing responses to search and information requests of
                  users about AgileEngine and/or Services;
                </ListItem>
                <ListItem>
                  improving operations of AgileEngine Websites;
                </ListItem>
                <ListItem>
                  tracking legal and contract compliance with geolocation of
                  access to the AgileEngine Websites and/or Services
                </ListItem>
              </List>
            </TableCell>
            <TableCell>
              Consent to&nbsp;collection and processing of&nbsp;Personal data
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3}>
              <strong>Websites for informational and marketing purposes</strong>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Name, surname, email address, mobile telephone number, city and
              country of&nbsp;living
            </TableCell>
            <TableCell>
              <List sx={{ p: 0 }}>
                <ListItem>
                  use of feedback forms on AgileEngine Websites;
                </ListItem>
                <ListItem>
                  providing information on AgileEngine and/or Services
                  (newsletters, information on promotions and other marketing
                  events)
                </ListItem>
              </List>
            </TableCell>
            <TableCell>
              Consent to&nbsp;collection and processing of&nbsp;Personal data
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>“cookies”, technical data</TableCell>
            <TableCell>
              <List sx={{ p: 0 }}>
                <ListItem>
                  providing information exchange from AgileEngine Websites via
                  e-mail or social networking;
                </ListItem>
                <ListItem>
                  tracking the preferences of the Users to provide customized
                  information about AgileEngine and/or Services;
                </ListItem>
                <ListItem>
                  providing responses to search and information requests of
                  users about AgileEngine and/or Services
                </ListItem>
              </List>
            </TableCell>
            <TableCell>
              Consent to&nbsp;collection and processing of&nbsp;Personal Data
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <p>
        It is necessary to collect and process the Users’ Personal data for use
        of AgileEngine Websites as well as for communication, business relations
        and/or AgileEngine` Services.
      </p>
      <p>
        There is&nbsp;a&nbsp;log-in option for the Websites via social networks
        managed by&nbsp;third parties (depending on&nbsp;the functionality and
        internal settings of&nbsp;the Websites). By&nbsp;making such
        an&nbsp;entry, the Users hereby agree to&nbsp;provide AgileEngine with
        the shared access to&nbsp;Personal data to&nbsp;the scope requested
        under the settings on&nbsp;Personal data in&nbsp;the respective social
        network.
      </p>
      <p>
        AgileEngine processes each category of&nbsp;Personal data for the period
        necessary to&nbsp;fulfill each of&nbsp;the purposes hereto. After that,
        such Personal data would be&nbsp;deleted. In&nbsp;any case, AgileEngine
        is&nbsp;entitled to&nbsp;store personal data for a&nbsp;period necessary
        for fulfillment of&nbsp;its obligations in&nbsp;respect of&nbsp;the
        Users for providing AgileEngine and/or Services as well as to meet the
        obligations required by the applicable law.
      </p>
      <p>
        <strong>Users&rsquo; rights related to Personal data</strong>
      </p>
      <List sx={{ mb: 3, mt: -1.5 }}>
        <ListItem>right of&nbsp;access to&nbsp;Personal data;</ListItem>
        <ListItem>right to&nbsp;rectification of&nbsp;Personal data;</ListItem>
        <ListItem>
          right of&nbsp;access to&nbsp;methods of&nbsp;processing
          of&nbsp;Personal data;
        </ListItem>
        <ListItem>
          right to&nbsp;be&nbsp;informed on&nbsp;Personal data breaches;
        </ListItem>
        <ListItem>
          right on&nbsp;limited transferring of&nbsp;Personal data to&nbsp;third
          parties;
        </ListItem>
        <ListItem>
          right to&nbsp;revoke the consent to&nbsp;collection and processing
          of&nbsp;Personal data;
        </ListItem>
        <ListItem>
          right to&nbsp;restriction on&nbsp;collection and processing
          of&nbsp;Personal data;
        </ListItem>
        <ListItem>right to&nbsp;erasure of&nbsp;Personal data.</ListItem>
      </List>
      <p>
        Please submit a&nbsp;request or&nbsp;complaint on&nbsp;Personal data
        to&nbsp;the AgileEngine data protection personnel at{' '}
        <Link href="mailto:policy@agileengine.com">policy@agileengine.com</Link>{' '}
        or&nbsp;contact the competent data protection authorities.
      </p>
      <p>
        <strong>Transfer of Personal data to third parties</strong>
      </p>
      <p>
        AgileEngine transfers Personal data to&nbsp;third parties (including
        cross-border transfer of&nbsp;Personal data) exclusively in&nbsp;the
        scope necessary for the purposes of&nbsp;collection and processing
        of&nbsp;Personal data for which such Personal data was initially
        collected by&nbsp;AgileEngine.
      </p>
      <p>
        AgileEngine transfers Personal data exclusively in&nbsp;case
        of&nbsp;necessity, in&nbsp;a&nbsp;minimum scope, for a&nbsp;minimum
        period of&nbsp;time, which is&nbsp;necessary under such transfer.
      </p>
      <p>
        Due to fulfillment of obligations related to Services, ensuring access
        to the Websites as well as improving of it and access AgileEngine may
        transfer Personal data to:
      </p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>affiliated companies of&nbsp;AgileEngine;</ListItem>
        <ListItem>partners of&nbsp;AgileEngine;</ListItem>
        <ListItem>Customers of&nbsp;AgileEngine;</ListItem>
        <ListItem>(sub)contractors of&nbsp;AgileEngine;</ListItem>
        <ListItem>
          third parties, in&nbsp;particular to&nbsp;state authorities
          (in&nbsp;case of compliance with the legislative provisions);
        </ListItem>
        <ListItem>
          third parties in&nbsp;case of&nbsp;any legal procedures related to
          reorganization of&nbsp;AgileEngine.
        </ListItem>
      </List>
      <p>
        AgileEngine should take all necessary legal, organizational and
        technical measures to&nbsp;ensure protection of&nbsp;Personal data.
      </p>
      <Typography variant="h2" id="cookies" mt={6}>
        VIII. Cookies policy
      </Typography>
      <p>
        Use of&nbsp;the Websites requires use of&nbsp;“cookies” installed on the
        Users&apos; electronic devices.
      </p>
      <p>AgileEngine uses the following categories of&nbsp;“cookies”:</p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>
          to&nbsp;provide users with a&nbsp;consistent access
          to&nbsp;functionality of&nbsp;the Websites;
        </ListItem>
        <ListItem>
          to&nbsp;improve and facilitate use of&nbsp;the Websites;
        </ListItem>
        <ListItem>
          to&nbsp;represent correctly advertising messages of&nbsp;AgileEngine
          on&nbsp;the Websites, third party websites, social networks;
        </ListItem>
        <ListItem>
          to&nbsp;provide access of&nbsp;the Users to&nbsp;exchange
          of&nbsp;information from the Websites via social networks;
        </ListItem>
        <ListItem>
          to&nbsp;monitor the metrics of&nbsp;the audience of&nbsp;the Websites
          (traffic analysis, usage trends etc.).
        </ListItem>
      </List>
      <p>
        Prior to&nbsp;using “cookies” on&nbsp;the Websites, AgileEngine requests
        the Users’ consent to&nbsp;such use by&nbsp;sending the respective
        notification (in&nbsp;a&nbsp;banner form).
      </p>
      <p>
        There are the following options provided for the Users in&nbsp;respect
        of&nbsp;“cookies” settings:
      </p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>allow automatic storage and use of&nbsp;“cookies”;</ListItem>
        <ListItem>warn about “cookies” use;</ListItem>
        <ListItem>customize “cookies” use;</ListItem>
        <ListItem>
          block “cookies” use (in&nbsp;such a&nbsp;case there may be&nbsp;some
          restrictions on&nbsp;operation of&nbsp;the Websites, e.g.
          no&nbsp;option of customized settings etc.).
        </ListItem>
      </List>
      <p>
        When using the Websites, it&nbsp;is&nbsp;possible to&nbsp;use functional
        options of&nbsp;the other resources, in&nbsp;particular:
      </p>
      <List sx={{ mb: 3, mt: -1.5, maxWidth: '40ch' }}>
        <ListItem>
          social networks (there is&nbsp;an&nbsp;option of&nbsp;direct content
          sharing from the Websites) (depending on&nbsp;functionality and
          internal settings of&nbsp;the Websites). There are separate “cookies”
          policies of&nbsp;such companies;
        </ListItem>
        <ListItem>
          Google Analytics for collecting statistical information
          of&nbsp;general character on&nbsp;use of&nbsp;the Websites (such
          information is&nbsp;used by AgileEngine exclusively for improving the
          Content and provision of&nbsp;the Services ordered via the Websites).
          Operation of&nbsp;Google Analytics is governed by&nbsp;the respective
          policy of&nbsp;Google;
        </ListItem>
        <ListItem>
          other resources, which safety was checked by&nbsp;AgileEngine.
        </ListItem>
      </List>
      <p>
        You may also see advertisements of&nbsp;our Websites on&nbsp;third-party
        websites, such as&nbsp;social media. These ads may be&nbsp;tailored
        to&nbsp;you by cookies (which are tracking your web activity and allow
        us&nbsp;to&nbsp;show ads to&nbsp;users who have visited our Website).
        You can see ads on&nbsp;social media because we&nbsp;involved
        a&nbsp;social network to&nbsp;show ads to&nbsp;our customers
        or&nbsp;users that match the demographic profile of&nbsp;our customers
        or&nbsp;users. In&nbsp;some cases, this may involve the transfer
        of&nbsp;your email address, social network and phone number. If&nbsp;you
        no&nbsp;longer wish to&nbsp;see special ads, you may change your cookie
        and privacy settings in&nbsp;your browser and on&nbsp;third-party
        websites.
      </p>
      <p>
        You can see our ads on&nbsp;AgileEngine Websites and third-party
        applications and websites, as&nbsp;well as&nbsp;on&nbsp;the various
        devices you use. We&nbsp;can use this process to&nbsp;improve the
        effectiveness of&nbsp;marketing efforts for the user.
      </p>
      <p>
        Please note that in&nbsp;case the function of&nbsp;“cookies” use is
        blocked, it&nbsp;may result in&nbsp;improper operation of&nbsp;the
        Websites. There is an&nbsp;option to&nbsp;block the function
        of&nbsp;“cookies” use in&nbsp;part or&nbsp;in&nbsp;full (certain types
        of&nbsp;“cookies”) by&nbsp;modifying the settings of&nbsp;the
        web-browsers on&nbsp;devices used for access to&nbsp;the Websites.
      </p>
      <Typography variant="h2" id="datastorage" mt={6}>
        IX. Personal data storage
      </Typography>
      <p>
        AgileEngine keep personal data to enable User`s continuance of
        AgileEngine Websites and/or Services use, for as long as it is required
        in order to fulfill the relevant purposes described in this ToU, and as
        may be required by law such as for tax and accounting purposes,
        compliance with Anti-Money Laundering laws, Anti-Bribery law or to
        resolve disputes and/or legal claims or as otherwise communicated to
        User.
      </p>
      <p>
        While retention requirements vary by jurisdiction, information about
        AgileEngine typical retention periods for different aspects of personal
        data are described below.
      </p>
      <p>
        Personal Data collected to comply with legal obligations under
        commercial or anti-money laundering laws may be retained after account
        closure for as long as is required under such laws.
      </p>
      <p>
        Contact Information such as User name, email address and phone number
        for marketing purposes is retained on an ongoing basis and until User
        (a) unsubscribe, or AgileEngine (b) delete User`s account.
      </p>
      <p>
        Content that User posts on AgileEngine Website such as support desk
        comments, photographs, videos, blog posts, and other content may be kept
        after User/AgileEngine closes account for audit and crime prevention
        purposes.
      </p>
      <p>
        Recording of video verification calls with Users (where applicable) may
        be kept for a period of up to six years, for audit/ compliance purposes
        and to resolve disputes and/or legal claims.
      </p>
      <p>
        Information collected via cookies, web page counters and other analytics
        tools is kept for a period of up to one year from the date of the
        collection of the cookie of the relevant cookie.
      </p>
      <Typography variant="h2" id="responsibility" mt={6}>
        X. Responsibility
      </Typography>
      <p>
        Breach of any ToU provisions will lead to bringing the Users to
        responsibility established under the ToU and the law applicable to
        regulation of such ToU.
      </p>
      <p>
        There are hyperlinks to third-party web resources inserted to the
        Websites. AgileEngine is not responsible for the content posted on such
        web resources, as well as for the collection and processing of Personal
        Data of Users, which may occur as a result of the use of such resources.
      </p>
      <p>
        AgileEngine takes every effort to provide the Users with secure,
        uninterrupted and correct access to the Websites. At the same time
        AgileEngine is not responsible for any interruptions in the work of the
        Websites, the incompatibility of such Websites with all types of the
        Users’ devices, and the inability of the Users to access to the Websites
        due to the absence of the Internet connection. AgileEngine retains
        discretion to restrict the Users’ access to the Websites in full or in
        part.
      </p>
      <p>
        AgileEngine is not liable for any damages arising for the Users from use
        of the Websites. Also, AgileEngine is not liable before any third
        parties for any Users’ actions committed with use of the Websites.
      </p>
      <Typography variant="h2" id="misc" mt={6}>
        XI. Miscellaneous
      </Typography>
      <p>
        ToU is effective as of the moment of its publishing on the Websites.
      </p>
      <p>
        Any disputes arising between AgileEngine and the Users related to use of
        the Websites are settled with negotiations held between the parties
        (such settlement should be initiated by the Users in question with
        communicating AgileEngine with the respective request) as well as in
        accordance with the applicable law.
      </p>
      <p>
        For any questions on use of the Websites please contact AgileEngine
        support at feedback form on the Websites. For the issues related to
        collection and processing of Personal data please contact AgileEngine
        data protection personnel at&nbsp;
        <Link href="mailto:policy@agileengine.com">policy@agileengine.com</Link>
        .
      </p>
    </Box>
  );
};
