export enum UserPrivilegedGroupNames {
  FinanceAdmin = 'ACG-SS-FinanceAdmin',
  FinanceManagerGlobal = 'ACG-SS-FinanceManagerGlobal',
  CompensationManager = 'ACG-SS-CompensationManager',
  BillRateManager = 'ACG-SS-BillRateManager',
  SecureStorageUser = 'ACG-SS-Users',
  FinanceManagerUS = 'ACG-SS-FinanceManagerUS',
  FinanceManagerRemote = 'ACG-SS-FinanceManagerRemote',
  FinanceManagerPortugal = 'ACG-SS-FinanceManagerPortugal',
  FinanceManagerPoland = 'ACG-SS-FinanceManagerPoland',
  FinanceManagerUkraine = 'ACG-SS-FinanceManagerUkraine',
  FinanceManagerIndia = 'ACG-SS-FinanceManagerIndia',
  FinanceManagerBrazil = 'ACG-SS-FinanceManagerBrazil',
  FinanceManagerColombia = 'ACG-SS-FinanceManagerColombia',
  FinanceManagerMexico = 'ACG-SS-FinanceManagerMexico',
  FinanceManagerArgentina = 'ACG-SS-FinanceManagerArgentina',
  FinanceManagerGuatemala = 'ACG-SS-FinanceManagerGuatemala',
}
