import { LocalStorageService } from './local-storage-service';
import { CookieService } from './cookies';
import { apiEndpoints } from '../constants/api';
import axios from 'axios';
import { SessionStorageService } from './session-storage-service';

const AUTH_TOKEN_KEY = 'accessToken';
export const CUSTOM_LOGIN_EMAIL = 'customEmail';

let isCustomLoginLoading = false;

export class AuthService {
  static getToken(): string | null {
    const token = LocalStorageService.getState<string>(AUTH_TOKEN_KEY);

    return token;
  }

  static getRockyToken(): string | null {
    const token = SessionStorageService.getState<string>(AUTH_TOKEN_KEY);

    return token;
  }

  static setRockyToken(token: string) {
    SessionStorageService.setState<string>(AUTH_TOKEN_KEY, token);
  }

  static clearRockyToken() {
    SessionStorageService.clearState(AUTH_TOKEN_KEY);
  }

  static clearToken() {
    LocalStorageService.clearState(AUTH_TOKEN_KEY);
  }

  static customLogin(customEmail: string) {
    if (!customEmail) {
      return;
    }

    LocalStorageService.clearState();
    if (!isCustomLoginLoading) {
      return axios
        .get(apiEndpoints.authCustomLogin(customEmail))
        .then((response) => {
          AuthService.setToken(response.data?.idToken);
          CookieService.setCookie(CUSTOM_LOGIN_EMAIL, customEmail, {
            maxAge: 60 * 60 * 24, // one day
          });
        })
        .catch(() => {
          CookieService.deleteCookie(CUSTOM_LOGIN_EMAIL);
        });
    }
    isCustomLoginLoading = true;
  }

  static setToken(token?: string) {
    LocalStorageService.setState(AUTH_TOKEN_KEY, token ?? '');
  }
}
