import React, {
  MouseEvent,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  fetchProjectStatus,
  getProjectAssessments,
} from '../../../../redux/projectStatus';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { StyledToggleButton } from '../../styledComponents/StyledToggleButton';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { blue } from '@mui/material/colors';
import { borderRadius } from '../../../../theme';
import {
  assessmentsTypes,
  AssessmentsTypesEnum,
  AssessmentDateContent,
  AssessmentsScoreColorEnum,
} from '../../types';
import { capitalizeFirstLetter, parseAssessments } from '../../utils';
import { getTimezoneOffset } from '../../../../utils';
import { fetchAccountsStatus } from '../../../../redux/accountsStatus';
import { ASSESSMENT_PLACEHOLDER } from '../../constants';
import {
  CreateAssessmentPayload,
  createAssessment,
  getAssessmentLoading,
  setIsRefetchingAssessment,
} from '../../../../redux/assessment';

const generalIconStyle = {
  fontSize: '1.2rem',
  color: 'white',
  mr: '0.5rem',
  display: 'inline-block',
  verticalAlign: 'middle',
};

const commentTextAreaStyle = { backgroundColor: 'white', mt: '0.5rem' };
const RED_AND_YELLOW_STATUSES = [
  AssessmentsScoreColorEnum.RED,
  AssessmentsScoreColorEnum.YELLOW,
];
export const ASSESSMENT_FORM_DATA_TEST_ID = 'assessment-form';

interface Props {
  period: string;
  isEditMode: boolean;
  assessment: AssessmentDateContent;
  toggleEditMode: () => void;
  projectId: string;
}

const AssessmentForm = ({
  period,
  assessment,
  isEditMode,
  toggleEditMode,
  projectId,
}: Props) => {
  const dispatch = useAppDispatch();

  const assessments = useAppSelector(getProjectAssessments);
  const parsedAssessments = useMemo<Map<string, AssessmentDateContent>>(
    () => parseAssessments(assessments),
    [assessments],
  );

  const [generalScore, setGeneralScore] = useState<number | null>(null);
  const [timelineScore, setTimelineScore] = useState<number | null>(null);
  const [scopeScore, setScopeScore] = useState<number | null>(null);
  const [generalComment, setGeneralComment] = useState<string>('');
  const [timelineComment, setTimelineComment] = useState<string>('');
  const [scopeComment, setScopeComment] = useState<string>('');

  const isLoading = useAppSelector(getAssessmentLoading);

  useEffect(() => {
    if (isEditMode && assessment) {
      setGeneralScore(assessment[AssessmentsTypesEnum.GENERAL].score);
      setGeneralComment(
        assessment[AssessmentsTypesEnum.GENERAL].comment as string,
      );

      setTimelineScore(assessment[AssessmentsTypesEnum.TIMELINE].score);
      setTimelineComment(
        assessment[AssessmentsTypesEnum.TIMELINE].comment as string,
      );

      setScopeScore(assessment[AssessmentsTypesEnum.SCOPE].score);
      setScopeComment(assessment[AssessmentsTypesEnum.SCOPE].comment as string);
    }
  }, [assessment, isEditMode]);

  const onLoadLastAssessment = useCallback(() => {
    const lastAssessment: AssessmentDateContent | undefined = Array.from(
      parsedAssessments.values(),
    )
      .slice()
      .reverse()
      .find((value: AssessmentDateContent) => value.GENERAL.id !== undefined);

    if (!lastAssessment) return;

    setGeneralScore(lastAssessment.GENERAL.score);
    setTimelineScore(lastAssessment.TIMELINE.score);
    setScopeScore(lastAssessment.SCOPE.score);

    setGeneralComment(lastAssessment.GENERAL.comment ?? '');
    setTimelineComment(lastAssessment.TIMELINE.comment ?? '');
    setScopeComment(lastAssessment.SCOPE.comment ?? '');
  }, [
    parsedAssessments,
    setGeneralScore,
    setTimelineScore,
    setScopeScore,
    setGeneralComment,
    setTimelineComment,
    setScopeComment,
  ]);

  const handleChangeStatus = (type: AssessmentsTypesEnum) => {
    return (event: MouseEvent<HTMLElement>, status: number | null) => {
      switch (type) {
        case AssessmentsTypesEnum.GENERAL:
          return setGeneralScore(status);
        case AssessmentsTypesEnum.TIMELINE:
          return setTimelineScore(status);
        case AssessmentsTypesEnum.SCOPE:
          return setScopeScore(status);
      }
    };
  };

  const getButtonGroupValue = (type: AssessmentsTypesEnum) => {
    switch (type) {
      case AssessmentsTypesEnum.GENERAL:
        return generalScore;
      case AssessmentsTypesEnum.TIMELINE:
        return timelineScore;
      case AssessmentsTypesEnum.SCOPE:
        return scopeScore;
    }
  };

  const isSaveButtonDisabled = () => {
    if (
      [generalScore, timelineScore, scopeScore].some(
        (score) => typeof score !== 'number',
      )
    ) {
      return true;
    }

    return (
      (RED_AND_YELLOW_STATUSES.includes(generalScore as number) &&
        generalComment === '') ||
      (RED_AND_YELLOW_STATUSES.includes(timelineScore as number) &&
        timelineComment === '') ||
      (RED_AND_YELLOW_STATUSES.includes(scopeScore as number) &&
        scopeComment === '')
    );
  };

  const isDiscardButtonDisabled = () =>
    !(
      [generalScore, timelineScore, scopeScore].some(
        (score) => typeof score === 'number',
      ) ||
      [generalComment, timelineComment, scopeComment].some(
        (comment) => comment !== '',
      )
    );

  const unselectStatus = (
    type: AssessmentsTypesEnum,
    status: AssessmentsScoreColorEnum,
  ) => {
    let score;

    if (type === AssessmentsTypesEnum.GENERAL) {
      score = generalScore;
    }

    if (type === AssessmentsTypesEnum.TIMELINE) {
      score = timelineScore;
    }

    if (type === AssessmentsTypesEnum.SCOPE) {
      score = scopeScore;
    }

    return typeof score !== 'number' || score === status ? '' : 'unselected';
  };

  const onDiscardFormData = () => {
    setGeneralScore(null);
    setTimelineScore(null);
    setScopeScore(null);
    setGeneralComment('');
    setTimelineComment('');
    setScopeComment('');
    if (isEditMode) {
      toggleEditMode();
    }
  };

  const getRequestBody = (): CreateAssessmentPayload => {
    const timezone = getTimezoneOffset();

    return {
      projectId: projectId as string,
      timezone,
      generalScore: generalScore as number,
      timelineScore: timelineScore as number,
      scopeScore: scopeScore as number,
      generalComment,
      timelineComment,
      scopeComment,
    };
  };

  const onSave = async () => {
    const requestBody = getRequestBody();

    try {
      await dispatch(createAssessment(requestBody)).unwrap();

      dispatch(setIsRefetchingAssessment(true));
      await Promise.allSettled([
        dispatch(
          fetchProjectStatus({ projectId: requestBody.projectId }),
        ).unwrap(),
        dispatch(fetchAccountsStatus()).unwrap(),
      ]);

      if (isEditMode) {
        toggleEditMode();
      }
    } finally {
      dispatch(setIsRefetchingAssessment(false));
    }
  };

  return (
    <Box
      data-testid={ASSESSMENT_FORM_DATA_TEST_ID}
      sx={{
        backgroundColor: (theme: Theme) => theme.palette.highlight.neutral,
        borderRadius,
        padding: '1rem',
        mt: '1rem',
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <AddCommentIcon color="primary" />
        <Typography variant="subtitle1">
          Set project statuses as of {period}
        </Typography>
        <Button onClick={onLoadLastAssessment} variant="text" size="small">
          Copy from previous
        </Button>
      </Stack>
      <Box>
        {assessmentsTypes.map((type: AssessmentsTypesEnum) => (
          <Box key={type} sx={{ mt: '1rem' }}>
            <Stack direction="row" alignItems="center">
              <Typography sx={{ width: '80px' }}>
                {capitalizeFirstLetter(type)}:
              </Typography>
              <ToggleButtonGroup
                aria-label={`Select ${type.toLowerCase()} status.`}
                exclusive
                value={getButtonGroupValue(type)}
                onChange={handleChangeStatus(type)}
                sx={{ width: '100%' }}
              >
                <StyledToggleButton
                  value={AssessmentsScoreColorEnum.GREEN}
                  aria-label="Green"
                  className={`green ${type.toLowerCase()} ${unselectStatus(
                    type,
                    AssessmentsScoreColorEnum.GREEN,
                  )}`}
                >
                  {type === AssessmentsTypesEnum.GENERAL && (
                    <CheckCircleRoundedIcon sx={generalIconStyle} />
                  )}
                  Green
                </StyledToggleButton>
                <StyledToggleButton
                  value={AssessmentsScoreColorEnum.YELLOW}
                  aria-label="Yellow"
                  className={`yellow ${type.toLowerCase()} ${unselectStatus(
                    type,
                    AssessmentsScoreColorEnum.YELLOW,
                  )}`}
                >
                  {type === AssessmentsTypesEnum.GENERAL && (
                    <WarningRoundedIcon sx={generalIconStyle} />
                  )}
                  Yellow
                </StyledToggleButton>
                <StyledToggleButton
                  value={AssessmentsScoreColorEnum.RED}
                  aria-label="Red"
                  className={`red ${type.toLowerCase()} ${unselectStatus(
                    type,
                    AssessmentsScoreColorEnum.RED,
                  )}`}
                >
                  {type === AssessmentsTypesEnum.GENERAL && (
                    <ErrorRoundedIcon sx={generalIconStyle} />
                  )}
                  Red
                </StyledToggleButton>
              </ToggleButtonGroup>
            </Stack>
            {type === AssessmentsTypesEnum.GENERAL && (
              <TextField
                fullWidth
                id="general-comment"
                multiline
                rows={3}
                placeholder={
                  ASSESSMENT_PLACEHOLDER[AssessmentsTypesEnum.GENERAL]
                }
                value={generalComment}
                onChange={(e) => setGeneralComment(e.target.value)}
                sx={commentTextAreaStyle}
              />
            )}
            {type === AssessmentsTypesEnum.TIMELINE &&
              typeof timelineScore === 'number' && (
                <TextField
                  fullWidth
                  id="timeline-comment"
                  multiline
                  rows={3}
                  placeholder={
                    ASSESSMENT_PLACEHOLDER[AssessmentsTypesEnum.TIMELINE]
                  }
                  value={timelineComment}
                  onChange={(e) => setTimelineComment(e.target.value)}
                  sx={commentTextAreaStyle}
                />
              )}
            {type === AssessmentsTypesEnum.SCOPE &&
              typeof scopeScore === 'number' && (
                <TextField
                  fullWidth
                  id="scope-comment"
                  multiline
                  rows={3}
                  placeholder={
                    ASSESSMENT_PLACEHOLDER[AssessmentsTypesEnum.SCOPE]
                  }
                  value={scopeComment}
                  onChange={(e) => setScopeComment(e.target.value)}
                  sx={commentTextAreaStyle}
                />
              )}
          </Box>
        ))}
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ mt: '1rem', columnGap: '0.5rem' }}
      >
        <LoadingButton
          variant="contained"
          loading={isLoading}
          disabled={isSaveButtonDisabled()}
          onClick={onSave}
        >
          Save
        </LoadingButton>
        <Button
          variant="contained"
          sx={{
            backgroundColor: blue[100],
            color: blue[500],
            ':hover': { backgroundColor: blue[200] },
          }}
          disabled={isDiscardButtonDisabled()}
          onClick={onDiscardFormData}
        >
          Discard
        </Button>
        {isSaveButtonDisabled() && (
          <Typography>Please set all statuses and add notes.</Typography>
        )}
      </Stack>
    </Box>
  );
};

export default AssessmentForm;
