import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import {
  SubmissionsState,
  UpdateKpisPayload,
  FetchSubmissionsPayload,
} from './submissions.types';
import {
  checkNewKpiBonus,
  fetchSubmissions,
  submitSubmissions,
} from './submissions.thunks';
import { submissionsStoreKey } from './submissions.const';
import { NewKpiBonus } from '../approvals/approvals.types';

const initialState: SubmissionsState = {
  apiStatus: API_STATUS.IDLE,
  individualKpis: [],
  sharedKpis: [],
  kpisForSubmission: [],
  newBonusShares: {},
};

export const submissionsSlice = createSlice({
  name: submissionsStoreKey,
  initialState,
  reducers: {
    updateIndividualKpis: (state, action: PayloadAction<UpdateKpisPayload>) => {
      const id = state.individualKpis[action.payload.index].id;
      const kpi = state.kpisForSubmission.find((kpi) => kpi.id === id);
      const value = action.payload.value;
      state.individualKpis[action.payload.index].value = value;
      if (kpi && typeof value === 'number') {
        kpi.value = value;
      } else if (!kpi && typeof value === 'number') {
        state.kpisForSubmission.push({ id, value });
      } else {
        state.kpisForSubmission = state.kpisForSubmission.filter(
          (kpi) => kpi.id !== id,
        );
        state.individualKpis[action.payload.index].value = null;
      }
    },
    updateSharedKpis: (state, action: PayloadAction<UpdateKpisPayload>) => {
      const id = state.sharedKpis[action.payload.index].id;
      const kpi = state.kpisForSubmission.find((kpi) => kpi.id === id);
      const value = action.payload.value;
      state.sharedKpis[action.payload.index].value = value;
      if (kpi && typeof value === 'number') {
        kpi.value = value;
      } else if (!kpi && typeof value === 'number') {
        state.kpisForSubmission.push({ id, value });
      } else {
        state.kpisForSubmission = state.kpisForSubmission.filter(
          (kpi) => kpi.id !== id,
        );
        state.sharedKpis[action.payload.index].value = null;
      }
    },
    setLoadingKpiId: (
      state,
      { payload }: PayloadAction<string | undefined>,
    ) => {
      if (!payload) {
        delete state.loadingKpiId;
        return;
      }

      state.loadingKpiId = payload;
    },
    clearSubmissions: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubmissions.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(
        fetchSubmissions.fulfilled,
        (state, action: PayloadAction<FetchSubmissionsPayload>) => {
          state.apiStatus = API_STATUS.IDLE;
          state.individualKpis = action.payload.individualKpis;
          state.sharedKpis = action.payload.sharedKpis;
        },
      )
      .addCase(submitSubmissions.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(submitSubmissions.fulfilled, (state) => {
        state.apiStatus = API_STATUS.IDLE;
        state.individualKpis = initialState.individualKpis;
        state.sharedKpis = initialState.sharedKpis;
        state.kpisForSubmission = initialState.kpisForSubmission;
      })
      .addCase(submitSubmissions.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });

    builder
      .addCase(
        checkNewKpiBonus.fulfilled,
        (state, { payload }: PayloadAction<NewKpiBonus[]>) => {
          delete state.loadingKpiId;

          if (!Array.isArray(payload)) {
            return;
          }

          payload.map(({ userKpiAssignmentId, payout, trueup }) => {
            state.newBonusShares[userKpiAssignmentId] = { payout, trueup };
          });
        },
      )
      .addCase(checkNewKpiBonus.rejected, (state) => {
        delete state.loadingKpiId;
      });
  },
});

export const { clearSubmissions, updateIndividualKpis, updateSharedKpis } =
  submissionsSlice.actions;
