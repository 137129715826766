import { Theme } from '@mui/material/styles';
import { avatarMediumSize, borderRadiusSmall } from '../../../../theme';

export const styles = {
  mt: 'auto',
  mb: 1,
  ml: 1,
  p: 0,

  '.MuiListItemButton-root': {
    alignItems: 'start',
    pl: 0.85,
    borderRadius: borderRadiusSmall,
  },

  '.MuiListItemIcon-root': {
    justifyContent: 'center',
  },

  '.MuiAvatar-root': {
    bgcolor: (theme: Theme) => `${theme.palette.brand}`,
  },

  '.MuiListItemText-root': {
    mt: 0,
    mb: 0,
    minHeight: avatarMediumSize,
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    justifyContent: 'center',

    '.MuiTypography-body1': {
      lineHeight: '120%',
      mb: '2px',
    },
  },
};
