import React, { FC, memo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import NoAssessmentsSVG from '../../../../../assets/no-assessments.svg';

import { BoxSVGStyles } from './styles';
import AssessmentsDetailsWrapper from '../AssessmentsDetailsWrapper';

interface Props {
  period: string;
}

const AssessmentNotProvided: FC<Props> = ({ period }) => {
  return (
    <AssessmentsDetailsWrapper period={period}>
      <Box sx={BoxSVGStyles}>
        <img src={NoAssessmentsSVG} alt="Assessment not provided" />
        <Typography variant="body1" color="text.secondary" mb={1}>
          Assessment not provided
        </Typography>
      </Box>
    </AssessmentsDetailsWrapper>
  );
};

export default memo(AssessmentNotProvided);
