import React, { FC, useEffect } from 'react';
import { PageWrapper } from '../../components';
import { Alert, Button, Link, Stack, Typography } from '@mui/material';
import AddAlertOutlinedIcon from '@mui/icons-material/AddAlertOutlined';
import GoogleDriveLogo from '../../assets/google-drive.svg';
import { useAppSelector } from '../../redux/hooks';
import { getSelectedAccount, getSelectedProject } from '../../redux/account';
import { useTitle } from '../../hooks/useTitle';
import { PageTitle } from '../../utils/pageTitles';

export const Risks: FC = () => {
  const account = useAppSelector(getSelectedAccount);
  const project = useAppSelector(getSelectedProject);
  const setPageTitle = useTitle();
  useEffect(() => {
    setPageTitle(
      `${account?.name ?? PageTitle.portfolio} | ${PageTitle.risks}`,
    );
  }, [setPageTitle, account]);

  return (
    <PageWrapper>
      <Stack
        sx={{
          padding: '22px 16px',
          backgroundColor: 'white',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderTop: 'none',
          borderRadius: '8px',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <Typography variant="h3" mb="22px">
          Project risk alerts
        </Typography>
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            gap: '32px',
            padding: '136px 0',
          }}
        >
          <Button
            component={Link}
            size="medium"
            variant="contained"
            color="primary"
            startIcon={<img src={GoogleDriveLogo} alt="google-drive" />}
            href={`https://drive.google.com/drive/u/0/search?q=%22${
              project?.name ?? account?.name
            }%22%20%22account%20summary%22%20type:spreadsheet`}
            target="_blank"
          >
            Open account summary
          </Button>
          <Alert
            sx={{
              background: '#F6F9FD',
            }}
            icon={<AddAlertOutlinedIcon color="disabled" />}
            variant="standard"
            severity="info"
          >
            <Typography variant="body2" mt="2px">
              Risk management coming in v2
            </Typography>
          </Alert>
        </Stack>
      </Stack>
    </PageWrapper>
  );
};
