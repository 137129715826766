import React, { FC } from 'react';
import firefoxGuideImg from '../../assets/firefox-guide-finance@2x.png';
import { Box } from '@mui/material';

export const CookieWarningView: FC = () => {
  return (
    <Box
      sx={{
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.45,
        color: 'text.primary',
        padding: 0,
        margin: 0,
      }}
    >
      <Box
        sx={{
          maxWidth: 1340,
          margin: '10vh auto 15vh',
          padding: '0 32px',

          '& h1': {
            fontWeight: 500,
            margin: '-12px 0 24px',
            letterSpacing: '-0.01em',
          },

          '& ol': {
            paddingLeft: '1rem',
          },

          '& li': {
            margin: '0 0 8px',
          },

          '& hr': {
            border: 0,
            height: 1,
            background: '#eee',
            margin: '24px 0',
          },

          '& a': {
            color: 'inherit',
          },
        }}
      >
        <svg
          style={{
            margin: '0 0 0 -4px',
          }}
          width="35"
          height="45"
          viewBox="0 0 35 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="35" height="45" fill="white" />
          <path
            d="M31.9879 8.01218C25.9698 7.81456 19.8861 10.0213 15.2917 14.6371L15.1536 14.776H5.49411C3.27654 14.776 2.16658 17.4697 3.73317 19.0436L7.31828 22.6454L5.99055 23.9794C5.12179 24.8522 5.12179 26.2638 5.99055 27.1366L12.95 34.1285C13.8188 35.0013 15.2238 35.0013 16.0926 34.1285L17.4203 32.7946L20.8719 36.2624C22.4408 37.8386 25.1197 36.7211 25.1197 34.4932V25.0592L25.3914 24.7863C29.9857 20.1705 32.1846 14.0584 31.9879 8.01218ZM11.7721 21.1303C10.7372 21.1303 9.89881 20.2881 9.89881 19.2482C9.89881 18.2084 10.7372 17.3662 11.7721 17.3662C12.8072 17.3662 13.6455 18.2084 13.6455 19.2482C13.6455 20.2881 12.8072 21.1303 11.7721 21.1303ZM17.3922 17.3662C18.4272 17.3662 19.2655 18.2084 19.2655 19.2482C19.2655 20.2881 18.4272 21.1303 17.3922 21.1303C16.3571 21.1303 15.5188 20.2881 15.5188 19.2482C15.5188 18.2084 16.3571 17.3662 17.3922 17.3662ZM15.2847 29.1292V26.7766C18.2539 26.7766 20.6705 24.3487 20.6705 21.3656H23.0122C23.0122 25.645 19.5465 29.1292 15.2847 29.1292Z"
            fill="url(#paint0_linear_5005_109092)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_5005_109092"
              x1="17.5"
              y1="46.8393"
              x2="17.5"
              y2="22.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFDB95" />
              <stop offset="1" stopColor="#FF7A00" />
            </linearGradient>
          </defs>
        </svg>
        <p>
          Hey, looks like you&apos;re using Firefox which blocks our cookies.
        </p>
        <h1>Turn off the “Enhanced Tracking Protection”</h1>
        <img
          src={firefoxGuideImg}
          alt="Firefox Enhanced Tracking Protection OFF"
          style={{
            width: '100%',
            maxWidth: 1340,
            height: 'auto',
            margin: '0 -32px 32px',
            border: `1px solid divider`,
            borderRadius: 8,
          }}
        />
        <p>Or try adding exceptions manually:</p>
        <ol>
          <li>Open Settings &gt; Privacy & Security</li>
          <li>
            Click “Manage Exceptions” in the “Enhanced Tracking Protection”
            section
          </li>
          <li>Add “rocky-finance.agileengine.com” to the exceptions</li>
          <li>Refresh Rocky Finance tab and try to login again</li>
        </ol>
        <hr />
        <p>
          If the problem persists, please reach out to
          <a href="mailto:rocky@agileengine.com">rocky@agileengine.com</a>
        </p>
      </Box>
    </Box>
  );
};
