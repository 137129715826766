import React, { PropsWithChildren, useMemo, useState } from 'react';
import { SharedKpiModel, Column, PlainObject } from '../../../types';
import { ColumnTextAlign, KpiStatus } from '../../../constants';
import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded';
import { CustomTableCell } from '../../../components/customTable';
import { PeopleIconWrapper } from '../../../components/tableCellRenders';
import { MetricEditableCell } from '../MetricEditableCell/MetricEditableCell';

const columns: Column<SharedKpiModel>[] = [
  {
    field: 'name',
    customRender: RenderName,
    pureContent: true,
    width: 1000,
    colSpan: 9,
  },
  {
    field: 'owner',
    width: 200,
    customRender: (row) => row.kpiOwner?.fullName,
    colSpan: 2,
  },
  {
    field: 'value',
    editable: true,
    customRender: editableCell,
    pureContent: true,
    colSpan: 1,
    width: 100,
    textAlign: ColumnTextAlign.right,
  },
];

interface MetricRowProps {
  data: SharedKpiModel;
}

export const SharedKpiRows = ({ data }: MetricRowProps): JSX.Element => {
  return (
    <ExpandableRow<SharedKpiModel>
      noZeroCell
      expandable={false}
      columns={columns}
      rowData={data}
    />
  );
};

interface ExpandableRowProps<T extends PlainObject> {
  open?: boolean;
  expandable?: boolean;
  noZeroCell?: boolean;
  columns: Column<T>[];
  rowData: T;
}

function ExpandableRow<T extends PlainObject>({
  children,
  expandable = true,
  noZeroCell = false,
  columns,
  rowData,
}: PropsWithChildren<ExpandableRowProps<T>>): JSX.Element {
  const [open, setOpen] = useState(false);

  const button = expandable ? (
    <IconButton aria-label="delete" size="small" onClick={() => setOpen(!open)}>
      {open ? (
        <UnfoldLessRoundedIcon fontSize="large" />
      ) : (
        <UnfoldMoreRoundedIcon fontSize="large" />
      )}
    </IconButton>
  ) : null;

  const isZeroCell = expandable || !noZeroCell;
  const style = isZeroCell ? { paddingLeft: 0 } : { paddingLeft: undefined };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: '0 !important' } }}>
        {isZeroCell ? (
          <TableCell colSpan={1} width={50}>
            {button}
          </TableCell>
        ) : null}
        {columns.map((column, index) => (
          <CustomTableCell
            pure={column.pureContent}
            style={style}
            width={column.width}
            key={column.field + index}
            align={column.textAlign || 'left'}
            colSpan={column.colSpan || 1}
          >
            {column.customRender
              ? column.customRender(rowData, column)
              : rowData[column.field]}
          </CustomTableCell>
        ))}
      </TableRow>
      <TableRow className="no-height">
        <TableCell style={{ padding: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {children}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export function RenderName(data: SharedKpiModel): JSX.Element {
  const totalOpenKpis = useMemo(
    () =>
      data?.userSharedKpis?.filter((x) => x.status === KpiStatus.NotSubmitted)
        ?.length,
    [data],
  );

  const totalSubmittedKpis = useMemo(
    () =>
      data?.userSharedKpis?.filter((x) => x.status === KpiStatus.Submitted)
        ?.length,
    [data],
  );

  const totalApprovedKpis = useMemo(
    () =>
      data?.userSharedKpis?.filter((x) => x.status === KpiStatus.Approved)
        ?.length,
    [data],
  );

  return (
    <PeopleIconWrapper isImage>
      <Typography variant="body1">{data.kpiDefinition.name}</Typography>
      <Typography variant="body2">
        {data?.userSharedKpis?.length || 0} people
        {!!totalOpenKpis && `, ${totalOpenKpis} open`}
        {!!totalSubmittedKpis && `, ${totalSubmittedKpis} waiting for approval`}
        {!!totalApprovedKpis && `, ${totalApprovedKpis} approved`}
      </Typography>
    </PeopleIconWrapper>
  );
}

export function editableCell(
  data: SharedKpiModel,
  column: Column<SharedKpiModel>,
): JSX.Element {
  return (
    <MetricEditableCell
      id={data.id}
      status={data.status}
      type={data?.kpiDefinition?.type}
      value={data[column.field as keyof SharedKpiModel] as number}
    />
  );
}
