import { PlainObject } from '../types';

function getClassNamesFromObject(obj: PlainObject): string {
  return Object.keys(obj)
    .map((key) => (obj[key] ? key : null))
    .filter((v) => v)
    .join(' ');
}

export function cn(
  ...args: (string | boolean | PlainObject | undefined)[]
): string {
  return args
    .map((arg) => {
      if (Array.isArray(arg)) {
        return cn(...arg);
      }

      if (typeof arg === 'object') {
        return getClassNamesFromObject(arg);
      }

      return arg && String(arg);
    })
    .filter((v) => v)
    .join(' ');
}
