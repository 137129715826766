import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants/api';
import { marginReportsStoreKey } from './marginReports.const';
import { notificationAlert } from '../notifications';
import { formatErrorMessage } from '../../utils';

export const fetchMarginReports = createAsyncThunk(
  `${marginReportsStoreKey}/fetchMarginReport`,
  async (accountIds: string[]) => {
    const response = await axios.post(`${apiEndpoints.accountMarginReport()}`, {
      accountIds,
      fetchAllMembers: true,
    });
    return response.data;
  },
);

export const fetchAccounts = createAsyncThunk(
  `${marginReportsStoreKey}/fetchAccounts`,
  async (_, thunkAPI) => {
    try {
      const response = await axios.post(apiEndpoints.fetchAccounts());
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(
          formatErrorMessage(err, 'Fetching accounts has failed'),
          {
            variant: 'error',
          },
        ),
      );
      throw new Error(String(err));
    }
  },
);
