import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AnyAsyncThunk,
  PendingActionFromAsyncThunk,
  RejectedActionFromAsyncThunk,
} from '@reduxjs/toolkit/dist/matchers';
import { API_STATUS } from '../../constants';
import {
  BonusHistory,
  ContractorType,
  UserKpisBonusShare,
} from '../../types/contractor';
import { EditableKpiType } from '../../types/kpiAssign';
import { fetchUserBonusPlan } from '../userData';
import { contractorStoreKey } from './contractor.const';
import {
  fetchContractor,
  fetchKpiAllTimeMetrics,
  getAllAssignmentsWithHistoricalData,
  getKpiAssignment,
  getUpdatedBonuslist,
} from './contractor.thunks';
import { UserKpiAssignment, UpdatedBonusListItem } from './contractor.types';

export interface ContractorState {
  apiStatus: API_STATUS;
  contractorData: ContractorType;
  loadingAllResultsFor: string[];
  bonusHistory: BonusHistory[];
  isUpdatingHistoricalData: boolean;
  kpiToUpdate: EditableKpiType | null;
  allAssignments: UserKpiAssignment[];
  updatedBonuslist: UpdatedBonusListItem[];
}

const initialState: ContractorState = {
  apiStatus: API_STATUS.IDLE,
  loadingAllResultsFor: [],
  contractorData: {
    avatarUrl: '',
    id: '',
    zohoId: '',
    email: '',
    fullName: '',
    country: '',
    city: '',
    legalLocation: '',
    departmentId: '',
    designation: '',
    createdAt: '',
    updatedAt: '',
    deactivatedAt: null,
    deletedAt: null,
    department: {
      id: '',
      name: '',
      zohoId: '',
      parentId: '',
      managerId: '',
      createdAt: '',
      updatedAt: '',
      deletedAt: '',
    },
    individualKpis: [],
    sharedKpis: [],
    breadcrumbs: [],
    canModifyMetrics: false,
  },
  bonusHistory: [],
  isUpdatingHistoricalData: false,
  kpiToUpdate: null,
  allAssignments: [],
  updatedBonuslist: [],
};

export const contractorSlice = createSlice({
  name: contractorStoreKey,
  initialState,
  reducers: {
    clearContractor: () => initialState,
    clearAssignmentToEdit: (state) => {
      state.kpiToUpdate = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContractor.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(
        fetchContractor.fulfilled,
        (state, action: PayloadAction<ContractorType>) => {
          state.apiStatus = API_STATUS.IDLE;
          state.contractorData = action.payload;
        },
      )
      .addCase(fetchContractor.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
        state.contractorData = initialState.contractorData;
      });
    builder
      .addCase(
        fetchKpiAllTimeMetrics.pending,
        (state, pending: PendingActionFromAsyncThunk<AnyAsyncThunk>) => {
          state.loadingAllResultsFor.push(pending?.meta?.arg?.kpiId);
        },
      )
      .addCase(
        fetchKpiAllTimeMetrics.fulfilled,
        (
          state,
          action: PayloadAction<{ data: ContractorType; kpiId: string }>,
        ) => {
          const kpiIndex = state.contractorData.individualKpis.findIndex(
            (x) => x.id === action.payload.kpiId,
          );

          if (kpiIndex > -1) {
            state.contractorData.individualKpis[kpiIndex].stats =
              action.payload.data.individualKpis.find(
                (y) => y.id === action.payload.kpiId,
              )?.stats;
          } else {
            const sharedKpiIndex = state.contractorData.sharedKpis.findIndex(
              (x) =>
                x.id === action.payload.kpiId ||
                x.userSharedKpis.findIndex(
                  (y) => y.id === action.payload.kpiId,
                ) > -1,
            );

            const latestSharedKpiIndex =
              action.payload.data.sharedKpis.findIndex(
                (x) =>
                  x.id === action.payload.kpiId ||
                  x.userSharedKpis.findIndex(
                    (y) => y.id === action.payload.kpiId,
                  ) > -1,
              );

            if (sharedKpiIndex > -1) {
              state.contractorData.sharedKpis[sharedKpiIndex].userSharedKpis =
                action.payload.data.sharedKpis[
                  latestSharedKpiIndex
                ]?.userSharedKpis;
            }
          }
          state.loadingAllResultsFor = state.loadingAllResultsFor.filter(
            (x) => x !== action.payload.kpiId,
          );
        },
      )
      .addCase(
        fetchKpiAllTimeMetrics.rejected,
        (state, rejected: RejectedActionFromAsyncThunk<AnyAsyncThunk>) => {
          state.loadingAllResultsFor = state.loadingAllResultsFor.filter(
            (x) => x !== rejected?.meta?.arg?.kpiId,
          );
          state.contractorData = initialState.contractorData;
        },
      );

    // fetch user's bonus plan
    builder.addCase(
      fetchUserBonusPlan.fulfilled,
      (state, { payload }: PayloadAction<UserKpisBonusShare>) => {
        state.bonusHistory = payload.kpiBonusshares.map((entry, index) => {
          const bonusDetails = payload.bonusShareDetails[index];
          return bonusDetails
            ? { ...entry, ...{ bonusDetails: bonusDetails } }
            : { ...entry, ...{ bonusDetails: [] } };
        });
      },
    );

    builder
      .addCase(
        getKpiAssignment.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          const kpiOwnerId =
            payload.kpiDefinition.scope === 'SHARED'
              ? payload.kpiDefinition.kpiOwnerId
              : payload.kpiOwnerId;

          state.kpiToUpdate = {
            id: payload.id,
            bestCase: payload.bestCase,
            bonusShare: payload.bonusShare,
            endPeriod: payload.endPeriod,
            kpiDefinition: payload.kpiDefinition,
            kpiDefinitionId: payload.kpiDefinition.id,
            kpiOwnerId,
            startPeriod: payload.startPeriod,
            worstCase: payload.worstCase,
            effectiveFrom: undefined,
            monthWiseShare: false,
            bonusPlanEntries: payload.bonusPlanEntries,
            deletedAt: payload.deletedAt,
          };
        },
      )
      .addCase(getKpiAssignment.rejected, (state) => {
        state.kpiToUpdate = null;
      });

    builder
      .addCase(
        getAllAssignmentsWithHistoricalData.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.allAssignments = payload;
          state.isUpdatingHistoricalData = false;
        },
      )
      .addCase(getAllAssignmentsWithHistoricalData.pending, (state) => {
        state.isUpdatingHistoricalData = true;
      });

    builder
      .addCase(
        getUpdatedBonuslist.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.updatedBonuslist = payload;
        },
      )
      .addCase(getUpdatedBonuslist.pending, (state) => {
        state.updatedBonuslist = initialState.updatedBonuslist;
      });
  },
});

export const { clearContractor, clearAssignmentToEdit } =
  contractorSlice.actions;
