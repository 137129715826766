import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import MainPage from '../pages/mainPage/MainPage';
import { NotFound } from '../pages/notFound/notFound';
import ResolveDefaultPage from './ResolveDefaultPage';
import { Guard } from '../components/guard/guard';
import { rootRoutes } from './Routes.constants';
import { paths } from '../constants/paths';
import Contractor from '../features/contractor/Contractor';
import { BudgetDetails } from '../features/budgets/BudgetDetails';
import { MyPortfolio } from '../pages/myPortfolio/MyPortfolio';
import { Risks } from '../pages/risks/risks';
import GlobalTracker from '../features/globalTracker/GlobalTracker';
import { TimeAndPeople } from '../pages/timeAndPeople/timeAndPeople';

const CustomRoutes: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // Redirecting to /portfolio from /accounts
  useEffect(() => {
    const pathname = location.pathname;
    if (pathname.includes(paths.accounts)) {
      const newPath = pathname.replace(paths.accounts, paths.portfolio);
      navigate(newPath);
    }
  }, [location, navigate]);

  return (
    <Routes>
      <Route path={`${paths.rockyBonuses}/:userId`} element={<Contractor />} />

      <Route path="/" element={<MainPage />}>
        {rootRoutes.map(({ path, permissions, element: Element }) => (
          <Route
            key={path}
            path={path}
            element={
              <Guard permissions={permissions}>
                <Element />
              </Guard>
            }
          />
        ))}
        <Route
          path={`${paths.portfolio}/:accountId`}
          element={
            <Guard permissions={['hasAccounts', 'isCompensationManager']}>
              <MyPortfolio />
            </Guard>
          }
        >
          {[
            {
              linkTo: '',
              element: <Navigate to={paths.ratesAndMargins} />,
            },
            {
              linkTo: paths.ratesAndMargins,
              element: <BudgetDetails />,
            },
            {
              linkTo: paths.timeAndPeople,
              element: <TimeAndPeople />,
            },
            {
              linkTo: paths.projectStatus,
              element: <GlobalTracker />,
            },
            {
              linkTo: paths.risks,
              element: <Risks />,
            },
          ].map(({ linkTo, element }) => (
            <React.Fragment key={linkTo}>
              <Route path={linkTo} element={element} />
              <Route path={`:projectId/${linkTo}`} element={element} />
            </React.Fragment>
          ))}
        </Route>

        <Route index element={<ResolveDefaultPage />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default CustomRoutes;
