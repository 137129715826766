import Axios from 'axios';
import { AuthService } from './auth-service';
import { AppConfig } from '../types';
import { ServerStatusCode } from '../constants';
import { getApiHostname } from '../utils/getLocalHostname';
import { auth } from '../config/firebase';
import { getCustomEmail } from '../hooks/useAuth';
export class InterceptorService {
  public static init(appConfig: AppConfig): void {
    Axios.defaults.baseURL = `${getApiHostname()}${appConfig.apiBaseUrl}`;

    InterceptorService.addRequestInterceptor();
    InterceptorService.addResponseInterceptor();
  }

  private static requestInterceptorId = -1;
  private static responseInterceptorId = -1;

  private static addRequestInterceptor(): void {
    if (InterceptorService.requestInterceptorId === -1) {
      InterceptorService.requestInterceptorId = Axios.interceptors.request.use(
        async (config) => {
          const customEmail = getCustomEmail();
          const token = customEmail
            ? AuthService.getToken()
            : await auth.currentUser?.getIdToken();
          const newConfig = {
            ...config,
            headers: {
              ...config.headers,
              ...(token ? { 'x-user-token': token } : {}),
            },
          };
          if (!customEmail && token) {
            AuthService.setToken(token);
          }
          return newConfig;
        },
      );
    }
  }

  private static addResponseInterceptor(): void {
    if (InterceptorService.responseInterceptorId === -1) {
      InterceptorService.responseInterceptorId =
        Axios.interceptors.response.use(
          (response) => response,
          (error) => {
            if (error.response.status === ServerStatusCode.UNAUTHORIZED) {
              AuthService.clearToken();
            }

            return Promise.reject(error);
          },
        );
    }
  }
}
