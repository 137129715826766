import React from 'react';
import { Box, Card, IconButton, Typography, useTheme } from '@mui/material';
import { ListItemCard } from '../../../components';
import { KpiStatus, KpiStatusLabels } from '../../../constants';
import { DepartmentType, IndividualKpi, SharedKpiModel } from '../../../types';
import { plural } from '../../../utils';
import { MemberKpisRows } from '../MemberKpisRows/MemberKpisRows';
import { SharedKpiRows } from '../SharedKpiRows/SharedKpiRows';
import { TeamTable } from '../TeamTable/TeamTable';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { borderRadius } from '../../../theme';
import _uniqBy from 'lodash/uniqBy';
import { LIST_GROUPED_BY_STATUS } from '../Approvals.const';

interface Props {
  departments: DepartmentType[];
  expandAll: boolean;
}

export function ListGroupedByStatus({
  departments,
  expandAll,
}: Props): JSX.Element {
  const byStatus = groupDepartmentsByKpiStatus(departments);
  const theme = useTheme();

  const renderDepartment = (department: DepartmentType): JSX.Element | null =>
    department?.members.some((x) => x.individualKpis.length) ? (
      <TeamTable key={department.id} title={department.name}>
        {department.members.map((member) => (
          <MemberKpisRows key={member.id} member={member} />
        ))}
      </TeamTable>
    ) : null;

  const getDepartmentList = (
    departments: DepartmentType[],
  ): DepartmentType[] => {
    if (!departments.length) {
      return [];
    }

    return [
      ...departments,
      ...departments.flatMap((x) => getDepartmentList(x.subDepartments)),
    ];
  };

  const getDescription = (kpiGroup: GroupedKpiResult) => {
    return kpiGroup.sharedKpis.length === 0 &&
      kpiGroup.individualKpisCount === 0
      ? kpiGroup.status === KpiStatus.NotSubmitted
        ? 'All metrics have results'
        : kpiGroup.status === KpiStatus.Submitted
        ? 'All metrics are approved'
        : 'No approved metrics yet'
      : `${
          kpiGroup.sharedKpis.length
            ? `${kpiGroup.sharedKpis.length} shared metric${plural(
                kpiGroup.sharedKpis.length,
              )},`
            : ''
        } ${kpiGroup.individualKpisCount} individual metric${plural(
          kpiGroup.individualKpisCount,
        )}`;
  };

  const renderEmptyCard = (kpiGroup: GroupedKpiResult) => (
    <Card
      key={kpiGroup.status}
      variant={'outlined'}
      sx={{ marginBottom: 1, overflow: 'initial' }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: '1em',
          background: theme.palette.background.paper,
          borderRadius: borderRadius,
          position: 'initial',
          paddingBottom: theme.spacing(2),
          zIndex: 2,
        }}
      >
        <Box ml={'6px'}>
          <IconButton aria-label="Expand/collapse" size="small">
            {kpiGroup.status === KpiStatus.Approved ? (
              <AccessTimeRoundedIcon />
            ) : (
              <PlaylistAddCheckRoundedIcon />
            )}
          </IconButton>
        </Box>
        <Box pl={2}>
          <Typography variant={'h5'}>
            {KpiStatusLabels[kpiGroup.status]}
          </Typography>
          <Typography variant="body2">{getDescription(kpiGroup)}</Typography>
        </Box>
      </Box>
    </Card>
  );

  return (
    <Box data-testid={LIST_GROUPED_BY_STATUS}>
      {byStatus.map((kpiGroup) =>
        kpiGroup.sharedKpis.length === 0 &&
        kpiGroup.individualKpisCount === 0 ? (
          renderEmptyCard(kpiGroup)
        ) : (
          <ListItemCard
            expanded={expandAll}
            enlargeTitle
            key={kpiGroup.status}
            title={KpiStatusLabels[kpiGroup.status]}
            description={getDescription(kpiGroup)}
          >
            <Box paddingX={2}>
              <TeamTable key={kpiGroup.status}>
                {kpiGroup.sharedKpis.map((sharedKpi) => (
                  <SharedKpiRows key={sharedKpi.id} data={sharedKpi} />
                ))}
              </TeamTable>
              {getDepartmentList(kpiGroup.departments).map(renderDepartment)}
            </Box>
          </ListItemCard>
        ),
      )}
    </Box>
  );
}

export interface GroupedKpiResult {
  status: KpiStatus;
  departments: DepartmentType[];
  individualKpisCount: number;
  sharedKpis: SharedKpiModel[];
}

function departmentHasKpis(department: DepartmentType): boolean {
  return !!(
    department.sharedKpis.length ||
    department.members.some(
      (mem) => mem.sharedKpis.length || mem.individualKpis.length,
    ) ||
    department.subDepartments.some(departmentHasKpis)
  );
}

function getDeparmentsWithKpisFilteredByStatus(
  departments: DepartmentType[],
  status: KpiStatus,
): DepartmentType[] {
  if (departments.length === 0) {
    return [];
  }

  return departments
    .map((dep) => ({
      ...dep,
      sharedKpis: dep.sharedKpis.filter((kpi) => kpi.status === status),
      members: dep.members.map((member) => ({
        ...member,
        individualKpis: member.individualKpis.filter(
          (kpi) => kpi.status === status,
        ),
        sharedKpis: member.sharedKpis.filter((kpi) => kpi.status === status),
      })),
      subDepartments: getDeparmentsWithKpisFilteredByStatus(
        dep.subDepartments,
        status,
      ),
    }))
    .filter(departmentHasKpis);
}

function getIndividualKpisByStatus(
  departments: DepartmentType[],
  status: KpiStatus,
): IndividualKpi[] {
  if (!departments.length) {
    return [];
  }

  const individualKpis = [
    ...departments.flatMap((dep) => [
      ...dep.members.flatMap((x) =>
        x.individualKpis.filter((x) => x.status === status),
      ),
      ...getIndividualKpisByStatus(dep.subDepartments, status),
    ]),
  ];

  return _uniqBy(individualKpis, 'id');
}

function getSharedKpisEqualedToStatus(
  kpis: SharedKpiModel[],
  status: KpiStatus,
): SharedKpiModel[] {
  return kpis
    .map((kpi) => {
      const userSharedKpis = kpi.userSharedKpis.filter(
        (v) => v.status === status,
      );

      return userSharedKpis.length
        ? {
            ...kpi,
            userSharedKpis,
          }
        : null;
    })
    .filter((v) => v !== null) as SharedKpiModel[];
}

function getSharedKpisByStatus(
  departments: DepartmentType[],
  status: KpiStatus,
): SharedKpiModel[] {
  if (!departments.length) {
    return [];
  }

  const sharedKpis = [
    ...departments.flatMap((dep) => [
      ...getSharedKpisEqualedToStatus(dep.sharedKpis, status),
      ...getSharedKpisByStatus(dep.subDepartments, status),
    ]),
  ];

  return _uniqBy(sharedKpis, 'id');
}

export function groupDepartmentsByKpiStatus(
  departments: DepartmentType[],
): GroupedKpiResult[] {
  const results: GroupedKpiResult[] = [];

  //filter tobe removed after Rejected status implemented
  const statuses = Object.values(KpiStatus).filter(
    (x) => x !== KpiStatus.Rejected,
  );

  for (const status of statuses) {
    results.push({
      status: status,
      departments: getDeparmentsWithKpisFilteredByStatus(departments, status),
      individualKpisCount: getIndividualKpisByStatus(departments, status)
        .length,
      sharedKpis: getSharedKpisByStatus(departments, status),
    });
  }

  return results;
}
