import * as yup from 'yup';

export const compensationMarginsValidationSchema = yup
  .object({
    compensationRate: yup
      .number()
      .positive()
      .min(0)
      .label('Pay rate')
      .required(),
    billRate: yup.number().positive().min(0).label('Bill rate').required(),
    allocation: yup
      .number()
      .positive()
      .min(0)
      .max(100)
      .label('Allocation')
      .required(),
  })
  .strict();
