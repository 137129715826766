import React, { ChangeEvent, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  approvalsSelectors,
  approvalsSlice,
  submitKpi,
} from '../../../redux/approvals';
import { ApprovalsPageMode } from '../Approvals.const';
import { KpiStatus, KPI_TYPES } from '../../../constants';
import { EditableCell } from '../../../components/tableCellRenders/EditableCell';
import {
  renderAdornment,
  formatByDefinitionType,
} from '../../../components/tableCellRenders';
import { is } from '../../../utils';
import { useAppDispatch } from '../../../redux/hooks';

interface Props {
  id: string;
  status?: KpiStatus;
  value?: number | string | null;
  type?: KPI_TYPES;
  disabled?: boolean;
  valueColor?: string;
  onChange?: (value: string | number) => void;
}

export function MetricEditableCell({
  id,
  status,
  value,
  type,
  disabled,
  valueColor,
  onChange,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const realValue = is.nullOrUndefined(value) ? '' : value;
  const [currentValue, setCurrentValue] = useState<string | number>(realValue);
  const pageMode: ApprovalsPageMode = useSelector(
    approvalsSelectors.getApprovalsPageMode,
  );

  return useMemo(() => {
    const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;

      if (e.target.value && isNaN(Number(newValue) as number)) {
        return;
      }

      setCurrentValue(newValue);
      dispatch(
        approvalsSlice.actions.setMetricValue({
          id,
          // todo: need to be replaced to InputMask
          value: newValue,
        }),
      );
      if (onChange) {
        onChange(newValue);
      }
    };

    const onBlur = () => {
      if (pageMode === ApprovalsPageMode.Edit) {
        return;
      }
      dispatch(submitKpi(id));
    };

    const getValue = (): number | string | null | JSX.Element => {
      if (pageMode === ApprovalsPageMode.Review) {
        return formatByDefinitionType(
          type,
          is.undefined(status) ||
            status === KpiStatus.Submitted ||
            status === KpiStatus.Approved
            ? value
            : null,
        );
      }
      return currentValue === null ||
        (typeof currentValue === 'number' && isNaN(currentValue))
        ? ''
        : currentValue;
    };

    return (
      <EditableCell
        adornment={renderAdornment(type)}
        name={id}
        editable={pageMode === ApprovalsPageMode.Edit}
        value={getValue() as string}
        onChange={onValueChange}
        onBlur={onBlur}
        disabled={disabled}
        valueColor={valueColor}
        inputProps={{
          inputMode: 'numeric',
        }}
      />
    );
  }, [
    pageMode,
    disabled,
    id,
    type,
    valueColor,
    currentValue,
    dispatch,
    onChange,
    status,
    value,
  ]);
}
