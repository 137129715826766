const kpiValueSubmissionKey = '/kpi-value-submission';

export const submissions = () => {
  return kpiValueSubmissionKey;
};

export const submitKpis = () => {
  return `${kpiValueSubmissionKey}/submit`;
};

export const approveKpis = () => {
  return `${kpiValueSubmissionKey}/approve`;
};

export const adjustKpis = () => {
  return `${kpiValueSubmissionKey}/adjust-values`;
};

export const closeCurrentPeriod = () => {
  return `${kpiValueSubmissionKey}/close-current`;
};

export const checkApproved = () => {
  return `${kpiValueSubmissionKey}/check-approved`;
};

export const calculateKpiBonus = () => {
  return `${kpiValueSubmissionKey}/calculate-kpi-bonus`;
};
