import { PlainObject } from '../../../../types';
import { ContractorKpiStatsPeriod } from '../../../../types/contractor';
import { getPeriodYearMonth } from '../../Contractor.utils';

export const groupAllTimePeriods = (
  periods?: ContractorKpiStatsPeriod[],
): ContractorKpiStatsPeriod[] => {
  const result = periods
    ? periods?.reduce((acum: PlainObject<number>, period) => {
        const [year] = getPeriodYearMonth(period.period);
        const yearKey = year.toString();
        return {
          ...acum,
          [yearKey]: (acum[yearKey] ?? 0) + period.payout,
        };
      }, {})
    : {};

  return Object.keys(result).map(
    (key) =>
      ({
        period: Number(`${key}01`),
        payout: result[key],
      } as ContractorKpiStatsPeriod),
  );
};

export const sortPeriods = (
  periods?: ContractorKpiStatsPeriod[],
): ContractorKpiStatsPeriod[] | undefined => {
  return periods?.slice().sort((a, b) => b.period - a.period);
};
