import { Box, styled } from '@mui/material';

export const FallbackWrapper = styled(Box)<{ someParam?: boolean }>(
  (params) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
    color: params.theme.palette.text.primary,
  }),
);
