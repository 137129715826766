import React, { useCallback, useState } from 'react';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import NavigationLink from './NavigationLink';
import { useAppSelector } from '../../redux/hooks';
import { userDataSelectors } from '../../redux/userData';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import ContactsRoundedIcon from '@mui/icons-material/ContactsRounded';
import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import { paths } from '../../constants/paths';
import SecurityIcon from '@mui/icons-material/Security';
import { ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import {
  BugReportOutlined,
  KeyboardArrowUpRounded,
  MenuRounded,
} from '@mui/icons-material';
import ReportIssueModal from '../../features/reportIssueModal/ReportIssueModal';

export const NAVIGATION_TEST_ID = 'navigation-test-id';

const Navigation: React.FC = () => {
  const user = useAppSelector(userDataSelectors.getUserData);
  const [isSecondaryNavOpen, setNavOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleSecondaryOptionsToggle = useCallback(() => {
    setNavOpen(!isSecondaryNavOpen);
  }, [isSecondaryNavOpen, setNavOpen]);

  const handleToggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen, setIsModalOpen]);

  if (!user) {
    return null;
  }

  const {
    canViewListOfContractors,
    canManageKpiDefinitions,
    canSubmitKpiValues,
    canApproveKpiValues,
    canGenerateReport,
    canViewMyMetrics,
    hasAccounts,
    isBillRateManager,
    isCompensationManager,
    isFinance,
  } = user.permissions;

  const hasBonusesAccess =
    canViewMyMetrics ||
    canViewListOfContractors ||
    canSubmitKpiValues ||
    canApproveKpiValues ||
    canGenerateReport;

  const hasMarginTrackerAccess =
    hasAccounts && (isBillRateManager || isCompensationManager);

  return (
    <List data-testid={NAVIGATION_TEST_ID} sx={{ mb: 4, ml: 1, mr: 2 }}>
      {hasBonusesAccess && (
        <>
          <Typography variant="body2" ml={2.5} mt={2} mb={0.5}>
            Bonuses
          </Typography>
          {canViewMyMetrics && (
            <NavigationLink
              to={paths.myMetrics}
              icon={<HowToRegRoundedIcon />}
              title="My metrics"
            />
          )}
          {canViewListOfContractors && (
            <NavigationLink
              to={paths.contractors}
              icon={<ContactsRoundedIcon />}
              title="Teams"
            />
          )}
          {canSubmitKpiValues && (
            <NavigationLink
              to={paths.submissions}
              icon={<AddchartRoundedIcon />}
              title="Monthly results"
            />
          )}
          {canApproveKpiValues && (
            <NavigationLink
              to={paths.approvals}
              icon={<EventAvailableRoundedIcon />}
              title="Approvals"
            />
          )}
          {canGenerateReport && (
            <NavigationLink
              to={paths.reports}
              icon={<SimCardDownloadOutlinedIcon />}
              title="Reports"
            />
          )}
          {isFinance && (
            <NavigationLink
              to={paths.clientInvoice}
              icon={<SimCardDownloadOutlinedIcon />}
              title="Client Invoices"
            />
          )}
        </>
      )}
      {canManageKpiDefinitions && (
        <>
          <Typography variant="body2" ml={2.5} mt={2} mb={0.5}>
            Administration
          </Typography>
          <NavigationLink
            to={paths.kpiDefinitions}
            icon={<WorkspacePremiumOutlinedIcon />}
            title="KPI definitions"
          />
        </>
      )}
      {hasMarginTrackerAccess && (
        <>
          <Typography variant="body2" ml={2.5} mt={2} mb={0.5}>
            Portfolio
          </Typography>
          <NavigationLink
            to={paths.portfolio}
            icon={<CalculateOutlinedIcon />}
            title={'My portfolio'}
          />
        </>
      )}

      <ListItemButton
        onClick={handleSecondaryOptionsToggle}
        sx={{ pl: 0, pt: 1, pb: 1, borderRadius: 1 }}
      >
        <ListItemIcon sx={{ justifyContent: 'center' }}>
          {isSecondaryNavOpen ? <KeyboardArrowUpRounded /> : <MenuRounded />}
        </ListItemIcon>
        <ListItemText
          primary="More"
          primaryTypographyProps={{
            mb: '2px',
          }}
          secondary="Report issue, terms"
        />
      </ListItemButton>
      {isSecondaryNavOpen && (
        <>
          <ListItemButton
            onClick={handleToggleModal}
            sx={{ pl: 0, pt: 1, pb: 1, borderRadius: 1 }}
          >
            <ListItemIcon sx={{ justifyContent: 'center' }}>
              <BugReportOutlined />
            </ListItemIcon>
            <ListItemText primary="Report issue" />
          </ListItemButton>
          {isModalOpen && (
            <ReportIssueModal
              isModalOpen={isModalOpen}
              handleCloseModal={handleToggleModal}
            />
          )}
          <NavigationLink
            to={paths.termsOfUse}
            icon={<SecurityIcon />}
            title={'Terms of use'}
          />
        </>
      )}
    </List>
  );
};

export default Navigation;
