import { KpiDefinitionTrueupFormula } from '../../../../../../constants';

export const trueUpPeriods = [
  {
    value: null,
    label: 'None',
  },
  {
    value: 3,
    label: 'Quarterly',
  },
  {
    value: 12,
    label: 'Yearly',
  },
];

export const trueUpFormulaLabels = {
  [KpiDefinitionTrueupFormula.CORRECTED]: 'Corrected',
  [KpiDefinitionTrueupFormula.SUMMARIZED_FLOORED]: 'Summarized floored',
};

export const trueUpOffests = [
  {
    value: 0,
    label: 'No offset',
  },
  {
    value: 1,
    label: '1 month after',
  },
];
