import React, { useEffect, useState, ReactNode, useCallback } from 'react';
import { useFormik } from 'formik';
import { NumericFormat } from 'react-number-format';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { customNumberFormat } from '../../../../utils';
import {
  KpiDefinitionCreateModel,
  ContractorType,
  UserBaseModel,
  PlainObject,
  KpiDefinitionRequestModel,
  DepartmentBaseType,
} from '../../../../types';
import {
  KPI_TYPES,
  KPI_SCOPES,
  KPI_FREQUENCY,
  API_STATUS,
} from '../../../../constants';
import { validationSchema } from './validationSchema';
import { getKpiDefinitionValues } from './kpiDefinitionModal.const';
import { useSelector } from 'react-redux';
import {
  fetchAvailableKpiOwners,
  createNewKpiDefinition,
  kpiDefinitionsSelectors,
  kpiDefinitionsSlice,
  fetchKpiDefinitionsCategories,
  saveKpiDefinition,
} from '../../../../redux/kpiDefinitions';
import {
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  ToggleButtonGroup,
  Typography,
  useTheme,
  Chip,
  Tooltip,
} from '@mui/material';
import { debounce } from '../../../../utils/debounce';
import { TooltipToggleButton } from '../../../../components';
import { KPI_SUB_TYPES, KPI_F_TYPE } from '../../kpiDefinitions.const';
import Stack from '@mui/material/Stack';
import { KpiDefinitionCreateFormModel } from './kpiDefinitionModal.types';
import {
  KpiDefinitionCategoryItem,
  KpiDefinitionItemWithOwners,
} from '../../../../redux/kpiDefinitions/kpiDefinitions.types';
import {
  fetchFinanceDepartmentId,
  fetchRootDepartments,
  rootDepartmentsSelectors,
} from '../../../../redux/rootDepartments';
import { useSnackbar } from 'notistack';
import { useAppDispatch } from '../../../../redux/hooks';
import { isArray } from 'lodash';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { TrueUpForm } from './components/trueUpForm/TrueUpForm';

interface Props {
  onClosed?: () => void;
  onSaved: () => void;
  handleActivateDeactivate?: () => void;
  kpiDefinition?: KpiDefinitionItemWithOwners | null;
}

export function KpiDefinitionModal({
  onClosed = () => null,
  onSaved,
  handleActivateDeactivate,
  kpiDefinition = null,
}: Props): JSX.Element {
  const { palette } = useTheme();

  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [isOpen, setCreateModalVisibility] = useState<boolean>(true);

  const createdKpiDefinition = useSelector(
    kpiDefinitionsSelectors.getCreatedKpiDefinition,
  );
  const createdKpiDefinitionLoading = useSelector(
    kpiDefinitionsSelectors.getCreatedKpiDefinitionLoading,
  );
  const availableKpiOwners: ContractorType[] | undefined = useSelector(
    kpiDefinitionsSelectors.getAvailableKpiOwners,
  );
  const rootDepartments: DepartmentBaseType[] = useSelector(
    rootDepartmentsSelectors.getRootDepartmentsList,
  );

  const rootDepartmentsApiStatus = useSelector(
    rootDepartmentsSelectors.getRootDepartmentsApiStatus,
  );

  const isRootDepartmentsLoading =
    rootDepartmentsApiStatus === API_STATUS.LOADING;

  const categories = useSelector(
    kpiDefinitionsSelectors.getKpiDefinitionsCategories,
  );

  const financeDepartmentId = useSelector(
    rootDepartmentsSelectors.getFinanceDepartmentId,
  );

  const financeRootDepartment = rootDepartments.find(
    (x) => x.id === financeDepartmentId,
  );

  const isEdit = kpiDefinition !== null;

  const formik = useFormik<KpiDefinitionCreateFormModel>({
    initialValues: getKpiDefinitionValues(kpiDefinition, categories),
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      if (!values || !formik.isValid) {
        return;
      }

      const {
        subType,
        type,
        kpiOwner,
        departments,
        category,
        defaultBestCase,
        defaultWorstCase,
        ...data
      } = values;

      if (isEdit && kpiDefinition) {
        const formData: KpiDefinitionRequestModel = {
          ...data,
          defaultBestCase: type === KPI_F_TYPE.FIXED ? null : defaultBestCase,
          defaultWorstCase: type === KPI_F_TYPE.FIXED ? null : defaultWorstCase,
          type: `${type}_${subType}` as KPI_TYPES,
          departmentIds: (departments || []).map(({ id }) => id),
          kpiOwnerId: kpiOwner?.id || '',
          categoryId: category?.id || null,
          scope: undefined,
          frequency: undefined,
        };

        dispatch(
          saveKpiDefinition({
            id: kpiDefinition.id,
            data: formData,
          }),
        );

        onSaved();
        closeDialog();
        dispatch(kpiDefinitionsSlice.actions.resetKpiDefinitionModal());
      } else {
        const formData: KpiDefinitionRequestModel = {
          ...data,
          defaultBestCase: type === KPI_F_TYPE.FIXED ? null : defaultBestCase,
          defaultWorstCase: type === KPI_F_TYPE.FIXED ? null : defaultWorstCase,
          type: `${type}_${subType}` as KPI_TYPES,
          departmentIds: (departments || []).map(({ id }) => id),
          kpiOwnerId: kpiOwner?.id || '',
          categoryId: category?.id || undefined,
        };
        dispatch(createNewKpiDefinition(formData));
      }
    },
  });

  const closeDialog = useCallback(() => {
    setCreateModalVisibility(false);

    debounce(() => {
      onClosed();
    }, 500);
  }, [setCreateModalVisibility, onClosed]);

  const { errors, handleBlur, touched, submitCount } = formik;

  useEffect(() => {
    debounce(() => {
      dispatch(fetchRootDepartments());
      dispatch(fetchAvailableKpiOwners());
      dispatch(fetchKpiDefinitionsCategories());
      dispatch(fetchFinanceDepartmentId());
    }, 300);

    return () => {
      debounce.decline();
      dispatch(kpiDefinitionsSlice.actions.resetKpiDefinitionModal());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!createdKpiDefinition) {
      return;
    }

    onSaved();
    closeDialog();
    dispatch(kpiDefinitionsSlice.actions.resetKpiDefinitionModal());
  }, [createdKpiDefinition, closeDialog, dispatch, onSaved]);

  useEffect(() => {
    if (formik.values.type !== KPI_F_TYPE.FIXED) {
      return;
    }

    formik.setValues({
      ...formik.values,
      defaultWorstCase: 0,
      defaultBestCase: 0,
    });
    // eslint-disable-next-line
  }, [formik.values.type]);

  useEffect(() => {
    if (
      formik.values.defaultBestCase === formik.values.defaultWorstCase &&
      !!formik.values.defaultBestCase &&
      formik.dirty
    ) {
      debounce(() => {
        enqueueSnackbar('Best case and worst case should not be same', {
          key: 'best_worst_same',
          variant: 'error',
        });
        formik.validateForm();
      }, 300);
    } else if (
      formik.values.defaultBestCase !== formik.values.defaultWorstCase &&
      !!formik.values.defaultBestCase &&
      formik.dirty
    ) {
      debounce.decline();
      closeSnackbar('best_worst_same');
      formik.validateForm();
    }
    // eslint-disable-next-line
  }, [
    formik.values.defaultBestCase,
    formik.values.defaultWorstCase,
    closeSnackbar,
    enqueueSnackbar,
  ]);

  useEffect(() => {
    formik.setValues(getKpiDefinitionValues(kpiDefinition, categories));
    // eslint-disable-next-line
  }, [categories, kpiDefinition]);

  const handleKpiOwnerChange = (
    e: React.SyntheticEvent,
    option: UserBaseModel | null,
  ) => {
    if (option === null) return;

    formik.setFieldValue('kpiOwner', option);
  };

  const handleCustomNumberFormatChange = (
    name: string,
    value: string | undefined,
  ) => {
    const newValue = customNumberFormat(value || '');

    formik.setFieldValue(name, newValue);
  };

  const handleChange = useCallback(
    (name: string, value: string | undefined) => {
      if (isNaN(Number(value) as number)) {
        return;
      }

      formik.setFieldValue(name, Number(value));
    },
    [formik],
  );

  const getErrorForField = (
    field: keyof KpiDefinitionCreateModel,
  ): ReactNode => {
    return (isOpen && touched[field] && errors[field]) as ReactNode;
  };

  const handleActivateDeactivateClick = () => {
    handleActivateDeactivate && handleActivateDeactivate();
    closeDialog();
  };

  const handleDepartmentsChange = (
    value: DepartmentBaseType | DepartmentBaseType[] | null,
  ) => {
    if (
      isArray(value) &&
      value.length > 1 &&
      financeRootDepartment &&
      value.findIndex((x) => x.id === financeDepartmentId) === -1
    ) {
      value.push(financeRootDepartment);
    }

    formik.setFieldValue('departments', value as DepartmentBaseType[]);
  };

  const getDepartmentChipDeleteIcon = (option: DepartmentBaseType) => {
    return disableFinanceDepartmentDelete(option) ? (
      <Tooltip
        title={
          'Cross-department metrics are automatically assigned to Finance to be managed by them'
        }
      >
        <HelpOutlineIcon />
      </Tooltip>
    ) : (
      <CancelRoundedIcon />
    );
  };

  const disableFinanceDepartmentDelete = (option: DepartmentBaseType) =>
    option.id === financeDepartmentId && formik.values.departments.length > 2;

  const handleDepartmentsDelete = (option: DepartmentBaseType) => {
    if (disableFinanceDepartmentDelete(option)) {
      return;
    }

    handleDepartmentsChange(
      formik.values.departments.filter((x) => x.id !== option.id),
    );
  };

  const renderWorstBestCases = () => {
    return (
      <>
        <Grid item xs={3}>
          <NumericFormat
            allowNegative={false}
            customInput={TextField}
            fullWidth
            label="Worst case"
            id="defaultWorstCase"
            variant="filled"
            InputProps={{
              endAdornment:
                formik.values.subType === KPI_SUB_TYPES.PERCENTAGE ? (
                  <InputAdornment position="start">%</InputAdornment>
                ) : null,
            }}
            name="defaultWorstCase"
            InputLabelProps={{
              shrink: true,
            }}
            inputMode="decimal"
            onBlur={handleBlur}
            onValueChange={({ value }) =>
              handleChange('defaultWorstCase', value)
            }
            value={formik.values.defaultWorstCase || null}
            error={Boolean(getErrorForField('defaultWorstCase'))}
            helperText={getErrorForField('defaultWorstCase')}
          />
        </Grid>
        <Grid item xs={3}>
          <NumericFormat
            allowNegative={false}
            customInput={TextField}
            fullWidth
            id="defaultBestCase"
            InputProps={{
              endAdornment:
                formik.values.subType === KPI_SUB_TYPES.PERCENTAGE ? (
                  <InputAdornment position="start">%</InputAdornment>
                ) : null,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputMode="decimal"
            label="Best case"
            name="defaultBestCase"
            onBlur={handleBlur}
            onValueChange={({ value }) =>
              handleChange('defaultBestCase', value)
            }
            value={formik.values.defaultBestCase || null}
            variant="filled"
            error={Boolean(getErrorForField('defaultBestCase'))}
            helperText={getErrorForField('defaultBestCase')}
          />
        </Grid>
      </>
    );
  };

  const renderToggle = (disabled = false) => (
    <ToggleButtonGroup
      value={formik.values.subType}
      size="small"
      sx={{ top: -8, position: 'relative' }}
      exclusive
      aria-label="Percentage or absolute"
      onChange={(_, newToggleValue) =>
        formik.setFieldValue('subType', newToggleValue)
      }
      disabled={disabled}
    >
      <TooltipToggleButton
        title="Percentage"
        value={KPI_SUB_TYPES.PERCENTAGE}
        sx={{
          width: '35px',
        }}
        disabled={disabled}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: 500,
            ...(disabled && { color: `${palette.text.secondary} !important` }),
          }}
        >
          %
        </Typography>
      </TooltipToggleButton>
      <TooltipToggleButton
        title="Absolute"
        value={KPI_SUB_TYPES.ABSOLUTE}
        sx={{
          width: '35px',
        }}
        disabled={disabled}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: 500,
            ...(disabled && { color: `${palette.text.secondary} !important` }),
          }}
        >
          #
        </Typography>
      </TooltipToggleButton>
    </ToggleButtonGroup>
  );

  const renderRadioGroup = (
    name: keyof KpiDefinitionCreateModel,
    label: string,
    collection: PlainObject,
    children: JSX.Element | null,
    divider = true,
    disabled = false,
  ) => (
    <Grid item xs={12} sx={{ mt: 2, mb: -1 }}>
      <FormLabel>{label}</FormLabel>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={1}>
          <RadioGroup
            row
            name={name}
            value={formik.values[name]}
            onChange={formik.handleChange}
          >
            {Object.keys(collection).map((key) => (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio disabled={disabled} />}
                label={collection[key]}
              />
            ))}
          </RadioGroup>
        </Stack>
        <Stack justifyContent="end">{children}</Stack>
      </Stack>

      {divider && (
        <Box sx={{ mt: 3, mb: 1 }}>
          <Divider />
        </Box>
      )}
    </Grid>
  );

  const scopeElement = renderRadioGroup(
    'scope',
    'Result type',
    {
      [KPI_SCOPES.INDIVIDUAL]: 'Individual',
      [KPI_SCOPES.SHARED]: 'Shared',
    },
    null,
    false,
    isEdit,
  );
  const frequencyElement = renderRadioGroup(
    'frequency',
    'Frequency',
    {
      [KPI_FREQUENCY.MONTHLY]: 'Monthly',
      [KPI_FREQUENCY.QUARTERLY]: 'Quarterly',
    },
    null,
    false,
    isEdit,
  );
  const typeElement = renderRadioGroup(
    'type',
    'Default values',
    {
      [KPI_F_TYPE.VARIABLE]: 'Variable',
      [KPI_F_TYPE.FIXED]: 'Fixed',
    },
    renderToggle(isEdit),
    false,
    isEdit,
  );

  return (
    <Dialog open={isOpen} scroll="body">
      <DialogTitle>{isEdit ? 'Edit metric' : 'New metric'}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="kpiDefinitionId"
              fullWidth
              variant="filled"
              name="name"
              label="Target metric name"
              onBlur={handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              error={Boolean(getErrorForField('name'))}
              helperText={getErrorForField('name')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="kpiDefinitionId"
              fullWidth
              variant="filled"
              name="description"
              label="Description"
              onBlur={handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
              error={Boolean(getErrorForField('description'))}
              helperText={getErrorForField('description')}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete<KpiDefinitionCategoryItem, boolean>
              id="category"
              fullWidth
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  variant="filled"
                  helperText="Leave blank for dymanic per each department"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {!categories.length && (
                          <CircularProgress color="inherit" size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
              value={formik.values.category}
              onChange={(e, value) => {
                formik.setFieldValue(
                  'category',
                  value as KpiDefinitionCategoryItem,
                );
              }}
              options={categories}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete<DepartmentBaseType, boolean>
              id="departments"
              multiple
              disableCloseOnSelect
              // disabled={isEdit}
              limitTags={2}
              loading={isRootDepartmentsLoading}
              options={rootDepartments}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Departments" variant="filled" />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={formik.values.departments}
              onChange={(e, value) => handleDepartmentsChange(value)}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option.name}
                    {...getTagProps({ index })}
                    key={option.id}
                    deleteIcon={getDepartmentChipDeleteIcon(option)}
                    onDelete={() => handleDepartmentsDelete(option)}
                    sx={{ m: '6px' }}
                  />
                ))
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete<UserBaseModel>
              id="kpiOwner"
              value={formik.values.kpiOwner || null}
              onChange={handleKpiOwnerChange}
              onBlur={handleBlur}
              options={availableKpiOwners || []}
              getOptionLabel={(option) => option.fullName}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              loading={!availableKpiOwners}
              clearIcon={null}
              componentsProps={{ clearIndicator: { disabled: true } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onBlur={handleBlur}
                  label="Default KPI owner"
                  variant="filled"
                  error={Boolean(getErrorForField('kpiOwner'))}
                  helperText={
                    getErrorForField('kpiOwner') &&
                    'KPI owner is a required field'
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {!availableKpiOwners ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          {scopeElement}
          {frequencyElement}

          <TrueUpForm
            disabled={isEdit}
            trueUpPeriodValue={formik.values.trueupPeriod}
            trueUpOffsetValue={formik.values.trueupOffset}
            trueUpFormulaValue={formik.values.trueupFormula}
            handleChange={formik.handleChange}
            onSetNewValues={(values) =>
              formik.setValues({
                ...formik.values,
                ...values,
              })
            }
          />

          {typeElement}

          <Grid item xs={6} sx={{ mb: 3 }}>
            <NumericFormat
              allowNegative={false}
              customInput={TextField}
              fullWidth
              id="defaultBonusShare"
              name="defaultBonusShare"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              inputMode="decimal"
              label="Bonus share"
              onValueChange={({ value }) =>
                handleCustomNumberFormatChange('defaultBonusShare', value)
              }
              onBlur={handleBlur}
              value={formik.values.defaultBonusShare || null}
              variant="filled"
              error={Boolean(getErrorForField('defaultBonusShare'))}
              helperText={getErrorForField('defaultBonusShare')}
            />
          </Grid>

          {formik.values.type === KPI_F_TYPE.VARIABLE ? (
            renderWorstBestCases()
          ) : (
            <Grid item xs={6} />
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Box>
          {isEdit && (
            <Button
              color={kpiDefinition?.isActive ? 'error' : 'primary'}
              onClick={handleActivateDeactivateClick}
            >
              {kpiDefinition?.isActive ? 'Deactivate' : 'Activate'}
            </Button>
          )}
        </Box>
        <Box>
          <Button onClick={closeDialog} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button
            disabled={
              createdKpiDefinitionLoading ||
              ((!formik.isValid || !formik.dirty) && !Boolean(submitCount))
            }
            onClick={formik.submitForm}
            variant="contained"
          >
            {isEdit ? 'Update' : 'Create'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
