import React, { FC } from 'react';
import { Collapse, Alert, IconButton, Typography, Box } from '@mui/material';
import { TermsOfUse } from './TermsOfUse';
import { TermsOfUseAccept } from './TermsOfUseAccept';
import { COLORS } from '../../utils/colors';
import logo from '../../assets/logo.svg';
import {
  customAlertBlue,
  customAlertStyles,
} from '../../pages/termsOfUse/TermsOfUsePageStyles';

interface Props {
  onTermsAccept: () => void;
}

export const TermsOfUseOverlay: FC<Props> = ({ onTermsAccept }) => {
  return (
    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
      <Collapse in={true} sx={{ width: '100%' }}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
            ></IconButton>
          }
          icon={false}
          sx={{ ...customAlertStyles, ...customAlertBlue }}
        >
          <Typography
            sx={{
              fontSize: 14,
              padding: '4px 8px',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              mr: '4px',
              backgroundColor: COLORS.BLUE.LIGHT,
              display: 'inline',
            }}
          >
            Important
          </Typography>{' '}
          Please read and accept terms of use to continue using Rocky Finance 🙏
        </Alert>
      </Collapse>
      <Box
        sx={(theme) => ({
          position: 'absolute',
          top: 100,
          left: 20,

          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        })}
      >
        <img src={logo} alt="Rocky Finance" />
      </Box>
      <TermsOfUse></TermsOfUse>
      <Box width={'100%'} maxWidth={'59.5rem'} mt={-6} mb={4}>
        <TermsOfUseAccept onAcceptClick={onTermsAccept}></TermsOfUseAccept>
      </Box>
    </Box>
  );
};
