import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { NoReportsComponent } from './components/NoReports';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { Stack, Typography } from '@mui/material';
import {
  HistoricalAssignmentEntry,
  UpdateHistoricalAssignment,
} from '../../../../types/contractor';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { updateUserBonusPlan } from '../../../../redux/userData';
import HistoricalDataTable from './components/HistoricalDataTable';
import { periodsSelectors } from '../../../../redux/periods';
import { PeriodType } from '../../../../types';
import {
  addMonthsToPeriod,
  getCurrentQuarter,
  getLastQuarter,
} from '../../Contractor.utils';
import * as _ from 'lodash';
import { contractorSelectors } from '../../../../redux/contractor';
import { KPI_FREQUENCY } from '../../../../constants';
import { v4 } from 'uuid';

interface Props {
  userId: string;
  isOpen: boolean;
  onClose: () => void;
}

const HistoricalDataEditModal: React.FC<Props> = ({
  userId,
  isOpen,
  onClose,
}) => {
  const currentPeriod: PeriodType | null = useAppSelector(
    periodsSelectors.getCurrentPeriod,
  );
  const allAssignments = useAppSelector(
    contractorSelectors.getAllAssignmentsWithHistoricalData,
  );

  const [updateHistoricalDataBody, setUpdateHistoricalDataBody] = useState(
    [] as UpdateHistoricalAssignment[],
  );
  const [historicalAssignments, setHistoricalAssignments] = useState<
    Record<string, HistoricalAssignmentEntry> | undefined
  >(undefined);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setHistoricalAssignments(
      _.keyBy(
        allAssignments.map((a) => {
          const modifiedKpiValuesForEdit = a.kpiValues
            .map((val) => {
              const lastQuarter = Array.from(
                { length: 3 },
                (_, i) => i + Number(getLastQuarter(val.period)[0]),
              );

              const bonusPlan:
                | number
                | { period: number; bonusShare: number }[] =
                a.kpiDefinition.frequency === KPI_FREQUENCY.QUARTERLY
                  ? lastQuarter
                      .map((p) => ({
                        period: p,
                        bonusShare:
                          a.bonusPlanEntries.find((bp) => bp.period === p)
                            ?.bonusShare ?? 0,
                      }))
                      .sort((a, b) => a.period - b.period)
                  : a.bonusPlanEntries.find((bp) => bp.period === val.period)
                      ?.bonusShare ?? 0;

              return {
                ...val,
                ...{
                  [a.kpiDefinition.frequency === KPI_FREQUENCY.QUARTERLY
                    ? 'bonusPlan'
                    : 'bonusShare']: bonusPlan,
                },
              };
            })
            .sort((a, b) => a.period - b.period);

          const currentQuarter = Array.from(
            { length: 3 },
            (_, i) => i + Number(getCurrentQuarter(currentPeriod?.period)[0]),
          );

          if (
            a.bonusPlanEntries.find((bp) => bp.period === currentPeriod?.period)
          ) {
            const currBonusPlan:
              | number
              | { period: number; bonusShare: number }[] =
              a.kpiDefinition.frequency === KPI_FREQUENCY.QUARTERLY
                ? currentQuarter
                    .map((p) => ({
                      period: p,
                      bonusShare:
                        a.bonusPlanEntries.find((bp) => bp.period === p)
                          ?.bonusShare ?? 0,
                    }))
                    .sort((a, b) => a.period - b.period)
                : a.bonusPlanEntries.find(
                    (bp) => bp.period === currentPeriod?.period,
                  )?.bonusShare ?? 0;

            const currentKpiValue = {
              id: v4(),
              period:
                a.kpiDefinition.frequency === KPI_FREQUENCY.QUARTERLY
                  ? addMonthsToPeriod(currentQuarter[2], 1)
                  : currentPeriod?.period,
              worstCase: a.worstCase,
              bestCase: a.bestCase,
              [a.kpiDefinition.frequency === KPI_FREQUENCY.QUARTERLY
                ? 'bonusPlan'
                : 'bonusShare']: currBonusPlan,
            };

            modifiedKpiValuesForEdit.push(currentKpiValue);
          }

          return {
            id: a.id,
            kpiValues: modifiedKpiValuesForEdit,
            name: a.kpiDefinition.name,
            scope: a.kpiDefinition.scope,
            frequency: a.kpiDefinition.frequency,
            type: a.kpiDefinition.type,
            kpiDefinitionId: a.kpiDefinitionId,
          };
        }),
        'id',
      ),
    );
  }, [allAssignments, currentPeriod?.period]);

  const handleHistoricalDataChange = useCallback(
    (ae: UpdateHistoricalAssignment) => {
      if (updateHistoricalDataBody.find((hae) => hae.id === ae.id)) {
        setUpdateHistoricalDataBody(
          updateHistoricalDataBody.map((hae) =>
            hae.id === ae.id
              ? {
                  ...hae,
                  ...ae,
                }
              : hae,
          ),
        );
      } else {
        setUpdateHistoricalDataBody([...updateHistoricalDataBody, ae]);
      }

      if (!historicalAssignments) return;

      const newHistoricalAssignments = historicalAssignments;
      for (const [key] of Object.entries(historicalAssignments)) {
        if (ae.id === key) {
          newHistoricalAssignments[key] = {
            ...newHistoricalAssignments[key],
            kpiValues: ae.monthlyKpiValues ?? ae.quarterlyKpiValues ?? [],
          };
        }
      }

      setHistoricalAssignments({
        ...newHistoricalAssignments,
      });
    },
    [updateHistoricalDataBody, historicalAssignments],
  );

  const onSaveBonuses = useCallback(() => {
    dispatch(
      updateUserBonusPlan({
        userId,
        assignments: updateHistoricalDataBody.map((record) => {
          const newRecord: UpdateHistoricalAssignment = {
            id: record.id,
            monthlyKpiValues: record.monthlyKpiValues,
            quarterlyKpiValues: record.quarterlyKpiValues,
          };

          if (currentPeriod) {
            if (newRecord.monthlyKpiValues) {
              newRecord.monthlyKpiValues = newRecord.monthlyKpiValues.filter(
                (x) => x.period !== currentPeriod.period,
              );
            }

            if (newRecord.quarterlyKpiValues) {
              const currentQuarterResultMonth = addMonthsToPeriod(
                Number(getCurrentQuarter(currentPeriod.period)[1]),
                1,
              );
              newRecord.quarterlyKpiValues =
                newRecord.quarterlyKpiValues.filter(
                  (x) => x.period !== currentQuarterResultMonth,
                );
            }
          }

          return newRecord;
        }),
      }),
    );
  }, [currentPeriod, dispatch, updateHistoricalDataBody, userId]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={'lg'}>
      <DialogTitle>
        Historical data edit
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 11,
            color: ({ palette }) => palette.action.active,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="body2">Adding 0 deletes a bonus entry</Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 0, py: 0 }} dividers>
        {!!historicalAssignments ? (
          <HistoricalDataTable
            historicalAssignments={historicalAssignments}
            handleHistoricalDataChange={handleHistoricalDataChange}
          />
        ) : (
          <NoReportsComponent />
        )}
      </DialogContent>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={0.25}
        sx={{
          height: 'fit-content',
        }}
      >
        <DialogActions sx={{ px: 4.5 }}>
          <Button onClick={onClose}>Close</Button>
          <Button onClick={() => onSaveBonuses()} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default HistoricalDataEditModal;
