import React, { PropsWithChildren, useEffect } from 'react';
import { Box, Typography, Card, TableBody, Table } from '@mui/material';
import { FakeTableRow } from '../../../components/customTable';
import { approvalsMetricStickyTopOffset } from '../../../theme/variables';

interface Props {
  title?: string;
}

export function TeamTable({
  title,
  children,
}: PropsWithChildren<Props>): JSX.Element {
  const [scrolled, setScrolled] = React.useState<boolean>(false);

  const handleScroll = () => {
    setScrolled(window.scrollY > 24);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box pb={2}>
      {title && (
        <Typography
          variant="h5"
          color="text.secondary"
          sx={{
            position: 'sticky',
            top: scrolled ? approvalsMetricStickyTopOffset : 0,
            background: (theme) => theme.palette.background.default,
            paddingY: 1,
            paddingX: 2,
            zIndex: '2',
            '.MuiCollapse-root &': { ml: -2, mr: -2 },
          }}
        >
          {title}
        </Typography>
      )}
      <Card
        sx={{
          mb: 2,
          boxShadow: `0px 2px 4px -1px rgba(0, 0, 0, 0.1)`,
          '.MuiCollapse-root &': {
            boxShadow: 'none',
            ml: -2,
            mr: -2,
            mb: 0,
          },
        }}
      >
        <Table
          aria-label="collapsible table"
          sx={{
            'tr:last-of-type td': { borderBottom: '0 !important' },
            minWidth: '900px',
          }}
        >
          <TableBody>
            <FakeTableRow />

            {children}
          </TableBody>
        </Table>
      </Card>
    </Box>
  );
}
