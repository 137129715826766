import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveIcon from '@mui/icons-material/Remove';
interface SummaryNumberProps {
  children?: React.ReactNode;
  value?: number;
  range?: { min: number; max: number };

  superscript?: number;
  subscript?: number;
}

export const CustomNumber = ({
  children,
  value,
  range,

  superscript,
  subscript,
}: SummaryNumberProps) => {
  const dataIsNotUndefined = value !== undefined && range !== undefined;

  const textColor =
    dataIsNotUndefined && value > range.max
      ? 'success.light'
      : dataIsNotUndefined && value <= range.min
      ? 'error.light'
      : 'inherit';

  if (superscript || subscript) {
    return (
      <>
        <Typography variant="h1" color={textColor} paddingLeft={'5px'}>
          {children}
        </Typography>
        {!!superscript || !!subscript ? (
          <Stack
            ml={1}
            display="flex"
            fontSize={11}
            sx={{
              justifyContent: !superscript ? 'end' : 'inherit',
            }}
          >
            {!!superscript && (
              <Box display="flex" flexDirection="row" alignItems="center">
                <AddRoundedIcon
                  fontSize="small"
                  sx={{ width: 12, height: 12 }}
                />
                {superscript}
              </Box>
            )}
            {!!subscript && (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="end"
              >
                <RemoveIcon fontSize="small" sx={{ width: 12, height: 12 }} />
                {subscript}
              </Box>
            )}
          </Stack>
        ) : null}
      </>
    );
  }

  return (
    <Typography variant="h1" color={textColor} paddingLeft={'5px'}>
      {children}
    </Typography>
  );
};
