import React, { FC, useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from '@mui/material';
import { KPI_SCOPES, KPI_TYPES } from '../../../../constants';
import { KpiDefinitionItem } from '../../../../types';
import { CustomTableCell } from '../../../../components/customTable';
import { plural } from '../../../../utils';
import {
  checkIfUserKpiAssignmentInActive,
  sortKpiDefinitions,
} from '../../kpiDefinitions.utils';
import { KpiContractorsModal } from '../kpiContractorsTable/kpiContractorsModal';
import { KpiDefinitionMenuDropDown } from '../kpiDefinitionMenuDropDown/kpiDefinitionMenuDropDown';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import { useAppSelector } from '../../../../redux/hooks';
import { periodsSelectors } from '../../../../redux/periods';

interface Props {
  kpis: KpiDefinitionItem[];
  handleEditKpiDefinition: (kpi: KpiDefinitionItem) => void;
  handleKpiStatusToggle: (kpi: KpiDefinitionItem) => void;
}

export const KpiDefintionsTable: FC<Props> = ({
  kpis,
  handleEditKpiDefinition,
  handleKpiStatusToggle,
}: Props) => {
  const sortedKpis = sortKpiDefinitions(kpis);

  const currentPeriod = useAppSelector(periodsSelectors.getCurrentPeriod);

  const renderTitleCell = (row: KpiDefinitionItem) => {
    return (
      <CustomTableCell
        autoWidth
        scope="row"
        pure
        sx={{
          paddingRight: 0,
        }}
      >
        <Box>
          {row.scope === KPI_SCOPES.SHARED ? (
            <Typography variant="body1">
              <Tooltip title="Shared metric" enterDelay={300} placement="top">
                <Box
                  sx={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    margin: '-2px 4px 0 0',
                    width: '14px',
                    height: '14px',
                    borderRadius: '4px',
                    textAlign: 'center',
                    lineHeight: '14px',
                    color: ({ palette }) => palette.secondary.contrastText,
                    backgroundColor: ({ palette }) => palette.secondary.main,
                  }}
                  component="span"
                >
                  <GroupRoundedIcon
                    sx={{
                      fontSize: '10px',
                    }}
                  />
                </Box>
              </Tooltip>
              {row.name}
            </Typography>
          ) : (
            <Typography variant="body1">{row.name}</Typography>
          )}
        </Box>
      </CustomTableCell>
    );
  };

  const renderBestWorstCell = (
    row: KpiDefinitionItem,
    key: 'defaultWorstCase' | 'defaultBestCase',
  ) => {
    return (
      <TableCell align="right">
        <Typography variant="body1">
          {(row.type === KPI_TYPES.FIXED_PERCENTAGE ||
            row.type === KPI_TYPES.VARIABLE_PERCENTAGE) &&
          row[key]
            ? `${row[key]}%`
            : `${row[key] ?? ''}`}
        </Typography>
      </TableCell>
    );
  };

  const [showContractorsForKpi, setKpiIDToShowContractors] =
    useState<string>('');

  const tableBody = useMemo(
    () =>
      sortedKpis.map((row: KpiDefinitionItem) => {
        const filteredUserAssignments = row.userKpiAssignments.filter(
          (x) =>
            !checkIfUserKpiAssignmentInActive(x, currentPeriod) ||
            !row.isActive,
        );

        return (
          <TableRow key={row.id}>
            {renderTitleCell(row)}
            {renderBestWorstCell(row, 'defaultWorstCase')}
            {renderBestWorstCell(row, 'defaultBestCase')}
            <TableCell width={250}>
              <Typography variant="body1">
                {row.departments.map((x) => x.name).join(', ')}
              </Typography>
            </TableCell>
            <TableCell>
              {!!filteredUserAssignments.length && (
                <Link
                  aria-disabled={!filteredUserAssignments.length}
                  onClick={() => setKpiIDToShowContractors(row.id)}
                  sx={{
                    whiteSpace: 'nowrap',
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                  component="button"
                  variant="body1"
                >
                  {`${filteredUserAssignments.length} contractor${plural(
                    filteredUserAssignments.length,
                  )}`}
                </Link>
              )}
            </TableCell>
            <TableCell width={150}>
              <Chip
                avatar={
                  <Avatar
                    alt={row.kpiOwner?.fullName}
                    src={row.kpiOwner?.avatarUrl}
                  />
                }
                label={row.kpiOwner?.fullName}
                variant="filled"
              />
            </TableCell>
            <TableCell>
              <KpiDefinitionMenuDropDown
                handleEditKpiDefinition={() => handleEditKpiDefinition(row)}
                handleActivateDeactivateKpiDefinition={() =>
                  handleKpiStatusToggle(row)
                }
                isActive={!!row.isActive}
              />
            </TableCell>
            {showContractorsForKpi === row.id && (
              <KpiContractorsModal
                isOpen={true}
                userKpiAssignments={filteredUserAssignments}
                kpiType={row.type}
                onClose={() => setKpiIDToShowContractors('')}
              />
            )}
          </TableRow>
        );
      }),
    [
      currentPeriod,
      handleEditKpiDefinition,
      handleKpiStatusToggle,
      showContractorsForKpi,
      sortedKpis,
    ],
  );

  return (
    <Table stickyHeader sx={{ minWidth: 700 }}>
      <TableHead>
        <TableRow>
          <TableCell>Metric</TableCell>
          <TableCell align="right">Worst</TableCell>
          <TableCell align="right">Best</TableCell>
          <TableCell>Departments</TableCell>
          <TableCell>Assigned to</TableCell>
          <TableCell>KPI owner</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{tableBody}</TableBody>
    </Table>
  );
};
