import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AuthService } from './auth-service';
import { AppConfig } from '../types';
import { ServerStatusCode } from '../constants';
import { is } from '../utils';
import { auth } from '../config/firebase';
import { getCustomEmail } from '../hooks/useAuth';

export class RockyApiService {
  public static instance: AxiosInstance;

  public static init(appConfig: AppConfig): void {
    RockyApiService.instance = axios.create({
      baseURL: (() => {
        if (is.dev || is.localhost) return appConfig.rockyBeDevUrl;
        if (is.stage) return appConfig.rockyBeStageUrl;
        if (is.preprod) return appConfig.rockyBeStageUrl;

        return appConfig.rockyBeProdUrl;
      })(),
      timeout: 3000,
    });

    RockyApiService.addRequestInterceptor();
    RockyApiService.addResponseInterceptor();
  }

  private static requestInterceptorId = -1;
  private static responseInterceptorId = -1;

  private static addRequestInterceptor(): void {
    if (RockyApiService.requestInterceptorId === -1) {
      RockyApiService.requestInterceptorId =
        RockyApiService.instance.interceptors.request.use(async (config) => {
          const customEmail = getCustomEmail();
          const token = customEmail
            ? AuthService.getToken()
            : await auth.currentUser?.getIdToken();
          const newConfig = {
            ...config,
            headers: {
              ...config.headers,
              ...(token ? { Authorization: `Bearer ${token}` } : {}),
            },
          };
          if (!customEmail && token) {
            AuthService.setToken(token);
          }
          return newConfig;
        });
    }
  }

  private static addResponseInterceptor(): void {
    if (RockyApiService.responseInterceptorId === -1) {
      RockyApiService.responseInterceptorId =
        RockyApiService.instance.interceptors.response.use(
          (response) => response,
          (error) => {
            if (error.response.status === ServerStatusCode.UNAUTHORIZED) {
              AuthService.clearToken();
            }

            return Promise.reject(error);
          },
        );
    }
  }

  public static async request<T = any, R = AxiosResponse<T>, D = any>(
    config: AxiosRequestConfig<D>,
  ): Promise<R> {
    return RockyApiService.instance.request(config);
  }
}
