import React, { Fragment } from 'react';
import Paper from '@mui/material/Paper';
import { BonusHistory, ContractorKpiStats } from '../../../../types/contractor';
import { BonusPlanTable } from './BonusPlanTable';

interface Props extends Partial<ContractorKpiStats> {
  bonusHistory: BonusHistory[];
  hidePaper?: boolean;
}

export const BonusPlanWidget = ({ bonusHistory, hidePaper = false }: Props) => {
  const Wrapper = hidePaper ? Fragment : Paper;

  const wrapperProps = hidePaper
    ? {}
    : { sx: { width: '240px', pt: 1, pl: 2, pr: 2, mt: 1 } };

  return (
    <Wrapper {...wrapperProps}>
      <BonusPlanTable bonusPlan={bonusHistory} />
    </Wrapper>
  );
};
