import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants/api';
import { adminStoreKey } from './admin.const';
import { adminReports } from '../../constants/api/admin';

export const fetchAdminReports = createAsyncThunk(
  `${adminStoreKey}/${adminReports()}`,
  async (params: { period: number }) => {
    const response = await axios.post(`${apiEndpoints.adminReports()}`, {
      period: params.period,
    });
    return response.data;
  },
);
