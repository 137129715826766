import { is } from '../../utils';
import { KpiStatus } from '../../constants';
import { ReportType } from '../../types/reports';

export const downloadCsv = (csvData: any, csvFilename: string) => {
  // Creating a Blob for having a csv file format and passing the data with type
  const blob = new Blob([csvData], { type: 'text/csv' });

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement('a');

  // Passing the blob downloading url
  a.setAttribute('href', url);

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute('download', csvFilename);

  // Performing a download with click
  a.click();
};

export function getFinalBonus(report: ReportType): number | null {
  return !is.nullOrUndefined(report.trueup) &&
    !is.nullOrUndefined(report.payout) &&
    report.status === KpiStatus.Approved
    ? report.payout + report.trueup
    : null;
}
