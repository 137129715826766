import { CompensationRange } from '../types';

export const iconColor = {
  defaultPayRateIconColor: 0,
  aboveRangeIconColor: 1,
  belowRangeIconColor: 2,
};

export const payRateColor = ({
  compensationRate,
  compensationRange,
}: {
  compensationRate?: number | null;
  compensationRange?: CompensationRange;
}) => {
  if (!compensationRate) {
    compensationRate = 0;
  }

  if (!compensationRange?.min || !compensationRange?.max) {
    return iconColor.defaultPayRateIconColor;
  }

  if (compensationRate > compensationRange.max) {
    return iconColor.aboveRangeIconColor;
  }
  if (compensationRate < compensationRange.min) {
    return iconColor.belowRangeIconColor;
  }

  return iconColor.defaultPayRateIconColor;
};
