export enum MarginHighlightType {
  SHOULD_NOT_HAPPEN = 'SHOULD_NOT_HAPPEN',
  PANIC = 'PANIC',
  RED = 'RED',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
}

export const MarginHighlightColor: {
  [key: string]: string;
} = {
  [MarginHighlightType.SHOULD_NOT_HAPPEN]: '#622F20CC',
  [MarginHighlightType.PANIC]: '#C62828CC',
  [MarginHighlightType.RED]: '#EF5350CC',
  [MarginHighlightType.YELLOW]: '#FBC02DCC',
  [MarginHighlightType.GREEN]: '#4CAF50CC',
};

export const MarginHighlightActions: {
  [key: string]: string[];
} = {
  [MarginHighlightType.SHOULD_NOT_HAPPEN]: [
    'Escalate to the VP and CTO level.',
    'Having this situation means improper financial planning, not acting on previous signs or the situation got out of control because of unexpected client’s financial issues.',
    'Usually should end up with moving engineers to other projects that can afford them.',
  ],
  [MarginHighlightType.PANIC]: [
    'Escalate to the VP and CTO level and get to joint decision.',
    'Freeze raises for everyone except excellent and very good performers.',
    'Consider moving to once per year raises.',
    'Ask CP if bill rates increase possible within 2-3 months.',
  ],
  [MarginHighlightType.RED]: [
    'Freeze raises for everyone except excellent and very good performers.',
    'Consider moving to once per year raises.',
    'Ask CP if bill rates increase possible within 2-3 months.',
    'Replan raises and look for promotions opportunities.',
    'Consider moving Sr. people to projects with higher rates.',
    'Replace people with ones of lower seniority and lower rates.',
  ],
  [MarginHighlightType.YELLOW]: [
    'Ask CP if bill rates increase possible within 3-6 months.',
    'Replan raises and look for promotions opportunities.',
    'Consider moving Sr. people to projects with higher rates.',
    'Replace people with ones of lower seniority and lower rates.',
  ],
  [MarginHighlightType.GREEN]: [
    'Routine promotions and raises planning to be within the range.',
    'Conservative approach to raises and promotions is recommended to keep the margins in a green zone for as long as possible.',
  ],
};

export const GrossMarginHandlingGuidelineDoc =
  'https://docs.google.com/document/d/1VTWxooE8SUOdaa1WO7csHG_Jwqz9DIzCMjKu2-x6uMo';
