import React, {
  PropsWithChildren,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Card, Box, Typography, IconButton, Collapse } from '@mui/material';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded';
import { approvalsMetricStickyTopOffset } from '../../theme/variables';
import { StyledListItemCard } from './StyledListItemCard';

interface ListItemCardProps {
  title: string;
  id?: string;
  description?: string;
  enlargeTitle?: boolean;
  stickyTitle?: boolean;
  onApprovals?: boolean;
  expanded?: boolean;
  disabled?: boolean;
  extendedColums?: JSX.Element;
  titleWrapper?: (title: JSX.Element) => JSX.Element;
  isClickOpenCard?: boolean;
  onClick?: () => void;
}

export const ListItemCard = ({
  children,
  title,
  description,
  id,
  enlargeTitle = false,
  stickyTitle = false,
  onApprovals = false,
  expanded = false,
  disabled = false,
  extendedColums,
  isClickOpenCard = true,
  onClick,
  titleWrapper = (title) => title,
}: PropsWithChildren<ListItemCardProps>): JSX.Element => {
  const [open, setOpen] = useState(expanded);

  const handleSetOpenCard = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event?.stopPropagation();
      setOpen(!open);
    },
    [setOpen, open],
  );

  const handleItemClick = useCallback(() => {
    isClickOpenCard ? setOpen(!open) : onClick?.();
  }, [isClickOpenCard, setOpen, onClick, open]);

  useEffect(() => {
    setOpen(expanded);
  }, [expanded]);

  return (
    <Card
      id={id}
      variant={open ? 'elevation' : 'outlined'}
      sx={{ marginBottom: 1, overflow: 'initial' }}
      className="hovered"
    >
      <StyledListItemCard
        open={open}
        approvalsMetricStickyTopOffset={approvalsMetricStickyTopOffset}
        stickyTitle={stickyTitle}
        onApprovals={onApprovals}
        onClick={handleItemClick}
      >
        {children && (
          <Box ml={'6px'}>
            <IconButton
              aria-label="Expand/collapse icon"
              size="small"
              onClick={handleSetOpenCard}
            >
              {open ? <UnfoldLessRoundedIcon /> : <UnfoldMoreRoundedIcon />}
            </IconButton>
          </Box>
        )}
        {!children && <Box ml={5}></Box>}
        <Box pl={2}>
          {titleWrapper(
            <Typography
              variant={enlargeTitle && open ? 'h4' : 'h5'}
              sx={{
                display: 'inline-block',
                width: (theme) => (extendedColums ? theme.spacing(20) : 'auto'),
                transition: (theme) =>
                  `all ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut} 0ms`,
                transitionProperty: 'font-size, font-weight',
              }}
              color={disabled ? 'text.secondary' : undefined}
            >
              {title}
            </Typography>,
          )}
          <Typography variant="body2">{description}</Typography>
        </Box>
        {extendedColums}
      </StyledListItemCard>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box>{children}</Box>
        </Collapse>
      )}
    </Card>
  );
};
