import { adminStoreKey } from './admin.const';
import { AdminState } from './admin.types';

interface State {
  [adminStoreKey]: AdminState;
}

const selectReportsState = (state: State) => {
  return state[adminStoreKey];
};

const getAdminReports = (state: State): any[] => {
  return selectReportsState(state).adminReports;
};

const getAdminApiStatus = (state: State): string => {
  return selectReportsState(state).apiStatus;
};

export const adminSelectors = {
  getAdminReports,
  getAdminApiStatus,
};
