import { minBy } from 'lodash';
import { accountsStoreKey } from './accounts.const';
import { AppStateWithAccountsData as State } from './accounts.types';

const select = (state: State) => state[accountsStoreKey];

export const getAccounts = (state: State) => select(state).accounts;
export const getProcessedAccounts = (state: State) => {
  const accounts = select(state).accounts;

  return accounts.map((acc) => {
    return {
      ...acc,
      latestTimelineAssessmentScore: minBy(
        acc.projects,
        (proj) => proj.latestTimelineAssessment?.score,
      )?.latestTimelineAssessment?.score,
      latestScopeAssessmentScore: minBy(
        acc.projects,
        (proj) => proj.latestScopeAssessment?.score,
      )?.latestScopeAssessment?.score,
      latestGeneralAssessmentScore: minBy(
        acc.projects,
        (proj) => proj.latestGeneralAssessment?.score,
      )?.latestGeneralAssessment?.score,
    };
  });
};
export const getError = (state: State) => select(state).error;
export const getGroupBy = (state: State) => select(state).groupBy;
export const getLoading = (state: State) => select(state).loading;
export const getSortBy = (state: State) => select(state).sortBy;

export const getSortOrder = (state: State) => select(state).sortOrder;
export const getDateFilterValue = (state: State) =>
  select(state).dateFilterValue;
export const getDateFilterValueLocal = (state: State) =>
  select(state).dateFilterValueLocal;
export const getUniqueFilterLists = (
  state: State,
): {
  pdms: { value: string; label: string }[];
  dms: { value: string; label: string }[];
  cps: { value: string; label: string }[];
} => select(state).uniqueFilterLists;

export const getAccountsListFetchErrorMessage = (state: State) =>
  select(state).accountListFetchErrorMessage;

export const accountsSelectors = {
  getAccounts,
  getProcessedAccounts,
  getError,
  getGroupBy,
  getLoading,
  getSortBy,

  getAccountsListFetchErrorMessage,
  getSortOrder,
  getDateFilterValue,
  getDateFilterValueLocal,
  getUniqueFilterLists,
};

export const selectAccountsPageFilters = (state: State) =>
  select(state).filters;
