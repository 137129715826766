import {
  AccountOverview,
  AccountStatus,
  FlagsCount,
  SowStatus,
} from '../../types';
import { getDateDifference } from '../../utils';
import {
  FilterAccountsBy,
  GroupAccountsBy,
  SortAccountsBy,
} from './accounts.types';

export const accountsStoreKey = 'accounts';

export const sumUpAccountMembers = (account: AccountOverview) =>
  account.projects.reduce(
    (amount, project) => amount + project.membersCount,
    0,
  );

export const sumUpExpiringAccountMembers = (account: AccountOverview) =>
  account.projects.reduce(
    (amount, project) => amount + project.membersWithSowToExpireWithinMonth,
    0,
  );

export const sortAccountsMap: Record<
  SortAccountsBy,
  (a: AccountOverview, b: AccountOverview) => number
> = {
  [SortAccountsBy.AccountName]: (a, b) => a.name.localeCompare(b.name),
  // [SortAccountsBy.Net]: (a, b) => a.netMargin - b.netMargin,  ###Commented until the functionality is disabled
  [SortAccountsBy.Margin]: (a, b) =>
    (a.grossMargin || 0) - (b.grossMargin || 0),
  // [SortAccountsBy.RaiseBudget]: (a, b) => a.raiseBudget - b.raiseBudget,   ###Commented until the functionality is disabled
  [SortAccountsBy.Headcount]: (a, b) =>
    sumUpAccountMembers(a) - sumUpAccountMembers(b),
};

export const groupAccontsByMap: Record<
  GroupAccountsBy,
  (accounts: AccountOverview[]) => AccountOverview[]
> = {
  [GroupAccountsBy.AccountName]: (accounts: AccountOverview[]) => {
    return accounts.sort((a, b) => a.name.localeCompare(b.name));
  },
  [GroupAccountsBy.ClientPartner]: (accounts: AccountOverview[]) => {
    const accountsGroupedByClientPartner = {} as {
      [key: string]: AccountOverview[];
    };

    for (const account of accounts) {
      if (account.clientPartner) {
        (accountsGroupedByClientPartner[account.clientPartner.id] ??= []).push(
          account,
        );
      }
    }

    return Object.keys(accountsGroupedByClientPartner)
      .sort()
      .map((key) => accountsGroupedByClientPartner[key])
      .flat();
  },
  [GroupAccountsBy.PortfolioDeliveryManager]: (accounts: AccountOverview[]) => {
    const accountsGroupedByPDM = {} as {
      [key: string]: AccountOverview[];
    };

    for (const account of accounts) {
      if (account.portfolioDeliveryManager) {
        (accountsGroupedByPDM[account.portfolioDeliveryManager.id] ??= []).push(
          account,
        );
      }
    }

    return Object.keys(accountsGroupedByPDM)
      .sort()
      .map((key) => accountsGroupedByPDM[key])
      .flat();
  },
  [GroupAccountsBy.DeliveryManager]: (accounts: AccountOverview[]) => {
    const accountsGroupedByDM = {} as {
      [key: string]: AccountOverview[];
    };

    for (const account of accounts) {
      if (account.deliveryManager) {
        (accountsGroupedByDM[account.deliveryManager.id] ??= []).push(account);
      }
    }

    return Object.keys(accountsGroupedByDM)
      .sort()
      .map((key) => accountsGroupedByDM[key])
      .flat();
  },
};

export const calculateAccountSowStatus = (account: AccountOverview) => {
  const accountSow = {
    expired: 0,
    potential: 0,
  };

  account.projects.forEach((p) => {
    switch (p.sowStatus) {
      case SowStatus.Expired:
        accountSow.expired++;
        break;
      case SowStatus.Potential:
        accountSow.potential++;
        break;
    }
  });

  return accountSow;
};

export const calculateAccountStartEndDate = (account: AccountOverview) => {
  if (account.projects.length === 0)
    return { accountStartDate: null, accountEndDate: null };

  const minStartDateProject = account.projects.reduce((min, next) => {
    if (!min.startedAt) return next;
    if (!next.startedAt) return min;

    return new Date(min.startedAt).getTime() <
      new Date(next.startedAt).getTime()
      ? min
      : next;
  });

  const maxEndDateProject = account.projects.reduce((max, next) => {
    if (max.endedAt === null) return max;
    if (next.endedAt === null) return max;

    return new Date(max.endedAt).getTime() > new Date(next.endedAt).getTime()
      ? max
      : next;
  });

  let endingWithinDays = undefined;

  if (maxEndDateProject.endedAt) {
    endingWithinDays = getDateDifference(
      new Date(),
      new Date(maxEndDateProject.endedAt),
    );
  }

  return {
    accountStartDate:
      minStartDateProject.startedAt &&
      new Date(minStartDateProject.startedAt)?.toLocaleDateString('en-US'),
    accountEndDate:
      maxEndDateProject.endedAt &&
      new Date(maxEndDateProject.endedAt)?.toLocaleDateString('en-US'),
    endingWithinDays: endingWithinDays,
  };
};

export const AccountsPageFiltersInitialState = {
  accountStatus: AccountStatus.Active,
  filterBy: FilterAccountsBy.PortfolioDeliveryManager,
  filterValues: [],
};

export const getFlagsToolTipMessage = (flagsCount: FlagsCount) => {
  if (flagsCount.red && flagsCount.yellow) {
    return `${flagsCount.red} red and ${flagsCount.yellow} yellow flags`;
  } else if (flagsCount.yellow) {
    return `${flagsCount.yellow} yellow ${
      flagsCount.yellow > 1 ? 'flags' : 'flag'
    }`;
  } else if (flagsCount.red) {
    return `${flagsCount.red} red ${flagsCount.red > 1 ? 'flags' : 'flag'}`;
  }
};
