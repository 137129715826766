import React, { FC, memo } from 'react';

import Button from '@mui/material/Button';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { AssessmentDateContent, AssessmentsTypesEnum } from '../../../types';
import AssessmentsDetailsWrapper from '../AssessmentsDetailsWrapper';
import AssessmentDetailItem from '../AssessmentDetailItem';

interface Props {
  period: string;
  isEditable: boolean;
  assessment: AssessmentDateContent;
  toggleEditMode: () => void;
}

const AssessmentsDetails: FC<Props> = ({
  period,
  assessment,
  isEditable,
  toggleEditMode,
}) => {
  if (!assessment) return null;

  return (
    <AssessmentsDetailsWrapper period={period}>
      <AssessmentDetailItem
        type={AssessmentsTypesEnum.GENERAL}
        score={assessment[AssessmentsTypesEnum.GENERAL]?.score}
        comment={assessment[AssessmentsTypesEnum.GENERAL]?.comment}
      />
      <AssessmentDetailItem
        type={AssessmentsTypesEnum.TIMELINE}
        score={assessment[AssessmentsTypesEnum.TIMELINE]?.score}
        comment={assessment[AssessmentsTypesEnum.TIMELINE]?.comment}
      />
      <AssessmentDetailItem
        type={AssessmentsTypesEnum.SCOPE}
        score={assessment[AssessmentsTypesEnum.SCOPE]?.score}
        comment={assessment[AssessmentsTypesEnum.SCOPE]?.comment}
      />
      {isEditable && (
        <Button
          size="small"
          variant="text"
          startIcon={<ModeEditIcon />}
          onClick={toggleEditMode}
        >
          Edit
        </Button>
      )}
    </AssessmentsDetailsWrapper>
  );
};

export default memo(AssessmentsDetails);
