import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  TextField,
  ListItem,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/SearchRounded';
import { UserWithPermissions, PlainObject, UserForSearch } from '../../types';
import { is, cn } from '../../utils';
import { MemberInfoCell } from '../tableCellRenders';
import Fuse from 'fuse.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { SearchListBoxComponent } from './components/SearchListboxComponent';
import { useMapUsersForSearch } from './UsersSearchBox.utils';
import { paths } from '../../constants/paths';

interface Props {
  options: UserWithPermissions[];
}

export function UsersSearchBox({ options }: Props): JSX.Element {
  const { pathname } = useLocation();
  const nav = useNavigate();
  const [searchValue, setSearchValue] = useState<UserForSearch | null | string>(
    null,
  );

  const searchOptions = useMapUsersForSearch(options);

  const valueIsUser =
    !is.string(searchValue) &&
    !is.nullOrUndefined(searchValue) &&
    is.object(searchValue);

  useEffect(() => {
    if (!valueIsUser) {
      return;
    }

    nav(`${paths.contractors}/${searchValue.departmentId}/${searchValue.id}`);
  }, [searchValue, valueIsUser, nav]);

  useEffect(() => {
    if (
      !valueIsUser ||
      `${paths.contractors}/${searchValue.departmentId}/${searchValue.id}` ===
        pathname
    ) {
      return;
    }

    setSearchValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, valueIsUser]);

  const fuse = new Fuse(searchOptions || [], {
    threshold: 0.35,
    keys: ['names', 'fullName'],
  });

  return (
    <Autocomplete<UserForSearch, false, false, true>
      freeSolo
      loading={options === null}
      size="small"
      value={searchValue}
      blurOnSelect
      onChange={(e, value) => setSearchValue(value)}
      options={searchOptions || []}
      getOptionLabel={(opt) => (is.string(opt) ? opt : opt.fullName)}
      ListboxComponent={SearchListBoxComponent}
      className={cn(
        'MuiAutocomplete-searchBox',
        valueIsUser ? 'MuiAutocomplete-hasValue' : '',
      )}
      componentsProps={{
        popper: {
          style: { width: '300px' },
        },
      }}
      filterOptions={(searchOptions, state) => {
        return fuse.search(state.inputValue).map(({ item }) => item);
      }}
      renderOption={(
        props,
        { id, fullName, avatarUrl, designation, deactivatedAt, country, city },
      ) => (
        <ListItem {...props} key={id}>
          <MemberInfoCell
            // eslint-disable-next-line
            hideAvatar={(props as PlainObject)['data-option-index'] > 2000}
            userInfo={{
              fullName: fullName,
              avatarUrl: avatarUrl,
              stream: designation,
              country: country,
              city: city,
            }}
            disabled={!!deactivatedAt}
          />
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search"
          InputProps={{
            ...(params.InputProps || {}),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="disabled" />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
