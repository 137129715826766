const usersKey = '/users';

export const userProfile = () => {
  return `${usersKey}/profile`;
};

export const usersList = () => {
  return `${usersKey}/list`;
};
export const userAccountsCount = () => {
  return `${usersKey}/fss/aci`;
};

export const fetchCompensationRange = () => {
  return `${usersKey}/compensation-ranges`;
};

export const fetchUserBonusPlan = (userId: string) => {
  return `/bonus-history/${userId}`;
};
