import { useState, useEffect } from 'react';

function useOnlineStatus() {
  const [isOffline, setOffline] = useState<boolean>(false);

  const offlineHandler = () => setOffline(true);
  const onlineHandler = () => setOffline(false);

  useEffect(() => {
    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

  return isOffline;
}

export default useOnlineStatus;
