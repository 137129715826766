import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { LocationType } from '../../types';
import { fetchLocations } from './locations.thunks';
import { locationsStoreKey } from './locations.const';

export interface LocationsState {
  apiStatus: API_STATUS;
  locationsList: LocationType[];
}

const initialState: LocationsState = {
  apiStatus: API_STATUS.IDLE,
  locationsList: [],
};

export const locationsSlice = createSlice({
  name: locationsStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocations.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(
        fetchLocations.fulfilled,
        (state, action: PayloadAction<LocationType[]>) => {
          state.apiStatus = API_STATUS.IDLE;
          state.locationsList = action.payload;
        },
      )
      .addCase(fetchLocations.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });
  },
});
