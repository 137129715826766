import { KPI_SCOPES } from '../../constants';
import { KpiDefinitionItem, PeriodType, UserKpiAssignment } from '../../types';

export const sortKpiDefinitions = (
  kpis: KpiDefinitionItem[],
): KpiDefinitionItem[] => {
  const sharedKpis = kpis
    .filter((x) => x.scope === KPI_SCOPES.SHARED)
    .sort(sortKpiByName);

  const individualKpis = kpis
    .filter((x) => x.scope !== KPI_SCOPES.SHARED)
    .sort(sortKpiByName);

  return [...sharedKpis, ...individualKpis];
};

const sortKpiByName = (a: KpiDefinitionItem, b: KpiDefinitionItem) =>
  a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;

export const checkIfUserKpiAssignmentInActive = (
  kpi: UserKpiAssignment,
  currentPeriod: PeriodType | null,
) =>
  (kpi?.endPeriod && currentPeriod && kpi?.endPeriod < currentPeriod?.period) ||
  !!kpi.deletedAt;
