import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import lookup from 'country-code-lookup';
import { normalizeCountry } from '../../utils/normalizeCountry';
import { Tooltip } from '@mui/material';
import { RockySkeleton } from '../rockySkeleton/RockySkeleton';
import { BREAKPOINTS } from './breakpoints';
import { COLORS } from '../../utils/colors';

interface Props {
  fullName: string;
  imageUrl?: string | null;
  country?: string;
  city?: string;
  extraSmall?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  isLoading?: boolean;
}

const avatarSizeMap = {
  extraSmall: {
    width: 20,
    height: 20,
    borderRadius: 6,
  },
  small: {
    width: 30,
    height: 30,
    borderRadius: 12,
  },
  medium: {
    borderRadius: 14,
    width: 40,
    height: 40,
  },
  large: {
    minWidth: 85,
    minHeight: 85,
    width: 85,
    height: 85,
    borderRadius: 25,

    [BREAKPOINTS.MOBILE]: {
      borderRadius: 12,
      width: 50,
      height: 50,
      marginRight: 12,
      marginBottom: 12,
      flexDirection: 'row',
    },
  },
};

const FinKitAvatarComponent: FC<Props> = ({
  fullName,
  imageUrl,
  city,
  country = '',
  large,
  small,
  medium,
  isLoading,
}) => {
  const memberShortName = fullName
    ? fullName
        .split(' ')
        .filter((s) => s.length > 0)
        .splice(0, 2)
        .join('+')
    : '';
  const noAvatarSrc = `https://eu.ui-avatars.com/api/?background=ffd9a3&color=000&name=${memberShortName}`;
  const [src, setSrc] = useState(imageUrl || noAvatarSrc);

  useEffect(() => {
    if (!imageUrl) {
      setSrc(noAvatarSrc);
      return;
    }
    setSrc(imageUrl);
  }, [imageUrl, noAvatarSrc]);

  const onImageLoadError = useCallback(
    () => setSrc(noAvatarSrc),
    [noAvatarSrc],
  );

  const size = large
    ? 'large'
    : small
    ? 'small'
    : medium
    ? 'medium'
    : 'extraSmall';

  const countryCode = lookup.byCountry(normalizeCountry(country));

  const flag = (
    <span
      style={{
        position: 'absolute',
        right: 0,

        [BREAKPOINTS.MOBILE]: {
          right: 12,
        },

        ...(large && {
          bottom: 0,
          width: 24,

          [BREAKPOINTS.MOBILE]: {
            bottom: 12,
          },
        }),

        ...((small || medium) && {
          bottom: -2,
          width: 18,

          [BREAKPOINTS.MOBILE]: {
            right: 0,
          },
        }),
      }}
    >
      {countryCode && (
        <ReactCountryFlag
          svg
          countryCode={countryCode?.iso2}
          style={{
            width: '1.1em',
            height: 'auto',

            borderRadius: 4,
            cursor: 'pointer',

            [BREAKPOINTS.MOBILE]: {
              right: 12,
            },

            ...(large && {
              fontSize: 24,
            }),

            ...((small || medium) && {
              fontSize: 16,
            }),
          }}
          aria-label={countryCode?.country}
        />
      )}
    </span>
  );

  return (
    <>
      {isLoading ? (
        <RockySkeleton
          isLoading
          element={<div></div>}
          style={{
            objectFit: 'cover',
            display: 'flex',
            objectPosition: '50% 35%',
            background: COLORS.BRAND.HIGHLIGHT,
            transform: 'translateY(25%)',
          }}
        />
      ) : (
        <div
          style={{
            position: 'relative',
            height: 'fit-content ',
            transition: 'all 0.2s',
          }}
        >
          <img
            alt={memberShortName}
            style={{
              objectFit: 'cover',
              display: 'flex',
              objectPosition: '50% 35%',
              background: COLORS.BRAND.HIGHLIGHT,
              ...avatarSizeMap[size],
            }}
            src={src}
            onError={onImageLoadError}
          ></img>
          {country && (
            <Tooltip
              title={
                <div>
                  {city}
                  {city ? ', ' : ''} {country}
                </div>
              }
            >
              {flag}
            </Tooltip>
          )}
        </div>
      )}
    </>
  );
};

export const FinKitAvatar = memo(FinKitAvatarComponent);
