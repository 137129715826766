import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../constants';
import { DepartmentType } from '../../types/contractors';
import { contractorsStoreKey } from './contractors.const';
import { fetchDepartments } from './contractors.thunks';

export interface ContractorsState {
  apiStatus: API_STATUS;
  departmentTree: DepartmentType[];
}

const initialState: ContractorsState = {
  apiStatus: API_STATUS.IDLE,
  departmentTree: [],
};

export const contractorsSlice = createSlice({
  name: contractorsStoreKey,
  initialState,
  reducers: {
    clearContractors: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepartments.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(
        fetchDepartments.fulfilled,
        (state, action: PayloadAction<DepartmentType[]>) => {
          state.apiStatus = API_STATUS.IDLE;
          state.departmentTree = action.payload || [];
        },
      )
      .addCase(fetchDepartments.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });
  },
});

export const { clearContractors } = contractorsSlice.actions;
