import React, { Fragment, useEffect, useRef } from 'react';
import { StyledTableBody } from './StyledTableBody';
import {
  HandleHideDummyProps,
  MemberRatesUpdate,
  UpdateDummyMemberProps,
} from './types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Box } from '@mui/material';
import {
  getEditableMode,
  getGroupBy,
  getAccountListFetchErrorMessage,
  GroupAccountMembersBy,
  resetDummyProjects,
  setAccountDummyMembers,
} from '../../redux/account';
import { ProjectRow } from './components';
import { TeamMemberView } from './TeamMemberView';
import { CompensationDummyMemberRow } from './CompensationDummyMemberRow';
import { StyledTableRow } from './StyledTableRow';
import { TableCell, Typography } from '@mui/material';
import {
  Account,
  DummmyProjectDetail,
  DummyMember,
  GroupedMembers,
} from '../../types';
import { CustomTableCell } from '../../components/customTable';

interface TeamBudgetsTableViewProps extends Pick<Account, 'marginHighlights'> {
  groupedMembers: GroupedMembers[];
  dummyMembers?: DummyMember[] | null;
  dummyProjects?: DummmyProjectDetail[] | null;
  hasAccounts: boolean;
  isEditableMode?: boolean | false;
  resetAllMode?: boolean | false;
  isProjectView: boolean;
  handleRatesChange: (values: MemberRatesUpdate) => void;
  handleHideDummyMember: (data: HandleHideDummyProps) => void;
  handleUpdateDummyMembers: (values: UpdateDummyMemberProps) => void;
  handleUpdateMembers: (values: UpdateDummyMemberProps) => void;
  accountName?: string;
}

export const TeamListMemberView = ({
  groupedMembers,
  hasAccounts,
  marginHighlights,
  dummyMembers,
  dummyProjects,
  isProjectView,
  isEditableMode = false,
  resetAllMode = false,
  handleRatesChange,
  handleHideDummyMember,
  handleUpdateDummyMembers,
  handleUpdateMembers,
  accountName,
}: TeamBudgetsTableViewProps) => {
  const containerRef = useRef(null);
  const dispatch = useAppDispatch();
  const editableMode = useAppSelector(getEditableMode);
  const groupBy = useAppSelector(getGroupBy);
  const accountListFetchError = useAppSelector(getAccountListFetchErrorMessage);

  useEffect(() => {
    if (!editableMode) {
      dispatch(setAccountDummyMembers());
      dispatch(resetDummyProjects());
    }
  }, [editableMode, dispatch]);

  const renderDummyMembers = (dummyMembers: DummyMember[], projectId: string) =>
    dummyMembers
      .filter((dummyMember: DummyMember) => dummyMember.projectId === projectId)
      .map((item, index) =>
        renderDummyMembersRow(item, index, dummyMembers.length, projectId),
      );

  const renderDummyMembersRow = (
    dummyMember: DummyMember,
    i: number,
    membersLength: number,
    projectId: string,
  ) => {
    const isLastRow = membersLength - 1 === i;
    return (
      <CompensationDummyMemberRow
        id={dummyMember.id}
        current={containerRef.current}
        key={dummyMember.id}
        isLastRow={isLastRow}
        dummyMember={dummyMember}
        marginHighlights={marginHighlights || []}
        handleHideDummyMember={handleHideDummyMember}
        handleRatesChange={handleRatesChange}
        handleUpdateDummyMembers={handleUpdateDummyMembers}
        projectId={projectId}
      />
    );
  };

  if (!groupedMembers?.length && accountListFetchError)
    return (
      <CustomTableCell colSpan={13} pure>
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            marginTop: '3%',
            marginBottom: '3%',
            fontSize: '1rem',
            color: 'grey',
          }}
        >
          {accountListFetchError}
        </Box>
      </CustomTableCell>
    );

  return (
    <StyledTableBody ref={containerRef}>
      {!!dummyProjects?.length &&
        dummyProjects.map((dummyProject, index) => (
          <Fragment key={index}>
            {!isProjectView && (
              <ProjectRow
                projectId={dummyProject.id}
                key={dummyProject.id}
                name={dummyProject.name}
                members={`${dummyProject.membersCount ?? 0} members`}
                margin={dummyProject.grossMargin}
                //Check if grouping is set on team name, can't get margin for other groupings as FSS can't calculate it
                showMargin={true}
                hideLink
              />
            )}
            {hasAccounts &&
              dummyMembers &&
              renderDummyMembers(dummyMembers, dummyProject.id)}
          </Fragment>
        ))}
      {groupedMembers?.map(({ groupName, members, projectId, margin }) => (
        <Fragment key={groupName}>
          {!isProjectView && (
            <ProjectRow
              projectId={projectId}
              key={groupName}
              name={groupName}
              members={`${members?.length ?? 0} members`}
              margin={margin}
              //Check if grouping is set on team name, can't get margin for other groupings as FSS can't calculate it
              showMargin={groupBy === GroupAccountMembersBy.Team}
            />
          )}
          {hasAccounts &&
            projectId &&
            dummyMembers &&
            renderDummyMembers(dummyMembers, projectId)}

          {hasAccounts && members.length ? (
            <>
              {members.map((member, index) => (
                <TeamMemberView
                  id={member.id}
                  key={member.id}
                  fullName={member.fullName}
                  seniority={member.seniority}
                  stream={member.stream}
                  compensationRate={member.compensationRate}
                  resource={{ billRate: member.resource?.billRate || 0 }}
                  companyRateCard={member.companyRateCard}
                  allocation={member.allocation}
                  grossMargin={member.grossMargin}
                  marginHighlights={marginHighlights || []}
                  isLastRow={members.length - 1 === index}
                  isEditableMode={isEditableMode}
                  resetAllMode={resetAllMode}
                  isRestricted={
                    ![
                      member.grossMargin,
                      member.compensationRate,
                      member.companyRateCard?.billRate,
                      member.compensationRange?.max,
                      member.compensationRange?.min,
                    ].some((val) => typeof val === 'number')
                  }
                  isHidden={member?.isHidden}
                  handleRatesChange={handleRatesChange}
                  handleUpdateMember={handleUpdateMembers}
                  compensationRange={member.compensationRange}
                  avatarUrl={member.avatarUrl}
                  actualPayRate={member.actualPayRate}
                  compensationType={member.compensationType}
                  legalLocation={member.legalLocation}
                  projectId={projectId}
                  accountName={accountName}
                />
              ))}
            </>
          ) : (
            <StyledTableRow isLastRow={true} isHidden={true}>
              <TableCell align="left">
                <Typography
                  whiteSpace="nowrap"
                  textAlign="center"
                  variant="h4"
                  color="slategray"
                >
                  There are no members in this team
                </Typography>
              </TableCell>
            </StyledTableRow>
          )}
        </Fragment>
      ))}
    </StyledTableBody>
  );
};
