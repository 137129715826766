import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { is } from '../utils';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseNonDevConfig = is.preprod
  ? {
      apiKey: 'AIzaSyCwJkdwyyVD0hMeITOTw7GkDDG_wHX-uW0',
      authDomain: 'financial--storage-pprod.firebaseapp.com',
      projectId: 'financial--storage-pprod',
      storageBucket: 'financial--storage-pprod.appspot.com',
      messagingSenderId: '1044769578952',
      appId: '1:1044769578952:web:26f1b89d4ed5dfdff9aafd',
      measurementId: 'G-GEBF7KGJCN',
    }
  : {
      apiKey: 'AIzaSyC9bzt0iHAbtp0f-hhC6p35QvexZw_S6rQ',
      authDomain: 'financial--storage-prod.firebaseapp.com',
      projectId: 'financial--storage-prod',
      storageBucket: 'financial--storage-prod.appspot.com',
      messagingSenderId: '1068475312338',
      appId: '1:1068475312338:web:5520aa5a09219a7cf4650b',
      measurementId: 'G-RXXNM13L69',
    };

const firebaseConfig = is.development
  ? {
      apiKey: 'AIzaSyAXXUElp30hoMTQpdjCULFHeBgs3c1lQhA',
      authDomain: 'financial--storage.firebaseapp.com',
      projectId: 'financial-secure-storage',
      storageBucket: 'financial-secure-storage.appspot.com',
      messagingSenderId: '799279169815',
      appId: '1:799279169815:web:c202719da37c4421243752',
      measurementId: 'G-DXRTQQ3PLT',
    }
  : firebaseNonDevConfig;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
