import { PlainObject } from '../../types';
import { ApprovalHeaderOption } from './Approvals.types';

export enum ApprovalsPageMode {
  Review,
  Edit,
}

export enum ApprovalsGroupBy {
  Departments,
  Teams,
  Status,
}

export const approvalsSortValues = <PlainObject<ApprovalHeaderOption>>{
  [ApprovalsGroupBy.Teams]: {
    value: ApprovalsGroupBy.Teams,
    label: 'Teams',
  },
  [ApprovalsGroupBy.Departments]: {
    value: ApprovalsGroupBy.Departments,
    label: 'Departments',
  },
  [ApprovalsGroupBy.Status]: {
    value: ApprovalsGroupBy.Status,
    label: 'Status',
  },
};

export const sortByRootDepartmentAll = {
  value: '',
  label: 'All',
};

export const approvalsSortOptions = Object.values(approvalsSortValues).sort(
  (a) => (a.value === ApprovalsGroupBy.Teams ? -1 : 1),
);

export const REVIEW_TOGGLE_TEST_ID = 'ReviewToggle';
export const ADJUST_TOGGLE_TEST_ID = 'AdjustToggle';

export const LIST_GROUPED_BY_ROOT_DEPARTMENTS = 'ListGroupedByRootDepartments';
export const LIST_GROUPED_BY_STATUS = 'ListGroupedByStatus';
export const LIST_GROUPED_BY_TEAMS = 'ListGroupedByTeams';
