import React from 'react';
import { Box, Table, TableBody, Typography } from '@mui/material';
import { ContractorKpiStatsPeriod } from '../../../../types/contractor';
import { PeriodsTableRow } from './PeriodTableRow';

interface Props {
  periods: ContractorKpiStatsPeriod[];
  showPeriodYear?: boolean;
  label?: string;
}

export const PeriodsTable = ({ periods, showPeriodYear, label }: Props) => {
  const periodrRows = periods.map((period, index) => (
    <PeriodsTableRow
      key={index}
      {...period}
      isLast={index === periods.length - 1}
      isFirst={index === 0}
      showPeriodYear={showPeriodYear}
    />
  ));

  return (
    <Box mt={4}>
      <Typography variant="body2" pb={0.5}>
        {label || 'Total payouts'}
      </Typography>
      <Table size="small">
        <TableBody>{periodrRows}</TableBody>
      </Table>
    </Box>
  );
};
