import { useMemo } from 'react';
import _uniqBy from 'lodash/uniqBy';
import { plural } from '../../utils';
import {
  DepartmentType,
  ContractorType,
  BaseKpiMetricModel,
  DepartmentMetricsModel,
  SharedKpiModel,
  UserSharedKpi,
} from '../../types';
import { KpiStatus } from '../../constants';

interface DepartmentBaseEntity {
  id: string;
  parent?: DepartmentBaseEntity;
}

interface DepartmentEntity extends DepartmentBaseEntity {
  value?: number | null;
  status: KpiStatus;
}

interface ValidationResult {
  notSubmitted: number;
  notApproved: number;
  isApproved: boolean;
  isReady: boolean;
}

function extractKpi(
  { status, value, kpiDefinition: { id } }: BaseKpiMetricModel,
  parent?: DepartmentBaseEntity,
): DepartmentEntity {
  return {
    id,
    status,
    value,
    parent,
  };
}

export function useCheckDepartments(
  departments: DepartmentType[],
): ValidationResult {
  return useMemo(() => {
    const list = extractEntities(departments);

    return list.reduce(
      (acum, entity) => ({
        ...acum,
        notSubmitted:
          entity.status !== KpiStatus.Approved &&
          entity.status !== KpiStatus.Submitted
            ? acum.notSubmitted + 1
            : acum.notSubmitted,
        notApproved:
          entity.status !== KpiStatus.Approved
            ? acum.notApproved + 1
            : acum.notApproved,
        isApproved: entity.status === KpiStatus.Approved && acum.isApproved,
        isReady:
          (entity.status === KpiStatus.Submitted ||
            entity.status === KpiStatus.Approved) &&
          acum.isReady,
      }),
      {
        notSubmitted: 0,
        notApproved: 0,
        isApproved: true,
        isReady: true,
      } as ValidationResult,
    );
  }, [departments]);
}

function extractSharedKpiStatus(userSharedKpis: UserSharedKpi[]): KpiStatus {
  const allApproved = userSharedKpis.every(
    (x) => x.status === KpiStatus.Approved,
  );
  if (allApproved) {
    return KpiStatus.Approved;
  }

  const allSubmitted = userSharedKpis.every(
    (x) => x.status === KpiStatus.Submitted || x.status === KpiStatus.Approved,
  );
  if (allSubmitted) {
    return KpiStatus.Submitted;
  }

  return KpiStatus.NotSubmitted;
}

function extractSharedKpi(
  shKpi: SharedKpiModel,
  parent?: DepartmentBaseEntity,
): DepartmentEntity {
  const {
    userSharedKpis,
    value,
    kpiDefinition: { id },
  } = shKpi;

  return {
    id,
    status: extractSharedKpiStatus(userSharedKpis),
    value,
    parent,
  };
}

function extractMember(
  { id, individualKpis, sharedKpis }: ContractorType,
  parent?: DepartmentBaseEntity,
): DepartmentEntity[] {
  const deepParent = {
    id,
    parent,
  };

  return [
    ...(individualKpis || []).reduce(
      (acum, kpi) => [...acum, extractKpi(kpi, deepParent)],
      [] as DepartmentEntity[],
    ),

    ...(sharedKpis || []).reduce(
      (acum, kpi) => [...acum, extractSharedKpi(kpi, deepParent)],
      [] as DepartmentEntity[],
    ),
  ];
}

function extractEntities(departments: DepartmentType[]): DepartmentEntity[] {
  return departments.reduce(
    (acum, { members, sharedKpis, subDepartments, id }) => {
      const parent = {
        id,
      };

      return [
        ...acum,

        ...extractEntities(subDepartments || []),

        ...(members || []).reduce(
          (acum, member): DepartmentEntity[] => [
            ...acum,
            ...extractMember(member, parent),
          ],
          [] as DepartmentEntity[],
        ),

        ...(sharedKpis || []).reduce(
          (acum, kpi): DepartmentEntity[] => [
            ...acum,
            extractSharedKpi(kpi, parent),
          ],
          [] as DepartmentEntity[],
        ),
      ];
    },
    [] as DepartmentEntity[],
  );
}

export function concatCardDescription({
  members,
  sharedKpis,
  subDepartments,
}: DepartmentType): string {
  const result = `${sharedKpis.length} shared metric${plural(
    sharedKpis.length,
  )}, ${subDepartments.length} sub-team${plural(subDepartments.length)}, ${
    members.length
  } people`;

  return result
    .replace(/(^|\s+)0[^,\d]+(,\s)?/gim, '$1')
    .replace(/(^|\s+)0[^,\d]+(,\s)?/gim, '$1')
    .replace(/,\s$/, '');
}

// sort by
function collectAllMetrics({
  subDepartments,
  members,
  sharedKpis,
}: DepartmentType): DepartmentMetricsModel {
  const metrics = (subDepartments || []).reduce(
    (acum, subDepartment) => {
      const subMetrics = collectAllMetrics(subDepartment);

      return {
        members: [...acum.members, ...subMetrics.members],
        sharedKpis: [...acum.sharedKpis, ...subMetrics.sharedKpis],
      };
    },
    {
      members: [],
      sharedKpis: [],
    } as DepartmentMetricsModel,
  );

  return {
    members: [...(members || []), ...metrics.members],
    sharedKpis: _uniqBy([...(sharedKpis || []), ...metrics.sharedKpis], 'id'),
  };
}

export function collectAllMetricsForRoot(
  deps: DepartmentType[],
): DepartmentType[] {
  return deps.map((department) => {
    const { members, sharedKpis } = collectAllMetrics(department);

    return {
      ...department,
      members,
      sharedKpis,
    };
  });
}

/**
 * @description
 *
 * @param departments - these departments being filtered are not necessarily root departments, they are just highest level departments
 *      of the list of 'departments'. So it user has 2 elements in 'departments' argument, this method will filter by on of `subDepartments'
 *      of 'departments' elements.
 * @param id
 * @returns
 */
export function filterRootDepartmentsById(
  departments: DepartmentType[],
  id: string,
): DepartmentType[] {
  return departments.map((department) => ({
    ...department,
    members: [],
    subDepartments: (department.subDepartments || []).filter(
      (subDepartment) => subDepartment.id === id,
    ),
  }));
}

export function extarctSubmittedKpiIds(
  departments: DepartmentType[],
): string[] {
  return departments.reduce((p, x) => {
    const ids = extarctKpiIdsFromDepartment(x);
    return [...p, ...ids];
  }, [] as string[]);
}

export function extarctKpiIdsFromDepartment(
  department: DepartmentType,
): string[] {
  return department.subDepartments.length === 0
    ? [
        ...department.members.flatMap((x) =>
          x.individualKpis
            .filter((x) => x.status === KpiStatus.Submitted)
            .flatMap((x) => x.id),
        ),
        ...department.sharedKpis.map((x) => x.id),
      ]
    : [
        ...department.members.flatMap((x) =>
          x.individualKpis
            .filter((x) => x.status === KpiStatus.Submitted)
            .flatMap((x) => x.id),
        ),
        ...department.sharedKpis.map((x) => x.id),
        ...extarctSubmittedKpiIds(department.subDepartments),
      ];
}

export function getTotalMetricsInDepartments(departments: DepartmentType[]) {
  const list = extractEntities(departments);

  return list.length;
}
