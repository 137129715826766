import React from 'react';
import { Box, Typography } from '@mui/material';

interface SummaryInfoProps {
  title: string;
  children?: React.ReactNode;
}

export const Info: React.FC<SummaryInfoProps> = ({ title, children }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: '1fr 1fr',
      }}
    >
      <Typography
        variant="subtitle1"
        fontWeight="normal"
        color="text.secondary"
        padding={'0px 5px'}
        noWrap
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};
