import { emptyValue, separators } from '../constants';

function toFixedComma(
  value?: string | number | null,
  fractionDigits = 2,
): string {
  const number = Number(value);

  if (value === null || isNaN(number)) {
    return emptyValue;
  }

  return number.toFixed(fractionDigits);
}

export function numberFormat(
  number?: string | number | null,
  separator = separators.comma,
): string {
  return toFixedComma(number)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separator}`)
    .replace(/(\..+)\s+/g, '$1')
    .toString();
}

export function toIntegerFormat(number?: number | string | null): string {
  return toFixedComma(number, 0);
}

export function customNumberFormat(value: null | string): string | number {
  if (!value) {
    return '';
  }

  const parts = value.split(separators.dot);

  const int = Number(parts[0].slice(0, 6));

  if (parts.length === 1) {
    return int;
  }

  const fraction = parts[1] && parts[1].slice(0, 2).replace(/\D+/g, '');
  const res = [int, fraction].join(separators.dot);

  if (isNaN(Number(fraction))) {
    return res;
  }

  return parseFloat(res);
}
