import React, { FC, useCallback, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { separators } from '../../../../../constants';
import InputAdornment from '@mui/material/InputAdornment';
import { NumericFormat, useNumericFormat } from 'react-number-format';
import { Theme } from '@mui/material';

let isFocussed = false;
export const HistoricalDataNumberField: FC<{
  id: string;
  name: string;
  value: any;
  handleChange: any;
  disabled: boolean;
  isPercentage?: boolean;
  formatProps?: {
    maxLength: number;
  };
}> = ({
  id,
  name,
  value,
  handleChange,
  disabled,
  isPercentage,
  formatProps,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { removeFormatting } = useNumericFormat({
    maxLength: formatProps?.maxLength,
    thousandSeparator: separators.comma,
    autoComplete: 'off',
    allowNegative: false,
  });

  const onPasteHandler = useCallback<
    React.ClipboardEventHandler<HTMLDivElement>
  >(
    (e) => {
      if (!removeFormatting) return;
      handleChange({
        fieldName: name,
        value: removeFormatting(e.clipboardData.getData('text')),
      });
    },
    [name, handleChange, removeFormatting],
  );

  return (
    <NumericFormat
      id={id}
      name={name}
      customInput={TextField}
      InputProps={{
        startAdornment: !!name.includes('bonusShare') && (
          <InputAdornment position="start">$</InputAdornment>
        ),
        endAdornment: !!isPercentage && (
          <InputAdornment position="end">%</InputAdornment>
        ),
        size: 'small',
        sx: {
          maxWidth: (theme: Theme) => theme.spacing(11),
          minWidth: (theme: Theme) => theme.spacing(9.5),
          textAlign: 'right',
        },
      }}
      value={value}
      variant="filled"
      hiddenLabel
      disabled={disabled}
      inputRef={inputRef}
      // Should be same (for equal UX) in the 'useNumericFormat' too
      inputProps={{ maxLength: formatProps?.maxLength }}
      thousandSeparator={separators.comma}
      autoComplete="off"
      allowNegative={false}
      //
      onClick={() => {
        const element: any = inputRef.current;
        if (!isFocussed) {
          element.setSelectionRange(0, element.value.length);
        }
      }}
      onFocus={() => {
        setTimeout(() => {
          isFocussed = true;
        }, 500);
      }}
      onBlur={() => {
        isFocussed = false;
      }}
      onValueChange={({ floatValue }) => {
        handleChange({ fieldName: name, value: floatValue });
      }}
      onPaste={onPasteHandler}
    />
  );
};
