import { projectStatusKey } from './projectStatus.const';
import { AppStateWithProjectStatusData as State } from './projectStatus.types';

const select = (state: State) => state[projectStatusKey];

export const getProjectAssessments = (state: State) =>
  select(state).assessments;
export const getCurrentProjectId = (state: State) => select(state).projectId;

export const getStatusReportsFolderLink = (state: State) =>
  select(state).statusReportsFolderLink;

export const getError = (state: State) => select(state).error;
export const getLoading = (state: State) => select(state).loading;
