import React from 'react';
import { FallbackWrapper } from '../../utils/errorPage.styles';
import { FallbackComponent } from '../../components/fallbackComponent/FallbackComponent';
import { ServerStatusCode } from '../../constants';

interface Props {
  showFullPage?: boolean;
}

export const NotFound = ({ showFullPage = false }: Props): JSX.Element => {
  return showFullPage ? (
    <FallbackWrapper>
      <FallbackComponent
        fullPage
        showBrandLogo
        statusCode={ServerStatusCode.NOT_FOUND}
      />
    </FallbackWrapper>
  ) : (
    <FallbackComponent statusCode={ServerStatusCode.NOT_FOUND} />
  );
};
