import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusReportState } from './statusReport.types';
import { statusReportStoreKey } from './statusReport.const';
import { fetchStatusReport } from './statusReport.thunks';

const initialState: StatusReportState = {
  isLoading: false,
  error: null,
  statusReportLink: '',
};

export const statusReportSlice = createSlice({
  name: statusReportStoreKey,
  initialState,
  reducers: {
    clearStatusReport: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatusReport.pending, (state) => {
        state.isLoading = true;
        state.error = initialState.error;
      })
      .addCase(
        fetchStatusReport.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.isLoading = false;
          state.statusReportLink = action.payload;
        },
      )
      .addCase(fetchStatusReport.rejected, (state) => {
        state.isLoading = false;
        state.error = new Error('Oops. Something went wrong. :(');
      });
  },
});

export const { clearStatusReport } = statusReportSlice.actions;
