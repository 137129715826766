const kpiAssignmentsKey = '/user-kpi-assignment';

export const kpiAssignments = () => {
  return kpiAssignmentsKey;
};

export const allKpiAssignmentsForUser = (userId: string) => {
  return `${kpiAssignmentsKey}/user/${userId}`;
};

export const sharedResultsForAssignment = (kpiId: string) => {
  return `${kpiAssignmentsKey}/shared-results/${kpiId}`;
};

export const bonusPlanUpdateSnippet = () => {
  return `${kpiAssignmentsKey}/updatedBonusPlan`;
};
