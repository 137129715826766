import React, { useState } from 'react';
import { IconButton, TableRow } from '@mui/material';
import { CustomTableCell } from '../../../../components/customTable';
import { priceFormat } from '../../../../components/tableCellRenders';
import {
  BonusEntry,
  ContractorKpiStatsPeriod,
} from '../../../../types/contractor';
import {
  cn,
  formatYYYYMMDateToMMMMyyString,
  formatYYYYMMDateToYString,
} from '../../../../utils';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

interface Props extends ContractorKpiStatsPeriod {
  isLast: boolean;
  isFirst: boolean;
  showPeriodYear?: boolean;
  prompt?: string;
  bonusDetails?: BonusEntry[];
}

export const PeriodsTableRow = ({
  period,
  payout,
  prompt,
  isLast,
  isFirst,
  showPeriodYear,
  bonusDetails,
}: Props) => {
  {
    const borderClassName = isLast ? '' : 'with-border';

    const periodLabel = showPeriodYear
      ? formatYYYYMMDateToYString(period)
      : formatYYYYMMDateToMMMMyyString(period, true);

    const isBonusPlanTable = !!bonusDetails;

    const [showBonusDetails, toggleShowBonusDetails] = useState(false);

    const labelColumnWidth = 110;
    const valueColumnWidth = 75;

    return (
      <>
        <TableRow
          className={cn(
            isLast && 'space-bottom no-border',
            isFirst && !isBonusPlanTable && 'space-top',
            'no-height',
            isBonusPlanTable && 'expandable',
          )}
          onClick={() => {
            isBonusPlanTable && toggleShowBonusDetails(!showBonusDetails);
          }}
        >
          <CustomTableCell
            width={labelColumnWidth}
            className={borderClassName}
            typographyVariant="body1"
            sx={{
              whiteSpace: 'nowrap',
              px: 0.75,
              py: 0.25,
              position: 'relative',
            }}
            align={isBonusPlanTable ? 'right' : 'inherit'}
          >
            {periodLabel}
            {isBonusPlanTable && !!bonusDetails.length && (
              <IconButton
                aria-label="Expand/collapse icon"
                size="small"
                sx={{
                  transform: 'scale(0.75)',
                  opacity: 0.5,
                  my: -0.5,
                }}
              >
                {showBonusDetails ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            )}
          </CustomTableCell>
          <CustomTableCell
            width={valueColumnWidth}
            className={borderClassName}
            typographyVariant="body1"
            sx={{
              whiteSpace: 'nowrap',
              px: 0.25,
              py: 0.25,
            }}
          >
            {priceFormat({ value: payout })}
            {prompt && <b>{'     ' + prompt}</b>}
          </CustomTableCell>
        </TableRow>
        {isBonusPlanTable &&
          showBonusDetails &&
          bonusDetails.map((bd) => (
            <TableRow
              key={`${bd.assignmentName}${bd.period}`}
              aria-label="collapsible table"
              className={cn('no-height')}
            >
              <CustomTableCell
                className={borderClassName}
                typographyVariant="body2"
                width={labelColumnWidth}
                sx={{ pl: 0.75 }}
                align="right"
              >
                {bd.assignmentName}
              </CustomTableCell>
              <CustomTableCell
                className={borderClassName}
                typographyVariant="body2"
                width={valueColumnWidth}
                sx={{
                  whiteSpace: 'nowrap',
                  px: 0.25,
                  verticalAlign: 'top',
                }}
              >
                {priceFormat({ value: bd.bonusShare })}
              </CustomTableCell>
            </TableRow>
          ))}
      </>
    );
  }
};
