import React, { Fragment } from 'react';
import Paper from '@mui/material/Paper';
import { Typography, Box } from '@mui/material';
import { ContractorKpiStats } from '../../../../types/contractor';
import { priceFormat } from '../../../../components/tableCellRenders';
import { FilterPeriodModel, FilterPeriodType } from '../../Contractor.types';
import { PeriodsTable } from './PeriodsTable';
import { groupAllTimePeriods, sortPeriods } from './PeriodDataWidget.utils';

interface Props extends Partial<ContractorKpiStats> {
  filteredPeriod: FilterPeriodModel;
  hidePaper?: boolean;
}

export const PeriodDataWidget = ({
  filteredPeriod,
  total,
  periods,
  hidePaper = false,
}: Props) => {
  const totalLabel = `${filteredPeriod.subtext ?? filteredPeriod.label} total`;

  const Wrapper = hidePaper ? Fragment : Paper;

  const groupPeriodsByYear = filteredPeriod.value === FilterPeriodType.AllTime;
  const groupedPeriods = groupPeriodsByYear
    ? groupAllTimePeriods(periods)
    : periods;

  const sortedPeriods = sortPeriods(groupedPeriods);

  const wrapperProps = hidePaper
    ? {}
    : { sx: { width: '240px', px: 6, py: 2 } };

  return (
    <Wrapper {...wrapperProps}>
      <Box>
        <Typography variant="body2" pb={0.5}>
          {totalLabel}
        </Typography>
        <Typography variant="h2">{priceFormat({ value: total })}</Typography>
      </Box>
      {sortedPeriods && !!sortedPeriods.length && (
        <PeriodsTable
          periods={sortedPeriods}
          showPeriodYear={groupPeriodsByYear}
        />
      )}
    </Wrapper>
  );
};
