import { ServerStatusCode } from '../../constants';
import { Box, Typography, Card } from '@mui/material';
import React from 'react';
import { fallbackErrorStates } from './FallbackComponent.const';
import { FallbackErrorState } from './FallbackComponent.types';
import disabledLogo from '../../assets/disabledLogo.svg';

interface Props {
  fullPage?: boolean;
  statusCode?: ServerStatusCode;
  showBrandLogo?: boolean;
}

const Content = ({
  state: argState,
  showBrandLogo,
}: {
  state?: FallbackErrorState;
  showBrandLogo: boolean;
}) => {
  const state = argState ?? fallbackErrorStates[0];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        height: showBrandLogo ? 'calc(100% - 170px)' : '100%',
        boxSizing: 'border-box',
        img: {
          width: 164,
          height: 164,
        },
      }}
      p={3}
    >
      {state.img}
      <Typography variant="h2" color="text.secondary" pt={1}>
        {state.title}
      </Typography>
      <Typography variant="body2" color="text.secondary" pt={1}>
        {state.subTitle}
      </Typography>
      {state.description && (
        <Typography variant="body2" color="text.secondary" pt={4}>
          {state.description}
        </Typography>
      )}

      {showBrandLogo && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
          }}
        >
          <img src={disabledLogo} alt="KPI Bonuses" />
        </Box>
      )}
    </Box>
  );
};

export function FallbackComponent({
  fullPage = false,
  statusCode,
  showBrandLogo = false,
}: Props) {
  const state = statusCode && fallbackErrorStates[statusCode];

  if (fullPage) {
    return (
      <Box sx={{ width: '100%', height: 'calc(100vh - 16px)' }}>
        <Content state={state} showBrandLogo={showBrandLogo} />
      </Box>
    );
  }

  return (
    <Card sx={{ height: '100%' }} variant="outlined">
      <Content state={state} showBrandLogo={showBrandLogo} />
    </Card>
  );
}
