import * as yup from 'yup';

export const validationSchema = yup
  .object({
    feedback: yup.string().required(),
    page: yup.string().required(),
  })
  .strict();

export const reportIncorrectSchemaValidationSchema = yup.object({
  incorrectFields: yup
    .array()
    .required('Please select at least one value.')
    .min(1, 'Please select at least one value.'),

  newFeedbackValue: yup.string().required('Cannot be emptyy'),
});
