import { KpiMetricsState } from './kpiMetrics.slice';
import { KpiMetricType } from '../../types/kpiMetric';
import { kpiMetricsStoreKey } from './kpiMetrics.const';

interface State {
  [kpiMetricsStoreKey]: KpiMetricsState;
}

const selectKpiMetricsState = (state: State) => {
  return state[kpiMetricsStoreKey];
};

const getKpiMetricsApiStatus = (state: State): string => {
  return selectKpiMetricsState(state).apiStatus;
};

const getKpiMetricsList = (state: State): KpiMetricType[] => {
  return selectKpiMetricsState(state).kpiMetricsList;
};

export const kpiMetricsSelectors = {
  getKpiMetricsApiStatus,
  getKpiMetricsList,
};
