export const REPORT_KEYS = {
  bonus: 'Bonus',
  trueUp: 'True Up Correction',
  finalBonus: 'Final Bonus',
  bonusType: 'Bonus type',
  department: 'Department',
  fullName: 'Full name',
  metricName: 'Metric',
  location: 'Location',
  period: 'Month Year',
  city: 'City office',
  frequency: 'Frequency',
};
