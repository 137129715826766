import { yellow, red, green, grey } from '@mui/material/colors';
import { AssessmentsScoreColorEnum } from '../features/globalTracker/types';

export const statusColors: [string, string, string, string] = [
  red[400],
  yellow[700],
  green[500],
  grey[300],
];

export const NOT_DEFINED_SCORE_VALUE = 3;

export const SCORE_TO_COLOR: {
  [key: number]: string;
} = {
  [AssessmentsScoreColorEnum.GREEN]: 'green',
  [AssessmentsScoreColorEnum.YELLOW]: 'yellow',
  [AssessmentsScoreColorEnum.RED]: 'red',
  [NOT_DEFINED_SCORE_VALUE]: 'No status provided',
};

export const LOOKER_REPORT =
  'https://lookerstudio.google.com/u/0/reporting/5ed4a040-2d48-4d8f-b0ab-6aad8bc478cc';
