import React, { FC, useEffect, useMemo } from 'react';
import { PageWrapper } from '../../components';
import { paths } from '../../constants/paths';
import { MyPortfolioHeader } from './components/MyPortfolioHeader';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  fetchAccount,
  getDateFilterValue,
  getDateFilterValueLocal,
  getLoading,
  getSelectedAccount,
  getSelectedProject,
  resetEditableMode,
  setSelectedProjectId,
} from '../../redux/account';
import { getMyPortfolioBreadcrumbs } from '../../features/budgets/Budgets.utils';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import { Outlet, useParams } from 'react-router-dom';
import { MyPortfolioHeaderForDetails } from './components/MyPortfolioHeaderForDetails';
import { Stack, styled } from '@mui/material';
import { cleanTimeAndPeople } from '../../redux/timeAndPeople';

export const MyPortfolio: FC = () => {
  const { accountId, projectId } = useParams();
  const dispatch = useAppDispatch();

  const dateFilterValueLocal = useAppSelector(getDateFilterValueLocal);
  const loading = useAppSelector(getLoading);
  const account = useAppSelector(getSelectedAccount);
  const project = useAppSelector(getSelectedProject);
  const dateFilterValue = useAppSelector(getDateFilterValue);

  const breadcrumbs = useMemo(
    () => (account ? getMyPortfolioBreadcrumbs(account, project) : []),
    [project, account],
  );

  const isProjectView = useMemo(
    () => Boolean(projectId && project),
    [projectId, project],
  );

  useEffect(() => {
    dispatch(resetEditableMode());
    if (accountId) {
      if (projectId) dispatch(setSelectedProjectId({ projectId }));
      else dispatch(setSelectedProjectId({ projectId: null }));

      const from = dateFilterValue;
      const to = dateFilterValue;

      dispatch(
        fetchAccount({
          accountId,
          fetchAllMembers: true,

          ...(from &&
            to && {
              periodFiltering: {
                from,
                to,
              },
            }),
        }),
      );
    }
  }, [accountId, dispatch, dateFilterValue, projectId]);

  useEffect(() => {
    return () => {
      dispatch(cleanTimeAndPeople());
    };
  }, [dispatch]);

  const StyledMyPortfolioStack = styled(Stack)`
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 0px;
    border-radius: ${({ theme }) => theme.spacing(1)};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `;

  return (
    <PageWrapper isLoading={loading} maxWidth={'1500px'}>
      <Breadcrumbs
        showPageLink={true}
        breadcrumbs={breadcrumbs}
        rootPath={paths.portfolio}
        pageName={'Portfolio'}
      />
      {account && (
        <>
          <MyPortfolioHeader
            account={project ? project : account}
            dateFilterValueLocal={dateFilterValueLocal}
          />
          <StyledMyPortfolioStack>
            <MyPortfolioHeaderForDetails
              account={account}
              projects={project ? [project] : account.projects}
              isProjectView={isProjectView}
            />
          </StyledMyPortfolioStack>
          <Outlet />
        </>
      )}
    </PageWrapper>
  );
};
