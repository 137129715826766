import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants/api';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { periodsStoreKey } from './periods.const';

export const fetchPeriods = createAsyncThunk(
  `${periodsStoreKey}/fetchPeriods`,
  async (_, thunkAPI) => {
    try {
      const periodsResponse = await axios.get(apiEndpoints.periods());
      const currentPeriodResponse = await axios.get(
        apiEndpoints.currentPeriod(),
      );

      return {
        periodsList: periodsResponse.data,
        currentPeriod: currentPeriodResponse.data,
      };
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err, 'Fetching periods failed'), {
          variant: 'error',
        }),
      );
      throw new Error(String(err));
    }
  },
);

export const fetchCheckApproved = createAsyncThunk(
  `${periodsStoreKey}/fetchCheckApproved`,
  async () => {
    try {
      const response = await axios.get(apiEndpoints.checkApproved());
      return response.data;
    } catch (err) {
      throw new Error(String(err));
    }
  },
);

export const closeCurrentPeriod = createAsyncThunk(
  `${periodsStoreKey}/closeCurrentPeriod`,
  async (_, thunkAPI) => {
    try {
      const response = await axios.post(apiEndpoints.closeCurrentPeriod());
      thunkAPI.dispatch(fetchPeriods());
      thunkAPI.dispatch(notificationAlert('Current period closed'));
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);
