import { MarginReportState } from './marginReports.types';
import { marginReportsStoreKey } from './marginReports.const';
import {
  MarginReportAccount,
  MarginReportResponseDto,
} from '../../types/marginReports';

interface AppStateWithMarginReport {
  [marginReportsStoreKey]: MarginReportState;
}

const selectMarginReportState = (state: AppStateWithMarginReport) => {
  return state[marginReportsStoreKey];
};

const getMarginReport = (
  state: AppStateWithMarginReport,
): MarginReportResponseDto | null => {
  return selectMarginReportState(state).marginReport;
};

const getReportApiStatus = (state: AppStateWithMarginReport): string => {
  return selectMarginReportState(state).fetchReportStatus;
};

const getAccountApiStatus = (state: AppStateWithMarginReport): string => {
  return selectMarginReportState(state).fetchAccountStatus;
};
const getAccounts = (
  state: AppStateWithMarginReport,
): MarginReportAccount[] => {
  return selectMarginReportState(state).accounts;
};

export const marginReportsSelectors = {
  getMarginReport,
  getReportApiStatus,
  getAccountApiStatus,
  getAccounts,
};
