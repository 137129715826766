import React, { useCallback, useState } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DropdownMenuOption, DropdownMenuOptionsProps } from './types';

export const DropdownMenu = <T,>({
  label,
  options,
  onClick,
  selectedItem,
}: DropdownMenuOptionsProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { palette } = useTheme();

  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleOnClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleOnSelect = useCallback(
    (option: DropdownMenuOption<T>) => {
      handleOnClose();
      onClick(option);
    },
    [handleOnClose, onClick],
  );

  return (
    <Stack>
      <Button
        size="small"
        variant="text"
        qa-id="dropdown-menu-button"
        onClick={handleOnClick}
        endIcon={<KeyboardArrowDownIcon sx={{ color: palette.text.primary }} />}
      >
        <Typography noWrap variant="subtitle1" color="text.primary">
          {selectedItem ? `${label}: ${selectedItem.label}` : label}
        </Typography>
      </Button>
      <Menu
        qa-id="dropdown-menu"
        open={open}
        onClose={handleOnClose}
        anchorEl={anchorEl}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            sx={{ width: '226px' }}
            onClick={() => {
              handleOnSelect(option);
            }}
          >
            <Typography>{option.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};
