import { is } from '../utils';

interface CookieOptions {
  domain?: string;
  path?: string;
  maxAge?: number;
}

const defaultOptions = {
  domain: `${window.location.host}`,
  path: '/',
  maxAge: 60 * 60 * 24 * 30, // 30 days
};

export class CookieService {
  private static escapeCookieName(str: string): string {
    return str.replace(/([.*+?^${}()|[\]/\\])/g, '\\$1');
  }

  private static matchCookieRegex(name: string): RegExp {
    return RegExp(`(?:^|;\\s*)${CookieService.escapeCookieName(name)}=([^;]*)`);
  }

  public static setCookie = (
    name: string,
    value: string,
    options?: CookieOptions,
  ): string => {
    const maxAge = options?.maxAge || defaultOptions.maxAge;
    const domain = options?.domain || defaultOptions.domain;
    const path = options?.path || defaultOptions.path;

    document.cookie = is.localhost
      ? `${name}=${value}; max-age=${maxAge}; path=${path}`
      : `${name}=${value}; max-age=${maxAge}; secure=true; domain=${domain}; path=${path}`;

    return value;
  };

  public static getCookie(name: string): string {
    const match = document.cookie.match(CookieService.matchCookieRegex(name));
    return match ? match[1] : '';
  }

  public static deleteCookie(name: string, options?: CookieOptions): void {
    const path = options?.path || defaultOptions.path;
    const domain = options?.domain || defaultOptions.domain;

    document.cookie = is.localhost
      ? `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=${path}`
      : `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${domain}; path=${path}`;
  }

  public static decodeJsonCookieValue<T = unknown>(name: string): T | null {
    const encodedValue = CookieService.getCookie(name);

    const decodedValue = decodeURIComponent(encodedValue);

    if (!decodedValue) {
      return null;
    }

    const jsonString = `{${decodedValue.replace(/(^.*{)|(}.*$)/gi, '')}}`;

    return JSON.parse(jsonString);
  }
}
