import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import emptyMetricsLogo from '../../../../assets/emptyMetrics.svg';
import emptyMetricsAllTimeLogo from '../../../../assets/emptyMetricsAllTime.svg';

interface Props {
  isAllTime: boolean;
  name: string;
  selfUser?: boolean;
}

export const ContractorEmptyState = ({
  isAllTime,
  name,
  selfUser = false,
}: Props) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: 'inherit',
        minHeight: 400,
      }}
    >
      <Box>
        <img
          src={isAllTime ? emptyMetricsAllTimeLogo : emptyMetricsLogo}
          alt={'no results'}
        />
        <Typography variant="h2" color="text.secondary" mt={2}>
          {`${
            selfUser ? `You don’t` : `${name} doesn’t`
          } have any metrics assigned${
            isAllTime ? '' : ' during selected period'
          }`}
        </Typography>
      </Box>
    </Paper>
  );
};
