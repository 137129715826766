import { createAsyncThunk } from '@reduxjs/toolkit';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { accountsStatusKey } from './accountsStatus.const';
import axios from 'axios';
import { apiEndpoints } from '../../constants/api';
import { AccountsStatus } from '../../features/globalTracker/types';

export const fetchAccountsStatus = createAsyncThunk(
  `${accountsStatusKey}/fetchAccountsStatus`,
  async (_, thunkAPI): Promise<AccountsStatus[]> => {
    try {
      const response = await axios.get(`${apiEndpoints.accountsStatus()}`);
      const accountsStatus = response.data.accounts;
      // TODO: Sort clients by general status when the latestGeneralAssessment value is returning.
      // Get the sortDataByGeneralStatus function from '../../features/globalTracker/utils'.
      // Example:
      // return sortDataByGeneralStatus(accountsStatus) as AccountsStatus[];

      return accountsStatus;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(
          formatErrorMessage(err, 'Fetching account has failed'),
          {
            variant: 'error',
          },
        ),
      );
      throw new Error(String(err));
    }
  },
);
