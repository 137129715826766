import { DepartmentBaseType } from '../../types';
import { rootDepartmentsStoreKey } from './rootDepartments.const';
import { RootDepartmentsState } from './rootDepartments.slice';

interface State {
  [rootDepartmentsStoreKey]: RootDepartmentsState;
}

const selectRootDepartmentsState = (state: State) => {
  return state[rootDepartmentsStoreKey];
};

const getRootDepartmentsApiStatus = (state: State): string => {
  return selectRootDepartmentsState(state).apiStatus;
};

const getRootDepartmentsList = (state: State): DepartmentBaseType[] => {
  return selectRootDepartmentsState(state).rootDepartments;
};

const getFinanceDepartmentId = (state: State): string => {
  return selectRootDepartmentsState(state).financeDepartmentId;
};

export const rootDepartmentsSelectors = {
  getRootDepartmentsApiStatus,
  getRootDepartmentsList,
  getFinanceDepartmentId,
};
