import React, { FC } from 'react';
import { AccountStatus, BaseAccount } from '../../../types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  accountSlice,
  getEditableMode,
  getGroupBy,
  getSortBy,
} from '../../../redux/account';
import { Stack, Tooltip, Typography } from '@mui/material';
import { DropdownMenu } from '../../../components/dropdown';
import CustomDatePicker from '../../../components/datePicker/CustomDatePicker';
import {
  groupAccountMembersOptions,
  sortOptionsForClientPartner,
} from '../../../features/budgets/BudgetDetails.const';
import TabSwitcher from '../../../components/tabSwitcher/TabSwitcher';
import { paths } from '../../../constants/paths';
import { useLocation, useParams } from 'react-router-dom';
import WorkOffOutlinedIcon from '@mui/icons-material/WorkOffOutlined';

interface Props {
  account: BaseAccount;
  dateFilterValueLocal: string | null;
}

export const MyPortfolioHeader: FC<Props> = ({
  account,
  dateFilterValueLocal,
}) => {
  const dispatch = useAppDispatch();
  const groupBy = useAppSelector(getGroupBy);
  const sortBy = useAppSelector(getSortBy);
  const isEditableMode = useAppSelector(getEditableMode);
  const { accountId, projectId } = useParams();

  const baseUrl = `${paths.portfolio}/${accountId}/${
    projectId ? projectId + '/' : ''
  }`;
  const location = useLocation();

  return (
    <Stack flexDirection="row" alignItems="center" mb={2} minHeight="40px">
      {account.status === AccountStatus.Closed && (
        <Tooltip
          title="Closed"
          color="disabled"
          placement="top"
          sx={{ marginRight: '5px' }}
        >
          <WorkOffOutlinedIcon />
        </Tooltip>
      )}
      <Typography
        variant="h2"
        mx="2"
        marginRight="16px"
        maxWidth="300px"
        color={account.status === AccountStatus.Closed ? 'gray' : 'black'}
      >
        {account?.name}
      </Typography>
      <TabSwitcher
        tabs={[
          {
            linkTo: baseUrl + paths.projectStatus,
            linkName: projectId ? 'Project status' : 'Project statuses',
          },
          {
            linkTo: baseUrl + paths.ratesAndMargins,
            linkName: 'Rates and margins',
          },
          {
            linkTo: baseUrl + paths.timeAndPeople,
            linkName: 'Time and people',
          },
          {
            linkTo: baseUrl + paths.risks,
            linkName: 'Risks',
          },
        ]}
      />
      {location.pathname.includes(paths.ratesAndMargins) ? (
        <Stack flexDirection="row" gap={3} ml="auto">
          {!isEditableMode && (
            <DropdownMenu
              label="Group by"
              options={groupAccountMembersOptions}
              selectedItem={{ label: groupBy, value: groupBy }}
              onClick={({ value }) =>
                dispatch(accountSlice.actions.setGroupBy(value))
              }
            />
          )}
          <DropdownMenu
            label="Sort by"
            options={sortOptionsForClientPartner}
            selectedItem={{ label: sortBy, value: sortBy }}
            onClick={({ value }) =>
              dispatch(accountSlice.actions.setSortBy({ sortBy: value }))
            }
          />
          {/* Date picker button */}
          <CustomDatePicker
            setDateFilter={{
              type: 'dispatch',
              func: accountSlice.actions.setDateFilterValueLocal,
              setGlobalFunc: accountSlice.actions.setDateFilterValue,
              clean: accountSlice.actions.cleanDateFilterValues,
            }}
            dateFilterValueLocal={dateFilterValueLocal}
          />
        </Stack>
      ) : null}
    </Stack>
  );
};
