import { DEFAULT_ERROR_MESSAGE } from '../constants';
import { notificationAlert } from '../redux/notifications';
import { Dispatch } from 'redux';

export const formatErrorMessage = (error: any, name?: string): string => {
  let errorMessage = `${name ?? DEFAULT_ERROR_MESSAGE} \n`;

  if (error?.response?.data?.data?.length > 0) {
    errorMessage = error.response.data.data.reduce(
      (acc: string, errObj: { description: string }) => {
        acc += `${errObj.description}\n`;
        return acc;
      },
      '',
    );
  } else if (error?.response?.data?.description) {
    errorMessage = error.response.data.description;
  } else if (error?.response?.data?.message) {
    errorMessage += error.response.data.message;
  } else if (error?.message) {
    errorMessage += error.message;
  }

  return errorMessage;
};

export const errorAbortHandler = async <T>(
  callback: () => T,
  options: {
    signal?: AbortSignal;
    errMsgOverride?: string;
    dispatch: Dispatch;
  },
) => {
  const { signal, dispatch } = options;

  try {
    return await callback();
  } catch (err) {
    if (signal && signal.aborted) return;

    dispatch &&
      dispatch(
        notificationAlert(
          options.errMsgOverride ||
            formatErrorMessage(err, DEFAULT_ERROR_MESSAGE),
          {
            variant: 'error',
          },
        ),
      );
    throw new Error(String(err));
  }
};
