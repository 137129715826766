import { TableSortLabel, styled } from '@mui/material';

export const AbsoluteTableSortLabel = styled(TableSortLabel)`
  .MuiTableSortLabel-icon {
    position: absolute;
    right: ${({ theme }) => theme.spacing(-2.8)};
    width: ${({ theme }) => theme.spacing(2)};
    height: ${({ theme }) => theme.spacing(2)};
  }
`;
