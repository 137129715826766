const rootKey = '/accounts';

export const fetchAccounts = () => {
  return `${rootKey}/fetchAccounts`;
};

export const accounts = () => {
  return rootKey;
};

export const accountMargins = (accountId: string) => {
  return `${rootKey}/${accountId}/margins`;
};

export const accountMarginReport = () => {
  return `${rootKey}/report`;
};

export const accountsStatus = () => {
  return `${rootKey}/status`;
};

export const saveLink = () => {
  return `${rootKey}/saveLinks`;
};
